import React from 'react';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input from '../Input/Input';

import './ProfilePhotoInput.scss';

const profilePhotoLabel = 'Profile Photo Image';
const profilePreview = 'Profile preview';

const ProfilePhotoPreview = ({ src }) => {
  return src ? (
    <img src={src} className="profilePhotoImage" alt={profilePreview} />
  ) : null;
};

const ProfilePhotoInput = ({ value, onChange }) => {
  const profilePhotoImgUrl = value
    ? `${process.env.REACT_APP_UPLOADS_URL}/${value}`
    : '';

  const openPreview = () => {
    if (profilePhotoImgUrl) window.open(profilePhotoImgUrl);
  };

  const removeFile = (event) => {
    event.preventDefault();
    onChange(null);
  };

  return (
    <div className="d-flex align-items-center">
      <Col xs={4} lg={3} className="m-2 d-flex">
        <div
          className="profilePhotoImageContainer"
          onClick={() => openPreview()}
        >
          <ProfilePhotoPreview src={profilePhotoImgUrl} />
        </div>
        {profilePhotoImgUrl && (
          <div
            className="removeThumb"
            onClick={(event) => {
              removeFile(event);
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faTimes} />
          </div>
        )}
      </Col>
      <Col lg={6} className="d-flex flex-column my-2">
        <Input
          label={profilePhotoLabel}
          elementType="image"
          containerClasses="Input--button Input--m-0"
          additionalClasses="btnDefault--medium"
          fileChange={onChange}
          isProfileImg
        />
      </Col>
    </div>
  );
};

ProfilePhotoInput.defaultProps = {
  value: '',
};

ProfilePhotoInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ProfilePhotoInput;
