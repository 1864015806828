import React from 'react';

import ProviderForm from '../ProviderForm/ProviderForm';
import Modal from '../../UI/Modal/Modal';
import './AddProviderModal.scss';

const AddProviderModal = ({ show, hideModal, action, setToast }) => {
  if (!show) {
    return null;
  }
  return (
    <Modal
      dialogClassName="add-provider-modal"
      title="Create New Offer"
      body={
        <ProviderForm
          setToast={setToast}
          onSubmitForm={async (data) => action(data)}
          onCancelForm={hideModal}
        />
      }
      handleClose={hideModal}
      size="md"
      show
      dataTestAttribute={{
        modal: 'add-video-provider-modal',
        action2: 'add-video-provider-modal-close',
      }}
    />
  );
};
export default AddProviderModal;
