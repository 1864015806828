import _cloneDeep from 'lodash/cloneDeep';
import controls from './controls';

/*
 * Used to delete and limit form fields and add select options data
 * All user access logic should be contained in this function
 */
export const getControls = ({
  id,
  role = 'ROLE_ADVERTISER',
  oldRole,
  previousControls,
  isAdmin,
  isBountyAdmin,
  companyOptions,
  brandOptions,
  editingSelf,
}) => {
  const updatedControls = _cloneDeep(controls);
  for (const key of Object.keys(updatedControls)) {
    if (previousControls?.[key]) {
      updatedControls[key] = previousControls[key];
    }

    if (key === 'company') {
      const { company } = updatedControls;
      company.elementConfig.options = companyOptions || [];
    }

    if (key === 'brand') {
      const { brand } = updatedControls;
      brand.elementConfig.options = brandOptions || [];
    }
  }

  /*
   * Only allow 'ROLE_ADMIN' & 'ROLE_BOUNTY_ADMIN' to edit roles
   * Disallow 'ROLE_BOUNTY_ADMIN' to edit the role of a 'ROLE_ADMIN'
   * Disallow user to edit own role
   */
  if (
    (!isAdmin && !isBountyAdmin) ||
    (oldRole === 'ROLE_ADMIN' && !isAdmin) ||
    editingSelf
  ) {
    delete updatedControls.role;
  }

  if (!isAdmin) {
    delete updatedControls.emailOtp;
  }

  // Remove 'ROLE_ADMIN' option from roles when user is a 'ROLE_BOUNTY_ADMIN'
  if (isBountyAdmin) {
    if (updatedControls.role) {
      const elementConfig = updatedControls.role.elementConfig;
      const adminRoleIdx = elementConfig.options.findIndex(
        (r) => r?.value === 'ROLE_ADMIN',
      );
      if (adminRoleIdx !== -1) {
        elementConfig.options.splice(adminRoleIdx, 1);
      }
    }
  }

  if (!id) {
    delete updatedControls.password;
    delete updatedControls.repeatPassword;
    delete updatedControls.phoneNumber;
    delete updatedControls.role;
    delete updatedControls.profileImgUrl;
    delete updatedControls.emailOtp;
  }

  if (role === 'ROLE_ADMIN' || role === 'ROLE_BOUNTY_ADMIN') {
    delete updatedControls.company;
    delete updatedControls.brand;
  }

  if (role === 'ROLE_COMPANY_ADMIN' || role === 'ROLE_COMPANY_USER') {
    delete updatedControls.brand;
  }

  if (role === 'ROLE_ADVERTISER' && editingSelf) {
    delete updatedControls.company;
    delete updatedControls.brand;
  }

  if (oldRole && !editingSelf) {
    delete updatedControls.profileImgUrl;
  }

  return updatedControls;
};
