import React, { useState, useReducer, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { debounce } from 'lodash';

import * as actions from '../../store/actions/index';

import ProviderService from '../../api/Providers';
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import Toaster from '../../components/UI/Toaster/Toaster';
import Modal from '../../components/UI/Modal/Modal';
import AddProviderModal from '../../components/Providers/AddProviderModal/AddProviderModal';
import Button from '../../components/UI/Button/Button';

import ToggleSwitch from '../../components/UI/ToggleSwitch/ToggleSwitch';
import ProviderTable from '../../components/Provider/ProviderTable/ProviderTable';
import ToasterBottom from '../../components/UI/Toaster/ToasterBottom';

const hideInactiveReducer = (state, bool) => {
  try {
    window.sessionStorage.setItem('hideInactiveProviders', bool);
    return bool;
  } catch {
    return bool;
  }
};

const ProvidersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(null);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [toastBottom, setToastBottom] = useState({
    show: false,
    success: false,
    body: '',
  });
  const [modal, setModal] = useState({
    show: false,
    header: '',
    body: '',
    handleClose: null,
    handleSave: null,
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [activeFilter, setActiveFilter] = useReducer(
    hideInactiveReducer,
    window.sessionStorage?.hideInactiveProviders
      ? window.sessionStorage?.hideInactiveProviders === 'true'
      : true,
  );

  const [fetchTick, setFetchTick] = useState(0);

  const getProviders = async (config, newFilters = {}) => {
    try {
      const result = await ProviderService.list({
        limit: config.limit,
        page: config.skip / config.limit + 1,
        order:
          config.sortInfo &&
          ['name', 'productType', 'url', 'active'].includes(
            config.sortInfo.name,
          )
            ? config.sortInfo.name
            : 'updatedAt',
        order_direction:
          config.sortInfo && config.sortInfo.dir === 1 ? 'ASC' : 'DESC',
        extended: true,
        status: activeFilter ? 'active' : 'inactive',
        ...newFilters,
      });

      const data = result.data ?? null;
      if (data) {
        dispatch(actions.setProviders(data));
      }

      const providers = data.data;
      return {
        data: providers,
        count: result.data.total_count,
      };
    } catch (error) {
      navigate('/');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  const search = async (event, type) => {
    const phrase = event.target.value;
    const newFilters = {
      ...filters,
      [type]: phrase.substring(0, 100),
    };
    debouncedCall();
    setFilters(newFilters);
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const closeToastBottom = () => {
    setToastBottom((prevState) => ({ ...prevState, show: false }));
  };

  const deleteHandler = useCallback(async (providerId) => {
    setModal({ show: false });
    try {
      await ProviderService.remove(providerId);
      setToast({
        show: true,
        class: 'success',
        header: 'Provider was removed successfully',
      });
    } catch ({ response }) {
      if (response.data?._errors) {
        setToast({
          show: true,
          class: 'error',
          header: 'We could not remove provider',
          body: Object.values(response.data._errors).map((error, i) => (
            <div key={Object.keys(response.data._errors)[i]}>{error}</div>
          )),
        });
      } else {
        setToast({
          show: true,
          class: 'error',
          header: 'We could not remove provider',
          body: `${response.status} - ${response.statusText}`,
        });
      }
    } finally {
      setFetchTick((tick) => tick + 1);
    }
  }, []);

  const onDeleteProvider = useCallback(
    async (provider) => {
      setModal({
        show: true,
        header: 'Delete confirmation',
        body: `Are you sure you want to delete provider ${provider.name} ?`,
        handleSave: () => deleteHandler(provider.id),
        handleClose: () => setModal({ show: false }),
      });
    },
    [deleteHandler],
  );

  const onAddProvider = async (provider) =>
    ProviderService.save(provider)
      .then((response) => ({
        errors: null,
        data: response?.data,
        action: () => {
          setShowAddForm(false);
          debouncedCall();
        },
      }))
      .catch((err) => {
        const errors = err.response.data._errors;
        const messages = err.response.data.message;
        if (!errors && !messages) {
          setToast({
            show: true,
            class: 'error',
            header: 'We could not add the Provider',
            body: `${err.response.status} - ${err.response.statusText}`,
          });
          return null;
        }

        return {
          errors,
          messages,
          data: null,
          action: () => setShowAddForm(false),
        };
      });

  const dataSource = useCallback(
    (config) => {
      return getProviders(config, filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchTick, activeFilter],
  );

  return (
    <Fragment>
      <ActionsNavbar>
        <Col
          className="
                mb-2
                mb-md-0
                d-flex
                flex-wrap
                align-items-center"
        >
          <Button
            clicked={() => navigate('/offers/add')}
            dataTestAttribute="add-provider"
          >
            Create New Offer
          </Button>
        </Col>
        <Col
          className="
                d-flex
                justify-content-start
                justify-content-sm-start
                flex-wrap
                align-content-center
                mt-2 mt-md-0"
          xs={12}
          md="auto"
        >
          <div style={{ float: 'right' }}>
            <ToggleSwitch
              label="Show offers with active campaign"
              value={activeFilter}
              onCheck={() => {
                setActiveFilter(!activeFilter);
              }}
            />
          </div>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-3">
        <Row>
          <Col>
            <ProviderTable
              dataSource={dataSource}
              search={search}
              onDeleteProviderClick={onDeleteProvider}
            />
          </Col>
        </Row>
      </Container>
      <Toaster toast={toast} closeToast={closeToast} />

      <ToasterBottom toast={toastBottom} closeToast={closeToastBottom} />
      {modal.show && (
        <Modal
          show
          title={modal.header}
          body={modal.body}
          cancel="No"
          save="Yes"
          handleClose={modal.handleClose}
          handleSave={modal.handleSave}
          dataTestAttribute={{
            modal: 'modal-delete-provider-confirmation',
            action1: 'cancel-provider-deletion',
            action2: 'confirm-provider-deletion',
          }}
        />
      )}
      <AddProviderModal
        show={showAddForm}
        setToast={setToastBottom}
        hideModal={() => setShowAddForm(false)}
        action={async (data) => onAddProvider(data)}
      />
    </Fragment>
  );
};

export default ProvidersPage;
