import React, { useMemo, useEffect, useState, useCallback } from 'react';
import BasePagination from 'react-bootstrap/Pagination';

const MOBILE_WIDTH = 1025;

const Pagination = (props) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < MOBILE_WIDTH,
  );

  const noOfPages = useMemo(() => {
    return isMobileView ? 4 : 19;
  }, [isMobileView]);

  const willPagesExceed = useMemo(() => {
    return props.current_page + noOfPages > props.total_pages;
  }, [noOfPages, props.current_page, props.total_pages]);

  const getPageArr = useCallback(
    (pageArray) => {
      return !willPagesExceed
        ? pageArray.slice(
            props.current_page - 1,
            props.current_page + noOfPages,
          )
        : pageArray.slice(
            props.total_pages > noOfPages
              ? props.total_pages - noOfPages - 1
              : 0,
            props.total_pages,
          );
    },
    [willPagesExceed, props.current_page, props.total_pages, noOfPages],
  );

  const pageArr = useMemo(() => {
    return getPageArr(
      [...Array(noOfPages + props.current_page).keys()],
      willPagesExceed,
    );
  }, [getPageArr, noOfPages, props.current_page, willPagesExceed]);

  const handleWindowSizeChange = useCallback(() => {
    setIsMobileView(window.innerWidth < MOBILE_WIDTH);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.total_pages > 1 ? (
    <BasePagination className="defaultPagination">
      {props.current_page > 1 ? (
        <>
          <BasePagination.First
            onClick={() => props.pageChange(1)}
            className="PaginationItem"
          />
          <BasePagination.Prev
            onClick={() => props.pageChange(props.current_page - 1)}
            className="PaginationItem"
          />
        </>
      ) : null}

      {pageArr.map((pageArrayIndex) => (
        <BasePagination.Item
          key={pageArrayIndex + 1}
          className={
            pageArrayIndex + 1 === props.current_page
              ? 'PaginationItem active'
              : 'PaginationItem'
          }
          onClick={() => props.pageChange(pageArrayIndex + 1)}
        >
          {pageArrayIndex + 1}
        </BasePagination.Item>
      ))}

      {!willPagesExceed ? (
        <>
          <BasePagination.Next
            onClick={() => props.pageChange(props.current_page + 1)}
            className="PaginationItem"
          />
          <BasePagination.Last
            onClick={() => props.pageChange(props.total_pages)}
            className="PaginationItem"
          />
        </>
      ) : null}
    </BasePagination>
  ) : null;
};

export default Pagination;
