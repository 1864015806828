import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import DataTable from '../../UI/DataTable/DataTable';
import Button from '../../UI/Button/Button';

const gridStyle = { height: 'calc(100vh - 195px)' };

const CompanyTable = ({
  dataSource,
  pathToDetail,
  onDeleteCompany,
  isAdvertiser,
}) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: 'name',
      header: 'Company name',
      width: 300,
      render: ({ data, value }) =>
        data.active ? (
          <Link
            className="link-bm-orange"
            to={`${pathToDetail}/${data.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {value}
          </Link>
        ) : (
          value
        ),
    },
    { header: 'Brands', name: 'brands', defaultFlex: 1 },
    { header: 'Offers', name: 'providers', defaultFlex: 1 },
    { header: 'Type', name: 'type', width: 150 },
    {
      name: 'id',
      header: 'Actions',
      width: 175,
      sortable: false,
      render: ({ value, data }) =>
        data.active ? (
          <div className="btnContainer">
            <Button
              additionalClasses="btnDefault--small"
              clicked={(e) => {
                e.stopPropagation();
                navigate(`${pathToDetail}/${value}`);
              }}
            >
              View
            </Button>
            {!isAdvertiser && (
              <Button
                additionalClasses="btnDefault--small btnDefault--red"
                clicked={(e) => {
                  e.stopPropagation();
                  onDeleteCompany(data);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        ) : null,
    },
  ];

  const onRowClick = ({ data }) => {
    if (!data.active) return;
    navigate(`${pathToDetail}/${data.id}`);
  };

  return (
    <DataTable
      id="company"
      columns={columns}
      dataSource={dataSource}
      style={gridStyle}
      columnOrderPersist
      onRowClick={onRowClick}
      pageSizes={[50, 100]}
    />
  );
};

export default CompanyTable;
