import React, { useEffect, useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';

import Button from '../../../UI/Button/Button';

import 'react-image-crop/dist/ReactCrop.css';

const convertToCrop = (idx, cropData, coords) => {
  const newCrop = { ...cropData };
  newCrop.x = coords?.left?.slice(0, -1);
  newCrop.y = coords?.top?.slice(0, -1);
  newCrop.width = coords?.width?.slice(0, -1);
  newCrop.height = coords?.height?.slice(0, -1);
  return newCrop;
};

const CropArea = ({
  index,
  imagePath,
  coord,
  onCoordChange,
  onEndEditArea,
}) => {
  const [crop, setCrop] = useState({ unit: '%', width: 20 });
  const [refresh, setRefresh] = useState(false);

  const onCropChange = useCallback(
    (idx, cropData) => {
      const newCoords = { ...coord };
      const leftRoundedTwoDecimals = (
        Math.round(cropData.x * 100) / 100
      ).toFixed(2);
      const topRoundedTwoDecimals = (
        Math.round(cropData.y * 100) / 100
      ).toFixed(2);
      const widthRoundedTwoDecimals = (
        Math.round(cropData.width * 100) / 100
      ).toFixed(2);
      const heightRoundedTwoDecimals = (
        Math.round(cropData.height * 100) / 100
      ).toFixed(2);

      newCoords.left = `${leftRoundedTwoDecimals}${cropData.unit}`;
      newCoords.top = `${topRoundedTwoDecimals}${cropData.unit}`;
      newCoords.width = `${widthRoundedTwoDecimals}${cropData.unit}`;
      newCoords.height = `${heightRoundedTwoDecimals}${cropData.unit}`;

      onCoordChange(idx, newCoords);
    },
    [coord, onCoordChange],
  );

  useEffect(() => {
    if (refresh) {
      onCropChange(index, crop);
    }
  }, [refresh, index, crop, onCropChange]);

  useEffect(() => {
    const newCrop = convertToCrop(index, crop, coord);
    setCrop(newCrop);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const floatBtn = () => (
    <div>
      <div
        style={{
          position: 'absolute',
          top: crop.y - crop.height < -crop.height + 20 ? 10 : -35,
          left: 10,
          maxWidth: 'calc(100% - 20px)',
          zIndex: -1,
        }}
      >
        <p className="image-linker-info-text">
          {coord?.targetUrl || 'There is no target url address'}
        </p>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: crop.y + crop.height < 90 ? -30 : 5,
          left: 10,
        }}
      >
        <Button
          additionalClasses="_copy-campaign btnDefault--small"
          clicked={() => {
            onEndEditArea(index);
          }}
          dataTestAttribute="crop-area-accept-button"
        >
          Accept
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <ReactCrop
        crop={crop}
        src={imagePath}
        renderSelectionAddon={floatBtn}
        onChange={(_, percentCrop) => {
          setCrop(percentCrop);
        }}
        onDragEnd={() => setRefresh(true)}
      />
    </div>
  );
};

export default React.memo(CropArea);
