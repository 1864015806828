import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { debounce } from 'lodash';

import BrandService from '../../api/Brands';
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import Input from '../../components/UI/Input/Input';
import BrandTable from '../../components/BrandsList/BrandTable/BrandTable';

const BrandsPage = () => {
  const navigate = useNavigate();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [refetchTick, setRefetchTick] = useState(0);
  const [fetchTick, setFetchTick] = useState(0);

  const getBrands = async (config, filters = {}) => {
    try {
      const result = await BrandService.list({
        limit: config.limit,
        page: config.skip / config.limit + 1,
        order:
          config.sortInfo && ['name', 'company'].includes(config.sortInfo.name)
            ? config.sortInfo.name
            : 'updatedAt',
        order_direction:
          config.sortInfo && config.sortInfo.dir === 1 ? 'ASC' : 'DESC',
        ...filters,
      });

      const brands = result.data.data;
      return {
        data: brands,
        count: result.data.total_count,
      };
    } catch (error) {
      navigate('/');
    }
  };

  const search = (event) => {
    const phrase = event.target.value;
    setSearchPhrase(phrase.substring(0, 100));
    // Avoiding multiple calls per user keyup
    debouncedCall();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  const dataSource = useCallback(
    (config) => {
      const withSearchPhrase = (searchPhrase && { name: searchPhrase }) || null;
      return getBrands(config, withSearchPhrase);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchTick, refetchTick],
  );

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
              mb-2
              mb-md-0
              d-flex
              align-items-center"
        >
          <Row
            className="
                  ms-1
                  justify-content-start
                  flex-wrap
                  flex-md-nowrap
                  align-items-center"
          >
            <Input
              type="text"
              elementConfig={{ placeholder: 'Search brands' }}
              additionalClasses="formInput"
              changed={search}
            />
          </Row>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-3">
        <Row>
          <Col>
            <BrandTable
              dataSource={dataSource}
              setRefetchTick={setRefetchTick}
              search={search}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BrandsPage;
