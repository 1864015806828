import instance from './Api';

/**
 * List users using filters
 * @param filters - example: {
 * provider: 'vidio',
 * limit: 20,
 * page: 2,
 * order: 'code',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
const list = (filters) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/carousel-images${query}`);
};

const remove = (id) => instance.delete(`/carousel-images/${id}`);

const create = (image) => instance.post('/carousel-images', image);

const update = (image) => instance.put(`/carousel-images/${image.id}`, image);

export default {
  list,
  remove,
  create,
  update,
};
