import React, { useState, useRef } from 'react';
import Input from '../../../components/UI/Input/Input';
import LinkButton from '../../../components/UI/Button/LinkButton';
import Modal from '../../../components/UI/Modal/Modal';
import './CampaignUrlModal.scss';

const CampaignUrlModal = ({
  campaignUrl: fullCampaignUrl,
  shortUrl,
  show,
  consumerVerificationUrl,
  isPaywall = false,
  onClose = () => {},
}) => {
  const [campaignUrl] = useState(shortUrl || fullCampaignUrl);
  const [isCopied, setCopied] = useState({
    campaignUrl: false,
    consumerVerificationUrl: false,
  });
  const codeRefs = useRef({
    campaignUrl: useRef(null),
    consumerVerificationUrl: useRef(null),
  });
  const [currentCodeRef, setCurrentCodeRef] = useState(
    isPaywall
      ? codeRefs.current.consumerVerificationUrl
      : codeRefs.current.campaignUrl,
  );

  function onShow() {
    setTimeout(() => {
      currentCodeRef.current.focus();
      currentCodeRef.current.select();
    });
  }

  function onInputClick(codeRef) {
    codeRef.current.select();
    document.execCommand('copy');
    setCurrentCodeRef(codeRef);
    setCopied({
      campaignUrl: codeRef === codeRefs.current.campaignUrl,
      consumerVerificationUrl:
        codeRef === codeRefs.current.consumerVerificationUrl,
    });
  }

  return (
    <Modal
      title="Get campaign url"
      body={
        <div className="campaign-url-modal-container">
          {!isPaywall && (
            <div className="campaign-url-modal-content">
              <Input
                elementConfig={{
                  readOnly: true,
                  onClick: () => onInputClick(codeRefs.current.campaignUrl),
                }}
                value={campaignUrl}
                ref={codeRefs.current.campaignUrl}
                autoFocus
                dataTestAttribute="copy-url-modal-input"
                additionalClasses="formInput formInput--bordered"
              />
              <span className="campaign-url-modal-content__copy-info">
                {isCopied.campaignUrl ? 'Copied!' : 'Click url to copy it'}
              </span>
              <LinkButton
                href={campaignUrl}
                target="_blank"
                className="pull-left"
              >
                Open url
              </LinkButton>
            </div>
          )}
          <div>
            <div className="label">
              <span className="label__title">Consumer verification page</span>
              <span>
                This page can be used to verify if the consumer has claimed the
                campaign&#39;s offer and is a public page
              </span>
            </div>
            <div className="campaign-url-modal-content">
              <Input
                elementConfig={{
                  readOnly: true,
                  onClick: () =>
                    onInputClick(codeRefs.current.consumerVerificationUrl),
                }}
                value={consumerVerificationUrl}
                ref={codeRefs.current.consumerVerificationUrl}
                autoFocus
                dataTestAttribute="copy-url-modal-input"
                additionalClasses="formInput formInput--bordered"
              />
              <span className="campaign-url-modal-content__copy-info">
                {isCopied.consumerVerificationUrl
                  ? 'Copied!'
                  : 'Click url to copy it'}
              </span>
              <LinkButton
                href={consumerVerificationUrl}
                target="_blank"
                className="pull-left"
              >
                Open url
              </LinkButton>
            </div>
          </div>
        </div>
      }
      onEnter={() => onShow()}
      save="Ok"
      handleClose={() => onClose()}
      handleSave={() => onClose()}
      show={show}
      dataTestAttribute={{
        modal: 'copy-url-modal',
        action2: 'copy-url-modal-close',
      }}
    />
  );
};

export default CampaignUrlModal;
