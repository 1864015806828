import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CampaignService from '../../../../api/Campaigns';
import PaywallService from '../../../../api/Paywall';
import CPDCService from '../../../../api/Cpdcs';

export const useCampaignReport = ({ campaignId }) => {
  const mountedRef = useRef(true);
  const [campaign, setCampaign] = useState(null);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [reportFields, setReportFields] = useState(null);
  const [qualifierQuestions, setQualifierFields] = useState([]);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [showPostQuestionData] = useState(false); /* setShowPostQuestionData */
  const [dataSource, setDataSource] = useState({
    type: 'string',
    value: 'connect-form',
  });

  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [filePassword, setFilePassword] = useState(null);
  const [showFilePasswordModal, setShowFilePasswordModal] = useState(false);
  const [fileDownload, setFileDownload] = useState({
    connectForm: false,
    postForm: false,
  });

  const formQuestions = useMemo(() => {
    return getFormQuestions(selectedForm, reportFields);
  }, [selectedForm, reportFields]);

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const getCampaignForms = (camp) => {
    if (Array.isArray(camp.landingPageContent)) {
      return camp.landingPageContent.filter((s) => s.type === 'vidio');
    }
    return [];
  };

  const getCampaignFormsOptions = (camp) =>
    getCampaignForms(camp).map((form, index) => ({
      value: form.id,
      displayValue: `Connect form ${index + 1}`,
    }));

  const onFormChange = (event) => {
    const newForm = forms.find((form) => form.id === event.target.value);
    setSelectedForm(newForm);
    setFilters({});
  };

  const download = useCallback(
    (source) => {
      const password = uuidv4();
      setFilePassword(password);

      const isPostForm = source === 'post-questions';
      const fileName = isPostForm ? 'pq-data.csv' : 'cpdcs-data.csv';
      const downloadType = isPostForm ? 'postForm' : 'connectForm';

      setFileDownload((state) => ({
        ...state,
        [downloadType]: true,
      }));

      CPDCService.downloadEncryptedData({
        campaignId,
        queryParams: {
          source: source ?? 'connect-form',
          filters: JSON.stringify(filters),
        },
        fileName,
        password,
        onError: () => {
          if (!mountedRef.current) return;
          setToast({
            show: true,
            class: 'error',
            header:
              'There was a problem downloading the report please try again',
          });
          setFileDownload((state) => ({
            ...state,
            [downloadType]: false,
          }));
        },
        onEnd: () => {
          if (!mountedRef.current) return;
          setShowFilePasswordModal(true);
          setFileDownload((state) => ({
            ...state,
            [downloadType]: false,
          }));
        },
      });
    },
    [campaignId, filters],
  );

  useEffect(() => {
    const updateDataSource = {
      type: 'string',
      value: showPostQuestionData ? 'post-questions' : 'connect-form',
    };
    setDataSource(updateDataSource);
  }, [showPostQuestionData]);

  useEffect(() => {
    CPDCService.getReportFieldsList(dataSource.value).then((result) => {
      if (result?.data) {
        const fields = [...(result.data?.fields ? result.data.fields : [])];
        const labels = result.data?.labels;
        const units = result.data?.units;
        const types = result.data?.types;
        const countTypes = result.data?.countTypes;
        const mFields = fields.map((f, i) => ({
          name: f,
          label: labels?.[i],
          unit: units?.[i],
          type: types?.[i],
          countType: countTypes?.[i],
        }));

        setReportFields(mFields);
      }
    });
  }, [dataSource]);

  useEffect(() => {
    CampaignService.get(campaignId).then((result) => {
      const campaignData = result.data;
      const formsList = getCampaignForms(campaignData);
      setCampaign(campaignData);
      setSelectedForm(formsList.length ? formsList[0] : null);
      setForms(formsList);
    });
  }, [campaignId]);

  useEffect(() => {
    if (campaign?.paywall?.enabled) {
      PaywallService.getQualifierFieldsList(campaignId).then((result) => {
        if (result?.data?.fields) {
          /* [{name, id, label, unit, type, countType}] */
          setQualifierFields(result.data.fields);
        }
      });
    }
  }, [campaign, campaignId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getters = {
    forms,
    campaign,
    isPaywallCampaign: campaign?.paywall?.enabled,
    selectedForm,
    filters,
    reportFields,
    showFilters,
    formQuestions,
    qualifierQuestions,
    filePassword,
    showFilePasswordModal,
    fileDownload,
    toast,
  };

  const actions = {
    downloadCsv: download,
    onFormChange,
    getCampaignFormsOptions,
    setShowFilters,
    setFilters,
    setFilePassword,
    setShowFilePasswordModal,
    closeToast,
  };

  return [getters, actions];
};

/**
 * Get all questions in form.
 * Merge form config with language configs
 * @param form
 * @param mFields
 */
function getFormQuestions(form, mFields) {
  const questions = form?.config?.inputs || [];
  if (form?.translations) {
    const translationConfigs = Object.values(form.translations);

    translationConfigs.forEach((configForLanguage) => {
      const transInputs = configForLanguage.inputs || [];
      const transTextFields = configForLanguage.textFields || [];
      transInputs.concat(transTextFields).forEach((transQuestion) => {
        const exists = questions.find(
          (question) => question.name === transQuestion.name,
        );
        if (!exists) {
          questions.push(transQuestion);
        }
      });
    });
  }

  const uniqueQuestions = [];
  const allQuestions = mFields ? [...mFields, ...questions] : questions;
  allQuestions.forEach((question) => {
    if (!uniqueQuestions.find((q) => q.name === question.name)) {
      uniqueQuestions.push(question);
    }
  });
  return uniqueQuestions;
}
