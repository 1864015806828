import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import Button from '../../../UI/Button/Button';

const GlobalSettingsButton = ({ addSectionHandler, serviceType }) => (
  <Button
    additionalClasses="settingsBtn btnDefault--with-icon-add"
    clicked={() => addSectionHandler(serviceType)}
    dataTestAttribute="add-global-settings-button"
  >
    <FontAwesomeIcon fixedWidth icon={faCogs} />
  </Button>
);

export default GlobalSettingsButton;
