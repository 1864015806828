import createInput from '../../../shared/Form/createInput';

export default {
  serviceType(providers, required = true, disabled = false) {
    return createInput(
      'Service type',
      {
        type: 'select',
        options:
          providers?.map((provider) => ({
            value: provider.id,
            label: provider.name,
          })) || [],
        disabled,
      },
      { required },
      '',
      false,
      'modern-select',
    );
  },
  verificationMethod: createInput(
    'User Verification Method',
    {
      type: 'select',
    },
    {
      required: false,
    },
    '',
    true,
    'modern-select',
  ),
  ageFrom: createInput(
    'Age from',
    {
      type: 'number',
      min: 0,
    },
    { required: false },
    '',
    true,
  ),
  ageTo: createInput(
    'Age to',
    {
      type: 'number',
      min: 0,
    },
    { required: false },
    '',
    true,
  ),
  gender(optionsArray) {
    return createInput(
      'Gender',
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'multiselect',
    );
  },
  countries(optionsArray) {
    return createInput(
      'Country',
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'autocomplete',
    );
  },
  cities(optionsArray) {
    return createInput(
      'Cities',
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'multiselect',
    );
  },
  qualifierQuestion(optionsArray, numberQuestion = 1) {
    return createInput(
      `Qualifier ${numberQuestion} Question`,
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'modern-select',
    );
  },
  qualifierAnswer(optionsArray, numberAnswer = 1) {
    return createInput(
      `Qualifier ${numberAnswer} Answer`,
      {
        type: 'text',
        placeholder:
          'Select all the target conditions you require for in your campaign',
        options: optionsArray,
      },
      { required: false },
      '',
      false,
      'multiselect',
    );
  },
  callbackType(required = true, disabled = false, value) {
    return createInput(
      'Callback type',
      {
        type: 'select',
        options: [
          { value: 1, label: 'Dynamic URL' },
          { value: 2, label: 'Fixed URL' },
        ],
        description:
          value === 1
            ? 'The URL parameter ?bm_referrer will provide the return address for the end-user. If not provided in the URL, the feature will be disabled.'
            : '',
        disabled,
      },
      { required },
      '',
      false,
      'modern-select',
    );
  },
  callbackUrl(required = false) {
    return createInput(
      'Callback URL',
      {
        type: 'text',
        placeholder: 'https://',
      },
      { required },
      '',
      true,
    );
  },
};
