import createInput from '../../../shared/Form/createInput';

export const emailSignInControl = {
  email: createInput(
    null,
    {
      type: 'email',
      placeholder: 'Email',
    },
    {
      required: true,
      isEmail: true,
    },
  ),
};

export default {
  password: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Password',
    },
    value: '',
    validation: {
      required: true,
      minLength: 15,
      maxLength: 128,
    },
    valid: false,
    touched: false,
  },
  repeatPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Repeat Password',
    },
    value: '',
    validation: {
      required: true,
      minLength: 15,
      maxLength: 128,
      isEqual: 'password',
    },
    valid: false,
    touched: false,
  },
};
