import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './ActionsNavbar.scss';

const ActionsNavbar = (props) => {
  const className = 'actionsNavbar ' + props.variant ?? '';
  return (
    <Container fluid>
      <Row className={className}>{props.children}</Row>
    </Container>
  );
};

export default ActionsNavbar;
