import React, { useEffect, useState, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from '../Button/Button';
import LanguageCard from './LanguageCard/LanguageCard';
import PlaceholderCard from './LanguageCard/PlaceholderCard/PlaceholderCard';
import Modal from '../Modal/Modal';
import { CampaignCreatorContext } from '../../Creator/Creator.context';
import definedLanguages from '../../../shared/languages';

const modalMessage =
  'By removing this language,' +
  'all related translations will not be visible on your site. Do you want to continue?';

const LanguagesInput = ({
  dataTestAttribute = 'add-language-input',
  onChange,
  name,
  values,
  defaultLanguage = 'en',
}) => {
  const [languages, setLanguages] = useState(
    values?.length > 0 ? values : ['en'],
  );
  const [defaultLanguage_, setDefaultLanguage] = useState(defaultLanguage);
  const [editedIdx, setEditedIdx] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isPaywall } = useContext(CampaignCreatorContext);

  useEffect(() => {
    if (!languages && !values.length) {
      onChange({ target: { value: ['en'] } });
    }
  }, [languages, onChange, values]);

  const openModal = (index) => {
    setEditedIdx(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setEditedIdx(null);
    setShowModal(false);
  };

  const removeLanguage = () => {
    const newLanguages = [...languages];
    newLanguages.splice(editedIdx, 1);
    setLanguages(newLanguages);

    setEditedIdx(null);
    setShowModal(false);
    onChange({ target: { value: newLanguages } });
  };

  const reorderLanguages = (result) => {
    if (!result.destination) return;

    const newLanguages = [...languages];
    const [removed] = newLanguages.splice(result.source.index, 1);
    newLanguages.splice(result.destination.index, 0, removed);

    setLanguages(newLanguages);
    onChange({ target: { value: newLanguages } });
  };

  const onFormChange = (option) => {
    const langs = [...languages];

    if (langs?.[editedIdx]) {
      langs[editedIdx] = option;
    } else {
      langs.push(option);
    }

    setLanguages(langs);
    onChange({ target: { value: langs }, defaultLanguage });
    setEditedIdx(null);
    setShowDropdown(false);
  };

  return (
    <>
      <div className="languagesInput" data-test-id={dataTestAttribute}>
        <Dropdown show={showDropdown}>
          <Dropdown.Toggle
            onClick={() => setShowDropdown(!showDropdown)}
            className="btnDefault btnDefault--with-icon btnDefault--with-icon-add mb-2"
            id="add-module"
            data-test-id="add-language-button"
          >
            Add Language
            <FontAwesomeIcon
              icon={faFlag}
              title="Add module"
              className=" btnDefault--with-icon__icon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(definedLanguages).map((k) => (
              <Dropdown.Item
                key={k}
                onClick={() => onFormChange(k)}
                data-test-id={`add-language-${k}`}
                disabled={languages && languages.includes(k)}
              >
                {definedLanguages[k]?.toUpperCase()}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <DragDropContext onDragEnd={reorderLanguages}>
          <Droppable droppableId="languagesInput">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {languages &&
                  languages.map((language, index) => (
                    <Draggable
                      key={JSON.stringify(`${name}_${language}_${index}`)}
                      draggableId={`${language}_${index}`}
                      index={index}
                    >
                      {(provided_) => (
                        <Row>
                          <Col xl={8} md={8}>
                            <LanguageCard
                              languageName={definedLanguages[language]}
                              defaultLanguage={defaultLanguage_}
                              idx={index}
                              provided={provided_}
                              onRemoveLanguage={openModal}
                              isPaywall
                            />
                          </Col>
                          <Col className="d-flex align-items-end">
                            <Button
                              additionalClasses={
                                (defaultLanguage_ === language || isPaywall) &&
                                `d-none`
                              }
                              type="button"
                              clicked={() => {
                                setDefaultLanguage(language);
                                onChange({
                                  target: {
                                    value: languages,
                                  },
                                  defaultLanguage: language,
                                });
                              }}
                            >
                              Set as Default
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
                {!languages?.length && (
                  <PlaceholderCard
                    onEditLanguage={() => setShowDropdown(!showDropdown)}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {showModal && (
        <Modal
          title="Remove language"
          body={modalMessage}
          cancel="No"
          save="Yes"
          handleSave={() => removeLanguage()}
          show
          handleClose={() => closeModal()}
          dataTestAttribute={{
            modal: 'modal-global-settings-language-form',
            action1: 'save-global-settings-language-form',
            action2: 'cancel-global-settings-language-form',
          }}
        />
      )}
    </>
  );
};
export default LanguagesInput;
