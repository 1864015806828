// eslint-disable-next-line import/no-cycle
import instance from './Api';

/**
 * List providers using filters
 * @param filters - example: {
 * limit: 20,
 * page: 2,
 * order: 'code',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
const list = (filters) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/provider${query}`);
};

/**
 * Create provider
 * @param provider
 * @return {Promise<AxiosResponse<any>>}
 */
const save = (provider) => instance.post('/provider', provider);

/**
 * Update provider
 * @param providerId
 * @param provider
 */
const update = (providerId, provider) =>
  instance.put(`/provider/${providerId}`, provider);

const updateTranslations = (providerId, translations) =>
  instance.put(`/provider/${providerId}/translations`, translations);

/**
 * Get provider stats
 * @param providerId
 * @return {Promise<AxiosResponse<any>>}
 */
const stats = (providerId) => instance.get(`/provider/${providerId}/stats`);

/**
 * Get provider list select options
 * @return {Promise<AxiosResponse<any>>}
 */
const options = () => instance.get('/provider/options');

/**
 * Delete provider
 * @param providerId
 * @return {Promise<AxiosResponse<any>>}
 */
const remove = (providerId) => instance.delete(`/provider/${providerId}`);
const getCampaignsWithPhoneVerification = (providerId) =>
  instance.get(`/provider/${providerId}/campaigns-with-phone-verification`);

const findOne = (providerId) =>
  instance.get(`/provider/${providerId}?extended=true`);

export default {
  list,
  save,
  remove,
  stats,
  options,
  update,
  updateTranslations,
  getCampaignsWithPhoneVerification,
  findOne,
};
