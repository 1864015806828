import createInput from '../../../shared/Form/createInput';

export default {
  ageFrom: createInput(
    'Age from',
    {
      type: 'number',
      min: 0,
    },
    { required: false },
    '',
    true,
  ),
  ageTo: createInput(
    'Age to',
    {
      type: 'number',
      min: 0,
    },
    { required: false },
    '',
    true,
  ),
  gender(optionsArray) {
    return createInput(
      'Gender',
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'multiselect',
    );
  },
  countries: createInput(
    'Country',
    {},
    { required: false },
    null,
    false,
    'autocomplete',
  ),
  cities: (disable) =>
    createInput(
      'Cities',
      { disabled: disable },
      { required: false },
      null,
      false,
      'multiselect',
    ),
  qualifierQuestion(optionsArray, numberQuestion = 1) {
    return createInput(
      `Qualifier ${numberQuestion} Question`,
      {
        type: 'text',
        options:
          optionsArray?.map((option) => ({
            value: option.value,
            label: option.label,
          })) || [],
      },
      { required: false },
      '',
      false,
      'modern-select',
    );
  },
  qualifierAnswer(optionsArray, numberAnswer = 1) {
    return createInput(
      `Qualifier ${numberAnswer} Answer`,
      {
        type: 'text',
        placeholder:
          'Select all the target conditions you require for in your campaign',
        options: optionsArray,
      },
      { required: false },
      '',
      false,
      'multiselect',
    );
  },
};
