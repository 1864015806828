import React, { useEffect, useState } from 'react';
import Modal from '../../UI/Modal/Modal';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';

import filterIcon from '../../../assets/icons/filter.svg';

import styles from './CampaignFilterForm.module.scss';
import CampaignFilterForm from './CampaignFilterForm';
import CompanyService from '../../../api/Companies';
import BrandService from '../../../api/Brands';

const CampaignFilterModal = ({
  advanceFilterConfig,
  setAdvanceFilterConfig,
  formSubmit,
  filterFormFields,
  setFilterFormFields,
  filterFormFieldsInitialValue,
  setTempFields,
  tempFields,
  toggleArchivedCampaigns,
  setToggleArchivedCampaigns,
  search,
  tagOptions,
}) => {
  const [selectCompanyOptions, setSelectCompanyOptions] = useState([]);
  const [selectBrandOptions, setSelectBrandOptions] = useState([]);
  const [showDateFields, setShowDateFields] = useState(true);

  useEffect(() => {
    (async () => {
      const { data: companyOptions } = await CompanyService.options(true);
      setSelectCompanyOptions(
        companyOptions.map((company) => ({
          ...company,
          value: company.label,
          id: company.value,
        })),
      );
      const { data: brandOptions } = await BrandService.brandOptions();
      setSelectBrandOptions(
        brandOptions.map((brands) => ({
          ...brands,
          value: brands.label,
        })),
      );
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setFilterFormFields({ ...tempFields });
    setTempFields(null);
    setAdvanceFilterConfig((config) => ({ ...config, show: false }));
  };

  const customCloseEvent = () => {
    setFilterFormFields(filterFormFieldsInitialValue);
    setShowDateFields(false);
    setTempFields(null);
    setAdvanceFilterConfig((config) => ({ ...config, isReset: true }));
    setTimeout(() => {
      setShowDateFields(true);
      formSubmit('reset');
    }, 100);
  };

  const handleSave = () => {
    setTempFields(null);
    formSubmit();
    setAdvanceFilterConfig(() => ({ isReset: false, show: false }));
  };

  const tagProps = {
    search,
    tagOptions,
  };

  return (
    <Modal
      title={
        <div className="d-flex align-items-center">
          <h4 className="my-0 ">
            <span>
              <img src={filterIcon} alt="filter" />
            </span>
            <span className="mx-4">Advance Filter</span>
          </h4>

          <div className="mx-4">
            <ToggleSwitch
              label="Archived Campaigns"
              value={toggleArchivedCampaigns}
              onCheck={() => {
                setToggleArchivedCampaigns(!toggleArchivedCampaigns);
              }}
              additionalLabelClasses={styles.switchLabel}
            />
          </div>
        </div>
      }
      dialogClassName={styles.advanceFilterDialog}
      save="Apply"
      cancel="Reset"
      headerClassName={styles.advanceFilterAction}
      footerClassName={styles.advanceFilterAction}
      body={
        <CampaignFilterForm
          setFilterFormFields={setFilterFormFields}
          filterFormFields={filterFormFields}
          selectBrandOptions={selectBrandOptions}
          selectCompanyOptions={selectCompanyOptions}
          showDateFields={showDateFields}
          {...tagProps}
        />
      }
      show={advanceFilterConfig.show}
      handleClose={handleClose}
      customCloseEvent={customCloseEvent}
      handleSave={handleSave}
    />
  );
};

export default CampaignFilterModal;
