import React, { useEffect, useState } from 'react';

import Modal from '../../UI/Modal/Modal';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';
import Input from '../../UI/Input/Input';
import CodesService from '../../../api/Codes';

const AddCodeModal = ({ openTick, providerId, onSave }) => {
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [form, setForm] = useState({
    code: '',
    consumerNote: '',
  });

  const [error, setError] = useState({
    code: '',
  });

  const setField = (key, value) => {
    setForm((state) => ({ ...state, [key]: value }));
  };

  const validateForm = () => {
    let err = 0;
    const errors = {
      code: '',
      consumerNote: '',
    };

    if (!form.code || form.code.length < 3) {
      errors.code = 'Code must be 3 characters or more';
      err++;
    }

    setError(errors);
    return !err;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    saveCode();
  };

  const saveCode = () => {
    const errors = {};
    if (form.code.length > 128) {
      errors.code = 'Code must be 128 characters or less';
    }
    if (form.consumerNote.length > 128) {
      errors.consumerNote = 'Note must be 128 characters or less';
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return;
    }

    setIsSaving(true);

    CodesService.create({
      code: form.code,
      provider: providerId,
      consumerNote: form.consumerNote,
    })
      .then(() => {
        setShow(false);
        onSave();
      })
      .catch((err) => {
        const data = err?.response?.data;
        const serverMessage =
          data?._errors?.code ??
          (Array.isArray(data?.message) && data.message?.[0]) ??
          '';
        const consumerNote = data?._errors?.consumerNote ?? '';
        setError({
          code: serverMessage ?? 'Something went wrong',
          consumerNote,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (openTick) {
      setShow(true);
      setForm({
        code: '',
        consumerNote: '',
      });
      setError({
        code: '',
        consumerNote: '',
      });
    }
  }, [openTick]);

  return (
    <Modal
      title="Add a Code or Coupon"
      size="md"
      show={show}
      cancel="Close"
      save="Save"
      handleClose={() => setShow(false)}
      handleSave={handleSave}
    >
      {isSaving && <OverlaySpinner />}
      <div className="mb-4">
        <Input
          elementType="text"
          label="Code"
          additionalClasses="formInput formInput--bordered formInput--smaller"
          required
          changed={(e) => setField('code', e.target.value.slice(0, 255))}
          value={form.code}
          touched={!!error.code}
          error={error.code}
          invalid={!!error.code}
          shouldValidate
        />
      </div>
      <div>
        <Input
          elementType="input"
          label="Consumer Note"
          additionalClasses="formInput formInput--bordered formInput--smaller"
          value={form.consumerNote}
          changed={(e) => setField('consumerNote', e.target.value)}
          touched={!!error.consumerNote}
          error={error.consumerNote}
          invalid={!!error.consumerNote}
          shouldValidate
        />
      </div>
    </Modal>
  );
};

export default AddCodeModal;
