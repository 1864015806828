import { boolean, number, string } from 'yup';

import { formatHttps } from '../../../shared/inputFormatting';

export const getSchema = (values) => {
  // Defines a regular expression that allows any non-whitespace characters
  // followed by :// and then any non-whitespace characters. This is a very
  // lenient check, essentially allowing any protocol.
  const urlRegex = /^[^\s]+:\/\/[^\s]+$/;

  return {
    callback: number(),
    callbackParams: boolean(),
    callbackUrl:
      values.callback === 2
        ? string()
            .matches(urlRegex, 'Must be a valid URL format')
            .required('Required')
        : string().nullable(true),
  };
};

const displayFields = {
  callback: {
    key: 'callback',
    label: 'Callback Type',
    type: 'modern-select',
    elementConfig: {
      options: [
        {
          value: 1,
          label: 'Dynamic URL',
        },
        {
          value: 2,
          label: 'Fixed URL',
        },
      ],
    },
    defaultValue: 0,
  },
  callbackUrl: {
    key: 'callbackUrl',
    label: 'Callback URL',
    defaultValue: '',
    elementConfig: {
      placeholder: 'https://',
    },
  },
  callbackParams: {
    key: 'callbackParams',
    defaultValue: false,
  },
  callbackCouponCodeParam: {
    key: 'callbackCouponCodeParam',
    defaultValue: false,
  },
};

export default displayFields;
