import React from 'react';
import { OfferAccordionItem } from '../OfferAccordion/OfferAccordion';
import ProviderForm from '../../Providers/ProviderForm/ProviderForm';

const OfferSectionGeneral = ({
  step,
  provider,
  setToastBottom,
  onModifyProvider,
  isFormValid,
  setIsFormValid,
  filePath,
  filePathLogo,
  backgroundColor,
  textColor,
  setIsLoading,
  isLoading,
  saveTick,
  providerActive,
}) => {
  return (
    <OfferAccordionItem
      step={step}
      title="General Profile"
      body={
        <ProviderForm
          setToast={setToastBottom}
          data={provider}
          onSubmitForm={async (data) => onModifyProvider(data)}
          onCancelForm={null}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          filePath={filePath}
          filePathLogo={filePathLogo}
          backgroundColor={backgroundColor}
          textColor={textColor}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          saveTick={saveTick}
          providerActive={providerActive}
        />
      }
    />
  );
};

export default OfferSectionGeneral;
