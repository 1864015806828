import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getMultiFactorResolver,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import bountyMediaLogo from '../../assets/images/bounty-hlogo.svg';
import Auth from '../../components/Auth/Auth';
import { authFail } from '../../store/actions/auth';
import { auth, app } from '../../vendor/firebase';
import GoogleSignInButton from '../../components/UI/GoogleButton/GoogleSignInButton';
import BountyBannerLogo from '../../components/UI/Logo/BountyBannerLogo';
import styles from './AuthPage.module.scss';

const setStateReducer = (state, newValues) => ({
  ...state,
  ...newValues,
});

const AuthPage = () => {
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider(app);
  const [mfaAuth, setMfaAuth] = useReducer(setStateReducer, {
    enabled: false,
    firebaseResolver: null,
  });

  const handleGoogleSignIn = () => {
    signInWithRedirect(auth, provider).catch((error) => {
      /**
       * Show MFA Auth Form
       * https://cloud.google.com/identity-platform/docs/web/mfa
       */
      if (error.code === 'auth/multi-factor-auth-required') {
        return setMfaAuth({
          enabled: true,
          firebaseResolver: getMultiFactorResolver(auth, error),
        });
      }

      if (error.code === 'auth/admin-restricted-operation') {
        dispatch(
          authFail(
            'The email address is not registered to Bounty platform. Please contact your admin.',
          ),
        );
      }
    });
  };

  const handleReturn = () => {
    setMfaAuth({
      enabled: false,
      firebaseResolver: null,
    });
  };

  return (
    <div className={styles.authPage}>
      <BountyBannerLogo />
      <section className={styles.loginBox}>
        <main className="d-flex flex-column justify-content-center align-items-center">
          {mfaAuth.enabled ? (
            <button
              onClick={handleReturn}
              className={`${styles.backIcon} ${styles.return}`}
            >
              <FontAwesomeIcon size="xl" width="54" icon={faArrowLeftLong} />{' '}
              <p>Return</p>
            </button>
          ) : null}

          {!mfaAuth.enabled && (
            <>
              <div className="mb-4">
                <img src={bountyMediaLogo} alt="Bounty Media logo small" />
              </div>
              <div className={`${styles.tagline} mb-2`}>
                <p>The best way to acquire consumer data at scale.</p>
              </div>
              {process.env.NODE_ENV !== 'production' && (
                <>
                  <div className={`${styles.glBox} mb-4`}>
                    <GoogleSignInButton onClick={handleGoogleSignIn} />
                  </div>{' '}
                  <div className={`${styles.orBox} mb-4`}>
                    <span />
                    <p className="mb-0 mx-4">OR</p>
                    <span />
                  </div>
                </>
              )}
            </>
          )}

          <Auth mfaAuth={mfaAuth} setMfaAuth={setMfaAuth} />
        </main>

        <div>
          <p className="small mt-4">
            Don&apos;t have an account? <Link to="/sign-up">Sign up now</Link>
          </p>
        </div>
      </section>
    </div>
  );
};

export default AuthPage;
