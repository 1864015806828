import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import CampaignService from '../../../api/Campaigns';
import FormikInput from '../../UI/FormikInput/FormikInput';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';

const CampaignSectionProtected = ({
  step,
  displayFields,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  sectionsRef,
  setToast,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useSelector((state) => state.auth);

  const onGenerateTokenAction = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await CampaignService.generateCsv(
        formik.values.id,
        formik.values.uniqueIdFrom,
        formik.values.uniqueIdTo,
      );
    } catch (error) {
      console.error(error);
      setToast({
        show: true,
        success: false,
        body: 'There was a problem downloading the csv file. Please try again',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="protected"
      title="One Time Token"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      sectionsRef={sectionsRef}
      generateTokenButton={isAdmin}
      onGenerateTokenAction={onGenerateTokenAction}
      isLoading={isLoading}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          One Time Token means the consumer must have a token in their URL when
          they land on the provider page. The token is generated using the
          signature, a delimiter”|” and a unique value. Using the decipher to be
          encrypted. This allows preventing unauthorized consumers from
          submitting the same campaign and submitting it more than once.
        </p>
      </CampaignAccordionItem.Tooltip>

      <>
        <Row className="mb-5">
          <Col>
            <ToggleSwitchInput
              id="protected"
              label="Enable One Time Token?"
              text={formik.values.protected ? 'Yes' : 'No'}
              value={formik.values.protected}
              description="Provides additional protection against abuse for campaign submissions"
              onCheck={(bool) => formik.updateFieldValue('protected', bool)}
            />
          </Col>

          {formik.values.protected
            ? ['signature', 'decipherKey'].map((fieldKey) => {
                return (
                  <Col key={fieldKey}>
                    <FormikInput
                      key={fieldKey}
                      field={displayFields[fieldKey]}
                      formik={formik}
                      validationSchema={validationSchema}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
        {formik.values.protected && isAdmin && (
          <>
            <p className="Label">Generate One-Time Tokens</p>
            <Row>
              <Col>
                <p className="formInputDescription">
                  The format for the token has to follow
                  &quot;[signature]|[unique identifier]&quot; The unique
                  identifier can be a numeric range. For example, choosing
                  100-200 below will generate [signature]|100 to
                  [signature]|200.
                </p>
              </Col>
              <Col>
                <FormikInput
                  key="uniqueIdFrom"
                  field={displayFields.uniqueIdFrom}
                  formik={formik}
                  validationSchema={validationSchema}
                />
              </Col>
              <Col>
                <FormikInput
                  key="uniqueIdTo"
                  field={displayFields.uniqueIdTo}
                  formik={formik}
                  validationSchema={validationSchema}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionProtected;
