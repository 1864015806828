import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Campaigns from '../../api/Campaigns';
import { formatDate } from '../../shared/dateTime';

const dateFormat = 'dd-MM-yyyy hh:mm';

/**
 * @param { campaign, campaignStateChange }
 * @returns {JSX.Element}
 * @example <CampaignEnable campaign={campaign} campaignStateChange={(enabled) => alert(enabled)} />
 * @constructor
 */
const CampaignEnable = ({
  campaign,
  campaignStateChange,
  requiresServiceType = false,
  setToast,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const isEnabled = campaign.enabled;
  const isProviderFallback = campaign.isProviderFallback;
  const btnText = isEnabled ? 'Stop Campaign' : 'Start Campaign';

  const updateCampaign = async () => {
    const apiCall = isEnabled ? Campaigns.disable : Campaigns.enable;
    try {
      const response = await apiCall(campaign);
      campaignStateChange(response.data.enabled);
    } catch (error) {
      if (error?.response?.status === 406) {
        setToast({
          show: true,
          class: 'error',
          header:
            error?.response?.data?.message ||
            'An unexpected error has occurred - please contact an administrator',
        });
      } else if (error?.response?.status !== 200) {
        setToast({
          show: true,
          class: 'error',
          header:
            'An unexpected server error has occurred - please contact an administrator',
        });
      } else {
        throw error;
      }
    }
    setShowConfirmationModal(false);
  };

  const onButtonClick = (event) => {
    event.preventDefault();

    if (requiresServiceType) {
      return setToast({
        show: true,
        class: 'error',
        header: 'Please save a "Service Type" and then try again.',
      });
    }

    setShowConfirmationModal(true);
    event.currentTarget.blur();
  };

  const getCurrency = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num);
  };

  const getPromptText = () => {
    const actionName = isEnabled === true ? 'stop' : 'start';

    const startSupplementalMessage = campaign.paywall?.enabled
      ? 'Changes to Paywall enablement, Qualifier questions, and Service provider assignment will not be permitted once the campaign has been started.'
      : '';

    if (actionName === 'start') {
      const fromDate = formatDate(campaign.startDate, dateFormat);
      const toDate = formatDate(campaign.endDate, dateFormat);
      const fromTo = isProviderFallback
        ? ''
        : ` from ${fromDate}, to ${toDate}`;
      const spendAmount = getCurrency(campaign.budget);
      const ratePerConnection = getCurrency(campaign.cpdcCost);
      const providerRateCopy = `at a rate of ${ratePerConnection}${fromTo}`;
      const atARateOf =
        campaign.paywall?.enabled && !campaign.videoProvider
          ? 'at a dynamic rate per connection, according to matched service providers'
          : providerRateCopy;

      return `I agree to spend ${spendAmount} from landing page “${campaign.name}” ${atARateOf}.
        ${startSupplementalMessage}
        Are you sure you want to ${actionName} the campaign?`;
    }

    if (isProviderFallback) {
      return 'Are you sure you want to stop this campaign? This will cause the fallback to be set to the default fallback campaign and your data may be inconsistent';
    }

    return `Are you sure, you want to ${actionName} the campaign?`;
  };

  return (
    <>
      <Button
        additionalClasses={isEnabled ? 'btnDefault--red me-0' : 'me-0'}
        clicked={onButtonClick}
        dataTestAttribute="start-stop-edited-campaign"
      >
        {btnText}
      </Button>

      {showConfirmationModal ? (
        <Modal
          title="Confirm change"
          body={getPromptText(campaign)}
          cancel="Cancel"
          save="Confirm"
          handleSave={updateCampaign}
          show
          handleClose={() => setShowConfirmationModal(false)}
          dataTestAttribute={{
            modal: 'start-stop-modal-campaign-form',
            action1: 'cancel-start-stop-edited-campaign',
            action2: 'confirm-start-stop-edited-campaign',
          }}
        />
      ) : null}
    </>
  );
};

export default CampaignEnable;
