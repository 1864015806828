import React from 'react';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const ToggleSwitchInput = ({
  children,
  id = 1,
  label,
  text,
  onCheck,
  value,
  disabled,
  description,
  dataTestAttribute,
}) => (
  <div className="Input toggleSwitchInput" data-test-id={dataTestAttribute}>
    {label ? (
      <label className="d-flex Label" htmlFor="name">
        <span className="d-block position-relative me-2">{label}</span>
        {children}
      </label>
    ) : null}

    <div>
      <ToggleSwitch
        id={id}
        label={text}
        onCheck={onCheck}
        value={value}
        disabled={disabled}
      />

      {description ? (
        <small className="formInputDescription formInputDescription--short">
          {description}
        </small>
      ) : null}
    </div>
  </div>
);

export default ToggleSwitchInput;
