import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '../../Button/Button';

import styles from './TableDataRow.module.scss';

import iconExclamation from '../../../../assets/icons/icon-exclamation.png';

const actionDisplayTest = (displayTest, rowData, disabled) => {
  const shouldDisplay = displayTest?.(rowData, disabled);
  if (displayTest && typeof shouldDisplay !== 'boolean') {
    console.warn(
      `TableDataRow: Boolean was not returned from display test (${rowData.id}), actions may be displayed incorrectly.`,
    );
  }
  // If displayTest is not defined, assumption is to show
  return shouldDisplay ?? true;
};

/**
 * @param {Object} rowData
 * @param {String|Function} pathToDetail - Enable click to route with record id
 * @param {Boolean} disableInactive - Disabled row if 'active' property is not truthy
 *
 * @param {Array} tableColumns
 * [{
 *  @param {String} key
 *  @param {String} label
 * }]
 *
 * @param {Array} actions - An array of CTA configurations:
 * [{
 *  @param {Function} display (optional) - Pre-check using record data before displaying
 *  @param {String} label
 *  @param {Boolean} requireAdmin (optional) - If true, must be super admin user to display
 *  @param {Function} onClick (optional) - On click callback
 * }]
 *
 */

const deleteHighlightColumns = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
];

const getCellValue = (key, value) => {
  switch (key) {
    case 'requestDeletedDate': {
      if (!value) {
        return '';
      }
      const d = new Date(value);
      return d.toLocaleDateString();
    }

    case 'dueDate': {
      const d = new Date(value);
      d.setDate(d.getDate() + 30);
      return d.toLocaleDateString();
    }

    default: {
      return value;
    }
  }
};

const TableDataRow = ({
  rowData,
  tableColumns,
  actions,
  pathToDetail,
  disableInactive,
  onDelete,
}) => {
  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state.auth);
  const hasActions = !!actions?.length;

  const disabled = disableInactive && !rowData.active;
  const disabledClass = disabled ? 'text-muted' : '';

  const showDeleteFormat = !!rowData?.pendingDelete;
  const highlightClassTop = showDeleteFormat ? styles.rowHighlightTop : '';
  const highlightClassBottom = showDeleteFormat
    ? styles.rowHighlightBottom
    : '';

  const redirectToDetails = () => {
    if ((disableInactive && !rowData.active) || !pathToDetail) return null;
    if (typeof pathToDetail === 'function') {
      return navigate(pathToDetail(rowData));
    }
    return navigate(`${pathToDetail}/${rowData.id}`);
  };

  const handleAction = (e, actionOnClick) => {
    e.preventDefault();
    e.stopPropagation();

    if (actionOnClick) {
      return actionOnClick(rowData);
    }

    return redirectToDetails();
  };

  return (
    <>
      <tr
        className={`${disabledClass} ${highlightClassTop}`}
        role={!disabled ? 'button' : ''}
        onClick={redirectToDetails}
        data-test-id="table-row"
      >
        {tableColumns.map((column) => (
          <td key={column.key}>
            <div className={styles.innerCell}>
              {getCellValue(column.key, rowData[column.key])}
              {showDeleteFormat &&
                deleteHighlightColumns.includes(column.key) && (
                  <img
                    className="ms-2"
                    width="16"
                    alt="Pending Deletion"
                    src={iconExclamation}
                  />
                )}
            </div>
          </td>
        ))}

        {hasActions && (
          <td>
            {actions.map((action) => {
              const displayCta = actionDisplayTest(
                action?.display,
                rowData,
                disabled,
              );

              if (!displayCta || (action.requireAdmin && !isAdmin)) {
                return null;
              }

              return (
                <Button
                  key={action.key}
                  clicked={(e) => handleAction(e, action.onClick)}
                  additionalClasses={`btnDefault--small ${
                    action.warningStyle && 'btnDefault--red'
                  }`}
                >
                  {action.label}
                </Button>
              );
            })}
          </td>
        )}
      </tr>

      {showDeleteFormat && onDelete && (
        <tr className={`${highlightClassBottom}`}>
          <td colSpan={tableColumns.length}>
            <Button
              clicked={(e) => handleAction(e, onDelete)}
              additionalClasses="btnDefault--small btnDefault--red"
            >
              Delete PII Data
            </Button>
            <small className="text-danger">
              Due: {getCellValue('dueDate', rowData.requestDeletedDate)}
            </small>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableDataRow;
