/* eslint-disable import/prefer-default-export */
export const convertFormInputs = (fieldType, inputSettings) => {
  if (!inputSettings) return inputSettings;
  const newInputSettings = [];
  Object.keys(inputSettings).map((el) => {
    const element = inputSettings[el];
    if (!element) return null;

    if (fieldType === 'inputs' || fieldType === 'textFields') {
      const newVal = {};
      let isEmptyGroup = true;
      Object.keys(element).map((e) => {
        if (!element[e]) return null;
        if (element[e].value) isEmptyGroup = false;
        const { value } = element[e];

        newVal[e] = value;

        if (element[e].elementType === 'textarea') {
          newVal[e] = value.split(/\r?\n/);
        }
        return newVal[e];
      });

      if (Object.keys(newVal).length > 0 && !isEmptyGroup) {
        newInputSettings.push(newVal);
      }
      return newVal;
    }
    if (fieldType === 'terms') {
      if (!element.value) return null;

      newInputSettings.push(element.value);
      return element;
    }
    return newInputSettings;
  });
  return newInputSettings.length > 0 ? newInputSettings : inputSettings.value;
};
