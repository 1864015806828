import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  hasNewTermsAndConditions: false,
  hasDataToFetch: false,
  errorAccessingResource: {
    show: false,
    class: null,
    header: null,
  },
};

const newTermsAndConditions = (state, action) =>
  updateObject(state, {
    hasNewTermsAndConditions: true,
    hasDataToFetch: action.hasDataToFetch,
  });

const resetTermsAndConditions = (state) =>
  updateObject(state, {
    hasNewTermsAndConditions: false,
    hasDataToFetch: false,
  });

const setErrorAccessingResource = (state, action) =>
  updateObject(state, {
    errorAccessingResource: {
      show: action.show,
      class: action.className,
      header: action.header,
    },
  });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_TERMS_AND_CONDITIONS:
      return newTermsAndConditions(state, action);
    case actionTypes.RESET_TERMS_AND_CONDITIONS:
      return resetTermsAndConditions(state, action);
    case actionTypes.SET_ERROR_ACCESSING_CAMPAIGN:
      return setErrorAccessingResource(state, action);
    default:
      return state;
  }
};

export default reducer;
