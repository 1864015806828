import instance from './Api';
import fileService from './File';

const upload = (file, providerId) => {
  const fd = new FormData();
  fd.append('file', file);

  return instance.post(`/codes/import/${providerId}`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const generateCsv = async (providerId) => {
  const res = await instance.get(`/codes/generate-csv/${providerId}`);
  const contentDisposition = res.headers['content-disposition'];
  const regex = /filename="(.+?)(?=\.\w+")/;
  const matches = contentDisposition.match(regex);
  const fileName = matches[1];
  fileService.download({
    url: `/codes/generate-csv/${providerId}`,
    fileName,
    fileType: 'csv',
  });
};

/**
 * List users using filters
 * @param filters - example: {
 * status: 'Available',
 * code: 'asd',
 * limit: 20,
 * page: 2,
 * order: 'code',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
const list = (filters) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/codes${query}`);
};

const remove = (id) => instance.delete(`/codes/${id}`);

const changeStatus = (id, status) =>
  instance.put(`/codes/${id}/change-status/${status}`);

const countAvailable = (providerId) =>
  instance.get(`/codes/count-available/${providerId}`);

const create = (codeData) => instance.post('/codes', codeData);

export default {
  upload,
  list,
  remove,
  changeStatus,
  countAvailable,
  create,
  generateCsv,
};
