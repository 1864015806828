import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import FormikInput from '../../UI/FormikInput/FormikInput';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';
import Tooltip from '../../UI/Tooltip/Tooltip';
import WebhookList from '../../Campaign/Webhook/WebhookList';

const CampaignSectionInformation = ({
  step,
  displayFields,
  selectOptions,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  sectionsRef,
  webhooks,
  setWebhookManageModal,
  setWebhooks,
}) => {
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  const togglePaywall = async (bool) => {
    if (bool) {
      formik.updateFieldValue('videoProvider', '');
      formik.updateFieldValue('fallbackUrl', '');
    }

    const paywallState = { ...formik.values.paywall };
    paywallState.enabled = bool;
    formik.updateFieldValue('paywall', paywallState);
  };

  const hasWebhooks = !!webhooks.length;
  const showTagField = isAdmin || isBountyAdmin;

  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="information"
      title="Information"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      sectionsRef={sectionsRef}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          Enter the essential information including the Campaign Name, Company
          Name, Brand, Budget, Maximum per Connection, and Campaign Duration to
          create a campaign. Please note that all these fields are mandatory,
          and it is necessary to provide complete information in order to
          proceed with campaign creation.
        </p>
        <p>
          The campaign and page will run until total budget is used, after which
          any visitor to the page will be redirected to the Fallback URL as
          specified.
        </p>
      </CampaignAccordionItem.Tooltip>

      <>
        <Row>
          {showTagField && (
            <Col xs={4}>
              <FormikInput
                key="tags"
                field={displayFields?.tags}
                options={selectOptions?.tags ?? null}
                formik={formik}
                validationSchema={validationSchema}
              />
            </Col>
          )}
          {[
            'name',
            'company',
            'brand',
            'budget',
            'maxPerConnection',
            !formik.values.paywall.enabled ? 'fallbackUrl' : null,
          ]
            .filter((e) => e)
            .map((fieldKey) => {
              return (
                <Col xs={4} key={fieldKey}>
                  <FormikInput
                    key={fieldKey}
                    field={displayFields[fieldKey]}
                    options={selectOptions[fieldKey] ?? null}
                    formik={formik}
                    validationSchema={validationSchema}
                  />
                </Col>
              );
            })}
          <ToggleSwitchInput
            id="paywallEnabled"
            label="Is this campaign on the Paywall?"
            text={formik.values.paywall.enabled ? 'Yes' : 'No'}
            disabled={!!formik.values.enabledAt}
            onCheck={togglePaywall}
            value={formik.values.paywall.enabled}
          >
            <Tooltip id="paywall-tooltip">
              <p>
                The paywall serves as the landing page for new consumers, where
                they are directed to complete an OTP (one-time password)
                process. If there are any campaigns available on the paywall
                that match the consumer`s qualifications, they will be presented
                with qualifying questions to determine their eligibility for
                those campaigns. Each provider page (paywall) can host multiple
                campaigns, and once the OTP process is completed, consumers may
                gain access to the campaigns that align with their
                qualifications.
              </p>
            </Tooltip>
          </ToggleSwitchInput>
        </Row>

        <h3 className="mt-4 mb-4">Duration</h3>
        <Row>
          <Col xs={4}>
            <FormikInput
              key="startDate"
              field={displayFields.startDate}
              options={selectOptions.startDate}
              formik={formik}
              validationSchema={validationSchema}
            />
          </Col>
          <Col xs={4}>
            <FormikInput
              key="endDate"
              field={displayFields.endDate}
              options={selectOptions.endDate}
              formik={formik}
              validationSchema={validationSchema}
            />
          </Col>
          <Col xs={4}>
            <FormikInput
              key="connectionTarget"
              field={displayFields.connectionTarget}
              formik={formik}
              validationSchema={validationSchema}
            />
          </Col>
        </Row>

        {hasWebhooks && (
          <Row className="mt-3">
            <Col xs={8}>
              <h3 className="mb-4">Webhooks</h3>
              <WebhookList
                setShowWebhookManageModal={setWebhookManageModal}
                webhooks={webhooks}
                setWebhooks={setWebhooks}
              />
            </Col>
          </Row>
        )}
      </>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionInformation;
