import { useEffect, useState } from 'react';
import TranslationApi from '../../../api/Translation';

export const useGlobalTranslations = () => {
  const [contentsByLanguage, setContentByLanguage] = useState([]);
  const [originalContents, setDefaultContent] = useState({});
  const [formData, setFormData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  //   const [searchValue, setSearch] = useState('');

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const getPaywallTranslationsByTranslationCode = async (translationCode) => {
    try {
      const translation = (
        await TranslationApi.getTranslationByCode(translationCode)
      ).data;
      const languages = (await TranslationApi.getLanguages()).data;
      const contents = (
        await TranslationApi.getContentByTranslationId(translation.id)
      ).data;

      const defaultContents = contents.filter((content) => {
        return !content.languageId;
      });

      languages.forEach((language) => {
        const reformat = language;
        reformat.contents = [];
        reformat.translationCode = translation.translationCode;
        reformat.translationId = translation.id;
      });

      contents.forEach((content) => {
        languages.forEach((language) => {
          if (language.id === content.languageId)
            language.contents.push(content);
        });

        if (content.languageId)
          defaultContents.forEach((defaultContent) => {
            const reformat = defaultContent;
            if (reformat.codeIdentifier === content.codeIdentifier)
              reformat[content.languageId] = content.contentText;
          });
      });

      languages.forEach((language) => {
        const reformat = language;
        const missing =
          defaultContents.length -
          reformat.contents.filter(
            (languageContent) => languageContent.contentText !== '',
          ).length;
        reformat.missingContent = missing
          ? `Missing ${missing} item/s`
          : 'Complete';
      });

      return { languages, defaultContents };
    } catch (err) {
      console.error(err);
    }
  };

  const getPaywallTranslationsByLanguage = async () => {
    setLoading(true);

    let allLanguages = [];
    const defaultContentsMapper = {};

    const promises = ['Common', 'Form', 'BountyPay'].map(
      async (translationCode) => {
        const { languages, defaultContents } =
          await getPaywallTranslationsByTranslationCode(translationCode);

        allLanguages = [...allLanguages, ...languages];

        defaultContents.sort((a, b) => {
          const contentA = a.codeIdentifier.toLowerCase();
          const contentB = b.codeIdentifier.toLowerCase();

          if (contentA < contentB) {
            return -1;
          }
          if (contentA > contentB) {
            return 1;
          }
          return 0;
        });

        defaultContentsMapper[translationCode] = defaultContents;
      },
    );

    await Promise.all(promises);

    allLanguages.forEach((language) => {
      const modifiedLanguage = language;
      modifiedLanguage.id = `${language.id}_${language.translationCode}`;

      switch (language.translationCode) {
        case 'Form':
          modifiedLanguage.code = 'Campaign';
          break;
        case 'BountyPay':
          modifiedLanguage.code = 'Consumer Dashboard';
          break;
        default:
          modifiedLanguage.code = 'Paywall';
      }
    });

    allLanguages.sort((a, b) => {
      const languageA = a.translationCode.toLowerCase();
      const languageB = b.translationCode.toLowerCase();

      if (languageA < languageB) {
        return -1;
      }
      if (languageA > languageB) {
        return 1;
      }
      return 0;
    });

    setContentByLanguage(allLanguages);
    setDefaultContent(defaultContentsMapper);

    setLoading(false);
  };

  const editLanguage = (data = {}) => {
    const _originalContents = originalContents[data.translationCode];
    const _initialValues = {};
    const dataId = data.id.split('_')[0];

    _originalContents.forEach((originalContent) => {
      const reformat = originalContent;

      _initialValues[originalContent.codeIdentifier] =
        originalContent[dataId] || '';

      reformat.value = originalContent[dataId] || '';
      reformat.key = originalContent.codeIdentifier;
      reformat.type = 'text';
      reformat.label = originalContent.contentText;
    });

    setFormData({
      originalContents: _originalContents,
      initialValues: _initialValues,
      translationId: data.translationId,
      languageId: dataId,
      language: data.languageName,
    });
    setShowForm(true);
  };

  useEffect(() => {
    getPaywallTranslationsByLanguage();
  }, []);

  const onFormSuccess = () => {
    setFormData({});
    getPaywallTranslationsByLanguage();
  };

  const getters = {
    contentsByLanguage,
    originalContents,
    formData,
    showForm,
    toast,
    loading,
  };
  const actions = {
    setShowForm,
    onFormSuccess,
    closeToast,
    setToast,
    getPaywallTranslationsByLanguage,
    editLanguage,
  };

  return [getters, actions];
};
