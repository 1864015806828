import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SwitchLanguageVersion from '../SwitchLanguageVersion/SwitchLanguageVersion';
import './SettingsBox.scss';

import { translationTypes } from '../../Creator.constants';

const SettingsBox = ({
  adding,
  children,
  changed,
  currentLanguage,
  description,
  image,
  languages,
  onChangeLanguage,
  onCloseModal,
  onSaveProgress,
  title,
  type,
}) => {
  const getSettingsTitle = () => {
    if (type === 'globalSettings') {
      return title;
    }
    const action = adding ? 'Edit' : 'Add';
    return `${action} module: ${title}`;
  };

  // TODO: Post-Question language
  //  implementation for questions
  const isLanguageType = translationTypes.includes(type);

  return (
    <>
      <div className="creator-settings-section__backdrop" />
      <div className="creator-settings-section">
        <div className="creator-settings-section__inner">
          <div className="creator-settings-section__header">
            <h5 className="mb-0 creator-settings-section__box-title">
              {getSettingsTitle()}
              {currentLanguage && (
                <span className="ms-2 creator-settings-section__language">
                  {`( ${currentLanguage?.toUpperCase()} )`}
                </span>
              )}
            </h5>
            <div className="creator-settings-section__close">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => onCloseModal()}
                title="Close module settings"
              />
            </div>
          </div>
          <Row className="creator-settings-section__description">
            <Col>
              <div className="d-block d-xl-flex">
                {image ? (
                  <div className="img-container">
                    <img
                      src={image}
                      alt="Module example"
                      title="Module example"
                      className="img-fluid img-thumbnail"
                    />
                  </div>
                ) : (
                  ''
                )}
                <p>{description}</p>
              </div>
            </Col>
          </Row>
          {isLanguageType && (
            <Row>
              <Col className="mb-4">
                <SwitchLanguageVersion
                  currentLanguage={currentLanguage}
                  dataChanged={changed}
                  languages={languages}
                  onSwitchLanguage={(lang) => onChangeLanguage(lang)}
                  onClickAction={onSaveProgress}
                />
              </Col>
            </Row>
          )}
          <div className="creator-settings">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SettingsBox;
