import { useState, useEffect, useCallback } from 'react';
import _cloneDeep from 'lodash/cloneDeep';

import CompanyService from '../../api/Companies';
import { createCategory, createQuestion } from './PostQuestions.formdata';

const usePostQuestions = ({ companyId, categoryQuestions, onUpdate }) => {
  // TODO: Change language - future sprint, add setter "setLanguage"
  //  Design how to sync language with campaign TBD
  const [language] = useState('en');
  const [errors, setErrors] = useState({});
  const [isSaving, setSaving] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [removeItem, _setRemoveItem] = useState({
    cId: null,
    qId: null,
    label: '',
    key: '',
    modalTick: 0,
    inProgress: false,
    isQuestion: false,
  });

  /**
   * Triggers modal confirmation dialog
   */
  const setRemoveItem = useCallback(
    (cId, qId, label, categoryIdx, questionIdx) => {
      const isReset = cId == null;
      const isQuestion = qId != null;
      const key = isReset ? '' : `${cId}${isQuestion ? `-${qId}` : ''}`;

      _setRemoveItem((state) => {
        return {
          cId: isReset ? null : cId,
          qId: isReset ? null : qId,
          cIdx: isReset ? null : categoryIdx,
          qIdx: isReset ? null : questionIdx ?? null,
          key,
          label: label ?? '(?)',
          inProgress: !isReset,
          isQuestion,
          modalTick: state.modalTick + !isReset,
        };
      });
    },
    [],
  );

  const handleConfirmRemoveItem = useCallback(
    (confirmed) => {
      const { inProgress, isQuestion, cId, qId } = removeItem;

      if (!inProgress) {
        return Promise.resolve(null);
      }

      const apiParams = isQuestion
        ? [companyId, cId, qId, confirmed]
        : [companyId, cId, confirmed];

      const apiAction = isQuestion
        ? 'removeCategoryQuestion'
        : 'removeCategory';

      return CompanyService[apiAction](...apiParams);
    },
    [removeItem, companyId],
  );

  const [postQuestionData, setPostQuestionData] = useState(
    categoryQuestions?.categories ?? [],
  );

  useEffect(() => {
    setPostQuestionData(categoryQuestions?.categories ?? []);
  }, [categoryQuestions]);

  const handleCategoryInput = (
    categoryIdx,
    questionIdx,
    fieldKey,
    ev,
    isLanguageInput = true,
  ) => {
    const value = ev?.target?.value ?? ev?.value ?? ev;
    setHasChange(true);

    setPostQuestionData((state) => {
      const _state = _cloneDeep(state);
      const isUpdateCategory = questionIdx == null;

      if (isUpdateCategory) {
        if (isLanguageInput) {
          _state[categoryIdx][fieldKey][language] = value;
        } else {
          _state[categoryIdx][fieldKey] = value;
        }
      } else if (isLanguageInput) {
        _state[categoryIdx].questions[questionIdx][fieldKey][language] = value;
      } else {
        _state[categoryIdx].questions[questionIdx][fieldKey] = value;
      }

      return _state;
    });
  };

  const verifyAllowRemoval = async (category, categoryIdx, questionIdx) => {
    const isQuestion = questionIdx != null;
    const isUnsavedItem =
      category.categoryId == null ||
      (isQuestion && category?.questions?.[questionIdx]?.questionId == null);

    if (isUnsavedItem) {
      return true;
    }

    const { categoryId, categoryLabel } = category;
    const questionId = category?.questions?.[questionIdx]?.questionId;

    // TODO: Language implementation (will EN always be available)
    const label = isQuestion
      ? `${category.questions[questionIdx].questionLabel.en} (${categoryLabel.en})`
      : categoryLabel.en;

    setRemoveItem(categoryId, questionId, label, categoryIdx, questionIdx);
    return false;
  };

  const handleCtaAction = async (
    ev,
    type,
    categoryIdx,
    subIdx,
    skipVerify = false,
  ) => {
    // eslint-disable-next-line no-unused-expressions
    ev?.preventDefault();
    setHasChange(true);

    if (!skipVerify) {
      switch (type) {
        case 'remove-category':
        case 'remove-question':
          if (
            !(await verifyAllowRemoval(
              postQuestionData[categoryIdx],
              categoryIdx,
              subIdx,
            ))
          ) {
            return false;
          }
          break;

        default:
      }
    }

    return setPostQuestionData((state) => {
      const _state = _cloneDeep(state);

      switch (type) {
        case 'add-category':
          _state.push(createCategory());
          break;
        case 'add-question-select':
          _state[categoryIdx].questions.push(createQuestion());
          break;
        case 'add-question-text':
          _state[categoryIdx].questions.push(createQuestion('text'));
          break;
        // case 'add-terms':
        //     _state[categoryIdx].questions.push(createTerms());
        //     break;
        case 'remove-category':
          _state.splice(categoryIdx, 1);
          break;
        case 'remove-question':
          _state[categoryIdx].questions.splice(subIdx, 1);
          break;
        // case 'remove-terms': {
        //     const langKeys = Object.keys(_state[categoryIdx].terms);
        //     _state[categoryIdx].terms = langKeys.reduce(
        //         (acc, langKey) => {
        //             acc[langKey] = _state[categoryIdx].terms[
        //                 langKey
        //             ].splice(subIdx, 1);
        //             return acc;
        //         },
        //         {},
        //     );
        //     break;
        // }
        default:
          console.error(`Invalid PostQuestion action call (${type})`);
      }

      return _state;
    });
  };

  const validateFormData = (categories) => {
    const requiredLanguageFields = ['categoryLabel', 'questionLabel'];
    const requiredSelectFields = ['values'];
    const requiredFields = ['categoryImgUrl'];
    const validationErrors = {};

    const categoryLabels = [];
    let questionLabels = [];

    const iterateObj = (obj, location) => {
      Object.keys(obj).forEach((key) => {
        if (key === 'categoryLabel') {
          questionLabels = [];
          const categoryRegex = new RegExp(obj.categoryLabel.en.trim(), 'i');
          const categoryDuplicatesFound = categoryLabels.some((label) =>
            categoryRegex.test(label),
          );
          if (categoryDuplicatesFound) {
            validationErrors[`${location}.categoryLabel`] =
              'Duplicate category labels are not allowed';
          }

          categoryLabels.push(obj.categoryLabel.en);
        }

        if (key === 'questionLabel') {
          const questionRegex = new RegExp(obj.questionLabel.en.trim(), 'i');
          const questionDuplicatesFound = questionLabels.some((label) =>
            questionRegex.test(label),
          );
          if (questionDuplicatesFound) {
            validationErrors[`${location}.questionLabel`] =
              'Duplicate question labels are not allowed';
          }

          questionLabels.push(obj.questionLabel.en);
        }

        const isRequiredField = requiredFields.includes(key);
        const isRequiredSelectField =
          requiredSelectFields.includes(key) && obj?.fieldType === 'select';
        const isRequiredLanguageField = requiredLanguageFields.includes(key);

        if (
          isRequiredField ||
          isRequiredSelectField ||
          isRequiredLanguageField
        ) {
          const value = isRequiredField ? obj[key] : obj[key][language];
          const filteredValue = Array.isArray(value)
            ? value.filter((e) => e.trim())
            : value;

          if (!filteredValue?.length) {
            validationErrors[`${location}.${key}`] = 'Required field';
          }
        } else if (key === 'questions' && !obj[key]?.length) {
          validationErrors[`${location}._summary`] =
            'All categories must have at least one question. Please add a question or remove this category.';
        }

        if (Array.isArray(obj[key])) {
          iterateArr(obj[key], `${location}.${key}`);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          iterateObj(obj[key], `${location}.${key}`);
        }
      });
    };

    const iterateArr = (arr, location = 'categories') => {
      for (const [idx, row] of arr.entries()) {
        if (Array.isArray(row)) {
          iterateArr(arr, `${location}[${idx}]`);
        } else if (typeof row === 'object' && row !== null) {
          iterateObj(row, `${location}[${idx}]`);
        }
      }
    };

    iterateArr(categories);

    const errCount = Object.keys(validationErrors).length;
    if (errCount && !validationErrors._summary) {
      validationErrors._summary = 'Errors were found, please review the form';
    }

    setErrors(validationErrors);
    return errCount;
  };

  const handleSavePostQuestions = async () => {
    if (validateFormData(postQuestionData)) {
      return false;
    }

    setSaving(true);
    return CompanyService.saveCategories(companyId, {
      categories: postQuestionData,
    })
      .then(() => {
        setHasChange(false);
        setSaving(false);
        if (onUpdate) onUpdate();
        return true;
      })
      .catch((error) => {
        setSaving(false);

        const serverMessage = error?.response?.data?.message;

        const formErrors = error?.response?.data?._errors ?? {};
        const formErrorCount = Object.keys(formErrors).length;
        const defaultMessage = formErrorCount
          ? 'Errors were found, please review the form'
          : 'Something went wrong';

        if (serverMessage) {
          setErrors({
            _summary: serverMessage,
          });
        } else {
          setErrors({
            _summary: error.message ?? defaultMessage,
            ...formErrors,
          });
        }
        return false;
      });
  };

  const categoryCount = postQuestionData.length;

  const getters = {
    language,
    errors,
    isSaving,
    hasChange,
    removeItem,
    postQuestionData,
    categoryCount,
  };

  const actions = {
    resetRemoveState: () => setRemoveItem(null),
    handleConfirmRemoveItem,
    handleCtaAction,
    handleCategoryInput,
    handleSavePostQuestions,
  };

  return [getters, actions];
};

export default usePostQuestions;
