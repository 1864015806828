import React from 'react';
import {
  campaignStatus,
  translateCampaignStatus,
} from '../../../shared/campaignStatus';

const CampaignStatus = ({ campaignStatusData }) => {
  const translatedStatusCode = translateCampaignStatus(
    campaignStatusData?.statusCode,
  );
  let classN;
  switch (campaignStatusData?.statusCode) {
    case campaignStatus.STATE_INACTIVE:
    case campaignStatus.STATE_FALLBACK_INACTIVE:
      classN = 'openCampaignStatus openCampaignStatus--inactive';
      break;
    case campaignStatus.STATE_ACTIVE:
    case campaignStatus.STATE_ACTIVE_PAYWALL:
    case campaignStatus.STATE_ACTIVE_PAYWALL_NO_PROVIDER:
      classN = 'openCampaignStatus openCampaignStatus--active';
      break;
    case campaignStatus.STATE_FALLBACK:
    case campaignStatus.STATE_FALLBACK_GLOBAL:
      classN = 'openCampaignStatus openCampaignStatus--fallback';
      break;
    case campaignStatus.STATE_DRAFT:
    case campaignStatus.STATE_SCHEDULED:
      classN = 'openCampaignStatus openCampaignStatus--draft';
      break;
    default:
      classN = 'openCampaignStatus openCampaignStatus--draft';
  }
  return campaignStatusData ? (
    <span className={classN}>{translatedStatusCode}</span>
  ) : (
    <span className={classN}>Draft</span>
  );
};

export default CampaignStatus;
