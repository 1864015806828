import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../OfferDetail.module.scss';
import { OfferAccordionItem } from '../OfferAccordion/OfferAccordion';
import FileUploadInput from '../../UI/FileUploadInput/FileUploadInput';
import ColorPickerInput from '../../UI/ColorPickerInput/ColorPickerInput';

const OfferLandingPage = ({
  step,
  filePath,
  setFilePath,
  filePathLogo,
  setFilePathLogo,
  backgroundColor,
  setBackgroundColor,
  textColor,
  setTextColor,
}) => {
  const removeFile = (event) => {
    event.preventDefault();
    setFilePath(null);
  };

  return (
    <OfferAccordionItem
      step={step}
      title="Landing Page"
      body={
        <div>
          <Row>
            <Col xs={12} md={4} lg={4} xl={4}>
              <div className="mb-4">
                <FileUploadInput
                  buttonText={filePath ? 'Replace Banner' : 'Upload Banner'}
                  label="Paywall Banner"
                  withPreview={false}
                  filePath={filePath}
                  onFileUrlChange={setFilePath}
                  required
                />
              </div>
              <div className="mb-4">
                <FileUploadInput
                  buttonText={filePathLogo ? 'Replace Logo' : 'Upload Logo'}
                  label="Paywall Logo"
                  filePath={filePathLogo}
                  onFileUrlChange={setFilePathLogo}
                  backgroundColor={backgroundColor}
                />
              </div>
              <div className="mb-4">
                <p className="Label">Background Colour</p>
                <ColorPickerInput
                  onChange={(e) => setBackgroundColor(e.target.value)}
                  name="Background Color"
                  value={backgroundColor}
                />
              </div>
              <div className="mb-4">
                <p className="Label">Text Colour</p>
                <ColorPickerInput
                  onChange={(e) => setTextColor(e.target.value)}
                  name="Text Color"
                  value={textColor}
                />
              </div>
            </Col>
            <Col xs={12} md={8} lg={8} xl={8}>
              {!!filePath && (
                <div className="d-flex">
                  <img
                    className={styles.imagePreview}
                    alt="Paywall Banner"
                    src={`${process.env.REACT_APP_UPLOADS_URL}/${filePath}`}
                    data-test-id={`upload-thumb` || null}
                  />
                  <div
                    className={styles.imagePreviewClose}
                    onClick={(event) => {
                      removeFile(event);
                    }}
                  >
                    <FontAwesomeIcon fixedWidth icon={faTimes} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      }
    />
  );
};

export default OfferLandingPage;
