import React from 'react';
import { transformToPercentage } from '../../../shared/transformNumbers';

/* eslint-disable import/prefer-default-export */
export const prepareValuesToDisplay = (counts, budget, actualBudget, cost) => {
  const percentages = transformToPercentage(counts.cpdcs * cost, budget);
  const percentages2 = transformToPercentage(counts.cpdcs * cost, actualBudget);
  return {
    uniqueVisits: {
      title: 'Unique visits',
      value: counts.uniquePageViews || 0,
      prefix: '',
      sufix: '',
    },
    visits: {
      title: 'Visits',
      value: counts.pageViews || 0,
      prefix: '',
      sufix: '',
    },
    budgetSpent: {
      title: 'Budget spent',
      value: percentages || 0,
      prefix: '',
      sufix:
        percentages === percentages2 ? (
          <span>
            <strong>%</strong>
          </span>
        ) : (
          <span>
            % ({`${percentages2}%`}
            <span title="With the new budget">*</span>)
          </span>
        ),
    },
    CPDCs: {
      title: 'Connections',
      value: counts.cpdcs || 0,
      prefix: '',
      sufix: '',
    },
  };
};
