import React from 'react';
import SelectAsync from '../SelectAsync/SelectAsync';

/**
 * Wrapper for SelectAsync component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @example <FormSelectAsync
 *              control={formControl} key={inputKey}
 *              optionsApiUrl="/api/paywall/countries?query=:query"
                apiDataKey="countries"
            />
 */
const FormSelectAsync = (props) => (
  <SelectAsync
    label={props.control.label}
    controlId={props.inputKey}
    touched={props.control.touched}
    changed={(event) => props.inputChangedHandler(event, props.inputKey)}
    defaultValue={props.defaultValue}
    error={props.control.error ?? props.error}
    required={props?.control?.validation?.required}
    optionsApiUrl={props.optionsApiUrl}
    apiDataKey={props.apiDataKey}
    isMulti={props.isMulti}
    placeholder={props.control.elementConfig.placeholder}
    disabled={props.control.elementConfig.disabled}
    description={props.control.elementConfig.description}
    apiQueryKey={props.apiQueryKey}
    value={props.value}
  />
);

export default FormSelectAsync;
