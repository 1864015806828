import React, { useEffect, useState } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';

import styles from './ToggleSwitch.module.scss';

const ToggleSwitch = ({
  id = 1,
  className = '',
  label,
  onCheck,
  disabled = false,
  value = false,
  darkMode = false,
  reverseLabel = false,
  description,
  required,
  additionalLabelClasses,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  const onChangeHandler = () => {
    if (disabled) return;
    setIsChecked(!isChecked);
    onCheck(!isChecked);
  };

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const requiredField = required && <span className="text-danger"> *</span>;

  return (
    <FormCheck
      custom
      type="switch"
      className={
        `${disabled ? styles.disabled : ''} ` +
          'toggleSwitch' +
          (darkMode ? ' toggleSwitch--dark' : '') +
          ' d-flex align-items-center ' +
          className ?? ''
      }
    >
      <FormCheck.Input
        id={id}
        checked={isChecked}
        onChange={() => onChangeHandler()}
        className={`${
          darkMode ? 'toggleSwitch-bg-light' : 'toggleSwitch-bg'
        } toggleSwitch__input flex-shrink-0 ${
          reverseLabel ? styles.reverseLabel : ''
        }`}
      />

      {label && (
        <div className={description ? 'd-flex flex-column' : ''}>
          {label && (
            <FormCheck.Label
              onClick={() => onChangeHandler()}
              className={`toggleSwitch__label ${additionalLabelClasses ?? ''}`}
            >
              {label}
              {requiredField}
            </FormCheck.Label>
          )}
          {description && (
            <span className="formInputDescription small">{description}</span>
          )}
        </div>
      )}
    </FormCheck>
  );
};

export default ToggleSwitch;
