import React, { Fragment } from 'react';

const PositionInput = ({ position, setPosition }) => (
  <Fragment>
    <div className="position-input">
      <div className="position-line">
        <div
          className={`position-tile ${
            position === 'top left' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('top left')}
        />
        <div
          className={`position-tile ${
            position === 'top center' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('top center')}
        />
        <div
          className={`position-tile ${
            position === 'top right' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('top right')}
        />
      </div>
      <div className="position-line">
        <div
          className={`position-tile ${
            position === 'center left' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('center left')}
        />
        <div
          className={`position-tile ${
            position === 'center center' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('center center')}
        />
        <div
          className={`position-tile ${
            position === 'center right' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('center right')}
        />
      </div>
      <div className="position-line">
        <div
          className={`position-tile ${
            position === 'bottom left' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('bottom left')}
        />
        <div
          className={`position-tile ${
            position === 'bottom center' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('bottom center')}
        />
        <div
          className={`position-tile ${
            position === 'bottom right' ? 'position-active' : ''
          }`}
          onClick={() => setPosition('bottom right')}
        />
      </div>
    </div>
    <div className="position-value">{position}</div>
  </Fragment>
);

export default PositionInput;
