import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';

const CampaignSectionEmail = ({
  step,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  sectionsRef,
}) => {
  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="email"
      title="Fields Settings"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      sectionsRef={sectionsRef}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          Specify the need for collecting extra information on the campaign by
          enabling/disabling the fields in this section.
        </p>
      </CampaignAccordionItem.Tooltip>

      <Row className="mb-5">
        <Col>
          <ToggleSwitchInput
            id="excludeEmail"
            label="Hide Email Fields?"
            text={formik.values.excludeEmail ? 'Yes' : 'No'}
            value={formik.values.excludeEmail}
            description="This will hide the email fields in the campaign submission forms"
            onCheck={(bool) => formik.updateFieldValue('excludeEmail', bool)}
          />
        </Col>
        <Col>
          <ToggleSwitchInput
            id="collectMailingAddress"
            label="Collect Mailing Address?"
            text={formik.values.collectMailingAddress ? 'Yes' : 'No'}
            value={formik.values.collectMailingAddress}
            description="This will enable the collection of the mailing address on the campaign form as an additional field."
            onCheck={(bool) =>
              formik.updateFieldValue('collectMailingAddress', bool)
            }
          />
        </Col>
        <Col>
          {
            // This column is only for consistency with other accordions that have three columns. We can delete it if it is deemed unnecessary.
          }
        </Col>
      </Row>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionEmail;
