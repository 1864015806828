import { useState } from 'react';

const useModalConfirm = ({
  id,
  title,
  body,
  toastSuccessMsg,
  onSuccess,
  apiRequest,
  supplementalErrorKey,
}) => {
  const [openTick, setTick] = useState(0);

  const formatSupplementalError = (response) => {
    const errorMessage = response.data?.message ?? '';
    const labelValuePairs = response?.data?.[supplementalErrorKey] ?? [];
    return [errorMessage, labelValuePairs];
  };

  const defaultProps = () => ({
    title,
    body,
    toastSuccessMsg: toastSuccessMsg ?? 'Action was completed successfully',
    apiId: id,
    onSuccess,
    apiRequest,
    formatSupplementalError,
    openTick,
  });

  const [modalProps, _setModalProps] = useState(defaultProps());

  const setModalProps = (newProps) => {
    setTick((n) => {
      const tick = n + 1;

      _setModalProps((state) => ({
        ...state,
        ...newProps,
        openTick: tick,
      }));

      return tick;
    });
  };

  const resetModalProps = () =>
    _setModalProps((state) => ({
      ...defaultProps(),
      openTick: state.openTick,
    }));

  return [modalProps, setModalProps, resetModalProps];
};

export default useModalConfirm;
