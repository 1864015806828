import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';

import UserService from '../../../api/Users';
import inputChangedHandler from '../../../shared/inputChangeHandler';

import AuthMfaEnroll from '../../../components/Auth/AuthMfaEnroll';
import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import bountyMediaLogo from '../../../assets/images/bounty-media-logo.png';

import { lockActivation, authFail } from '../../../store/actions/auth';

import setPasswordControl, { emailSignInControl } from './controls';

import styles from './ActivatePage.module.scss';

/**
 * Handle 3 states:
 *  (i) Submit email (sign in link)
 *  (ii) Enroll MFA
 *  (iii) Set password
 *
 * !! Firebase will invalidate the session against MFA enrollment
 *  if setting a password prior to MFA.
 *
 * !! Setting MFA must occur within a set time of the last sign in
 *  else the user will be signed out.
 *
 * isEmailLinkAuth: True when user loads this page from an email link
 * setPassword: True when calling "lockActivation(true)" here
 * setMultiFactor: True when user session activates (App.js), and no MFA
 *  is set for required accounts (role-based)
 */
const ActivatePage = () => {
  const navigate = useNavigate();
  const initialLoadRef = useRef(true);
  const dispatch = useDispatch();
  const firebaseAuth = getAuth();
  const [controls, setControls] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const setError = (errorMessage) => dispatch(authFail(errorMessage));

  const [isEmailLinkAuth, setEmailLinkAuth] = useState(false);

  const { error, userId, setPassword, setMultiFactor } = useSelector(
    (state) => state.auth,
  );

  const showMfaEnrollment = useMemo(
    () => !isEmailLinkAuth && setMultiFactor,
    [isEmailLinkAuth, setMultiFactor],
  );

  /**
   * Show intermediate loading phase between:
   *  user email address submission -> user session activation (App.js)
   */
  const isLoading = useMemo(
    () => !isEmailLinkAuth && !userId,
    [isEmailLinkAuth, userId],
  );

  useEffect(() => {
    if (!initialLoadRef.current) return;
    const isEmailSignIn =
      !setPassword && isSignInWithEmailLink(firebaseAuth, window.location.href);

    setEmailLinkAuth(isEmailSignIn);
    setControls(isEmailSignIn ? emailSignInControl : setPasswordControl);
    initialLoadRef.current = false;
  }, [firebaseAuth, setPassword]);

  /** Firebase email link sign in */
  const signInWithEmail = (email) => {
    // store.auth.setPassword: true, prevent redirect to campaign list
    dispatch(lockActivation(true));

    signInWithEmailLink(firebaseAuth, email, window.location.href)
      .then(() => {
        /**
         * MainRouter will NOT (since package update) cause a refresh
         * New password will be prompted ( isEmailLinkAuth = false )
         * User data will be stored on auth change event
         *  Refer: firebase.onAuthStateChanged in App.js
         */
        setEmailLinkAuth(false);
        setControls(setPasswordControl);
      })
      .catch((_error) => {
        dispatch(lockActivation(false));
        setError(_error.message.replace(/Firebase:( )?/i, ''));
      });
  };

  const activationHandler = async (event) => {
    event.preventDefault();
    if (isEmailLinkAuth) {
      return signInWithEmail(controls.email.value);
    }

    const isMatchingPassword =
      controls.password.value === controls.repeatPassword.value;
    if (!isMatchingPassword) {
      return setError('Password and Repeat Password must be identical');
    }

    return UserService.activate(
      controls.password.value,
      controls.repeatPassword.value,
    )
      .then(() => {
        dispatch(lockActivation(false));
        navigate('/');
      })
      .catch((err) => {
        let errorMessage = '';

        Object.keys(err.response.data._errors).forEach((param) => {
          const msg = err.response.data._errors[param];
          if (!errorMessage.includes(msg)) {
            errorMessage += ` ${msg}`;
          }
        });
        setError(errorMessage);
      });
  };

  const inputChangeHandler = (event, inputIdentifier) => {
    const { value } = event.target;
    const [updatedCampaignForm, formIsValid] = inputChangedHandler(
      value,
      { controls },
      inputIdentifier,
    );
    setControls(updatedCampaignForm);
    setIsFormValid(formIsValid);
  };

  const renderForm = () => {
    const formElementsArray = [];
    Object.keys(controls).forEach((key) => {
      formElementsArray.push({
        id: key,
        config: controls[key],
      });
    });

    return (
      <form className={styles.activateForm} onSubmit={activationHandler}>
        {formElementsArray.map((formElement) => (
          <Input
            additionalClasses={styles.formInput}
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangeHandler(event, formElement.id)}
            error={formElement.config.error}
          />
        ))}
        <p className={styles.subText}>
          By activating my account, I agree to the service{' '}
          <Link to="/terms-and-conditions" target="_blank">
            Terms & Conditions
          </Link>
        </p>
        <Button additionalClasses={styles.btnDefault} disabled={!isFormValid}>
          Activate
        </Button>
      </form>
    );
  };

  const getErrorMessage = () =>
    error ? (
      <p className={styles.authFormError}>
        <FontAwesomeIcon
          className={styles.authFormErrorIcon}
          data-test-id="activation-page-error"
          icon={faExclamationCircle}
        />
        {error}
      </p>
    ) : null;

  if (!isLoading && showMfaEnrollment) {
    // Activation step (ii)
    return <AuthMfaEnroll />;
  }

  return (
    <div className={styles.activatePage}>
      <div>
        {isLoading && <OverlaySpinner />}

        {/* Activation steps (i) and (iii) */}
        {!isLoading && !showMfaEnrollment && (
          <>
            <img
              className={styles.logo}
              src={bountyMediaLogo}
              alt="Bounty Media logo"
            />
            {getErrorMessage()}
            {renderForm()}
          </>
        )}
      </div>
    </div>
  );
};

export const ActivateRedirect = () => {
  const location = useLocation();
  return <Navigate to={'/activate' + location.search} replace />;
};

export default ActivatePage;
