import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import TableData from '../TableData/TableData';
import Pagination from '../Pagination/Pagination';

const TablePaginated = ({
  tableData,
  tableColumns,
  tableFilters,
  isLoading,
  actions,
  evenColumns,
  pathToDetail,
  disableInactive,
  currentPage,
  totalPages,
  totalCount,
  onPageUpdated,
  onFilterUpdated,
  onDelete = null,
}) => {
  const tableDataProps = {
    tableData,
    tableColumns,
    tableFilters,
    isLoading,
    actions,
    evenColumns,
    pathToDetail,
    disableInactive,
    onFilterUpdated,
    onDelete,
  };

  const paginationProps = {
    current_page: Number(currentPage),
    total_pages: totalPages,
    total_count: totalCount,
    pageChange: onPageUpdated,
  };

  const showPagination = !isLoading && tableData?.length;

  return (
    <Container fluid className="pt-3">
      <Row>
        <Col>
          <TableData {...tableDataProps} />

          {showPagination ? <Pagination {...paginationProps} /> : null}
        </Col>
      </Row>
    </Container>
  );
};

export default TablePaginated;
