import React from 'react';

import PostQuestionSelector from '../../../PostQuestions/PostQuestionSelector/PostQuestionSelector';
import usePostQuestionCategorySelector from './PostQuestionCategorySelector.hooks';

import styles from './PostQuestionCategorySelector.module.scss';

const PostQuestionCategorySelector = ({
  config,
  dataChanged,
  onInputChange,
}) => {
  const [getters, actions] = usePostQuestionCategorySelector({
    config,
    dataChanged,
    onInputChange,
  });

  const { langKey, hasCategories, categories, selectedQuestionIds } = getters;
  const { handleToggle, handleSelectAll } = actions;

  return (
    <div className="mt-3">
      <p className="Label">
        Question Categories
        <span className="text-danger"> *</span>
      </p>

      {!hasCategories && (
        <p className="p-1 small text-muted">
          There are no available Post-Question configurations for the company
        </p>
      )}

      {hasCategories
        ? categories.map((category, cidx) => {
            return (
              <div
                className={styles.questionCategory}
                key={category.categoryId || cidx}
              >
                <h5>
                  <span className={styles.categoryText}>Category: </span>
                  {category.categoryLabel?.[langKey]}{' '}
                  <button
                    className={styles.selectAll}
                    onClick={(ev) => handleSelectAll(ev, category.questions)}
                  >
                    <small>Toggle all</small>
                  </button>
                </h5>

                {category.questions.map((question) => (
                  <PostQuestionSelector
                    key={question.questionId}
                    question={question}
                    checked={selectedQuestionIds.includes(question.questionId)}
                    onToggle={handleToggle}
                  />
                ))}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default PostQuestionCategorySelector;
