import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DashboardCampaignSection from '../../components/Dashboard/DashBoardCampaignSection';
import DashBoardProviderSection from '../../components/Dashboard/DashBoardProviderSection';
import DashboardConnectionsSection from '../../components/Dashboard/DashboardConnectionsSection';
import DashboardRevenueSection from '../../components/Dashboard/DashboardRevenueSection';

import UserService from '../../api/Users';

import styles from './DashboardPage.module.scss';

const DashboardPage = () => {
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState('');
  const [headerBackground] = useState('var(--bs-orange)');

  useEffect(() => {
    const init = async () => {
      try {
        const response = await UserService.get(user.uid);
        const { user: userData } = response.data;
        if (!userData) {
          throw new Error('Network failure on user update');
        }
        setName(userData.name ?? user.email);
      } catch (error) {
        process.env.NODE_ENV !== 'production' && console.error(error);
        setName(user.email);
      }
    };

    init();
  }, [user.email, user.uid]);

  return (
    <Container fluid className={`pt-5 ${styles.dashboardContainer}`}>
      <div style={{ background: headerBackground }} className={styles.header}>
        <h1>Welcome, {name}!</h1>
        <p>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3609 16H14.998C15.5503 16 15.998 15.5523 15.998 15L15.998 7.94313C15.998 7.39085 15.5503 6.94313 14.998 6.94313H10.3609C9.80859 6.94313 9.36088 7.39085 9.36088 7.94314V15C9.36088 15.5523 9.8086 16 10.3609 16Z"
              fill="white"
            />
            <path
              d="M10.3609 4.21947L14.998 4.21947C15.5503 4.21947 15.998 3.77175 15.998 3.21947V0.999996C15.998 0.447711 15.5503 -4.29153e-06 14.998 -4.29153e-06L10.3609 -4.29153e-06C9.80859 -4.29153e-06 9.36088 0.44771 9.36088 0.999995V3.21947C9.36088 3.77175 9.8086 4.21947 10.3609 4.21947Z"
              fill="white"
            />
            <path
              d="M5.63717 0.0019989H1C0.447715 0.0019989 0 0.449715 0 1.002V8.05886C0 8.61115 0.447714 9.05886 0.999999 9.05886H5.63717C6.18945 9.05886 6.63717 8.61115 6.63717 8.05886V1.002C6.63717 0.449714 6.18946 0.0019989 5.63717 0.0019989Z"
              fill="white"
            />
            <path
              d="M5.63717 11.7805H1C0.447715 11.7805 0 12.2282 0 12.7805V15C0 15.5523 0.447714 16 0.999999 16H5.63717C6.18945 16 6.63717 15.5523 6.63717 15V12.7805C6.63717 12.2282 6.18946 11.7805 5.63717 11.7805Z"
              fill="white"
            />
          </svg>
          <span className="ms-2">
            Welcome to your dashboard. Here’s a quick overview for you.
          </span>
        </p>
      </div>
      <div className={styles.mainContainer}>
        <Row>
          <Col xl={8} className="mb-4">
            <div className={styles.infoContainer}>
              <DashboardCampaignSection />
            </div>
          </Col>
          <Col xl={4} className="mb-4">
            <div className={styles.infoContainer}>
              <DashBoardProviderSection />
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col xl={6} className="mb-4">
            <div className={styles.infoContainer}>
              <DashboardConnectionsSection />
            </div>
          </Col>
          <Col xl={6} className="mb-4">
            <div className={styles.infoContainer}>
              <DashboardRevenueSection />
            </div>
          </Col>
        </Row> */}
      </div>
    </Container>
  );
};

export default DashboardPage;
