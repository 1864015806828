import createInput from '../../../shared/Form/createInput';

export default {
  name: createInput('Company name', { type: 'text' }, { required: true }),
  type: createInput(
    'Company type',
    {
      type: 'modern-select',
      options: [
        { label: 'Provider Partner', value: 'PROVIDER' },
        { label: 'Advertising Partner', value: 'ADVERTISER' },
      ],
    },
    { required: true },
    '',
    false,
    'modern-select',
  ),
  providers: createInput(
    'Offers',
    {
      type: 'multiselect',
      placeholder: 'No offers',
    },
    { required: false, testId: 'input-providers' },
    '',
    true,
    'multiselect',
  ),
  brands: createInput(
    'Brands',
    {
      type: 'multiselect',
      placeholder: 'Enter new brand(s)',
      options: [],
    },
    { required: true, testId: 'input-brands' },
    undefined,
    true,
    'create-multi',
  ),
  industries: createInput(
    'Industries',
    {
      type: 'multiselect',
      placeholder: 'Select Industries',
    },
    { required: true, testId: 'input-industries', maxLength: 3, minLength: 1 },
    [],
    false,
    'create-multi',
  ),
};
