import React from 'react';

import styles from './TextLink.module.scss';

const TextLink = (props) => (
  <button
    target={props.target || '_self'}
    disabled={props.disabled}
    className={`${styles.link} ${props.muted ? styles.muted : ''} ${
      props.className ?? ''
    }`}
    onClick={props.clicked}
    data-test-id={props.dataTestAttribute || null}
  >
    {props.children}
  </button>
);

export default TextLink;
