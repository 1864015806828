import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { useLogout } from './App.logout';

export const useSockets = () => {
  const logout = useLogout();
  const { token } = useSelector((state) => state.auth);

  const [enabled, setEnabled] = useState(false);
  const [socketClient, setSocketClient] = useState(null);
  const [socketConnecting, setSocketConnecting] = useState(false);

  window.enableSockets = setEnabled; // For testing

  /* Handle connect/disconnect */
  useEffect(() => {
    if (enabled) {
      if (!token && socketClient) {
        socketClient.disconnect();
        setSocketClient(null);
      }

      if (token && !socketClient && !socketConnecting) {
        setSocketConnecting(true);

        const socket = io.connect(process.env.REACT_APP_API_URL, {
          withCredentials: true, // Required for sticky cookie sessions
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: `Bearer ${token}`,
              },
            },
          },
        });

        socket.on('connect', () => {
          setSocketClient(socket);
          setSocketConnecting(false);
        });

        /**
         * Socket.io will retry connection on an error
         *  a disconnect event will mean all retry attempts failed
         *
         * Ref: https://socket.io/docs/v4/client-socket-instance/#disconnect
         */
        socket.on('disconnect', () => {
          setSocketClient(null);
          setSocketConnecting(false);
        });

        socket.on('signout', () => {
          logout();
        });
      }
    }

    /* Clean up */
    return () => {
      if (socketClient) {
        socketClient.disconnect();
      }
    };
  }, [enabled, logout, token, socketClient, socketConnecting]);

  return socketClient;
};
