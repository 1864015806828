import React from 'react';

export const CampaignCallbackContext = React.createContext(null);
export const CampaignDataContext = React.createContext({});
export const CampaignFilterContext = React.createContext({
  name: '',
  brand: '',
  company: '',
  tags: [],
});
