import React from 'react';
import Table from 'react-bootstrap/Table';

import Input from '../Input/Input';
import TableDataRow from './TableDataRow/TableDataRow';
import OverlaySpinner from '../OverlaySpinner/OverlaySpinner';

/**
 * @param {Array} tableData - array of row objects
 * @param {Array} tableColumns - array of key, label pairs - key must match in tableData
 * @param {Array} tableFilters - (optional) array matching column length, with input filter configurations
 * @param {Array} actions - refer to TableDataRow
 * @param {String} pathToDetail - refer to TableDataRow
 * @param {Boolean} disableInactive - refer to TableDataRow
 */

const TableData = ({
  tableData,
  tableColumns,
  tableFilters,
  isLoading,
  actions,
  evenColumns,
  pathToDetail,
  disableInactive,
  onFilterUpdated,
  actionWidth = 220,
  onDelete = null,
}) => {
  const hasActions = !!actions?.length;
  const columns = tableColumns.length + hasActions;
  const width = evenColumns
    ? {
        width: `${parseInt((1 / columns) * 100, 10)}%`,
      }
    : {};

  return (
    <>
      {(isLoading || !tableData) && <OverlaySpinner />}
      {tableData && (
        <Table
          id="data-list-table"
          className="defaultTable"
          striped
          hover
          responsive
        >
          <thead>
            <tr>
              {tableColumns.map((column) => (
                <th {...width} key={column.key}>
                  {column.label}
                </th>
              ))}
              {hasActions && (
                <th key="actions" width={actionWidth}>
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tableFilters?.length && (
              <tr>
                {tableFilters.map((filter, i) => (
                  <td key={filter?.key ?? i}>
                    {filter ? (
                      <div className={filter.className}>
                        <Input
                          value={filter.value}
                          elementType={filter.type} // text|select
                          elementConfig={{
                            placeholder: filter.placeholder,
                            options: filter.options ?? [],
                          }}
                          additionalClasses="formInput listInput"
                          changed={(event) =>
                            onFilterUpdated(
                              filter.key,
                              event?.target?.value ?? event?.value ?? event,
                            )
                          }
                          dataTestAttribute={`table-filter-${filter.key}`}
                        />
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
            )}
            {tableData.map((rowData, i) => (
              <TableDataRow
                key={rowData.id ?? i}
                rowData={rowData}
                tableColumns={tableColumns}
                actions={actions}
                pathToDetail={pathToDetail}
                disableInactive={disableInactive}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default TableData;
