import { boolean } from 'yup';

export const getSchema = () => ({
  excludeEmail: boolean(),
});

const displayFields = {
  excludeEmail: {
    key: 'excludeEmail',
    defaultValue: false,
  },
};

export default displayFields;
