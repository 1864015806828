import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CustomBrowserRouter } from './router/CustomBrowserRouter';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './vendor/firebase'; // Initialise firebase
import './index.scss';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <CustomBrowserRouter>
      <App />
    </CustomBrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
