import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import FormikInput from '../../UI/FormikInput/FormikInput';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';

const CampaignSectionCallback = ({
  step,
  displayFields,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  sectionsRef,
}) => {
  const callbackEnabled = !!formik.values.callback;

  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="callback"
      title="Callback URL"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      sectionsRef={sectionsRef}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          The callback URL system makes the publishers able to dynamically send
          the users to the Bounty platform to claim offers and on successful
          campaign redemption, to send the user to a landing page other than the
          normal Bounty success page. The callback URL will also be used on the
          publisher platform to receive the information from Bounty that is
          required on their end to update the user`s status.
        </p>
      </CampaignAccordionItem.Tooltip>

      <Row>
        <Col xs={6}>
          <ToggleSwitchInput
            id="callbackEnabled"
            label="Enable Callback URL"
            text={callbackEnabled ? 'Yes' : 'No'}
            description="At completion of the campaign submission, the user can return to a designated URL"
            onCheck={(bool) => {
              formik.updateFieldValue('callback', bool ? 1 : 0);
            }}
            value={callbackEnabled}
          />
        </Col>

        {callbackEnabled && (
          <>
            <Col xs={6}>
              <FormikInput
                key="callback"
                field={displayFields.callback}
                formik={formik}
                validationSchema={validationSchema}
                description={
                  formik.values.callback === 1
                    ? 'The URL parameter ?bm_referrer will provide the return address for the end-user. If not provided in the URL, the feature will be disabled.'
                    : ''
                }
              />
              {formik.values.callback === 2 && (
                <FormikInput
                  key="callbackUrl"
                  field={displayFields.callbackUrl}
                  formik={formik}
                  validationSchema={validationSchema}
                />
              )}
            </Col>
            <Col xs={6}>
              <div className="mt-4">
                <ToggleSwitchInput
                  id="callbackParams"
                  label="Include Consumer Parameters"
                  text={formik.values.callbackParams ? 'Yes' : 'No'}
                  description="The following parameters will be passed back in the return URL if enabled: bm_cid, bm_pid, bm_bid, bm_campaign_name, bm_submission_date"
                  onCheck={(bool) => {
                    formik.updateFieldValue('callbackParams', bool);
                  }}
                  value={!!formik.values.callbackParams}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mt-4">
                <ToggleSwitchInput
                  id="callbackCouponCodeParam"
                  label="Include Offer Code"
                  text={formik.values.callbackCouponCodeParam ? 'Yes' : 'No'}
                  description="The following parameter will be passed back in the return URL if enabled: coupon_code"
                  onCheck={(bool) => {
                    formik.updateFieldValue('callbackCouponCodeParam', bool);
                  }}
                  value={!!formik.values.callbackCouponCodeParam}
                />
              </div>
            </Col>
          </>
        )}
      </Row>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionCallback;
