import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import englishLabels from 'react-phone-number-input/locale/en';

/**
 * Field configurations
 *
 * type: Refers to the switch logic in Input.js
 * elementConfig.type is required to pass native type
 *
 * New options --
 *
 *  defaultValue: any                         -   default value
 *  formatOutput(value, opts): func           -   preprocess the returned selection value
 *  formatInputValue(value): func             -   preprocess "value" into Input component
 *  transposeOptions(opts): func              -   get (value, label) from an unshaped list of options
 *  valueKey: string                          -   match this property key against the options list "value"
 *                                                  property, to display the selection
 */
export const displayFields = (formik) => ({
  companyPrimaryColor: {
    key: 'companyPrimaryColor',
    label: 'Company Primary Color',
    type: 'colorPicker',
    dataTestAttribute: 'companyPrimaryColor',
  },
  countries: {
    key: 'countries',
    label: 'Countries',
    type: 'multiselect',
    defaultValue: [],
    elementConfig: {
      placeholder: 'Select Country',
      options: [
        ...getCountries().map((country) => ({
          value: englishLabels[country],
          label: `${englishLabels[country]} +${getCountryCallingCode(country)}`,
        })),
      ],
    },
    formatOutput: (arr) => {
      if (!arr) {
        return null;
      }
      return arr.map((e) => e.value);
    },
    formatInputValue: (value) => {
      if (Array.isArray(value)) {
        return value.map((selection) => {
          return {
            value: selection,
            label: selection,
          };
        });
      }
      return null;
    },
  },
  website: {
    key: 'website',
    label: 'Website',
    elementConfig: {
      placeholder: 'https://domain.url',
    },
  },
  contactPerson: {
    key: 'contactPerson',
    label: 'Contact Person',
  },
  contactEmail: {
    key: 'contactEmail',
    label: 'Contact Email',
  },
  contactPhone: {
    key: 'contactPhone',
    label: 'Contact Phone',
  },
  address: {
    key: 'address',
    label: 'Address',
  },
  audienceSizeUpTo: {
    key: 'audienceSizeUpTo',
    label: 'Audience Size Up To',
    elementConfig: {
      type: 'number',
    },
  },
  audienceGenderMale: {
    key: 'audienceGenderMale',
    label: 'Male',
    elementConfig: {
      type: 'number',
    },
    formatOutput: (value) => {
      const isDirty = value > 0;
      if (isDirty > 0) {
        formik.setFieldValue('audienceGenderFemale', 100 - value);
      } else formik.setFieldValue('audienceGenderFemale', null);
      return value ? Number(value) : null;
    },
  },
  audienceGenderFemale: {
    key: 'audienceGenderFemale',
    label: 'Female',
    elementConfig: {
      type: 'number',
    },
    formatOutput: (value) => {
      const isDirty = value > 0;
      if (isDirty > 0) {
        formik.setFieldValue('audienceGenderMale', 100 - value);
      } else formik.setFieldValue('audienceGendeMale', null);
      return value ? Number(value) : null;
    },
  },
  audienceTechRating: {
    key: 'audienceTechRating',
    label: 'Audience Tech Rating',
    elementConfig: {
      type: 'number',
    },
  },
  companyLogo: {
    key: 'companyLogo',
    type: 'image',
    label: 'Company Logo',
    fileChange: (uploadPath, keyName) => {
      formik.setFieldValue(keyName, uploadPath);
    },
  },
});
