import React, { useState, useCallback, useRef, useEffect } from 'react';

import DataTable from '../../UI/DataTable/DataTable';
import CampaignService from '../../../api/Campaigns';
import TextLink from '../../UI/Button/TextLink';

import styles from './CampaignHealthCheck.module.scss';

const StatusPassFail = ({ value: bool }) => {
  return (
    <div className={`${styles.statusContainer}`}>
      <span className={`${styles.status} ${bool && styles.statusPass}`} />
    </div>
  );
};

const StatusDescription = ({ value: str }) => {
  return <div className={`${styles.descriptionContainer}`}>{str}</div>;
};

const columns = [
  { header: '#', name: 'id', width: 60 },
  { header: 'Check', name: 'label', minWidth: 180 },
  {
    header: 'Status',
    name: 'status',
    width: 100,
    textAlign: 'center',
    render: StatusPassFail,
  },
  {
    header: 'Description',
    name: 'description',
    defaultFlex: 1,
    render: StatusDescription,
  },
];

const CampaignHealthCheck = ({ id }) => {
  const [loadError, setLoadError] = useState(false);
  const [footNote, setFootNote] = useState('');
  const reload = () => setLoadError(false);

  const getHealthCheck = async (id_) => {
    try {
      const { data: campaignHealth } = await CampaignService.getHealthCheck(
        id_,
      );

      if (!Array.isArray(campaignHealth?.healthChecks)) {
        throw new Error('Invalid Campaign health status response');
      }

      if (campaignHealth?.footNote) {
        setFootNote(campaignHealth.footNote);
      }

      return {
        data: campaignHealth.healthChecks,
        count: campaignHealth.healthChecks.length,
      };
    } catch (error) {
      setLoadError(true);
      return {
        data: [],
        count: 0,
      };
    }
  };

  const getDataSource = useCallback(() => getHealthCheck(id), [id]);

  if (loadError) {
    return (
      <div>
        Something went wrong,<TextLink clicked={reload}>try again</TextLink>?
      </div>
    );
  }

  return (
    <>
      <DataTable
        id="brand"
        columns={columns}
        dataSource={getDataSource}
        columnOrderPersist
        paginate={false}
        minRowHeight={0}
        rowHeight={null}
        style={{
          minHeight: '400px',
        }}
      />
      <p>
        <small>{footNote}</small>
      </p>
    </>
  );
};

export default CampaignHealthCheck;
