import { object, string } from 'yup';

export const getSchema = (answers) => {
  const answerFields = Object.assign(
    ...answers.map((answer) => ({
      [answer.id]: string().when('questionType', {
        is: 'integer',
        then: (schema) =>
          schema
            .required('Please enter an answer')
            .matches(/^[0-9]+$/, 'Must be a number')
            .max(100, 'Must be less than 100 characters'),
        otherwise: (schema) =>
          schema
            .required('Please enter an answer')
            .max(100, 'Must be less than 100 characters'),
      }),
    })),
  );

  return object({
    questionType: string().required('Please select a question type'),
    questionLabel: string()
      .required('Please enter a question label')
      .max(100, 'Must be less than 100 characters'),
    ...answerFields,
  });
};

export const getFormFields = ({
  answers = [],
  disableQuestionType = false,
  language = 'en',
  questionTranslations = {},
  questionType = 'select',
}) => {
  return [
    {
      key: 'questionType',
      label: 'Question Type',
      value: questionType,
      type: 'select',
      elementConfig: {
        disabled: disableQuestionType,
        options: [
          {
            value: 'multiselect',
            displayValue: 'Multi Select',
          },
          {
            value: 'select',
            displayValue: 'Single Select',
          },
          {
            value: 'integer',
            displayValue: 'Number',
          },
        ],
      },
    },
    {
      key: 'questionLabel',
      label: 'Question',
      type: 'text',
      value: questionTranslations[language] || '',
    },
    ...answers.map((answer, index) => ({
      key: answer.id,
      label: `Answer ${index + 1}`,
      type: 'text',
      value: answer.answerTranslations?.[language] || '',
      isNewAnswer: answer.isNewAnswer,
    })),
  ];
};
