/* eslint-disable import/prefer-default-export */

/**
 * Converts LandingPage config data
 * @param {Array} data
 * @param {Object} inputDefinition
 * @param {Object} termDefinition
 * @param {Object} textFieldDefinition
 *
 * @returns {Object}
 */
export const convertInitialData = (
  data,
  inputDefinition,
  termDefinition,
  textFieldDefinition,
) => {
  const newInputs = {};
  let inputsAreValid = false;
  const newTerms = {};
  let termsAreValid = false;
  const newTextFields = {};
  let textFieldsAreValid = false;

  data.map((el) => {
    let inputsLength = Object.keys(newInputs).length;
    let textFieldsLength = Object.keys(newInputs).length;

    if (el.id === 'inputs') {
      const configValues = el.config.value;
      const isValid = el.config.valid;
      inputsAreValid = isValid;

      if (configValues) {
        configValues.map((e) => {
          Object.keys(e).map((field) => {
            const control = inputDefinition[field];
            const nField = {
              [field]: {
                ...control,
                value: e[field],
                valid: isValid,
              },
            };
            newInputs[`input_${inputsLength}`] = {
              ...newInputs[`input_${inputsLength}`],
              ...nField,
            };
            return control;
          });
          inputsLength += 1;
          return configValues;
        });
      }
    }
    if (el.id === 'terms') {
      const configValues = el.config.value;
      const isValid = el.config.valid;
      termsAreValid = isValid;
      if (configValues) {
        configValues.map((e, i) => {
          const control = termDefinition;

          newTerms[`term_${i}`] = {
            ...control,
            value: e,
            valid: isValid,
          };
          return control;
        });
      }
    }
    if (el.id === 'textFields') {
      const configValues = el.config.value;
      const isValid = el.config.valid;
      textFieldsAreValid = isValid;

      if (configValues) {
        configValues.map((e) => {
          Object.keys(e).map((field) => {
            const control = textFieldDefinition[field];
            const nField = {
              [field]: {
                ...control,
                value: e[field],
                valid: isValid,
              },
            };
            newTextFields[`textField_${textFieldsLength}`] = {
              ...newTextFields[`textField_${textFieldsLength}`],
              ...nField,
            };
            return control;
          });
          textFieldsLength += 1;
          return configValues;
        });
      }
    }
    return { newInputs, newTerms, newTextFields };
  });

  return {
    newInputs: {
      data: newInputs,
      formIsValid: inputsAreValid,
    },
    newTerms: {
      data: newTerms,
      formIsValid: termsAreValid,
    },
    newTextFields: {
      data: newTextFields,
      formIsValid: textFieldsAreValid,
    },
  };
};

/**
 *
 * @param {Object} translationsConfig
 * @param {Array} newSlides
 *
 * @returns {Object} newTranslationsConfig
 */
export const replaceSlidesInTranslations = (translationsConfig, newSlides) => {
  if (!translationsConfig || !Object.keys(translationsConfig).length) {
    return translationsConfig;
  }

  const newTranlationConfig = { ...translationsConfig };
  return Object.keys(newTranlationConfig).map((lang) => {
    const item = { ...newTranlationConfig[lang] };
    return {
      ...item,
      slides: newSlides,
    };
  });
};

/**
 *
 * @param {Array} slides
 *
 * @returns {Array} reducedSldies
 */
export const reduceProviderInfoInBanners = (slides) => {
  if (!slides || !slides.length) return slides;
  return [...slides].map((item) => {
    const newItem = { ...item };
    newItem.provider = item?.provider?.id;

    return newItem;
  });
};
