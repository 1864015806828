import ReactDataGrid from '@inovua/reactdatagrid-community';
import React, { useState, useEffect } from 'react';

const DataTable = ({
  id,
  columnOrderPersist,
  columns,
  pageChangeHandler,
  dataSource,
  page,
  totalPages,
  totalCount,
  style,
  paginate = true,
  selectedRow,
  setSelectedRow,
  onSelectionChange,
  ...props
}) => {
  const [columnOrder, setColumnOrder] = useState([]);

  const handleColumnChange = (data) => {
    if (!columnOrderPersist) return;
    localStorage.setItem(`${id}ColumnOrder`, JSON.stringify(data));
    setColumnOrder(data);
  };

  useEffect(() => {
    if (!columnOrderPersist) {
      setColumnOrder(() => columns.map((column) => column.name));
    } else {
      const order = localStorage.getItem(`${id}ColumnOrder`);
      if (order) {
        setColumnOrder(JSON.parse(order));
      } else {
        setColumnOrder(() => columns.map((column) => column.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactDataGrid
      {...props}
      idProperty="id"
      style={{ ...style, marginBottom: '10px' }}
      dataSource={dataSource}
      columns={columns}
      onColumnOrderChange={handleColumnChange}
      columnOrder={columnOrder}
      rowReorderColumn
      pagination={paginate}
      showCellBorders={false}
      onSelectionChange={onSelectionChange}
      selected={selectedRow}
    />
  );
};

export default DataTable;
