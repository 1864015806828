import { validateForm } from './Form/validation';

const inputChangedHandler = (value, prevState, inputIdentifier) => {
  const updatedForm = {
    ...prevState.controls,
  };
  const updatedFormElement = {
    ...updatedForm[inputIdentifier],
  };
  updatedFormElement.value = value;

  const errorMessage = validateForm(
    updatedFormElement.label || updatedFormElement.elementConfig?.placeholder,
    updatedFormElement.value,
    updatedFormElement.validation,
    prevState,
  );

  updatedFormElement.touched = true;
  updatedFormElement.valid = !errorMessage;
  updatedFormElement.error = errorMessage;
  updatedForm[inputIdentifier] = updatedFormElement;

  let formIsValid = true;
  Object.keys(updatedForm).forEach((inputId) => {
    formIsValid = updatedForm[inputId].valid && formIsValid;
  });
  return [updatedForm, formIsValid];
};

export default inputChangedHandler;
