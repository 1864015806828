import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import DataTable from '../../../components/UI/DataTable/DataTable';
import TermsAndConditionService from '../../../api/TermsAndConditions';
import { formatDate } from '../../../shared/dateTime';
import Input from '../../../components/UI/Input/Input';
import Modal from '../../../components/UI/Modal/Modal';
import Toaster from '../../../components/UI/Toaster/Toaster';
import styles from './UsersTable.module.scss';

const gridStyle = { height: 'calc(100vh - 295px)' };

const UserTable = (props) => {
  const { dataSource, users, search, deactivationClick, resendClick } = props;

  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [termsModalData, setTermsModalData] = useState({});
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { userId, isAdmin, isBountyAdmin, isCompanyUser, isCompanyAdmin } =
    useSelector((state) => state.auth);
  const navigate = useNavigate();

  const rolesOptions = {
    options: [
      {
        value: '',
        displayValue: 'Search by Role',
      },
      {
        value: 'ROLE_ADMIN',
        displayValue: 'Super Admin',
      },
      { value: 'ROLE_BOUNTY_ADMIN', displayValue: 'Bounty Admin' },
      {
        value: 'ROLE_COMPANY_ADMIN',
        displayValue: 'Company Admin',
      },
      { value: 'ROLE_COMPANY_USER', displayValue: 'Company User' },
      {
        value: 'ROLE_ADVERTISER',
        displayValue: 'Advertising Agency',
      },
    ],
  };

  const setRejectedTermsAndConditions = async () => {
    const terms = {};
    for (const user of users) {
      terms[user.id] = {
        rejectedTermsAndConditions: user.rejectedTermsAndConditions ?? [],
      };
    }
    setTermsModalData(terms);
  };

  const resendTermsAndConditions = async (companyId, userUuid) => {
    setIsTermsModalOpen(false);
    await TermsAndConditionService.resendTermsAndConditions(
      companyId,
      userUuid,
    );
    const usersRejectedTerms = termsModalData[
      userUuid
    ].rejectedTermsAndConditions.filter((item) => item.companyId !== companyId);
    setTermsModalData((state) => {
      return {
        ...state,
        [userUuid]: {
          ...state[userUuid],
          rejectedTermsAndConditions: usersRejectedTerms,
        },
      };
    });
    setToast({
      show: true,
      class: 'success',
      header:
        'The T&C’s have been resent to the advertising agency and they would see it upon login.',
    });
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  useEffect(() => {
    setRejectedTermsAndConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const allowEdit = (user) => {
    if (
      isCompanyUser &&
      !['ROLE_COMPANY_USER', 'ROLE_ADVERTISER'].includes(user.role)
    ) {
      return false;
    }
    return !(isBountyAdmin && user.role === 'ROLE_ADMIN');
  };

  const allowDeactivate = (user) => {
    if (isBountyAdmin && user.role === 'ROLE_ADMIN') {
      return false;
    }
    if (
      isCompanyAdmin &&
      (user.role === 'ROLE_ADMIN' || user.role === 'ROLE_BOUNTY_ADMIN')
    ) {
      return false;
    }
    if (
      isCompanyUser &&
      (user.role === 'ROLE_ADMIN' ||
        user.role === 'ROLE_BOUNTY_ADMIN' ||
        user.role === 'ROLE_COMPANY_ADMIN')
    ) {
      return false;
    }
    return true;
  };

  const roleTranslation = {
    ROLE_ADMIN: 'Super Admin',
    ROLE_BOUNTY_ADMIN: 'Bounty Admin',
    ROLE_COMPANY_ADMIN: 'Company Admin',
    ROLE_COMPANY_USER: 'Company User',
    ROLE_ADVERTISER: 'Advertising Agency',
  };

  const columns = [
    {
      header: 'Name',
      name: 'name',
      defaultFlex: 1,
    },
    {
      header: 'Role',
      name: 'role',
      defaultFlex: 1,
      render: ({ value }) => <span>{roleTranslation[value]}</span>,
    },
    {
      header: 'Company',
      name: 'company',
      width: 130,
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span>
          {data.role === 'ROLE_ADMIN' || data.role === 'ROLE_BOUNTY_ADMIN'
            ? 'N/A'
            : value}
        </span>
      ),
    },
    {
      header: 'Email',
      name: 'email',
      width: 150,
      defaultFlex: 1,
    },
    {
      header: 'Created Date',
      name: 'createdDate',
      width: 130,
      defaultFlex: 1,
      render: ({ value }) => (
        <span>
          {value ? formatDate(new Date(value), 'dd/MM/yyyy HH:mm') : ''}
        </span>
      ),
    },
    {
      header: 'Date Last Accessed',
      name: 'lastSignInTime',
      with: 130,
      defaultFlex: 1,
      render: ({ value }) => (
        <span>
          {value ? formatDate(new Date(value), 'dd/MM/yyyy HH:mm') : ''}
        </span>
      ),
    },
    {
      header: 'Phone Number',
      name: 'phoneNumber',
      width: 130,
      defaultFlex: 1,
    },
    {
      header: 'Activated',
      name: 'activated',
      width: 130,
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span>
          {value ? (
            <span className="userStatus userStatus--active">Active</span>
          ) : (
            <span
              className="userStatus userStatus--inactive"
              data-test-id={`status-activated-user-${data.email}`}
            >
              Inactive
            </span>
          )}
        </span>
      ),
    },
    {
      header: 'Actions',
      name: 'id',
      width: 350,
      sortable: false,
      render: ({ data }) => (
        <div className="btnContainer">
          {allowEdit(data) ? (
            <Link
              to={`/users/edit/${data.id}`}
              data-test-id={`edit-user-${data.email}-button`}
              className="btnDefault btnDefault--small"
              onClick={(e) => e.stopPropagation()}
            >
              Edit
            </Link>
          ) : null}
          {data.activated && userId !== data.id && allowDeactivate(data) ? (
            <button
              className="btnDefault btnDefault--small btnDefault--red"
              onClick={(e) => {
                deactivationClick(data.id);
                e.stopPropagation();
              }}
              data-test-id={`deactivate-${data.email}-user-button`}
            >
              Deactivate
            </button>
          ) : null}
          {!data.activated ? (
            <button
              className="btnDefault btnDefault--small btnDefault--red"
              onClick={(e) => {
                e.stopPropagation();
                resendClick(data.id);
              }}
              data-test-id={`resend-${data.email}-user-button`}
            >
              Send invitation
            </button>
          ) : null}
          {(isCompanyUser || isCompanyAdmin || isAdmin || isBountyAdmin) &&
          data.role === 'ROLE_ADVERTISER' &&
          termsModalData[data.id]?.rejectedTermsAndConditions?.length ? (
            <button
              className="btnDefault btnDefault--small btnDefault--red"
              onClick={async (e) => {
                e.stopPropagation();
                if (isCompanyUser || isCompanyAdmin) {
                  resendTermsAndConditions(
                    [data.id]?.rejectedTermsAndConditions?.[0]?.companyId,
                    data.id,
                  );
                } else {
                  setSelectedUser(data);
                  setTimeout(() => {
                    setIsTermsModalOpen(true);
                  }, 100);
                }
              }}
            >
              Resend T&C
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  const onRowClick = ({ data }) => {
    navigate(`/users/edit/${data.id}`);
  };

  return (
    <>
      <Toaster toast={toast} closeToast={closeToast} />
      {isTermsModalOpen && (
        <Modal
          key={Math.floor(Math.random() * 1000)}
          title="Resend Terms and Conditions"
          body={termsModalData[selectedUser.id].rejectedTermsAndConditions.map(
            (modalData) => (
              <div
                key={Math.floor(Math.random() * 1000)}
                className="d-flex justify-content-between mb-4"
              >
                <div>{modalData.companyName}</div>
                <button
                  className="btnDefault btnDefault--small btnDefault--red"
                  onClick={async (e) => {
                    e.stopPropagation();
                    resendTermsAndConditions(
                      modalData.companyId,
                      selectedUser.id,
                    );
                  }}
                >
                  resend
                </button>
              </div>
            ),
          )}
          handleClose={() => {
            setIsTermsModalOpen(false);
            setSelectedUser({});
          }}
          show
        />
      )}
      <div className={`${styles.filterContainer} mb-2 p-2 d-flex gap-3`}>
        <Col md={2}>
          <Input
            elementType="text"
            label="Search by Name"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'name')}
            dataTestAttribute="search-by-name-input"
          />
        </Col>
        <Col md={2}>
          <Input
            elementType="select"
            elementConfig={rolesOptions}
            label="Search by Role"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'role')}
            dataTestAttribute="search-by-role-input"
          />
        </Col>
        <Col md={2}>
          <Input
            elementType="text"
            label="Search by Company Name"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'company')}
            dataTestAttribute="search-by-company-input"
          />
        </Col>
        <Col md={2}>
          <Input
            elementType="text"
            label="Search by Email"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'email')}
            dataTestAttribute="search-by-email-input"
          />
        </Col>
      </div>
      <div>
        <DataTable
          id="users"
          columns={columns}
          dataSource={dataSource}
          style={gridStyle}
          columnOrderPersist
          pageSizes={[50, 100]}
          onRowClick={onRowClick}
        />
      </div>
    </>
  );
};

export default UserTable;
