import React, { useContext } from 'react';

import { PostQuestionsContext } from '../PostQuestions.context';
import styles from './PostQuestionSelector.module.scss';

const PostQuestionSelector = ({ question, checked, onToggle }) => {
  const { langKey } = useContext(PostQuestionsContext);
  const isChecked = !!checked;

  const questionLabel = question.questionLabel?.[langKey];
  const btnClass = isChecked ? styles.btnChecked : styles.btnStd;
  const isSelect = question.fieldType === 'select';
  const selectText = isChecked ? 'Deselect' : 'Select';

  const handleSelect = (ev) => {
    ev.preventDefault();
    onToggle(question.questionId, !isChecked);
  };

  return (
    <button
      title={`${selectText} question: '${questionLabel}'`}
      className={btnClass}
      onClick={handleSelect}
      aria-pressed={isChecked}
    >
      <h5 className={styles.textTitle}> {questionLabel} </h5>

      <div className="d-flex justify-content-start">
        <span className={`${styles.questionDetail}`}>
          Field Type:{' '}
          <span className={styles.textBold}>{question.fieldType}</span>
        </span>
        {question.required ? (
          <span className={`${styles.questionDetail} ${styles.textBold}`}>
            Required
          </span>
        ) : null}
        {question.multiple ? (
          <span className={`${styles.questionDetail} ${styles.textBold}`}>
            Allow Multiple Answers
          </span>
        ) : null}
      </div>

      {isSelect ? (
        <div className="d-flex justify-content-start">
          <span className={`${styles.questionDetail}`}>
            Available Answers:{' '}
            <span className={`${styles.textBold} ${styles.textDark}`}>
              {question.values[langKey].join(', ')}
            </span>
          </span>
        </div>
      ) : null}
    </button>
  );
};

export default PostQuestionSelector;
