import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import FileService from '../../../api/File';

/**
 * Upload button
 * Using 'react-dropzone' to handle upload input and dropzone
 * @param onFileUploadSuccess On each file upload success
 * @param onFileUploadError On each file upload error
 * @param onUploadStart On first file upload start
 * @param buttonText
 * @param multiple
 * @param dataTestAttribute
 * @param fileService
 * @returns {JSX.Element}
 * @constructor
 */
const FileUploadButton = ({
  onFileUploadSuccess,
  onFileUploadError,
  onUploadStart,
  additionalClasses,
  buttonText,
  multiple,
  dataTestAttribute,
  fileService = FileService,
  uploadUrl, // full path, or ID, based on the API method (fileService) utilised
}) => {
  const [pending, setPending] = useState(false);

  /**
   * Return error message as unified object
   * @param file
   * @param response
   * @returns {{filename: string, errors: array}}
   */
  const getErrorMessages = (file, response) => {
    let errorMessages = ['Upload error'];
    if (response.data && response.data._errors) {
      errorMessages = Object.values(response.data._errors);
    }
    return {
      filename: file.name,
      errors: errorMessages,
    };
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPending(true);

      if (onUploadStart) {
        onUploadStart();
      }

      const uploadPromises = [];
      let countUploaded = 0;

      acceptedFiles.forEach((file) => {
        const uploadPromise = fileService
          .upload(file, uploadUrl)
          .then((response) => {
            if (response.data) {
              countUploaded += response.data;
            }
            onFileUploadSuccess(file, { ...response, countUploaded });
          })
          .catch((error) => {
            onFileUploadError(file, getErrorMessages(file, error.response));
          });
        uploadPromises.push(uploadPromise);
      });

      Promise.all(uploadPromises).finally(() => {
        setPending(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onFileUploadSuccess,
      onFileUploadError,
      onUploadStart,
      fileService,
      uploadUrl,
    ],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const inputClasses = [
    `btnDefault ${isDragActive ? 'btnDefault--green' : ''}`,
    additionalClasses,
  ];

  return (
    <button
      {...getRootProps()}
      title="Click here or drop files"
      className={inputClasses.join(' ')}
      type="button"
    >
      <input
        name="fileUpload"
        {...getInputProps({ multiple })}
        data-test-id={`${dataTestAttribute}-input` || null}
      />
      {buttonText ? (
        <span
          className="me-2"
          data-test-id={`${dataTestAttribute}-span` || null}
        >
          {buttonText}
        </span>
      ) : null}
      {pending ? (
        <FontAwesomeIcon fixedWidth icon={faCircleNotch} spin />
      ) : (
        <FontAwesomeIcon fixedWidth icon={faFileUpload} />
      )}
    </button>
  );
};
export default FileUploadButton;
