import React from 'react';
import Input from '../Input/Input';

/**
 * Wrapper for input component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @example <FormInput control={formControl} key={inputKey}/>
 */
const FormInput = (props) => (
  <Input
    disabled={props.disabled}
    additionalClasses="formInput formInput--bordered"
    label={props.control.label}
    key={props.inputKey}
    keyName={props.inputKey}
    elementType={props.control.elementType}
    elementConfig={props.control.elementConfig}
    value={props.value}
    invalid={!props.control.valid}
    shouldValidate={props.control.validation}
    touched={props.control.touched}
    changed={(event, action) =>
      props.inputChangedHandler(event, props.inputKey, action)
    }
    error={props.control.error ?? props.error}
    dataTestAttribute={props.dataTestAttribute || null}
    required={props?.control?.validation?.required}
    onFocus={props?.onFocus}
  />
);

export default FormInput;
