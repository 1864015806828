import React, { useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import PromptModal from '../UI/PromptModal/PromptModal';
import Button from '../UI/Button/Button';
import { PostQuestionsContext } from './PostQuestions.context';
import PostQuestionCategory from './PostQuestionCategory/PostQuestionCategory';
import usePostQuestions from './PostQuestions.hooks';

import ConfirmDeleteModal, {
  useModalConfirm as useConfirmDeleteModal,
} from '../Modal/ModalConfirm/ModalConfirm';

import styles from './PostQuestionCategory/PostQuestionCategory.module.scss';

const PostQuestions = ({
  companyId,
  categoryQuestions,
  onUpdate,
  setAccordionActiveKey,
  saveTick,
  setAccordionsFormSaveStatus,
}) => {
  const [getters, actions] = usePostQuestions({
    companyId,
    categoryQuestions,
    onUpdate,
  });

  const {
    language,
    hasChange,
    removeItem,
    postQuestionData,
    categoryCount,
    errors,
  } = getters;

  const {
    handleCategoryInput,
    handleCtaAction,
    handleSavePostQuestions,
    handleConfirmRemoveItem,
    resetRemoveState,
  } = actions;

  const [confirmDeleteProps, setConfirmDeleteProps] = useConfirmDeleteModal({
    title: 'Remove confirmation',
    supplementalErrorKey: 'records',
    apiRequest: () => {},
  });

  useEffect(() => {
    if (saveTick) {
      (async () => {
        const isSuccess = await handleSavePostQuestions();
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            postConnectForm: isSuccess,
          };
        });
      })();
    }
  }, [saveTick]);

  useEffect(() => {
    if (removeItem.cId) {
      const body = removeItem.isQuestion
        ? [
            `Removal of question '${removeItem.label}' will be actioned immediately.`,
            'Are you sure you want to continue?',
          ]
        : [
            `Removal of category '${removeItem.label}' will result in the removal of any post-form questions associated with this category.`,
            'Are you sure you want to continue?',
          ];

      setConfirmDeleteProps({
        body,
        apiRequest: handleConfirmRemoveItem,
        onSuccessContext: removeItem,
        onSuccess: () => {
          const action = removeItem.isQuestion
            ? 'remove-question'
            : 'remove-category';
          handleCtaAction(null, action, removeItem.cIdx, removeItem.qIdx, true);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeItem, handleConfirmRemoveItem]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setAccordionActiveKey('2');
    }
  }, [errors]);

  const providerValue = useMemo(
    () => ({ langKey: language, errors }),
    [language, errors],
  );

  return (
    <PostQuestionsContext.Provider value={providerValue}>
      <PromptModal when={hasChange} />

      <ConfirmDeleteModal {...confirmDeleteProps} onClose={resetRemoveState} />

      {errors._summary ? (
        <Alert variant="danger">
          <FontAwesomeIcon
            className="authFormError__icon"
            icon={faExclamationCircle}
          />
          {errors._summary}
        </Alert>
      ) : null}

      <form data-test-id="post-questions-form">
        {/* Header */}
        <div className="mb-4 d-flex btnContainer">
          <Button
            additionalClasses="btnDefault mt-2"
            dataTestAttribute="button-category-add"
            clicked={(ev) => handleCtaAction(ev, 'add-category')}
          >
            Add Category
            {categoryCount ? (
              <small className={styles.postQuestionCategoryCounter}>
                ({categoryCount})
              </small>
            ) : null}
          </Button>
        </div>

        {/* Questions */}
        {postQuestionData.map((category, idx) => (
          <PostQuestionCategory
            key={category.categoryId ?? idx}
            idx={idx}
            category={category}
            onInput={handleCategoryInput}
            handleAction={handleCtaAction}
            removeItem={removeItem}
          />
        ))}
      </form>
    </PostQuestionsContext.Provider>
  );
};

export default PostQuestions;
