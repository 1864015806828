import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faCircleNotch,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';

import CpdcService from '../../../api/Cpdcs';
import CampaignService from '../../../api/Campaigns';
import CampaignUrlModal from '../../../pages/CampaignsPage/CampaignEditPage/CampaignUrlModal';
import Button from '../../UI/Button/Button';
import PromptModal from '../../UI/PromptModal/PromptModal';
import ToasterBottom from '../../UI/Toaster/ToasterBottom';
import Modal from '../../UI/Modal/Modal';
import CampaignEnable from '../../CampaignEnable/CampaignEnable';

import styles from '../CampaignDetail.module.scss';
import CampaignStatus from '../../Campaign/CampaignStatus/CampaignStatus';
import CampaignWebhookModal from '../../Campaign/Webhook/CampaignWebhookModal';
import CampaignHealthCheck from '../CampaignHealthCheck/CampaignHealthCheck';

const CampaignHeader = ({
  loading,
  formik,
  pageState,
  campaignStatus,
  saveTick,
  onSaveAction,
  successTick,
  genericErrorTick,
  genericErrorMessage,
  webhookManageModal,
  setWebhookManageModal,
  setWebhooks,
  toast,
  setToast,
}) => {
  const PUBLIC_PAGES_URL = process.env.REACT_APP_PUBLIC_PAGES_URL.replace(
    /\/$/,
    '',
  );
  const navigate = useNavigate();

  const { isAdmin, isBountyAdmin, isCompanyAdmin } = useSelector(
    (state) => state.auth,
  );

  const mountedRef = useRef(true);
  const saveRef = useRef(null);

  const [pending, setPending] = useState(false);
  const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
  const [filePassword, setFilePassword] = useState(null);
  const [showFilePasswordModal, setShowFilePasswordModal] = useState(false);
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCampaignHealth, setShowCampaignHealth] = useState(false);

  const download = useCallback(
    (source) => {
      setPending(true);
      const password = uuidv4();
      setFilePassword(password);

      CpdcService.downloadEncryptedData({
        campaignId: formik.values.id,
        queryParams: {
          source: source ?? 'connect-form',
        },
        password,
        onError: () => {
          if (!mountedRef.current) return;
          setPending(false);
          setToast({
            show: true,
            success: false,
            body: 'There was a problem downloading the report please try again',
          });
        },
        onEnd: () => {
          if (!mountedRef.current) return;
          setPending(false);
          setShowFilePasswordModal(true);
        },
      });
    },
    [formik.values.id],
  );

  const campaignHasStarted = !!formik.values.enabledAt;

  const closeToast = () => {
    setToast((state) => ({ ...state, show: false }));
  };

  const showPreviewHandler = async () => {
    const token = await CampaignService.getToken(formik.values.id);
    const PREVIEW_PAGES_URL = process.env.REACT_APP_PREVIEW_PAGES_URL.replace(
      /\/+$/,
      '',
    );
    window.open(
      `${PREVIEW_PAGES_URL}/${formik.values.slug}?token=${token.data}`,
    );
  };

  const showHealthCheck = () => setShowCampaignHealth(true);

  const promptBeforeDuplicate = () => {
    setShowDuplicatePrompt(true);
  };

  const duplicateCampaign = async () => {
    setShowDuplicatePrompt(false);

    const r = await CampaignService.duplicate(formik.values.id).catch(
      (err) => err,
    );

    if (r?.data?.id) {
      navigate(`/campaigns/edit/${r.data.id}`);
    } else {
      const msg = r?.response?.data?.error || r?.response?.data?.message;
      setToast({
        show: true,
        body: msg || 'Something went wrong, try again?',
      });

      if (r?.response?.data?.id) {
        navigate(`/campaigns/edit/${r.response.data.id}`);
      }
    }
  };

  const doSave = async () => {
    setShowConfirmationModal(false);
    formik.handleSubmit();
  };

  const savePrecheck = async () => {
    const errors = await formik.validateForm();
    const errorKeys = Object.keys(errors);
    const formIsValid = errorKeys.length === 0;

    const questionErrors = errors.paywall?.qualificationRequirements?.questions;

    if (questionErrors) {
      questionErrors.forEach((questionError, idx) => {
        errorKeys.push(
          `paywall.qualificationRequirements.questions[${idx}].answers`,
        );
      });
    }

    if (!formIsValid) {
      formik.setTouchedFields(errorKeys);
      setToast({
        show: true,
        success: false,
        body: 'Missing or invalid required field(s)',
      });

      return false;
    }

    return true;
  };

  const confirmSave = async () => {
    const formReady = await savePrecheck();
    if (formReady) {
      setShowConfirmationModal(true);
    }
  };

  useEffect(() => {
    if (successTick) {
      setToast({
        show: true,
        success: true,
        body: 'You have successfully saved the campaign',
      });
    }
  }, [successTick]);

  useEffect(() => {
    if (genericErrorTick) {
      setToast({
        show: true,
        success: false,
        body: genericErrorMessage,
      });
    }
  }, [genericErrorTick, genericErrorMessage]);

  /**
   * All external save triggers sholud use the tick
   *  function, to trigger this confirmSave modal
   */
  useEffect(() => {
    const handleSave = async () => {
      if (saveTick && saveRef.current !== saveTick) {
        saveRef.current = saveTick;

        const formReady = await savePrecheck();
        if (formReady) {
          if (formik.values.id) {
            confirmSave();
          } else {
            formik.handleSubmit();
          }
        }
      }
    };

    handleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTick, formik]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (loading) {
    return null;
  }

  const isDirty = formik.dirty;
  const hasLocalisedSectionUpdate = !!pageState.sectionsUpdated.length;
  const isCreateCampaign = !formik.values.id;
  const title = isCreateCampaign ? 'Create Campaign' : formik.values.name;
  const hasProvider = !!formik.values.videoProvider?.id;

  return (
    <div className={`${styles.container} ${styles.header}`}>
      {/* Title + CTA */}
      <div className={styles.headerInner}>
        <Link className={styles.backButton} to="/campaigns">
          <FontAwesomeIcon
            size="xl"
            width="54"
            icon={faArrowLeftLong}
            className={styles.backIcon}
          />
        </Link>

        <div>
          <h3 className="mb-0 d-flex align-items-center gap-3">
            {title} <CampaignStatus campaignStatusData={campaignStatus} />
          </h3>
          {hasProvider && (
            <div className={styles.providerLink}>
              <span>Offer: </span>
              <Link
                className="link-bm-orange"
                to={`/offers/edit/${formik.values.videoProvider.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {formik.values.videoProvider.name}
              </Link>
            </div>
          )}
        </div>

        <div className={`${styles.headerControls} btnContainer`}>
          {!isCreateCampaign && (
            <Dropdown className="d-inline-flex">
              <Dropdown.Toggle
                data-test-id="campaign-more-actions-dropdown"
                className="btnDefault p-3"
              >
                <FontAwesomeIcon fixedWidth icon={faEllipsisH} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowUrlModal(true)}>
                  Get URL
                </Dropdown.Item>

                <Dropdown.Item onClick={showPreviewHandler}>
                  Show preview
                </Dropdown.Item>

                {(isAdmin || isBountyAdmin) && (
                  <Dropdown.Item onClick={showHealthCheck}>
                    Health Check
                  </Dropdown.Item>
                )}

                {(isAdmin || isCompanyAdmin || isBountyAdmin) && (
                  <Dropdown.Item onClick={promptBeforeDuplicate}>
                    Duplicate Campaign
                  </Dropdown.Item>
                )}

                {(isAdmin || isCompanyAdmin) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() =>
                        navigate(
                          `/campaigns/edit/${formik.values.id}/manage-terms/`,
                        )
                      }
                    >
                      Manage Terms and Conditions
                    </Dropdown.Item>
                  </>
                )}

                {campaignHasStarted && (isAdmin || isCompanyAdmin) && (
                  <Dropdown.Divider />
                )}

                {campaignHasStarted && (isAdmin || isCompanyAdmin) && (
                  <Dropdown.Item
                    onClick={() => download()}
                    data-test-id="campaign-download-button"
                  >
                    Download Connections data
                    {pending && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="ms-3"
                        icon={faCircleNotch}
                        spin
                      />
                    )}
                  </Dropdown.Item>
                )}

                {campaignHasStarted && (isAdmin || isCompanyAdmin) && (
                  <Dropdown.Item
                    onClick={() => download('post-questions')}
                    data-test-id="campaign-download-postformdata-button"
                  >
                    Download Post Form data
                    {pending && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="ml-3"
                        icon={faCircleNotch}
                        spin
                      />
                    )}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}

          <Button
            additionalClasses={styles.buttonLastChild}
            clicked={onSaveAction}
            disabled={!isDirty && !hasLocalisedSectionUpdate}
          >
            {!isCreateCampaign ? 'Save' : 'Create'}
          </Button>

          {!isCreateCampaign && !formik.values.globalFallbackMode ? (
            <CampaignEnable
              requiresServiceType={
                !formik.values.paywall.enabled && !formik.values.videoProvider
              }
              campaign={formik.values}
              campaignStateChange={(bool) =>
                formik.setFieldValue('enabled', bool)
              }
              setToast={setToast}
            />
          ) : null}
        </div>
      </div>

      <CampaignUrlModal
        campaignUrl={formik.values.campaignUrl}
        shortUrl={formik.values.shortUrl}
        consumerVerificationUrl={`${PUBLIC_PAGES_URL}/verifyme/${formik.values.id}`}
        onClose={() => setShowUrlModal(false)}
        show={showUrlModal}
        isPaywall={formik.values?.paywall?.enabled}
      />

      <Modal
        title="Campaign Health Check"
        body={<CampaignHealthCheck id={formik.values.id} />}
        size="lg"
        cancel="Close"
        show={showCampaignHealth}
        handleClose={() => setShowCampaignHealth(false)}
      />

      <Modal
        title="Update campaign data"
        body="Are you sure you want to save changes?"
        cancel="No"
        save="Yes"
        handleSave={doSave}
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        dataTestAttribute={{
          modal: 'modal-campaign-form',
          action1: 'cancel-campaign-form',
          action2: 'save-campaign-form',
        }}
      />

      <Modal
        title="Duplicate Campaign"
        body="Are you sure you want to duplicate?"
        cancel="No"
        save="Yes"
        handleSave={duplicateCampaign}
        show={showDuplicatePrompt}
        handleClose={() => setShowDuplicatePrompt(false)}
      />

      <CampaignWebhookModal
        show={webhookManageModal.show}
        data={webhookManageModal.data}
        setWebhooks={setWebhooks}
        close={() =>
          setWebhookManageModal((prevState) => ({
            ...prevState,
            show: !prevState.show,
          }))
        }
      />

      <Modal
        title="File password"
        body={
          <>
            <p>The password to extract the zip file is:</p>
            <p>
              <strong>{filePassword}</strong>
            </p>
            <p>
              The csv file contains PII (personally identifiable information)
              and should be treated with care
            </p>
            <p className="mb-0">
              It is recommended to use a program such as 7-zip to extract the
              csv file
            </p>
          </>
        }
        show={showFilePasswordModal}
        handleClose={() => {
          setFilePassword(false);
          setShowFilePasswordModal(false);
        }}
      />

      <PromptModal when={formik.dirty} />

      <ToasterBottom toast={toast} closeToast={closeToast} />
    </div>
  );
};

export default CampaignHeader;
