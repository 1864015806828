import React from 'react';
// eslint-disable-next-line import/no-cycle
import Input from '../Input/Input';

/**
 * Part of carousel input.
 * Form displayed for active slide
 * @param onFormChanged
 * @param slide
 * @returns {JSX.Element}
 * @constructor
 */
const CarouselSlideForm = ({ onFormChanged, slide }) => {
  const onFormChangeHandle = (key, val) => {
    const newSlideData = { ...slide };
    newSlideData[key] = val;
    onFormChanged(newSlideData);
  };

  return (
    <>
      <p className="carouselInputInfo text-truncate">
        Selected:&nbsp;
        <a
          title={slide.imageUrl}
          href={slide.imageUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {slide.imageUrl}
        </a>
      </p>
      <Input
        keyName="carousel-thumb-url"
        additionalClasses="formInput formInput--bordered"
        value={slide.linkUrl}
        changed={(event) => onFormChangeHandle('linkUrl', event.target.value)}
        label="Link url"
      />
      <Input
        keyName="carousel-thumb-alt"
        additionalClasses="formInput formInput--bordered"
        value={slide.imageAlt}
        changed={(event) => onFormChangeHandle('imageAlt', event.target.value)}
        label="Alternative text"
        elementConfig={{
          description: "Alternate text if the image can't be displayed",
        }}
      />
    </>
  );
};
export default CarouselSlideForm;
