export const emptyReportDataLabel = (type, label = '') => {
  if (type === 'external') {
    return `No ${label?.toLocaleLowerCase()}`;
  }
  return 'No answer given';
};

export default {
  emptyReportDataLabel,
};
