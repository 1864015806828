import React, { useContext, useMemo } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { PostQuestionsContext } from '../PostQuestions.context';
import PostQuestion from '../PostQuestion/PostQuestion';
// import PostQuestionTerms from '../PostQuestionTerms/PostQuestionTerms';

import styles from './PostQuestionCategory.module.scss';

const CategoryAction = ({ children, clicked }) => {
  return (
    <Button
      additionalClasses="btnDefault btnDefault--medium my-2"
      dataTestAttribute="button-category-add-term"
      clicked={clicked}
    >
      {children}
    </Button>
  );
};

const CategoryPreview = ({ src }) => {
  return src ? (
    <img
      src={src}
      className={styles.postQuestionCategoryImage}
      alt="Category preview"
    />
  ) : null;
};

const PostQuestionCategory = ({
  idx,
  category,
  onInput,
  handleAction,
  removeItem,
}) => {
  const { langKey, errors } = useContext(PostQuestionsContext);
  const hasQuestions = !!category?.questions?.length;
  // const hasTerms = !!Object.keys(category?.terms ?? {})?.length;

  const createOnChangeHandler =
    (categoryIdx, questionIdx, fieldKey, isLanguageInput = true) =>
    (value) =>
      onInput(categoryIdx, questionIdx, fieldKey, value, isLanguageInput);

  const addQuestionCta = (ev) => handleAction(ev, 'add-question-select', idx);
  const addTextCta = (ev) => handleAction(ev, 'add-question-text', idx);
  const removeCategoryCta = (ev) => handleAction(ev, 'remove-category', idx);

  const selectFieldCount = useMemo(
    () => category.questions.filter((e) => e.fieldType === 'select').length,
    [category.questions],
  );

  const textfieldCount = useMemo(
    () => category.questions.length - selectFieldCount,
    [category.questions, selectFieldCount],
  );

  const categoryImgUrl = category.categoryImgUrl
    ? `${process.env.REACT_APP_UPLOADS_URL}/${category.categoryImgUrl}`
    : '';

  return (
    <div className={`${styles.postQuestionCategory} mx-n3 mx-sm-0`}>
      {/* Header */}
      <h5>
        Category{' '}
        <span className="small text-muted">
          {category.categoryId ? '' : '(new)'}
        </span>
      </h5>

      {/* Error Summary */}
      {errors[`categories[${idx}]._summary`] ? (
        <Alert variant="danger">
          <FontAwesomeIcon
            className="authFormError__icon"
            icon={faExclamationCircle}
          />
          {errors[`categories[${idx}]._summary`]}
        </Alert>
      ) : null}

      {/* Form */}
      <Row className="mt-3 mb-4">
        <Col sm={8} lg={8} className="d-flex flex-column">
          <Row>
            <Col lg={6} className="my-2">
              <Input
                type="text"
                label="Category Name"
                required
                value={category.categoryLabel?.[langKey] ?? ''}
                additionalClasses={`formInput formInput--bordered ${
                  errors[`categories[${idx}].categoryLabel`]
                    ? styles.formInputInvalid
                    : ''
                }`}
                changed={createOnChangeHandler(idx, null, 'categoryLabel')}
                error={errors[`categories[${idx}].categoryLabel`] ?? ''}
                touched
              />
            </Col>
            <Col lg={6} className="d-flex flex-column align-items-start my-2">
              <Input
                label="Category Image"
                required
                elementType="image"
                containerClasses="Input--button Input--m-0"
                touched
                additionalClasses="btnDefault--medium"
                fileChange={createOnChangeHandler(
                  idx,
                  null,
                  'categoryImgUrl',
                  false,
                )}
                elementConfig={{
                  description: 'Aspect ratio 1:1 (Square)',
                }}
                error={errors[`categories[${idx}].categoryImgUrl`] ?? ''}
              />
            </Col>
            <Col className="my-2 d-sm-none">
              <div className={styles.postQuestionCategoryImageContainer}>
                <CategoryPreview src={categoryImgUrl} />
              </div>
            </Col>
          </Row>
          <Row className="mt-auto">
            <Col className="d-flex flex-fill flex-wrap align-items-end mt-3">
              {/* <CategoryAction
                                clicked={(ev) =>
                                    handleAction(ev, 'add-terms', idx)
                                }
                            >
                                Add Terms
                            </CategoryAction> */}
              <CategoryAction clicked={addQuestionCta}>
                Add Question
                {selectFieldCount ? (
                  <small className={styles.postQuestionCategoryCounter}>
                    ({selectFieldCount})
                  </small>
                ) : null}
              </CategoryAction>
              <CategoryAction clicked={addTextCta}>
                Add Text Field
                {textfieldCount ? (
                  <small className={styles.postQuestionCategoryCounter}>
                    ({textfieldCount})
                  </small>
                ) : null}
              </CategoryAction>
            </Col>
          </Row>
        </Col>
        <Col sm={4} lg={4} className="my-2 d-none d-sm-block">
          <div className={styles.postQuestionCategoryImageContainer}>
            <CategoryPreview src={categoryImgUrl} />
          </div>
        </Col>
      </Row>

      {/* Terms */}
      {/* {hasTerms ? (
                <Row>
                    <Col className="my-2">
                        <PostQuestionTerms terms={category.terms} />
                    </Col>
                </Row>
            ) : null} */}

      {/* Questions */}
      {hasQuestions ? (
        <Row>
          <Col>
            {category.questions.map((question, questionIdx) => (
              <PostQuestion
                key={question.questionId ?? questionIdx}
                categoryId={category.categoryId}
                categoryIdx={idx}
                questionIdx={questionIdx}
                question={question}
                handleAction={handleAction}
                createOnChangeHandler={createOnChangeHandler}
                removeItem={removeItem}
              />
            ))}
          </Col>
        </Row>
      ) : null}

      {/* Footer */}
      <Row className="mt-2">
        <Col className="d-flex flex-fill flex-wrap align-items-end justify-content-end">
          <span className="small text-muted">
            {category.categoryId
              ? `(Category: ${category.categoryLabel[langKey]})`
              : '(new)'}
          </span>
          <Button
            additionalClasses="btnDefault btnDefault--medium btnDefault--red ms-auto mt-2 me-0"
            dataTestAttribute="button-category-remove"
            clicked={removeCategoryCta}
            isLoading={removeItem.key === `${category.categoryId}`}
            disabled={removeItem.inProgress}
          >
            Remove Category
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PostQuestionCategory;
