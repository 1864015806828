import instance from './Api';
import fileService from './File';

/**
 * List users using filters
 * @param filters - example: {
 * email: 'test',
 * role: 'ROLE_ADMIN',
 * company: 'a',
 * name: 'Jake',
 * limit: 20,
 * page: 2,
 * order: 'name',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
/* TODO: global listing method? */
const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });
  return instance.get(`/campaigns${query}`);
};

const save = (campaign) => instance.post('/campaigns', campaign);
const enable = (campaign) => instance.put(`/campaigns/${campaign.id}/enable`);
const disable = (campaign) => instance.put(`/campaigns/${campaign.id}/disable`);
const get = (id) => instance.get(`/campaigns/${id}`);
const countEvents = (id) => instance.get(`/campaigns/${id}/count`);
const getStatus = (id) => instance.get(`/campaigns/${id}/status`);
const getToken = (id) => instance.get(`/campaigns/${id}/token`);
const getBrandNames = () => instance.get('/campaigns/get-brand-names');
const getWebhooks = (id) => instance.get(`/campaigns/${id}/webhooks`);
const getWebhook = (id, companyHookId) =>
  instance.get(`/campaigns/${id}/webhooks/${companyHookId}`);
const saveWebhook = (id, companyWebhookId, payload) =>
  instance.put(`/campaigns/${id}/webhooks/${companyWebhookId}`, payload);
const getHealthCheck = (id) => instance.get(`/campaigns/${id}/health`);
const archiveCampaigns = (campaignIds) =>
  instance.put('/campaigns/archive/archive-campaigns', campaignIds);
const restoreCampaigns = (campaignIds) =>
  instance.put('/campaigns/archive/restore-campaigns', campaignIds);
const duplicate = (id) => instance.get(`/campaigns/${id}/duplicate`);

/* TODO: parse response */
const update = (id, campaign) => instance.put(`/campaigns/${id}`, campaign);

const generateCsv = async (campaignId, from, to) => {
  const url = `/campaigns/generate-csv/${campaignId}?from=${from}&to=${to}`;
  const res = await instance.get(url);
  const contentDisposition = res.headers['content-disposition'];
  const regex = /filename="(.+?)(?=\.\w+")/;
  const matches = contentDisposition.match(regex);
  const fileName = matches && matches.length > 1 ? matches[1] : 'campaign';
  fileService.download({
    url,
    fileName,
    fileType: 'csv',
  });
};

export default {
  list,
  save,
  enable,
  disable,
  duplicate,
  get,
  update,
  countEvents,
  getToken,
  getBrandNames,
  getStatus,
  getWebhook,
  getWebhooks,
  saveWebhook,
  getHealthCheck,
  generateCsv,
  archiveCampaigns,
  restoreCampaigns,
};
