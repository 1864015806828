import React, { useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as DOMPurify from 'dompurify';
import './Input.Editor.scss';

import eyeIcon from '../../../assets/icons/eye.svg';
import eyeSlashIcon from '../../../assets/icons/eye-slash.svg';

import FileUploadInput from '../FileUploadInput/FileUploadInput';
// eslint-disable-next-line import/no-cycle
import CarouselInput from '../CarouselInput/CarouselInput';
import PositionInput from '../PositionInput/PositionInput';
import ColorPickerInput from '../ColorPickerInput/ColorPickerInput';
import BannerPickerInput from '../BannerPickerInput/BannerPickerInput';
// eslint-disable-next-line import/no-cycle
import LanguagesInput from '../LanguagesInput/LanguagesInput';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const createMultiStyles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: '#efefef' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const defaultInputValue = (value) => {
  return value === null ? '' : value;
};

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="passwordInputContainer">
      <input {...props} type={showPassword ? 'text' : 'password'} />
      <span className="passwordToggleIcon" onClick={toggleShowPassword}>
        {showPassword ? (
          <img src={eyeSlashIcon} alt="Eye Slash" />
        ) : (
          <img src={eyeIcon} alt="Eye" />
        )}
      </span>
    </div>
  );
};

const defaultInputElement = (
  keyName,
  inputClasses,
  elementConfig = {},
  value,
  changed,
  dataTestAttribute,
  ref,
  pressed,
  disabled,
  onFocus,
) => {
  if (elementConfig && elementConfig.type === 'password') {
    return (
      <PasswordInput
        disabled={disabled}
        name={keyName}
        className={inputClasses.join(' ')}
        {...elementConfig}
        value={defaultInputValue(value)}
        onChange={changed}
        onKeyDown={pressed}
        onFocus={onFocus}
        data-test-id={dataTestAttribute}
        ref={ref}
      />
    );
  }

  return (
    <input
      disabled={disabled}
      name={keyName}
      className={inputClasses.join(' ')}
      {...elementConfig}
      value={defaultInputValue(value)}
      onChange={changed}
      onKeyDown={pressed}
      onFocus={onFocus}
      data-test-id={dataTestAttribute}
      ref={ref}
    />
  );
};

/**
 *
 * Input red ERROR border requires:
 *  error={errorMessage}
 *  invalid={bool}
 *  shouldValidate
 */

const Input = React.forwardRef((props, ref) => {
  const {
    keyName,
    elementConfig,
    value,
    changed,
    dataTestAttribute,
    label,
    error,
    fileChange,
    invalid,
    shouldValidate,
    isLoading,
    touched,
    containerClasses,
    additionalClasses,
    additionalLabelClasses,
    elementType,
    required,
    context,
    pressed,
    disabled,
    onFocus,
    isProfileImg = false,
    hideLabel,
    hideErrorMessage,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = elementConfig?.options ?? [];

  let inputElement;
  let selectClasses;
  const inputClasses = ['InputElement', additionalClasses];

  /**
   * Transpose multiselect array ids [id,id] into objects [{value:id,label:x}]
   *  to function correctly with the react-select component
   */
  const multiselectValue = useMemo(() => {
    const isMultiselect = elementType === 'multiselect';
    const isCreateMulti = elementType === 'create-multi';

    if (!isCreateMulti && !isMultiselect) {
      return null;
    }

    const isEmptyValue = !value;
    const isEmptyOptions = !options || !options?.length;

    if (isEmptyValue || (!isCreateMulti && isEmptyOptions)) {
      return [];
    }

    const isValidValue = Array.isArray(value) && value[0] instanceof Object;
    if (isValidValue) {
      return value;
    }

    return value.map((e) => {
      return options.find((o) => o.value === e);
    });
  }, [value, elementType, options]);

  let autoCompleteDefaultValue = { value, label: value };

  if (keyName === 'countries') {
    if (value && value.length > 0) {
      autoCompleteDefaultValue = options.find(
        (option) => option.value === value,
      );
    }
  }

  if (invalid && shouldValidate && touched) {
    inputClasses.push('invalid');
    selectClasses = 'formSelect invalid';
  }

  if (elementType === 'boolean') {
    inputClasses.push('toggleSwitch__input custom-control-input');
  }

  const editorState = useMemo(() => {
    const isWysiwyg = elementType === 'richtext';
    if (!isWysiwyg) return null;
    if (value.length === 0) return EditorState.createEmpty();

    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    return EditorState.createWithContent(contentState);
  }, [value, elementType]);

  switch (elementType) {
    case 'input':
      inputElement = defaultInputElement(
        keyName,
        inputClasses,
        elementConfig,
        value,
        changed,
        dataTestAttribute,
        ref,
        pressed,
        disabled,
        onFocus,
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          name={keyName}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
          data-test-id={dataTestAttribute}
          disabled={disabled || elementConfig?.disabled || false}
        />
      );
      break;

    case 'richtext':
      inputElement = (
        <Editor
          name={keyName}
          editorClassName={inputClasses.join(' ')}
          data-test-id={dataTestAttribute}
          defaultEditorState={editorState}
          onEditorStateChange={(_editorState) => {
            const rawContentState = convertToRaw(
              _editorState.getCurrentContent(),
            );
            const htmlOutput = draftToHtml(rawContentState);
            const cleanHtml = DOMPurify.sanitize(htmlOutput);
            const eventTargetValue = {
              target: {
                value: cleanHtml,
              },
            };
            changed(eventTargetValue, keyName);
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'history',
            ],
            inline: { inDropdown: false },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
          ref={ref}
        />
      );
      break;
    /**
     * Select
     *  Takes value as id - must match within options
     */
    case 'select':
      inputElement = (
        <select
          name={keyName}
          className={inputClasses.join(' ')}
          value={value}
          onChange={changed}
          data-test-id={dataTestAttribute}
          disabled={disabled || elementConfig?.disabled || false}
          ref={ref}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case 'modern-select':
      inputElement = (
        <Select
          className={`${inputClasses.join(' ')} formSelect`}
          isLoading={!!isLoading}
          isDisabled={disabled || elementConfig?.disabled || false}
          onChange={changed}
          options={options}
          placeholder={elementConfig.placeholder}
          // value must be null for placeholder to display
          value={
            value
              ? {
                  value,
                  label: options?.find((el) => el?.value === value)?.label,
                }
              : null
          }
          inputId={dataTestAttribute}
          name={keyName}
        />
      );
      break;
    case 'image':
    case 'json':
      if (typeof fileChange !== 'function') {
        console.error(`Input ${elementType}: fileChange was not defined.`);
      }
      inputElement = (
        <FileUploadInput
          buttonText={value ? 'Change file' : 'Upload file'}
          filePath={value}
          ref={ref}
          additionalClasses={additionalClasses}
          onFileUrlChange={(fileUrl) => fileChange(fileUrl, keyName)}
          dataTestAttribute={dataTestAttribute}
          uploadUrl={
            isProfileImg ? '/file/upload/profile-picture' : '/file/upload'
          }
        />
      );
      break;
    case 'carousel':
      inputElement = (
        <CarouselInput
          onChange={changed}
          name={keyName}
          ref={ref}
          value={value}
        />
      );
      break;
    case 'boolean':
      inputElement = (
        <ToggleSwitch
          onCheck={(event) =>
            changed({ ...event, target: { value: !value } }, keyName)
          }
          additionalLabelClasses={additionalLabelClasses}
          darkMode
          required={required}
          label={label}
          description={elementConfig?.description}
          dataTestAttribute={dataTestAttribute}
          ref={ref}
          value={elementConfig?.reverseBoolean ? !value : !!value}
        />
      );
      break;
    case 'position':
      inputElement = (
        <PositionInput
          position={value}
          setPosition={(val) => changed({ target: { value: val } }, keyName)}
        />
      );
      break;
    case 'colorPicker':
      inputElement = (
        <ColorPickerInput
          onChange={changed}
          name={keyName}
          value={value}
          dataTestAttribute={dataTestAttribute}
        />
      );
      break;
    case 'bannerPicker':
      inputElement = (
        <BannerPickerInput
          onChange={changed}
          name={keyName}
          value={value}
          serviceType={context?.serviceType}
          dataTestAttribute={dataTestAttribute}
        />
      );
      break;
    case 'languages':
      inputElement = (
        <LanguagesInput
          onKeyDown={pressed}
          onChange={changed}
          {...elementConfig}
          name={keyName}
          values={value}
          dataTestAttribute={dataTestAttribute}
        />
      );
      break;
    case 'autocomplete':
      inputElement = (
        <CreatableSelect
          isClearable
          onChange={(event) => changed(event, 'change')}
          onInputChange={(event, action) => changed(event, action.action)}
          isDisabled={disabled || elementConfig?.disabled || false}
          options={options}
          placeholder={elementConfig.placeholder}
          defaultValue={autoCompleteDefaultValue}
          inputId={dataTestAttribute}
        />
      );
      break;
    /**
     * Multi select with custom options
     *  Value can be id with matching option, or object with [{value, label}]
     */
    case 'create-multi':
      inputElement = (
        // onInputChange returns empty on blur event
        <CreatableSelect
          isMulti
          className={selectClasses}
          onChange={(event, actionMeta) => {
            if (actionMeta?.removedValue?.isFixed) return;
            changed(event, 'change');
          }}
          isDisabled={disabled || elementConfig?.disabled || false}
          options={elementConfig.options}
          placeholder={elementConfig.placeholder}
          isClearable={false}
          styles={createMultiStyles}
          value={multiselectValue}
          inputId={dataTestAttribute}
        />
      );
      break;
    /**
     * Multi select
     *  Value can be id with matching option, or object with [{value, label}]
     */
    case 'multiselect':
      inputElement = (
        <Select
          className={selectClasses}
          isMulti
          isLoading={!!isLoading}
          isDisabled={disabled || elementConfig?.disabled || false}
          {...elementConfig}
          onChange={(event) => changed(event, 'change')}
          value={multiselectValue}
          inputId={dataTestAttribute}
        />
      );
      break;

    // Types: ['text']
    default:
      inputElement = defaultInputElement(
        keyName,
        inputClasses,
        elementConfig,
        value,
        changed,
        dataTestAttribute,
        ref,
        pressed,
      );
  }
  const inputDivClasses = ['Input', keyName, containerClasses].filter((e) => e);

  const requiredField = required && <span className="text-danger"> *</span>;
  const showError = error && touched && !hideErrorMessage;
  const showDescription =
    elementConfig?.description && !showError && elementType !== 'boolean';

  return (
    <div className={inputDivClasses.join(' ')}>
      {label && elementType !== 'boolean' && (
        <label
          className={`Label ${hideLabel ? 'visuallyHidden' : ''}`}
          htmlFor={keyName}
        >
          {label}
          {requiredField}
        </label>
      )}

      {inputElement}

      {showDescription && (
        <small className="mt-2 formInputDescription">
          {elementConfig.description}
        </small>
      )}

      {showError && (
        <span
          className={`formInputError ${
            elementType === 'bannerPicker' ? 'bannerPickerError' : ''
          }`}
        >
          {error}
        </span>
      )}
    </div>
  );
});

export default Input;
