import React from 'react';
import CarouselImageTile from './CarouselImageTile/CarouselImageTile';

const CarouselImagesList = (props) => {
  const {
    images,
    onDelete,
    onEditAlt,
    isEditing,
    setIsEditing,
    changeEditAltInput,
    alt,
    setAlt,
  } = props;

  return (
    <div className="carousel-image-container" id="carousel-images-list-table">
      {images.map((image) => (
        <CarouselImageTile
          key={image.id}
          image={image}
          onDelete={onDelete}
          editAlt={onEditAlt}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          changeEditAltInput={changeEditAltInput}
          alt={alt}
          setAlt={setAlt}
        />
      ))}
    </div>
  );
};

export default CarouselImagesList;
