import React from 'react';

const LinkButton = (props) => (
  <a
    href={props.href}
    target={props.target || '_self'}
    disabled={props.disabled}
    className={['btnDefault', props.additionalClasses].join(' ')}
    onClick={props.clicked}
    data-test-id={props.dataTestAttribute || null}
  >
    {props.children}
  </a>
);

export default LinkButton;
