import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { OfferAccordionItem } from '../OfferAccordion/OfferAccordion';
import ProviderPriceForm from '../../Provider/ProviderPriceForm/ProviderPriceForm';
import ProviderCostHistory from '../../Providers/ProviderCostHistory';
import styles from '../OfferDetail.module.scss';

const OfferConnectionPrice = ({
  step,
  costs,
  saveCostsHandler,
  costsHistory,
  getCostHistory,
}) => {
  return (
    <OfferAccordionItem
      step={step}
      title="Connection Price"
      body={
        <Row>
          <Col xs={12} md={4} lg={4} xl={4}>
            {!costs ? null : (
              <ProviderPriceForm
                key={costs}
                cost={costs}
                onSave={saveCostsHandler}
              />
            )}
          </Col>
          <Col xs={12} md={6} lg={6} xl={6}>
            {!costsHistory?.data?.length ? null : (
              <div className={styles.tableContainer}>
                <ProviderCostHistory
                  costsHistory={costsHistory}
                  onPageChange={getCostHistory}
                />
              </div>
            )}
          </Col>
        </Row>
      }
    />
  );
};

export default OfferConnectionPrice;
