import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CPDCWhitelist from '../../../api/CpdcWhitelist';
import Button from '../../UI/Button/Button';
import TablePaginated from '../../UI/TablePaginated/TablePaginated';
import ActionsNavbar from '../../ActionsNavbar/ActionsNavbar';
import Input from '../../UI/Input/Input';
import DomainDeleteModal, {
  useModalConfirm as useDomainDeleteModal,
} from '../../Modal/ModalConfirm/ModalConfirm';

import DomainAddModal from './DomainAddModal';
import styles from './DomainWhitelistManager.module.scss';

const tableColumns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'domain',
    label: 'Domain',
  },
];

const getTableActions = (onDeleteDomain, canDelete) => {
  const actions = [];

  if (canDelete) {
    actions.push({
      key: 'delete',
      onClick: onDeleteDomain,
      label: 'Delete',
      warningStyle: true,
      display: () => true,
    });
  }

  return actions;
};

const DomainWhitelistManager = () => {
  const navigate = useNavigate();
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);
  const [isChecked] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [domains, setDomains] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  const getDomains = useCallback(async (pageNr, filters = {}) => {
    CPDCWhitelist.list({
      page: pageNr,
      ...filters,
    })
      .then((result) => {
        setDomains(result.data.data);
        setCurrentPage(pageNr ?? 1);
        setTotalPages(result.data.total_pages);
        setTotalCount(result.data.total_count);
      })
      .catch(() => {
        navigate('/');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [domainDeleteProps, setDomainDeleteProps] = useDomainDeleteModal({
    title: 'Delete confirmation',
    apiRequest: CPDCWhitelist.remove,
    supplementalErrorKey: 'domains',
    onSuccess: getDomains,
  });

  const onDeleteDomain = (domain) => {
    setDomainDeleteProps({
      apiId: domain.id,
      body: `Are you sure you want to delete '${domain.domain}'?`,
      toastSuccessMsg: domain.domain + ' has successfully been deleted',
    });
  };

  // eslint-disable-next-line no-shadow
  const onAddDomain = async (domains) =>
    CPDCWhitelist.save(domains)
      .then((response) => ({
        errors: null,
        data: response?.data,
        action: () => {
          setShowAddForm(false);
          getDomains();
        },
      }))
      .catch((err) => {
        const errors = err.response.data?._errors ?? {
          domains: 'Something went wrong, try again?',
        };
        return {
          errors,
          data: null,
          action: () => setShowAddForm(false),
        };
      });

  /* init fetch */
  useEffect(() => {
    const withSearchPhrase = (searchPhrase && { name: searchPhrase }) || null;
    getDomains(currentPage, withSearchPhrase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const pageChangeHandler = (pageNr) => {
    const withSearchPhrase = (searchPhrase && { name: searchPhrase }) || null;
    getDomains(pageNr, withSearchPhrase);
  };

  const searchUpdated = (event) => {
    const phrase = event.target.value;
    setSearchPhrase(phrase.substring(0, 100));

    if (phrase.length < 3 && phrase.length > 0) return;
    getDomains(1, {
      name: phrase.substring(0, 100),
    });
  };

  const noDomainsSet = !(domains && domains.length);

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
                mb-2
                mb-md-0
                d-flex
                align-items-center"
        >
          <Row
            className="
                    ms-1
                    justify-content-start
                    flex-wrap
                    flex-md-nowrap
                    align-items-center"
          >
            {(isAdmin || isBountyAdmin) && (
              <Button
                clicked={() => setShowAddForm(true)}
                dataTestAttribute="add-domain"
              >
                Add domain
              </Button>
            )}
            <Input
              elementType="input"
              elementConfig={{
                placeholder: 'Search domains',
                type: 'text',
              }}
              additionalClasses="formInput"
              value={searchPhrase}
              changed={searchUpdated}
            />
          </Row>
        </Col>
      </ActionsNavbar>

      <Container fluid className="pt-3">
        <Row>
          <Col>
            <h2>Whitelisted Domains</h2>
            <p>
              <small>
                Any domains configured will skip real-time email inbox
                verification during CPDC submission.
              </small>
            </p>

            {noDomainsSet ? (
              <p className={styles.emptyResults}>
                No whitelisted domains{' '}
                {searchPhrase ? 'found' : 'are currently active'}.
              </p>
            ) : (
              <TablePaginated
                tableColumns={tableColumns}
                tableData={domains}
                actions={getTableActions(
                  onDeleteDomain,
                  isAdmin || isBountyAdmin,
                )}
                disableInactive
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageUpdated={pageChangeHandler}
              />
            )}
          </Col>
        </Row>
      </Container>

      <DomainAddModal
        show={showAddForm}
        hideModal={() => setShowAddForm(false)}
        action={async (data) => onAddDomain(data)}
      />
      <DomainDeleteModal {...domainDeleteProps} />
    </>
  );
};

export default DomainWhitelistManager;
