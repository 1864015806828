import { useCallback, useEffect, useState, useMemo } from 'react';

import CompanyService from '../../api/Companies';

export const useCompanyModal = (
  setRefresh,
  saveTick,
  _setCompany,
  setToast,
  setSaveTick,
) => {
  const [companyId, setCompanyId] = useState(null);
  const [accordionsFormSaveStatus, setAccordionsFormSaveStatus] = useState({
    brandingAndDemography: null,
    postConnectForm: null,
    generalProfile: null,
  });

  const isAllAccordionsFormSaved = useMemo(() => {
    return !Object.values(accordionsFormSaveStatus).includes(null);
  }, [accordionsFormSaveStatus]);

  const isAllAccordionsFormValid = useMemo(() => {
    return Object.values(accordionsFormSaveStatus).every((key) => key);
  }, [accordionsFormSaveStatus]);

  const [companyModalProps, _setCompanyModalProps] = useState({
    showTick: 0,
    variant: 'webhook',
    formData: null,
    onSuccess: null,
  });

  const [webhookList, setWebhookList] = useState([]);

  const getWebhookList = useCallback(async () => {
    if (companyId) {
      const response = await CompanyService.webhookList(companyId);

      if (response.data?.data?.list) {
        setWebhookList(response.data.data.list);
      }
    }
  }, [companyId]);

  const setCompanyModalProps = (modalProps) =>
    _setCompanyModalProps((state) => ({
      showTick: state.showTick + 1,
      ...modalProps,
    }));

  const addWebhook = () => {
    setCompanyModalProps({
      variant: 'webhook',
      formData: null,
      onSuccess: getWebhookList,
    });
  };

  const editWebhook = (data = {}) => {
    setCompanyModalProps({
      variant: 'webhook',
      formData: data,
      onSuccess: getWebhookList,
    });
  };

  useEffect(() => {
    getWebhookList();
  }, [getWebhookList]);

  useEffect(() => {
    if (!saveTick || !isAllAccordionsFormSaved) {
      return;
    }
    if (isAllAccordionsFormValid) {
      setToast({
        show: true,
        header: 'Company updated successfully',
        class: 'success',
      });
    } else {
      setToast({
        show: true,
        class: 'error',
        header: 'Company Update Failed',
      });
    }
    // reset all accordions save status
    setAccordionsFormSaveStatus({
      generalProfile: null,
      brandingAndDemography: null,
      postConnectForm: null,
    });
    setSaveTick(0);
    setRefresh((n) => n + 1);
  }, [isAllAccordionsFormValid, saveTick, accordionsFormSaveStatus]);

  const getters = {
    webhookList,
    companyModalProps,
    accordionsFormSaveStatus,
    isAllAccordionsFormSaved,
  };
  const actions = {
    addWebhook,
    editWebhook,
    setCompanyId,
    setAccordionsFormSaveStatus,
  };

  return [getters, actions];
};
