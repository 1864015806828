import createInput from '../../../shared/Form/createInput';
import userTypes from '../../../shared/userTypes';

export default {
  profileImgUrl: {
    elementType: 'profile-photo',
    value: '',
    label: 'Profile Photo',
  },
  name: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Name',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    error: null,
    testId: 'input-name',
    label: 'Name',
  },
  email: {
    elementType: 'input',
    elementConfig: {
      type: 'email',
      placeholder: 'Email',
    },
    value: '',
    validation: {
      required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
    error: null,
    testId: 'input-email',
    label: 'Email',
  },
  phoneNumber: {
    elementType: 'phone',
    elementConfig: {
      type: 'text',
      placeholder: 'No Phone Number Supplied',
    },
    value: '',
    validation: {
      required: true,
      isPhone: true,
    },
    valid: false,
    touched: false,
    error: null,
    testId: 'input-phone',
    label: 'Phone Number',
  },
  password: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Password',
    },
    value: '',
    validation: {
      required: false,
      minLength: 15,
      maxLength: 128,
    },
    valid: true,
    touched: false,
    error: null,
    testId: 'input-password',
    label: 'Update Password',
  },
  repeatPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Repeat Password',
    },
    value: '',
    validation: {
      required: false,
      minLength: 15,
      maxLength: 128,
      isEqual: 'password',
    },
    valid: true,
    touched: false,
    error: null,
    testId: 'input-password-repeat',
    label: 'Repeat password',
  },
  role: createInput(
    'Role',
    {
      options: Object.keys(userTypes).map((key) => ({
        value: key,
        label: userTypes[key],
      })),
    },
    { required: true, testId: 'input-role' },
    '',
    true,
    'modern-select',
  ),
  company: createInput(
    'Company',
    {
      placeholder: 'Search for a company',
    },
    { required: true, testId: 'input-company' },
    '',
    true,
    'multiselect',
  ),
  brand: createInput(
    'Brand',
    {
      placeholder: 'Search for brand(s)',
    },
    { required: true, testId: 'input-brands' },
    null,
    true,
    'multiselect',
  ),
  emailOtp: {
    elementType: 'toggle',
    value: false,
    label: 'EMAIL OTP ENABLED?',
    description:
      'Enabling email OTP means the user will not receive the OTP via SMS.',
  },
};
