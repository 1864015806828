import instance from './Api';

const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });
  return instance.get(`/brands${query}`);
};

const save = (brand) => instance.post('/brands', brand);
const update = (id, brand) => instance.patch(`/brands/${id}`, brand);
const get = (id) => instance.get(`/brands/${id}`);
const remove = (id) => instance.delete(`/brands/${id}`);
const brandCompaniesOptions = (company) =>
  instance.post('/brands/company/options', company);
const brandOptions = () => instance.get('brands/options/all');

export default {
  list,
  save,
  update,
  get,
  remove,
  brandCompaniesOptions,
  brandOptions,
};
