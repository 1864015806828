import React from 'react';
import OfferDetailEdit from '../../../components/OfferDetail/OfferDetail';

const CompanyDetailsPage = ({ scrollTop }) => {
  return (
    <div>
      <OfferDetailEdit scrollTop={scrollTop} />
    </div>
  );
};

export default CompanyDetailsPage;
