import React from 'react';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import Creator from '../../Creator/Creator';

const DEFAULT_VERIFICATION_METHOD = 'email';

const completionStatusTest = (formValues) => {
  return !!(formValues.landingPageContent || []).find(
    (e) => e.type === 'vidio',
  );
};

const CampaignSectionBuilder = ({
  step,
  pageState,
  fieldKeys,
  formik,
  validationSchema,
  isLastStep,
  onSaveAction,
  sectionsRef,
  addSectionUpdated,
}) => {
  const campaignPaywallEnabled = !!formik.values.paywall?.enabled;

  const updateModulesOnClose = async (sections, globalConfig) => {
    formik.updateFieldValue('landingPageContent', sections);
    if (globalConfig) {
      formik.updateFieldValue('globalSettings', globalConfig);
    }

    // Formik wont recognise a deeply nested property change.
    //  To do so requires updating a value using dot notation to
    //  trigger "dirty" state. However, the updated value must also
    //  be different so we can't just select the first object key
    //  and update it.
    //  Reference: https://github.com/jaredpalmer/formik/issues/3459
    //
    // The simplest solution here is to detect any localised update,
    //  and pass this "updated" state into the accordion to enable
    //  the save button, rather than hacking formik itself.
    addSectionUpdated('builder');
  };

  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="builder"
      title="Landing Page Builder"
      formik={formik}
      fieldKeys={fieldKeys}
      validationSchema={validationSchema}
      completionStatusTest={completionStatusTest}
      isLastStep={isLastStep}
      onSaveAction={onSaveAction}
      sectionsRef={sectionsRef}
      isUpdated={!!pageState.sectionsUpdated.length}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          A landing page builder is a tool used to create modules (header,
          image, text, form, etc...) for a campaign`s landing page.
        </p>
      </CampaignAccordionItem.Tooltip>

      <Creator
        campaignData={formik.values}
        landingPageSections={formik.values.landingPageContent || []}
        saveSections={updateModulesOnClose}
        serviceType={formik.values.videoProvider?.id}
        globalSettings={formik.values.globalSettings}
        globalBrandLogoUrl={formik.values.companyLogoUrl || ''}
        videoProvider={formik.values.videoProvider}
        campaignId={formik.values.id}
        verificationMethod={
          formik.values.verificationMethod?.name || DEFAULT_VERIFICATION_METHOD
        }
        isPaywall={campaignPaywallEnabled}
      />
    </CampaignAccordionItem>
  );
};

export default CampaignSectionBuilder;
