import React from 'react';

import FormikInput from '../../../../components/UI/FormikInput/FormikInput';
import Input from '../../../../components/UI/Input/Input';
import Modal from '../../../../components/UI/Modal/Modal';
import Button from '../../../../components/UI/Button/Button';

import languages from '../../../../shared/languages';

import { useQualifierQuestionsForm } from './QualifierQuestionsForm.hooks';

const QualifierQuestionsForm = ({
  initialData = {},
  setShowForm,
  onSuccess,
  showForm,
  setToast,
}) => {
  const [getters, actions] = useQualifierQuestionsForm({
    initialData,
    setShowForm,
    onSuccess,
    setToast,
  });

  const {
    isUpdate,
    isLoading,
    formik,
    checkLanguageChange,
    language,
    formFields,
    validationSchema,
    formikIsReady,
  } = getters;

  const {
    handleClose,
    submitForm,
    setCheckLanguageChange,
    updateLanguage,
    addAnswer,
    removeAnswer,
  } = actions;

  return (
    <Modal
      title={`${isUpdate ? 'Update' : 'Create'} Qualifier Question`}
      handleClose={handleClose}
      handleSave={submitForm}
      size="lg"
      show={showForm}
      cancel="Cancel"
      save="Save"
      loading={isLoading}
      checkBeforeClose={!!Object.keys(formik.touched).length}
    >
      {checkLanguageChange ? (
        <div
          style={{ zIndex: 10, backgroundColor: '#ebebebE6' }}
          className="w-100 h-100 top-0 p-3 start-0 gap-3 position-absolute align-items-center justify-content-center flex-column d-flex"
        >
          <p className="bold fw-bold text-center fs-5">Warning</p>
          <p className="text-center mb-1">
            Changing the language will reset all answers.
          </p>
          <p className="text-center">
            Please save if you do not want to lose your updates.
          </p>
          <div className="d-flex gap-2 flex-wrap align-items-center justify-content-center">
            <Button clicked={() => setCheckLanguageChange(null)}>Cancel</Button>
            <Button
              clicked={() => {
                updateLanguage(checkLanguageChange, true);
                setCheckLanguageChange(null);
              }}
            >
              Continue (reset all)
            </Button>
          </div>
        </div>
      ) : null}

      <>
        <div className="mb-4">
          <Input
            key="language"
            keyName="language"
            label="Translation"
            additionalClasses="formInput formInput--bordered"
            elementType="select"
            elementConfig={{
              options: Object.entries(languages).map(
                ([value, displayValue]) => ({
                  value,
                  displayValue,
                }),
              ),
              disabled: !isUpdate,
            }}
            value={language}
            changed={(event) => updateLanguage(event.target.value)}
          />
        </div>

        {formikIsReady && (
          <>
            <form onSubmit={formik.handleSubmit}>
              {formFields.map((field) => {
                return (
                  <div className="position-relative" key={field.key}>
                    <FormikInput
                      field={field}
                      formik={formik}
                      validationSchema={validationSchema}
                    />
                    {field.isNewAnswer && (
                      <button
                        style={{
                          boxSizing: 'border-box',
                        }}
                        type="button"
                        className="btn-close p-2 mt-1 position-absolute end-0 top-0"
                        onClick={() => removeAnswer(field.key)}
                        aria-label="Remove Answer"
                      />
                    )}
                  </div>
                );
              })}
            </form>
            <Button clicked={addAnswer}>Add Answer</Button>
          </>
        )}
      </>
    </Modal>
  );
};

export default QualifierQuestionsForm;
