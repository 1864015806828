import React from 'react';
import Col from 'react-bootstrap/Col';
import { formatDate } from '../../../shared/dateTime';
import Button from '../../UI/Button/Button';
import DataTable from '../../UI/DataTable/DataTable';
import Input from '../../UI/Input/Input';

import styles from './Codes.module.scss';

const gridStyle = { height: 'calc(100vh - 350px)' };
const dateFormat = 'dd-MM-yyyy hh:mm:ss';

const CodesTable = ({ dataSource, onDelete, onStatusChange, search }) => {
  const columns = [
    {
      name: 'code',
      header: 'Code',
      width: 300,
    },
    { header: 'Status', name: 'status', defaultFlex: 1 },
    {
      header: 'Bounty Consumer',
      name: 'cpdc',
      defaultFlex: 1,
      render: ({ value }) => value?.phoneNumber,
    },
    {
      header: 'Date Redeemed',
      name: 'dateUsed',
      defaultFlex: 1,
      render: ({ value }) => `${formatDate(value, dateFormat)}`,
    },
    {
      header: 'Consumer Note',
      name: 'consumerNote',
      defaultFlex: 1,
    },
    {
      name: 'id',
      header: 'Actions',
      width: 250,
      sortable: false,
      render: ({ value, data }) => (
        <div className="btnContainer">
          <Button
            clicked={() => onDelete(value)}
            additionalClasses="btnDefault--small btnDefault--red"
          >
            Delete
          </Button>
          <Button
            clicked={() =>
              onStatusChange(
                value,
                data.status === 'Available' ? 'Used' : 'Available',
              )
            }
            additionalClasses="btnDefault--small"
          >
            {data.status === 'Available' ? 'Mark as used' : 'Make available'}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={`${styles.filterContainer} mb-2 p-2 d-flex gap-3`}>
        <Col md={2} className="">
          <Input
            elementType="text"
            label="Search by Code"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'code')}
            dataTestAttribute="search-by-name-input"
          />
        </Col>
      </div>
      <DataTable
        id="code"
        columns={columns}
        dataSource={dataSource}
        style={gridStyle}
        columnOrderPersist
        pageSizes={[50, 100]}
      />
    </>
  );
};

export default CodesTable;
