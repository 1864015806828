import React, { useEffect, useMemo, useRef } from 'react';

import { Row, Col } from 'react-bootstrap';
import { CompanyAccordionItem } from '../CompanyAccordion/CompanyAccordion';
import FormikInput from '../../UI/FormikInput/FormikInput';
import { useCompanyBrandAndDemography } from './CompanyBrandAndDemography.hooks';
import { displayFields } from './CompanyBrandAndDemography.controls';

const CompanyBrandAndDemography = ({
  step,
  setAccordionActiveKey,
  saveTick,
  companyId,
  company,
  accordionsFormSaveStatus,
  setAccordionsFormSaveStatus,
}) => {
  const [getters] = useCompanyBrandAndDemography(
    company,
    companyId,
    setAccordionsFormSaveStatus,
  );
  const { formik, validationSchema } = getters;

  const isFormValid = useMemo(
    () => formik.isValid || Object.keys(formik.errors).length === 0,
    [formik.isValid, formik.errors],
  );

  useEffect(() => {
    const isGeneralProfileAccordionSaved =
      accordionsFormSaveStatus.generalProfile !== null;
    if (!saveTick || !isGeneralProfileAccordionSaved) {
      return;
    }

    if (!isFormValid) {
      setAccordionsFormSaveStatus((prevState) => {
        return {
          ...prevState,
          brandingAndDemography: false,
        };
      });
      setAccordionActiveKey('1');
      return;
    }

    const isBrandingAndDemographySaved =
      accordionsFormSaveStatus.brandingAndDemography !== null;

    if (!isBrandingAndDemographySaved) {
      formik.submitForm();
    }
  }, [saveTick, accordionsFormSaveStatus, isFormValid]);

  return (
    <CompanyAccordionItem
      step={step}
      title="Branding and demography"
      body={
        <Row>
          <form onSubmit={formik.handleSubmit}>
            {[
              'companyPrimaryColor',
              'countries',
              'website',
              'contactPerson',
              'contactEmail',
              'contactPhone',
              'address',
              'audienceSizeUpTo',
              'audienceGenderDistribution',
              'audienceTechRating',
              'companyLogo',
            ].map((fieldKey) => {
              return (
                <Row key={fieldKey}>
                  {fieldKey === 'audienceGenderDistribution' ? (
                    <>
                      <Col md={12}>
                        <h5>Audience Gender Distribution</h5>
                      </Col>
                      {['audienceGenderMale', 'audienceGenderFemale'].map(
                        (genderFieldKey) => {
                          return (
                            <Col key={genderFieldKey}>
                              <FormikInput
                                containerClass="mb-4 with-percent-sign"
                                key={genderFieldKey}
                                formik={formik}
                                field={displayFields(formik)[genderFieldKey]}
                                validationSchema={validationSchema}
                              />
                            </Col>
                          );
                        },
                      )}
                    </>
                  ) : (
                    <FormikInput
                      key={fieldKey}
                      formik={formik}
                      field={displayFields(formik)[fieldKey]}
                      validationSchema={validationSchema}
                    />
                  )}
                </Row>
              );
            })}
          </form>
        </Row>
      }
    />
  );
};

export default CompanyBrandAndDemography;
