import { string, object, mixed, bool } from 'yup';
import _capitalize from 'lodash/capitalize';

export const getSchema = () => ({
  videoProvider: mixed().when('paywall', {
    is: (paywall) => !paywall?.enabled,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.nullable(true),
  }),
  verificationMethod: object()
    .shape({
      name: string(),
    })
    .optional(),
  isProviderFallback: bool(),
  multipleSubscriptionsAllowed: bool(),
});

const displayFields = {
  videoProvider: {
    key: 'videoProvider',
    label: 'SELECT AN OFFER',
    type: 'modern-select',
    elementConfig: {},
    valueKey: 'id',
    defaultValue: null,
    formatOutput: (selectedId, options) => {
      return options.find((o) => o.id === selectedId);
    },
    transposeOptions: (options) => {
      return options.map((o) => ({
        value: o.id,
        label: o.name,
      }));
    },
  },
  verificationMethod: {
    key: 'verificationMethod',
    label: 'User Verification Method',
    type: 'modern-select',
    defaultValue: { name: 'email' },
    elementConfig: {},
    formatOutput: (name, options) => {
      return options.find((o) => o.name === name);
    },
    formatInputValue: (value) => {
      return value?.name ?? '';
    },
    transposeOptions: (options) => {
      return options.map((o) => ({
        label: o.name === 'sms' ? o.name.toUpperCase() : _capitalize(o.name),
        value: o.name,
      }));
    },
  },
  isProviderFallback: {
    key: 'isProviderFallback',
    defaultValue: false,
  },
  multipleSubscriptionsAllowed: {
    key: 'multipleSubscriptionsAllowed',
    defaultValue: false,
  },
};

export default displayFields;
