import { format } from 'date-fns';

export const inputFormatDate = "yyyy-MM-dd'T'HH:mm";

export const formatDate = (date, dateFormat = inputFormatDate) => {
  if (!date) return '';

  try {
    return format(new Date(date), dateFormat);
  } catch (_) {
    return '';
  }
};

export const formatDateToISO = (date) => {
  if (!date) return new Date().toISOString();
  try {
    return new Date(date).toISOString();
  } catch (_) {
    return date;
  }
};
