import React from 'react';
import { useSelector } from 'react-redux';

import { CompanyAccordionItem } from '../CompanyAccordion/CompanyAccordion';
import TableData from '../../UI/TableData/TableData';
import Button from '../../UI/Button/Button';

const CompanySectionIntegrations = ({
  step,
  webhookList,
  addWebhook,
  editWebhook,
}) => {
  const { isAdmin, isBountyAdmin, isCompanyAdmin } = useSelector(
    (state) => state.auth,
  );

  const showAddEditWebhook = isAdmin || isBountyAdmin || isCompanyAdmin;

  return (
    <CompanyAccordionItem
      step={step}
      title="Integrations"
      body={
        <div>
          <h4 className="mb-4">Webhook API Endpoints</h4>
          {showAddEditWebhook && (
            <Button type="submit" additionalClasses="me-3" clicked={addWebhook}>
              Add Webhook Endpoint
            </Button>
          )}

          <div className="mt-4">
            <TableData
              tableData={webhookList}
              tableColumns={[
                { key: 'name', label: 'Name' },
                { key: 'endpoint', label: 'Endpoint' },
              ]}
              actionWidth={95}
              actions={
                showAddEditWebhook
                  ? [
                      {
                        key: 'edit',
                        label: 'Edit',
                        onClick: editWebhook,
                      },
                    ]
                  : []
              }
            />
          </div>
        </div>
      }
    />
  );
};

export default CompanySectionIntegrations;
