import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import _set from 'lodash/set';

import Modal from '../../UI/Modal/Modal';
import CampaignService from '../../../api/Campaigns';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import FormikInput from '../../UI/FormikInput/FormikInput';
import Tooltip from '../../UI/Tooltip/Tooltip';
import Button from '../../UI/Button/Button';
import styles from './CampaignWebhookModal.module.scss';

import { formatDate } from '../../../shared/dateTime';

const getFlatKey = (key) => {
  return key.replace(/\.([a-z])/g, (match, capture) => capture.toUpperCase());
};

const previewTemplate = (data) => {
  return `
    <html>
      <head>
        <title>Webhook Map Preview</title>
      </head>
      <body>
        <pre style="background-color: #e9e8e8; padding: 20px">${data}</pre>
      </body
    </html>
  `;
};

const inputConfigMapKey = (idx, placeholder) => ({
  type: 'text',
  key: `fieldMappings[${idx}].mappedKey`,
  elementConfig: {
    placeholder,
  },
  formatOutput(value) {
    return value.replace(/[\s`~+\-=!@)#($*%&^{}[\];':"<>?,/)]*/g, '');
  },
});

const inputConfigDefault = (idx) => ({
  type: 'text',
  key: `fieldMappings[${idx}].defaultValue`,
  elementConfig: {
    placeholder: 'Add a default value',
  },
});

const WebhookFieldRow = ({
  idx,
  formik,
  webhookFieldConfig,
  validationSchema,
  onEnableField,
}) => {
  const toggleField = (key, bool) => {
    if (bool) {
      onEnableField();
    }

    const formikKey = `fieldMappings[${idx}][${key}]`;
    formik.setFieldValue(formikKey, bool);
    formik.setFieldTouched(formikKey);
  };

  return (
    <Row className={styles.webhookFieldRow}>
      <Col xs="4" className="col-4 py-2 d-flex align-items-center">
        <ToggleSwitch
          additionalLabelClasses={styles.switchLabel}
          value={webhookFieldConfig?.enabled ? 1 : 0}
          onCheck={(bool) => toggleField('enabled', bool)}
        />
        <span>{webhookFieldConfig?.label}</span>
      </Col>
      <Col xs="4" className="col-4 py-2 d-flex align-items-center">
        <FormikInput
          containerClass=""
          key="mappedKey"
          field={inputConfigMapKey(
            idx,
            getFlatKey(
              webhookFieldConfig.defaultWebhookKey || webhookFieldConfig.key,
            ),
          )}
          formik={formik}
          validationSchema={validationSchema}
        />
      </Col>
      <Col xs="4" className="col-4 py-2 d-flex align-items-center">
        <FormikInput
          containerClass="my-2"
          key="defaultValue"
          field={inputConfigDefault(idx)}
          formik={formik}
          validationSchema={validationSchema}
        />
      </Col>
    </Row>
  );
};

const CampaignWebhookModal = ({ data, show, close, setWebhooks }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {},
  });

  const webhook = data?.campaignWebhook?.find(
    (e) => e.id === formik.values?.id,
  );

  const webhookError = webhook?.failureInfo ?? null;

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value);
    formik.setFieldTouched(key);
  };

  const onEnableField = () => {
    setFieldValue('enabled', true);
  };

  const getWebhook = async (campaignId, companyWebhookId) => {
    setLoading(true);
    const response = await CampaignService.getWebhook(
      campaignId,
      companyWebhookId,
    ).catch(() => null);
    setLoading(false);

    if (response?.status === 200) {
      formik.resetForm({ values: response.data });
    }
  };

  const saveWebhook = async () => {
    const save = await CampaignService.saveWebhook(
      id,
      data.id,
      formik.values,
    ).catch(() => null);

    if (save?.status === 200) {
      const response = await CampaignService.getWebhooks(id).catch(() => null);
      const { data: webhooksData } = response?.data ?? {};
      const { list: webhooksList } = webhooksData ?? {};
      setWebhooks(webhooksList);
      close();
    }
  };

  useEffect(() => {
    if (show && data.id) {
      getWebhook(id, data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, id, data]);

  const toggleLabel = formik.values?.enabled
    ? 'Webhook Enabled'
    : 'Webhook Disabled';
  const fieldMappings = formik.values?.fieldMappings ?? [];

  const getWebhookPayload = async () => {
    setLoading(true);
    const payload = {};

    for (const field of fieldMappings) {
      if (field.enabled) {
        const payloadFieldKey =
          field.mappedKey || getFlatKey(field.defaultWebhookKey || field.key);

        _set(payload, payloadFieldKey, field.defaultValue);
      }
    }

    const webhookPayload = JSON.stringify(payload, null, 2);
    const newTab = window.open();
    newTab.document.open();
    newTab.document.write(previewTemplate(webhookPayload));
    newTab.document.close();

    setLoading(false);
  };

  return (
    <Modal
      save="Save"
      handleSave={saveWebhook}
      title={`Webhook: ${data?.name}`}
      handleClose={close}
      size="lg"
      show={show}
    >
      {loading && <OverlaySpinner />}

      <div className="mt-1">
        {webhookError ? (
          <div className="alert alert-danger">
            <div>
              This webhook was auto-disabled due to &quot;{webhookError.message}
              &quot;
            </div>
            <div>
              The last failed attempt occurred for Connection ID: $
              {webhookError.cpdcId} at{' '}
              {formatDate(new Date(webhookError.date), 'dd MMM yyyy HH:mm')}
            </div>
            <div>
              Network response was &quot;{webhookError.requestError}&quot;
            </div>
            <div className="mt-2">
              <strong>
                Please ask a company admin to review the company webhook
                configuration (if you cannot do so) before re-enabling this
                webhook.
              </strong>
            </div>
          </div>
        ) : null}

        <div className="d-flex mb-2">
          <h4 className="d-flex align-items-center m-0">Mapping table</h4>

          <div className="d-flex align-items-center ms-auto">
            <ToggleSwitch
              reverseLabel
              additionalLabelClasses={styles.switchLabel}
              label={toggleLabel}
              value={formik.values?.enabled ? 1 : 0}
              onCheck={(bool) => setFieldValue('enabled', bool)}
            />

            <Button
              additionalClasses="btnDefault--small ms-4 me-0"
              clicked={getWebhookPayload}
            >
              Preview
            </Button>
          </div>
        </div>

        <Row className={styles.webhookFieldRow}>
          <Col
            xs="4"
            className="col-4 d-flex flex-column justify-content-start pt-3 pb-3 col-4"
          >
            <div className="d-flex align-items-center position-relative">
              <h5 className="mb-0 me-2">Field</h5>
              <Tooltip id="field">
                <p>
                  Disable/enable fields via the toggle. Disabled fields are not
                  sent in the payload.
                </p>
              </Tooltip>
            </div>
          </Col>
          <Col
            xs="4"
            className="col-4 d-flex flex-column justify-content-start pt-3 pb-3 col-4"
          >
            <div className="d-flex align-items-center position-relative">
              <h5 className="mb-0 me-2">Mapping Key</h5>
              <Tooltip id="mapping-key">
                <p>
                  Map this value to your own property name. Use format:
                  `propertyKey.nestedPropertyKey` for deeply nested properties.
                  No spaces or special characters are allowed (underscore
                  &apos;_&apos; is allowed).
                </p>
              </Tooltip>
            </div>
          </Col>
          <Col
            xs="4"
            className="col-4 d-flex flex-column justify-content-start pt-3 pb-3 col-4"
          >
            <div className="d-flex align-items-center position-relative">
              <h5 className="mb-0 me-2">Default Value</h5>
              <Tooltip id="default-value">
                <p>Default if no value exists.</p>
              </Tooltip>
            </div>
          </Col>
        </Row>

        {fieldMappings.map((config, idx) => {
          return (
            <WebhookFieldRow
              key={config.key}
              idx={idx}
              formik={formik}
              webhookFieldConfig={config}
              onEnableField={onEnableField}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default CampaignWebhookModal;
