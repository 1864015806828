import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CarouselThumb = ({ imageUrl, onSelect, onRemove, selected }) => {
  const onRemoveHandle = (event) => {
    event.stopPropagation();
    onRemove();
  };
  return (
    <div
      className={`carouselInputThumb ${
        selected ? 'carouselInputThumb--selected' : null
      }`}
      style={{
        backgroundImage: `url('${process.env.REACT_APP_UPLOADS_URL}/${imageUrl}')`,
      }}
      onClick={onSelect}
      data-test-id="carousel-input-thumb"
    >
      <div className="removeThumb" onClick={(event) => onRemoveHandle(event)}>
        <FontAwesomeIcon fixedWidth icon={faTimes} />
      </div>
    </div>
  );
};
export default CarouselThumb;
