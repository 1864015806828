import React from 'react';
import { Container } from 'react-bootstrap';

import styles from './StatisticsPanel.module.scss';

const formatNumber = (num) => (num ? num.toLocaleString() : 0);

const StatisticsPanel = ({ stats = [] }) => {
  const lastIdx = stats.length - 1;

  return (
    <Container fluid className={styles.outer}>
      <div className={styles.statistics}>
        {stats.map((stat, idx) => {
          return (
            <>
              <div className={`d-flex ${styles.cell}`}>
                <span className={`text-semibold ${styles.label}`}>
                  {stat.label}
                </span>
                <div className="mx-3">
                  <ProfileSvg />
                </div>
                <span className={styles.statistic}>
                  {formatNumber(stat.count)}
                </span>
              </div>
              {idx !== lastIdx && <div className={styles.divider} />}
            </>
          );
        })}
      </div>
    </Container>
  );
};

const ProfileSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ clipPath: 'url(#statistics_profile)' }}>
      <path
        d="M11.9969 13.3905C9.59621 13.3905 7.57888 15.1024 7.20206 17.4576C7.15983 17.7045 7.23129 17.9611 7.40022 18.1625C7.57239 18.3574 7.81927 18.4711 8.07591 18.4711C8.51445 18.4711 8.88154 18.1625 8.94975 17.7337C9.19014 16.2426 10.4733 15.1576 11.9969 15.1576C13.5204 15.1576 14.8036 16.2394 15.044 17.7337C15.1122 18.1593 15.4793 18.4711 15.9178 18.4711C16.1777 18.4711 16.4246 18.3574 16.5935 18.1625V18.156C16.7624 17.9579 16.8339 17.7045 16.7917 17.4576C16.4148 15.0992 14.3975 13.3872 11.9969 13.3872V13.3905Z"
        fill="#FF6F00"
      />
      <path
        d="M11.9935 12.5979C12.2046 12.5979 12.4158 12.5816 12.6302 12.5427C13.9783 12.3218 15.2582 11.0419 15.4791 9.69372C15.674 8.51126 15.3199 7.36454 14.5046 6.54916C13.8451 5.88971 12.9518 5.52588 11.9967 5.52588C11.7791 5.52588 11.5647 5.54212 11.3633 5.57786C10.0151 5.802 8.73521 7.08192 8.51431 8.4268C8.3194 9.60926 8.67349 10.756 9.48887 11.5714C10.1581 12.2373 11.0449 12.5979 11.9935 12.5979ZM10.236 9.06026C10.236 7.84207 11.1196 7.29632 11.9967 7.29632C12.8738 7.29632 13.7574 7.84207 13.7574 9.06026C13.7574 10.2785 12.8738 10.8242 11.9967 10.8242C11.5322 10.8242 11.1001 10.6683 10.7785 10.3824C10.4212 10.0673 10.2328 9.60926 10.2328 9.06026H10.236Z"
        fill="#FF6F00"
      />
      <path
        d="M11.9968 0C5.38278 0 0 5.38603 0 12.0032C0 18.6205 5.38278 24 11.9968 24C18.6107 24 23.9935 18.6172 23.9935 12.0032C23.9935 5.38928 18.614 0 11.9968 0ZM11.9968 22.1451C6.40282 22.1451 1.85165 17.5972 1.85165 12.0032C1.85165 6.40931 6.40282 1.85815 11.9968 1.85815C17.5907 1.85815 22.1419 6.40931 22.1419 12.0032C22.1419 17.5972 17.5907 22.1451 11.9968 22.1451Z"
        fill="#FF6F00"
      />
    </g>
    <defs>
      <clipPath id="statistics_profile">
        <rect width="23.9935" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StatisticsPanel;
