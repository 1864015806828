import React, { useState, useReducer, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';

import UserService from '../../api/Users';
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';

import Modal from '../../components/UI/Modal/Modal';
import ToggleSwitch from '../../components/UI/ToggleSwitch/ToggleSwitch';
import Toaster from '../../components/UI/Toaster/Toaster';
import UserTable from './UsersTable/UsersTable';

const hideInactiveReducer = (state, bool) => {
  window.sessionStorage.setItem('hideInactiveUsers', bool);
  return bool;
};

const UsersPage = () => {
  const navigate = useNavigate();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchFilters, setSearchFilters] = useState({});
  const [userToDeactivateId, setUserToDeactivatedId] = useState(null);
  const [users, setUsers] = useState([]);
  const [fetchTick, setFetchTick] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useReducer(
    hideInactiveReducer,
    window.sessionStorage?.hideInactiveUsers === 'true' ?? false,
  );

  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const { isAdmin, isBountyAdmin, isAdvertiser } = useSelector(
    (state) => state.auth,
  );

  const getUsers = async (config = {}, filters = {}) => {
    try {
      const result = await UserService.list({
        limit: config.limit,
        page: config.skip / config.limit + 1,
        order: config.sortInfo ? config.sortInfo.name : 'updatedAt',
        ...(isChecked ? { activated: isChecked } : {}),
        orderDirection:
          config.sortInfo && config.sortInfo.dir === 1 ? 'asc' : 'desc',
        ...filters,
      });

      const data = result.data.data;

      return {
        data,
        count: result.data.total_count,
      };
    } catch (error) {
      navigate('/');
    }
  };

  const deactivationClickHandler = (id) => {
    setUserToDeactivatedId(id);
    setShowModal(true);
  };

  const deactivationCancelHandler = () => {
    setUserToDeactivatedId(null);
    setShowModal(false);
  };

  const deactivationHandler = () => {
    UserService.deactivate(userToDeactivateId)
      .then(() => {
        setUserToDeactivatedId(null);
        setShowModal(false);
        setFetchTick((tick) => tick + 1);
      })
      .catch((error) => {
        setUserToDeactivatedId(null);
        setShowModal(false);
        setToast({
          show: true,
          class: 'error',
          header:
            error.response?.data?.message ??
            'There was a problem during deactivation of the user.',
        });
      });
  };

  const resendClickHandler = (id) => {
    UserService.resend(id)
      .then(() => {
        setToast({
          show: true,
          class: 'success',
          header: 'Invitation email sent',
        });
        setFetchTick((tick) => tick + 1);
      })
      .catch((error) => {
        setToast({
          show: true,
          class: 'error',
          header:
            error?.response?.data?.message ??
            'We could not send invitation email',
        });
      });
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const search = async (event, type) => {
    const phrase = event.target.value;
    setSearchPhrase(phrase.substring(0, 100));
    setSearchFilters({ [type]: phrase.substring(0, 100) });

    if (phrase.length < 3 && phrase.length > 0) return;

    // Avoiding multiple calls per user keyup
    debouncedCall();
  };

  const dataSource = useCallback(
    async (config) => {
      const withSearchPhrase = (searchPhrase && searchFilters) || {};
      const result = await getUsers(config, withSearchPhrase);
      setUsers(result.data);
      return result;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, fetchTick],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
                mb-2
                mb-md-0
                d-flex
                flex-wrap
                align-items-center"
        >
          <Dropdown>
            <Dropdown.Toggle
              className="btnDefault btnDefault--with-icon btnDefault--with-icon-add"
              id="download-csv"
              data-test-id="download-csv-button"
            >
              Invite
              <FontAwesomeIcon
                icon={faArrowDown}
                title="Invite"
                className=" btnDefault--with-icon__icon"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!isAdvertiser && (
                <>
                  {isAdmin && (
                    <Dropdown.Item href="/users/add/ROLE_ADMIN">
                      Invite Super Admin
                    </Dropdown.Item>
                  )}
                  {(isAdmin || isBountyAdmin) && (
                    <Dropdown.Item href="/users/add/ROLE_BOUNTY_ADMIN">
                      Invite Bounty Admin
                    </Dropdown.Item>
                  )}

                  {isAdmin && (
                    <Dropdown.Item href="/users/add/ROLE_COMPANY_ADMIN">
                      Invite Company Admin
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item href="/users/add/ROLE_COMPANY_USER">
                    Invite Company User
                  </Dropdown.Item>
                  <Dropdown.Item href="/users/add/ROLE_ADVERTISER">
                    Invite Advertising Agency
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col
          className="
                d-flex
                justify-content-start
                justify-content-sm-start
                flex-wrap
                align-content-center
                mt-2 mt-md-0"
          xs={12}
          md="auto"
        >
          <div style={{ float: 'right' }}>
            <ToggleSwitch
              value={isChecked}
              label="Hide inactive users"
              onCheck={() => {
                setIsChecked(!isChecked);
              }}
            />
          </div>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-3">
        <Row>
          <Col>
            <>
              <Modal
                show={showModal}
                title="Deactivation confirmation"
                body="Are you sure you want to deactivate the user?"
                cancel="No"
                save="Yes"
                handleClose={deactivationCancelHandler}
                handleSave={deactivationHandler}
                dataTestAttribute={{
                  modal: 'modal-deactivation',
                  action1: 'no-deactivation-form',
                  action2: 'yes-deactivation-form',
                }}
              />
              <UserTable
                dataSource={dataSource}
                users={users}
                search={search}
                deactivationClick={deactivationClickHandler}
                resendClick={resendClickHandler}
              />
            </>
          </Col>
        </Row>
      </Container>
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default UsersPage;
