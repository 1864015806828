import createInput from '../../../shared/Form/createInput';

export default {
  coords: {
    width: createInput(
      'Width',
      {
        type: 'number',
        min: 0,
        max: 100,
        step: 0.01,
      },
      {
        required: true,
        isFloat: true,
        min: 0,
        max: 100,
      },
      20,
      true,
    ),
    height: createInput(
      'Height',
      {
        type: 'number',
        min: 0,
        max: 100,
        step: 0.01,
      },
      {
        required: true,
        isFloat: true,
        min: 0,
        max: 100,
      },
      10,
      true,
    ),
    left: createInput(
      'Left',
      {
        type: 'number',
        min: 0,
        max: 100,
        step: 0.01,
      },
      {
        required: true,
        isFloat: true,
        min: 0,
        max: 100,
      },
      40,
      true,
    ),
    top: createInput(
      'Top',
      {
        type: 'number',
        min: 0,
        max: 100,
        step: 0.01,
      },
      {
        required: true,
        isFloat: true,
        min: 0,
        max: 100,
      },
      45,
      true,
    ),
    targetUrl: createInput(
      'Target Url',
      {
        placeholder: 'Add target url',
      },
      {
        required: true,
        isUrl: true,
      },
      '',
      false,
    ),
    title: createInput(
      'Title',
      {
        placeholder: 'Add title',
      },
      {
        required: false,
      },
      '',
      true,
    ),
  },
  formIsValid: false,
};
