import React, { useState, useMemo } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUploadButton from '../FileUploadButton/FileUploadButton';

const Preview = ({
  filePath,
  openPreview,
  label,
  dataTestAttribute,
  removeFile,
  backgroundColor,
}) => {
  const containerStyle = backgroundColor
    ? {
        backgroundColor,
      }
    : null;

  const isFileExtensionJson = useMemo(() => {
    if (filePath) {
      return filePath.match(/\.([^.]+)$/)[1] === 'json';
    }
  }, [filePath]);
  return (
    <div className="previewThumb ms-2 ">
      <div
        title="Click to open preview"
        onClick={() => openPreview()}
        className={`${
          !isFileExtensionJson ? 'formInput formInput--bordered p-2' : ''
        } d-flex justify-content-center align-items-center`}
        style={containerStyle}
      >
        {filePath && !isFileExtensionJson ? (
          <img
            alt={label}
            src={`${process.env.REACT_APP_UPLOADS_URL}/${filePath}`}
            data-test-id={`${dataTestAttribute}-upload-thumb` || null}
          />
        ) : (
          <a
            href={`${process.env.REACT_APP_UPLOADS_URL}/${filePath}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {filePath}
          </a>
        )}
      </div>
      {filePath && (
        <div
          className="removeThumb"
          onClick={(event) => {
            removeFile(event);
          }}
        >
          <FontAwesomeIcon fixedWidth icon={faTimes} />
        </div>
      )}
    </div>
  );
};

/**
 * File upload input.
 * Using FileUploadButton with presentation of uploaded file
 * @param label
 * @param buttonText
 * @param filePath
 * @param onFileUrlChange
 * @param dataTestAttribute
 * @returns {JSX.Element}
 * @constructor
 */
const FileUploadInput = ({
  label,
  buttonText,
  filePath,
  additionalClasses,
  onFileUrlChange,
  dataTestAttribute,
  withPreview = true,
  required,
  description,
  uploadUrl,
  backgroundColor,
}) => {
  const [error, setError] = useState(null);

  const showErrors = (errorObject) => {
    const errorMessage = `${errorObject.errors.join(', ')}`;
    setError(errorMessage);
  };

  const openPreview = () => {
    window.open(`${process.env.REACT_APP_UPLOADS_URL}/${filePath}`);
  };

  const removeFile = (event) => {
    event.preventDefault();
    onFileUrlChange(null);
    setError(required ? 'You must upload a file' : null);
  };

  const requiredField = required && <span className="text-danger"> *</span>;

  return (
    <div>
      <div className={`fileUploadInput ${label ? 'addTopMargin' : ''}`}>
        <div>
          {label ? (
            <label className="Label" htmlFor="fileUpload">
              {label}
              {requiredField}
            </label>
          ) : null}

          <FileUploadButton
            additionalClasses={additionalClasses}
            onUploadStart={() => setError(null)}
            onFileUploadSuccess={(_, response) =>
              onFileUrlChange(response.data)
            }
            onFileUploadError={(_, errorObject) => showErrors(errorObject)}
            buttonText={buttonText}
            multiple={false}
            dataTestAttribute={`${dataTestAttribute}-upload`}
            uploadUrl={uploadUrl}
          />
        </div>

        {!!(withPreview && filePath) && (
          <Preview
            filePath={filePath}
            openPreview={openPreview}
            label={label}
            dataTestAttribute={dataTestAttribute}
            removeFile={removeFile}
            backgroundColor={backgroundColor}
          />
        )}

        {error && (
          <div
            className="formInputError fileUploadError"
            data-test-id={`${dataTestAttribute}-upload-error` || null}
          >
            {error}
          </div>
        )}
      </div>

      {description && (
        <small className="formInputDescription">{description}</small>
      )}
    </div>
  );
};
export default FileUploadInput;
