import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faArrowLeftLong,
} from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import UserService from '../../../api/Users';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import ToasterBottom from '../../../components/UI/Toaster/ToasterBottom';
import bountyMediaBannerLogo from '../../../assets/images/bounty-login-banner.svg';
import inputChangedHandler from '../../../shared/inputChangeHandler';
import style from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  return (
    <div className={style.container}>
      <section className={style.imgBox}>
        <img
          className={style.logo}
          src={bountyMediaBannerLogo}
          alt="Bounty Media logo"
        />
      </section>
      <section className={style.resetPasswordBox}>
        <button
          onClick={() => navigate('/')}
          className={`${style.backIcon} ${style.return}`}
        >
          <FontAwesomeIcon size="xl" width="54" icon={faArrowLeftLong} />
          <p>Return</p>
        </button>
        <Content />
      </section>
    </div>
  );
};
const checkIfPageIsAccessible = async (passwordResetToken) => {
  if (!passwordResetToken) {
    return { accessible: false, user: null };
  }
  try {
    const { data } = await UserService.getByPasswordResetToken(
      passwordResetToken,
    );

    return { accessible: true, user: data };
  } catch (err) {
    return { accessible: false, user: null };
  }
};
const Content = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [user, setUser] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [form, setForm] = useState({
    displayErrorMessage: false,
    controls: {
      password: {
        elementType: 'input',
        label: 'Password',
        value: '',
        valid: true,
        validation: {
          minLength: 15,
          isStrongPassword: true,
        },
        elementConfig: {
          placeholder: 'Enter password',
          type: 'password',
          label: 'Password',
        },
      },
      confirmPassword: {
        elementType: 'input',
        label: 'Confirm Password',
        value: '',
        valid: true,
        validation: { isEqual: 'password' },
        elementConfig: {
          placeholder: 'Confirm password',
          type: 'password',
        },
      },
    },
  });
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const closeToast = () => {
    setToast((state) => ({ ...state, show: false }));
  };
  useEffect(() => {
    (async () => {
      const passwordResetToken = queryParams.get('passwordResetToken');
      const { accessible, user: user_ } = await checkIfPageIsAccessible(
        passwordResetToken,
      );
      if (!accessible) {
        navigate('/');
      }
      setUser(user_);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetPasswordSuccess) {
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          navigate('/auth');
        }
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [resetPasswordSuccess]);
  const displayErrorMessage = () => {
    const error = [];
    Object.keys(form.controls).map((key) => {
      const customError = 'Password does not match';
      const regex = /must be equal/gi;
      const matches =
        form.controls[key]?.error && form.controls[key].error.match(regex);

      if (form.controls[key]?.error) {
        error.push(matches ? customError : form.controls[key].error);
      }
    });
    return (
      <div className="authFormErrorText">
        {error.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}
      </div>
    );
  };
  return (
    <div className={style.contentBox}>
      <div className="mb-2">
        <h3 style={{ color: '#ff6f00' }}>Reset Password</h3>
      </div>
      {resetPasswordSuccess ? (
        <>
          <div className="mb-4 text-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'rgb(61,207,79)' }}
            />
            <b className="ms-2">Password reset successful</b>
          </div>

          <div className="mb-4 text-center">
            <small>
              Your password for the account {user && user.email} has been reset
              successfully. Please return back to the login page and try signing
              in.
            </small>
          </div>
          <Button
            additionalClasses="mt-2 text-capitalize w-75"
            dataTestAttribute="login-button"
            clicked={() => navigate('/')}
          >
            Return
          </Button>
        </>
      ) : (
        <>
          <div className="mb-4 text-center">
            Reset password for your account <b>{user?.email || ''}</b>
          </div>
          <div className="mb-4 text-center" style={{ color: '#b6b5b5' }}>
            <small>
              Updated Password must be at least 15 characters long and include
              combination of uppercase, lowercase, numbers and special
              characters
            </small>
          </div>
          <div>
            <Container fluid>
              <form
                className="authForm"
                onSubmit={async (e) => {
                  e.preventDefault();

                  //  1.frontend validation
                  if (
                    form.controls.password.value.length === 0 ||
                    form.controls.confirmPassword.value.length === 0
                  ) {
                    setForm((prevState) => ({
                      ...prevState,
                      displayErrorMessage: true,
                      controls: {
                        ...prevState.controls,
                        password: {
                          ...prevState.controls.password,
                          valid: !(form.controls.password.value.length === 0),
                          error: 'These fields are required',
                        },
                        confirmPassword: {
                          ...prevState.controls.confirmPassword,
                          valid: !(
                            form.controls.confirmPassword.value.length === 0
                          ),
                        },
                      },
                    }));
                    return;
                  }

                  const frontendValidationErrors = [];
                  Object.keys(form.controls).map((key) => {
                    if (form.controls[key]?.error) {
                      frontendValidationErrors.push(form.controls[key].error);
                    }
                  });
                  if (frontendValidationErrors.length > 0) {
                    setForm((prevState) => ({
                      ...prevState,
                      displayErrorMessage: true,
                    }));
                    return;
                  }

                  //  2.backend validation
                  UserService.resetPassword(
                    form.controls.password.value,
                    queryParams.get('passwordResetToken'),
                    user.id,
                  )
                    .then(() => {
                      setToast({
                        show: true,
                        success: true,
                        body: 'Reset Password Successfully',
                      });
                      setResetPasswordSuccess(true);
                    })
                    .catch((err) => {
                      console.err(err.response.data.message);
                    });
                }}
              >
                {Object.keys(form.controls).map((key) => {
                  return (
                    <Input
                      key={key}
                      additionalClasses="formInput"
                      elementType={form.controls[key].elementType}
                      elementConfig={form.controls[key].elementConfig}
                      error
                      invalid={
                        !form.controls[key].valid && form.displayErrorMessage
                      }
                      touched
                      shouldValidate
                      required={form.controls[key].validation?.required}
                      changed={(e) => {
                        setForm((prevState) => {
                          const [updatedControls, newFormIsValid] =
                            inputChangedHandler(e.target.value, prevState, key);
                          return {
                            ...prevState,
                            controls: updatedControls,
                          };
                        });
                      }}
                    />
                  );
                })}
                {form.displayErrorMessage && displayErrorMessage()}
                <Button
                  additionalClasses="mt-2 text-capitalize"
                  dataTestAttribute="login-button"
                >
                  Reset Password
                </Button>
              </form>
            </Container>
            <ToasterBottom toast={toast} closeToast={closeToast} />
          </div>
        </>
      )}
    </div>
  );
};
export default ResetPasswordPage;
