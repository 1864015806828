import React, { useRef, useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import OverlaySpinner from '../../../UI/OverlaySpinner/OverlaySpinner';
import FormInput from '../../../UI/FormInput/FormInput';
import Button from '../../../UI/Button/Button';

import ProviderService from '../../../../api/Providers';

const getTranslationForm = (lang, t, d, showRedemptionInput) => {
  const translation = t?.[lang];
  return {
    name: translation?.name ?? d.name.value,
    productName: translation?.productName ?? d.productName.value,
    ...(showRedemptionInput && {
      redemptionDetails:
        translation?.redemptionDetails ?? d.redemptionDetails.value,
    }),
  };
};

const ProviderTranslationsForm = ({
  providerId,
  defaults,
  currentLanguage,
  translations,
  setTranslations,
  showRedemptionDetailInput,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const languageRef = useRef(currentLanguage);

  const [translationForm, setTranslationForm] = useState(
    getTranslationForm(
      currentLanguage,
      translations,
      defaults,
      showRedemptionDetailInput,
    ),
  );

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const updatedTranslations = {
      ...translations,
      [currentLanguage]: translationForm,
    };

    ProviderService.updateTranslations(providerId, updatedTranslations)
      .then(() => {
        setTranslations(updatedTranslations);
      })
      .catch((err) => {
        setError(
          err.response?.message ?? 'Saving translations failed, try again?',
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onInputChangeHandler = ({ target: { value } }, field) => {
    setTranslationForm((state) => ({ ...state, [field]: value }));
  };

  useEffect(() => {
    if (languageRef.value !== currentLanguage) {
      setTranslationForm(
        getTranslationForm(
          currentLanguage,
          translations,
          defaults,
          showRedemptionDetailInput,
        ),
      );
    }
  }, [currentLanguage, defaults, translations, showRedemptionDetailInput]);

  return (
    <Row>
      <Col>
        {isLoading && <OverlaySpinner />}

        <form
          onSubmit={(event) => submitForm(event)}
          data-test-id="provider-form"
        >
          <Row className="mb-4">
            <Col>
              <FormInput
                control={defaults.name}
                inputKey="name"
                value={translationForm.name}
                inputChangedHandler={onInputChangeHandler}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <FormInput
                control={defaults.productName}
                inputKey="productName"
                value={translationForm.productName}
                inputChangedHandler={onInputChangeHandler}
                dataTestAttribute="client-brand-input"
              />
            </Col>
          </Row>
          {showRedemptionDetailInput && (
            <Row className="mb-4">
              <Col>
                <FormInput
                  control={defaults.redemptionDetails}
                  inputKey="redemptionDetails"
                  value={translationForm.redemptionDetails}
                  inputChangedHandler={onInputChangeHandler}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-auto">
              <Button
                type="submit"
                additionalClasses="mr-3"
                dataTestAttribute="save-provider"
              >
                Save Translation
              </Button>
              {error ? <p className="text-danger mt-3">{error}</p> : null}
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default ProviderTranslationsForm;
