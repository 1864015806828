import createInput from '../../../../shared/Form/createInput';

export default {
  formInput: {
    label: createInput(
      'Question',
      {
        placeholder: "e.g. What's your favorite color?",
      },
      {
        required: true,
      },
      '',
      false,
      'text',
    ),
    name: createInput(
      'Short identifier (only letters)',
      {
        description:
          'The same value in different language versions' +
          ' will be treated as a single question',
        placeholder: 'e.g. color',
      },
      {
        required: true,
        unique: [],
        regex: {
          test: /^\d/,
          message: 'must not start with a number',
        },
      },
      '',
      false,
      'text',
    ),
    placeholder: createInput(
      'Response placeholder',
      {
        description:
          'A text that will be visible before any option is selected',
        placeholder: 'e.g. (select color)',
      },
      {
        required: false,
      },
      '',
      true,
      'text',
    ),
    answers: createInput(
      'Answers',
      {
        description: 'Every answer in a new line',
        placeholder: 'e.g. Red\nYellow\nBlue\nPink',
        rows: 5,
      },
      {
        required: true,
        maxLength: 1000,
        maxLengthPerLine: true,
      },
      '',
      false,
      'textarea',
    ),
    isMulti: createInput(
      'Allow multiple answers to be selected',
      {},
      {
        required: false,
      },
      '',
      true,
      'checkbox',
    ),
    required: createInput(
      'Required',
      {},
      {
        required: false,
      },
      '',
      true,
      'checkbox',
    ),
  },
  textField: {
    label: createInput(
      'Question',
      {
        placeholder: "e.g. What's your favorite color?",
      },
      {
        required: true,
      },
      '',
      false,
      'text',
    ),
    name: createInput(
      'Short identifier (only letters)',
      {
        description:
          'The same value in different language versions' +
          ' will be treated as a single question',
        placeholder: 'e.g. color',
      },
      {
        required: true,
        unique: [],
      },
      '',
      false,
      'text',
    ),
    required: createInput(
      'Required',
      {
        placeholder: 'Required',
      },
      {
        required: false,
      },
      '',
      true,
      'checkbox',
    ),
  },
  term: createInput(
    'Form term',
    {
      placeholder: 'Form term',
    },
    {
      required: false,
    },
    '',
    true,
    'text',
  ),
  currentLanguage: '',
  formIsValid: false,
};
