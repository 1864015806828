const baseColors = [
  'rgba(255, 111, 0, .8)',
  'rgba(54, 162, 235, .8)',
  'rgba(255, 206, 86, .8)',
  'rgba(75, 192, 192, .8)',
  'rgba(153, 102, 255, .8)',
  'rgba(255, 159, 64, .8)',
];

/**
 * repeating the same colors 10 times to support more values
 */
export default Array.from({ length: 10 }, () => baseColors).flat();
