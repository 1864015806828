import { string, object } from 'yup';

export const getSchema = () =>
  object().shape({
    name: string().required('Please enter a name'),
    email: string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
    company: string().required('Please enter a company'),
    country: object().shape({
      value: string().required('Please select a country'),
      label: string(),
    }),
    phoneNumber: string()
      .min(8, 'Please enter a valid phone number')
      .max(15, 'Please enter a valid phone number')
      .matches(
        /^(\+\d{1,3}[- ]?)?\d{8,12}$/,
        'Please enter a valid phone number',
      )
      .required('Please enter a phone number'),
  });

const displayFields = {
  name: {
    key: 'name',
    label: 'Name',
    type: 'text',
    defaultValue: '',
    elementConfig: {
      placeholder: 'Enter Name*',
    },
  },
  email: {
    key: 'email',
    label: 'Email',
    type: 'text',
    defaultValue: '',

    elementConfig: {
      placeholder: 'Enter Email*',
    },
  },
  company: {
    key: 'company',
    label: 'Company',
    type: 'text',
    defaultValue: '',
    elementConfig: {
      placeholder: 'Enter Company*',
    },
  },
  country: {
    key: 'country',
    label: 'Select Country',
    type: 'autocomplete',
    defaultValue: '',
    elementConfig: {
      placeholder: 'Select Country*',
    },
  },
  phoneNumber: {
    key: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    defaultValue: '',
    elementConfig: {
      placeholder: 'Enter Phone Number*',
    },
  },
};

export default displayFields;
