import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BrandEditModal from '../BrandEditModal/BrandEditModal';
import DataTable from '../../UI/DataTable/DataTable';
import BrandDeleteModal, {
  useModalConfirm as useBrandDeleteModal,
} from '../../Modal/ModalConfirm/ModalConfirm';
import BrandService from '../../../api/Brands';
import Button from '../../UI/Button/Button';

const gridStyle = { height: 'calc(100vh - 200px)' };

const BrandTable = ({ dataSource, setRefetchTick }) => {
  const columns = [
    { header: 'Brand Name', name: 'name', defaultFlex: 1 },
    {
      header: 'Company name',
      name: 'company',

      defaultFlex: 1,
      render: ({ value, data }) => (
        <Link
          className="link-bm-orange"
          to={`/companies/${data.companyId}`}
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      ),
    },
    {
      header: '# Agencies',
      name: 'userCount',
      type: 'number',
      defaultFlex: 1,
    },
    {
      header: '# Campaigns',
      name: 'campaignCount',
      type: 'number',
      defaultFlex: 1,
      render: ({ value, data }) => (
        <Link
          className="link-bm-orange"
          to={`/campaigns?brand=${data.name}`}
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      ),
    },
    {
      header: 'Actions',
      sortable: false,
      name: 'id',
      defaultFlex: 1,
      render: ({ value, data }) => (
        <div className="btnContainer">
          <Button
            additionalClasses="btnDefault--small"
            clicked={(e) =>
              openModal(e, 'edit', value, data.name, data.brandLogo)
            }
          >
            Edit
          </Button>

          <Button
            additionalClasses="btnDefault--small btnDefault--red"
            clicked={(e) =>
              openModal(e, 'delete', value, data.name, data.brandLogo)
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const [brandDeleteProps, setBrandDeleteProps, resetDeleteProps] =
    useBrandDeleteModal({
      title: 'Delete confirmation',
      apiRequest: BrandService.remove,
      supplementalErrorKey: 'campaigns',
      onSuccess: () => reset('delete'),
    });

  const [brandEdit, setBrandEdit] = useState({
    id: undefined,
    name: undefined,
    brandLogo: undefined,
  });

  const openModal = (e, type, id, name, brandLogo) => {
    e.stopPropagation();
    switch (type) {
      case 'delete':
        setBrandDeleteProps({
          apiId: id,
          body: `Are you sure you want to delete '${name}'? Any advertising agencies associated will no longer have access to this brand or it's respective campaigns.`,
          toastSuccessMsg: name + ' has successfully been removed',
        });
        break;
      case 'edit':
        setBrandEdit({ id, name, brandLogo });
        break;
      default:
    }
  };

  const onRowClick = ({ data }) => {
    setBrandEdit({ id: data.id, name: data.name, brandLogo: data.brandLogo });
  };

  const reset = (type) => {
    switch (type) {
      case 'delete':
        resetDeleteProps();
        break;
      case 'edit':
        setBrandEdit({ id: undefined, name: undefined, brandLogo: undefined });
        break;
      default:
    }
    setRefetchTick((tick) => tick + 1);
  };
  return (
    <>
      <div>
        <DataTable
          id="brand"
          columns={columns}
          dataSource={dataSource}
          style={gridStyle}
          columnOrderPersist
          onRowClick={onRowClick}
          pageSizes={[50, 100]}
        />
      </div>
      <BrandEditModal
        id={brandEdit.id}
        brandName={brandEdit.name}
        brandLogo={brandEdit.brandLogo}
        show={brandEdit.id !== undefined}
        close={() => reset('edit')}
      />
      <BrandDeleteModal {...brandDeleteProps} />
    </>
  );
};

export default BrandTable;
