import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';
import Modal from '../../UI/Modal/Modal';

const DomainForm = ({ onSubmitForm }) => {
  const [domains, setDomains] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    setErrors(null);
    setIsLoading(true);

    const domainArray = domains
      .split('\n')
      .map((e) => e.replace(/\s/g, ''))
      .filter((e) => e);

    const result = await onSubmitForm(domainArray);
    setIsLoading(false);

    if (result?.errors) {
      const errorKeys = Object.keys(result.errors);
      const errorMessages = errorKeys.reduce((str, key) => {
        return str + ' ' + result.errors[key];
      }, '');
      return setErrors(errorMessages);
    }

    if (result) {
      result.action();
    }
  };
  return (
    <Row>
      <Col>
        {isLoading && <OverlaySpinner />}
        <form onSubmit={(event) => submitForm(event)}>
          <div>
            <Input
              elementType="textarea"
              label="Domain(s)"
              additionalClasses="formInput formInput--bordered formInput--smaller formInput-textarea--long"
              required
              value={domains}
              changed={(e) => {
                setDomains(e.target.value);
              }}
              elementConfig={{
                placeholder:
                  'whitelisted-domain-1.com\nwhitelisted-domain-2.com\nwhitelisted-domain-3.com',
              }}
            />
          </div>
          {errors ? <p className="text-danger mt-2">{errors}</p> : null}
          <Button
            type="submit"
            additionalClasses="mt-3 me-3"
            dataTestAttribute="save-domain"
          >
            Save
          </Button>
        </form>
      </Col>
    </Row>
  );
};

const AddDomainModal = ({ show, hideModal, action }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal
      dialogClassName="add-domain-modal"
      title="Add Domain(s)"
      body={
        <DomainForm
          onSubmitForm={async (data) => action(data)}
          onCancelForm={hideModal}
        />
      }
      handleClose={hideModal}
      size="md"
      show
      dataTestAttribute={{
        modal: 'add-domain-modal',
        action2: 'add-domain-modal-close',
      }}
    />
  );
};

export default AddDomainModal;
