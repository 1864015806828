import React from 'react';
import { useParams } from 'react-router';

import CampaignDetailAddEdit from '../../../components/CampaignDetail/CampaignDetail';

const CampaignDetailPage = () => {
  const { id: campaignId } = useParams();
  return <CampaignDetailAddEdit campaignId={campaignId} />;
};

export default CampaignDetailPage;
