import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import * as SettingsForms from '../../Settings/SettingsForms';

import { CampaignCreatorContext } from '../../Creator.context';

const AddSectionButton = ({ addSectionHandler }) => {
  const allowSingleInstanceModuleKeys = ['postQuestions', 'vidio'];
  const { assignedModuleKeys } = useContext(CampaignCreatorContext);
  const disableAddModules = allowSingleInstanceModuleKeys.filter((key) =>
    assignedModuleKeys.includes(key),
  );

  const moduleTypes = Object.keys(SettingsForms).filter(
    (el) => el !== 'globalSettings',
  );

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="btnDefault btnDefault--with-icon btnDefault--with-icon-add"
        id="add-module"
        data-test-id="add-section-button"
      >
        ADD MODULE
        <FontAwesomeIcon
          icon={faPlusCircle}
          title="Add module"
          className=" btnDefault--with-icon__icon"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {moduleTypes.map((moduleName) => (
          <Dropdown.Item
            key={moduleName}
            disabled={disableAddModules.includes(moduleName)}
            onClick={() => addSectionHandler(moduleName)}
            data-test-id={`add-section-${moduleName}`}
          >
            {SettingsForms[moduleName].name.toUpperCase()}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddSectionButton;
