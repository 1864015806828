import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Input from '../../../UI/Input/Input';
import './CoordsInputs.scss';

const CoordsInputs = ({
  editedInput,
  input,
  isFocused,
  onLocalInputChange,
  onDeleteInputHandler,
}) => {
  const targetUrlInputRef = useRef(null);
  const inputArr = input && Object.keys(input);

  useEffect(() => {
    if (isFocused) {
      targetUrlInputRef.current.focus();
      targetUrlInputRef.current.select();
    }
  }, [isFocused]);

  if (!inputArr || inputArr.length === 0) return null;

  const renderInput = inputArr.map((el) => {
    const inputEl = input[el];
    const inputField = inputEl;

    const isTargetUrlFocused = el === 'targetUrl' && isFocused;
    const focusedInputClass = isTargetUrlFocused ? 'formInput--focused' : '';

    const inputClasses = 'formInput formInput--bordered';
    const customClass =
      el === 'targetUrl' ? 'coordInput coordInput--url' : 'coordInput';

    const field = (
      <div key={`${editedInput}_${el}`} className={customClass}>
        <Input
          {...(el === 'targetUrl' && { ref: targetUrlInputRef })}
          additionalClasses={`${inputClasses} ${focusedInputClass}`}
          keyName={`${editedInput}_${el}`}
          elementType={inputField.elementType}
          elementConfig={inputField.elementConfig}
          label={inputField.label}
          value={inputField.value}
          invalid={!inputField.valid}
          shouldValidate={inputField.validation}
          touched={inputField.touched}
          changed={(event) => onLocalInputChange(event, editedInput, el)}
          error={inputField.error}
          required={inputField?.validation?.required}
        />
      </div>
    );

    return field;
  });

  return (
    <div className="image-linker-form--inputs">
      <div key={`${editedInput}`} className="imgCoordsGroup">
        {renderInput}
        <button
          type="button"
          className="btnRemove"
          onClick={(event) => onDeleteInputHandler(event, editedInput)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default CoordsInputs;
