import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import CompanyAccordion from './CompanyAccordion/CompanyAccordion';
import CompanySectionGeneralProfile from './CompanySections/CompanySectionGeneral';
import CompanyBrandAndDemography from './CompanyBrandAndDemography/CompanyBrandAndDemography';
import CompanySectionIntegrations from './CompanySections/CompanySectionIntegrations';
import CompanySectionPostConnectForms from './CompanySections/CompanySectionPostConnectForms';
import CompanyHeader from './CompanyHeader/CompanyHeader';
import CompanyService from '../../api/Companies';
import * as storeActions from '../../store/actions/index';
import OverlaySpinner from '../UI/OverlaySpinner/OverlaySpinner';
import CompanyModal from './CompanyModal/CompanyModal';
import Toaster from '../UI/Toaster/Toaster';

import { useCompanyModal } from './CompanyDetailsPage.hooks';

import styles from './CompanyDetail.module.scss';

const getSelectedIds = (e) => (typeof e === 'object' ? e.value : e);
const CompanyDetail = ({ companyId, scrollTop }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [company, _setCompany] = useState(null);
  const [accordionActiveKey, setAccordionActiveKey] = useState('0');
  const [postQuestions, setPostQuestions] = useState([]);
  const [saveTick, setSaveTick] = useState(0);
  const [refreshTick, setRefresh] = useState(0);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [
    { companyModalProps, webhookList, accordionsFormSaveStatus },
    { addWebhook, editWebhook, setCompanyId, setAccordionsFormSaveStatus },
  ] = useCompanyModal(setRefresh, saveTick, _setCompany, setToast, setSaveTick);

  const loading = useMemo(() => {
    return !company;
  }, [company]);

  useEffect(() => {
    CompanyService.get(companyId)
      .then((response) => {
        if (response.data) {
          _setCompany({
            ...response.data,
            providers: response.data.providers.map(getSelectedIds),
          });
          setCompanyId(response.data?.id);
          setPostQuestions(response.data?.postQuestions ?? []);
        }
      })
      .catch((error) => {
        const { response } = error;
        const errData = response.data;
        dispatch(
          storeActions.setErrorAccessingResource(
            true,
            'error',
            errData.message || 'Error accessing company',
          ),
        );
        navigate('/');
      });
  }, [companyId, dispatch, navigate, refreshTick]);

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const headerClass =
    Math.ceil(scrollTop) > 35
      ? `${styles.companyActionBar} py-3 shadow-sm`
      : '';
  return (
    <>
      <div className={`${styles.companyDetailSmallScreenAlert}`}>
        <p>
          <small>
            This page is built for <strong>desktop use only</strong>
          </small>
        </p>
      </div>

      <div className={`${styles.companyDetail} py-5`}>
        {loading && <OverlaySpinner />}
        <div className={headerClass}>
          <CompanyHeader
            title={company?.name}
            loading={loading}
            setSaveTick={setSaveTick}
          />
        </div>

        <CompanyAccordion
          onSelect={(key) => setAccordionActiveKey(key)}
          activeKey={accordionActiveKey}
          loading={loading}
        >
          <CompanySectionGeneralProfile
            step={0}
            company={company}
            setCompany={_setCompany}
            companyId={companyId}
            saveTick={saveTick}
            setAccordionActiveKey={setAccordionActiveKey}
            setAccordionsFormSaveStatus={setAccordionsFormSaveStatus}
          />
          <CompanyBrandAndDemography
            saveTick={saveTick}
            step={1}
            companyId={companyId}
            company={company}
            setAccordionActiveKey={setAccordionActiveKey}
            accordionsFormSaveStatus={accordionsFormSaveStatus}
            setAccordionsFormSaveStatus={setAccordionsFormSaveStatus}
          />
          <CompanySectionIntegrations
            step={2}
            webhookList={webhookList}
            addWebhook={addWebhook}
            editWebhook={editWebhook}
          />
          <CompanySectionPostConnectForms
            step={3}
            postQuestions={postQuestions}
            companyId={companyId}
            setRefresh={setRefresh}
            setAccordionActiveKey={setAccordionActiveKey}
            refreshTick={refreshTick}
            saveTick={saveTick}
            setAccordionsFormSaveStatus={setAccordionsFormSaveStatus}
          />
        </CompanyAccordion>
      </div>
      <CompanyModal
        companyName={company?.name}
        companyId={company?.id}
        {...companyModalProps}
      />
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default CompanyDetail;
