import React from 'react';

import CompanyForm from '../CompanyForm/CompanyForm';
import Modal from '../../UI/Modal/Modal';
import './CompanyAddModal.scss';

const CompanyAddModal = ({ show, hideModal, action }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal
      dialogClassName="add-company-modal"
      title="Add Company"
      body={
        <CompanyForm
          isAddCompanyModal
          onSubmitForm={async (data) => action(data)}
          onCancelForm={hideModal}
        />
      }
      handleClose={hideModal}
      size="md"
      show
      dataTestAttribute={{
        modal: 'add-company-modal',
        action2: 'add-company-modal-close',
      }}
    />
  );
};

export default CompanyAddModal;
