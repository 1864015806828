import React from 'react';

import bountyMediaBannerLogo from '../../../assets/images/bounty-login-banner.svg';

import styles from './BountyBannerLogo.module.scss';

const BountyBannerLogo = () => {
  return (
    <section className={styles.imgBox}>
      <img
        className={styles.logo}
        src={bountyMediaBannerLogo}
        alt="Bounty Media banner logo"
      />
    </section>
  );
};

export default BountyBannerLogo;
