import React from 'react';
import {
  campaignStatus,
  translateCampaignStatus,
} from '../../../../shared/campaignStatus';
import './campaignStatus.scss';

const CampaignStatus = ({ statusCode }) => {
  const translatedStatusCode = translateCampaignStatus(statusCode);
  let classN;
  switch (statusCode) {
    case campaignStatus.STATE_INACTIVE:
    case campaignStatus.STATE_FALLBACK_INACTIVE:
      classN = 'campaignStatus campaignStatus--inactive';
      break;
    case campaignStatus.STATE_ACTIVE:
    case campaignStatus.STATE_ACTIVE_PAYWALL:
    case campaignStatus.STATE_ACTIVE_PAYWALL_NO_PROVIDER:
      classN = 'campaignStatus campaignStatus--active';
      break;
    case campaignStatus.STATE_FALLBACK:
    case campaignStatus.STATE_FALLBACK_GLOBAL:
      classN = 'campaignStatus campaignStatus--fallback';
      break;
    case campaignStatus.STATE_DRAFT:
    case campaignStatus.STATE_SCHEDULED:
      classN = 'campaignStatus campaignStatus--draft';
      break;
    default:
      classN = 'campaignStatus campaignStatus--default';
  }
  return (
    <span
      className={classN}
      data-test-id={`campaign-edit-page-row-${translatedStatusCode}`}
    >
      {translatedStatusCode}
    </span>
  );
};

export default CampaignStatus;
