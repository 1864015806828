import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
} from 'firebase/auth';

import UserService from '../../api/Users';

import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import inputChangedHandler from '../../shared/inputChangeHandler';
import controls from './controls';
import { authFail, resetPassword } from '../../store/actions/auth';
import AuthMfaSignIn from './AuthMfaSignIn';

import './Auth.scss';
import { AuthErrorMessage } from './AuthErrorMessage';

const setSessionEmail = (value) => {
  window.sessionStorage.setItem('auth-email', value);
};

const sessionEmail = window.sessionStorage['auth-email'] ?? '';

const Auth = ({ mfaAuth, setMfaAuth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const firebaseAuth = getAuth();
  const error = useSelector((state) => state.auth.error);
  //   const [formIsValid, setFormIsValid] = useState(false);
  const [isEmailOtpEnabled, setIsEmailOtpEnabled] = useState(false);
  const [authForm, setAuthForm] = useState({
    controls: {
      email: {
        ...controls.email,
        value: sessionEmail,
        touched: true,
        valid: !!sessionEmail,
      },
      password: {
        ...controls.password,
        valid: true,
        touched: true,
      },
    },
  });

  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState(2);

  const validateLoginData = () => {
    if (
      authForm.controls.email.value === '' ||
      authForm.controls.password.value === ''
    )
      return false;

    return true;
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    dispatch(authFail(null));
    if (!validateLoginData()) {
      dispatch(authFail('Email or password cannot be empty.'));
      return;
    }
    setSessionEmail(authForm.controls.email.value);

    try {
      const res = await UserService.checkEmailOtp(
        authForm.controls.email.value,
      );
      if (res) setIsEmailOtpEnabled(res.data.isEmailOtpEnabled);
    } catch (e) {
      if (e?.response?.status === '404') {
        dispatch(authFail('Invalid email or password, please try again.'));
        setLoginAttemptsLeft((count) => count - 1);
      }
    }

    signInWithEmailAndPassword(
      firebaseAuth,
      authForm.controls.email.value,
      authForm.controls.password.value,
    ).catch((_error) => {
      /**
       * Show MFA Auth Form
       * https://cloud.google.com/identity-platform/docs/web/mfa
       */
      switch (_error.code) {
        case 'auth/multi-factor-auth-required':
          return setMfaAuth({
            enabled: true,
            firebaseResolver: getMultiFactorResolver(firebaseAuth, _error),
          });
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          dispatch(authFail('Invalid email or password, please try again.'));

          setLoginAttemptsLeft((count) => count - 1);
          break;
        case 'auth/user-disabled':
          dispatch(
            authFail(
              'Account is not active, please contact your administrator.',
            ),
          );

          break;
        case 'auth/too-many-requests':
          dispatch(
            authFail('Maximum number of attempts reached. Try again later.'),
          );

          setLoginAttemptsLeft(0);
          break;
        default:
          dispatch(authFail(_error.message));
      }

      return null;
    });
  };

  const onInputChangeHandler = (event, inputIdentifier) => {
    const { value } = event.target;
    setAuthForm((prevState) => {
      const [updatedControls] = inputChangedHandler(
        value,
        prevState,
        inputIdentifier,
      );
      //   setFormIsValid(newFormIsValid);
      return { controls: updatedControls };
    });
  };

  const getFormElements = () => {
    const formElementsArray = [];
    Object.keys(authForm.controls).forEach((key) => {
      formElementsArray.push({
        id: key,
        config: authForm.controls[key],
      });
    });
    return formElementsArray;
  };

  const form = (
    <form className="authForm" onSubmit={loginHandler}>
      {getFormElements().map((formElement) => (
        <Input
          additionalClasses="formInput"
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={formElement.config.value === '' && error ? 1 : 0}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => onInputChangeHandler(event, formElement.id)}
          required={formElement?.config?.validation?.required}
        />
      ))}

      {error && <AuthErrorMessage className="mb-2" error={error} />}
      {loginAttemptsLeft < 2 && loginAttemptsLeft > 0 && (
        <AuthErrorMessage
          className="authFormAttemptText my-2"
          error={`${loginAttemptsLeft} attempts remaining`}
        />
      )}
      <div className="formText d-flex justify-content-end">
        <button
          style={{ border: 'none', background: 'none' }}
          type="button"
          className="forgotPassword"
          onClick={(e) => {
            e.preventDefault();
            dispatch(resetPassword(true));
            navigate('/');
          }}
        >
          Forgot your Password?
        </button>
      </div>
      <Button
        additionalClasses="btnDefault text-capitalize"
        dataTestAttribute="login-button"
      >
        Sign in
      </Button>
      <div
        className="formText d-flex justify-content-start mt-3"
        onClick={() => navigate('/contact-us')}
      >
        <p className="contactUs">
          Unable to Login?
          <span> Contact Us</span>
        </p>
      </div>
    </form>
  );

  return (
    <div>
      {mfaAuth.enabled ? (
        <AuthMfaSignIn
          firebaseResolver={mfaAuth.firebaseResolver}
          isEmailOtpEnabled={isEmailOtpEnabled}
          email={authForm.controls.email.value}
        />
      ) : (
        form
      )}
    </div>
  );
};

export default Auth;
