import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';

import Section from './Section/Section';
import AddSectionButton from './Section/AddSectionButton/AddSectionButton';
import GlobalSettingsButton from './Settings/GlobalSettingsButton/GlobalSettingsButton';

import SettingsBox from './Settings/SettingsBox/SettingsBox';
import Settings from './Settings/Settings';
import * as SettingsForms from './Settings/SettingsForms';
import ImageLinker from './ImageLinker/ImageLinker';

import { CampaignCreatorContext } from './Creator.context';

import {
  getCreatorSettingsForType,
  parseToInt,
} from '../../shared/creatorSettings';
import inputChangedHandler from '../../shared/inputChangeHandler';
import Modal from '../UI/Modal/Modal';
import { convertFormInputs } from '../../shared/Form/vidioInputs';

import { translationTypes } from './Creator.constants';

const getItemStyle = (isDragging, draggableStyle, globalStyle) => ({
  ...draggableStyle,
  opacity: isDragging ? 0.5 : 1,
  color: (globalStyle && globalStyle.mainColor) || '',
  backgroundColor: (globalStyle && globalStyle.backgroundColor) || '',
});

const sectionCustomStyle = (globalStyle) => ({
  paddingTop: parseToInt(globalStyle && globalStyle.paddingTop) || '',
  paddingBottom: parseToInt(globalStyle && globalStyle.paddingBottom) || '',
});

const GLOBAL_SETTINGS = 'globalSettings';

const Creator = (props) => {
  const {
    campaignData,
    landingPageSections,
    saveSections: save,
    serviceType,
    globalSettings,
    globalBrandLogoUrl,
    videoProvider,
    campaignId,
    verificationMethod,
    isPaywall,
  } = props;
  const [landingPageSettings, setLandingPageSettings] = useState({
    settings: {},
  });
  const [scroll, setScroll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [typeAdding, setTypeAdding] = useState(null);
  const [removingIndex, setRemovingIndex] = useState(-1);

  const [currentLanguage, setCurrentLanguage] = useState(
    globalSettings?.config?.defaultLanguage ??
      SettingsForms.globalSettings.config.defaultLanguage,
  );

  // !! dataChanged causes a whole lot of re-renders
  const [dataChanged, setDataChanged] = useState(false);

  const [showImageLinker, setShowImageLinker] = useState(false);
  const [imgUrl, setImgUrl] = useState('');

  const getLanguages = (settings) =>
    settings?.config?.languages ||
    SettingsForms.globalSettings.config.languages;

  const saveSections = (list, gSettings = null) => {
    if (isPaywall) {
      // Keep "Connect Form" to first position
      const connectModuleIdx = list.findIndex((e) => e.type === 'vidio');
      const hasConnectModule = connectModuleIdx !== -1;
      if (hasConnectModule) {
        const [connectModule] = list.splice(connectModuleIdx, 1);
        list.unshift(connectModule);
      }
    }
    save(list, gSettings);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const list = [...landingPageSections];
    const [removed] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, removed);
    saveSections(list);
  };

  const resetOpenedSettings = (idx, lang) => {
    if (landingPageSections?.length > 0 && landingPageSections[idx]) {
      const settings = getCreatorSettingsForType(
        SettingsForms,
        globalSettings,
        landingPageSections,
        landingPageSections[idx].type,
        idx,
        fileChange,
        lang,
        globalBrandLogoUrl,
      );
      setLandingPageSettings({ settings });
      setOpenSettings(true);
    }
    setCurrentLanguage(lang);
    setDataChanged(false);
  };

  const openSettingsHandler = (index) => {
    const settings = getCreatorSettingsForType(
      SettingsForms,
      globalSettings,
      landingPageSections,
      landingPageSections[index].type,
      index,
      fileChange,
      currentLanguage,
      globalBrandLogoUrl,
    );
    setLandingPageSettings({ settings });
    setOpenSettings(true);
    setTypeAdding(landingPageSections[index].type);
    setSectionIndex(index);
  };

  const saveSettingsHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    const sections = [...landingPageSections];

    let globSetts = null;
    let section;
    let config;
    let translations;
    if (sections[sectionIndex]) {
      section = { ...sections[sectionIndex] };
      config = { ...section.config };
      translations = { ...section?.translations };
    } else {
      section = {
        id: Math.floor(Math.random() * 1000000).toString(),
        type: typeAdding,
        config: {},
      };
      config = {};
      translations = {};
      setScroll(true);
    }

    let coords = null;
    config.layout = config.layout || {};

    Object.keys(landingPageSettings.settings.controls).forEach((key) => {
      if (key === 'languages') {
        config.defaultLanguage =
          landingPageSettings.settings.controls[
            key
          ].elementConfig.defaultLanguage;
      }
      if (!translationTypes.includes(typeAdding)) {
        config[key] = landingPageSettings.settings.controls[key].value;
        if (landingPageSettings.settings?.coords) {
          coords = landingPageSettings.settings.coords;
        }
      } else {
        // Translation source
        const inputsSettings = landingPageSettings.settings.controls[key];
        config[key] = convertFormInputs(key, inputsSettings);

        // Dont put in translation object
        if (inputsSettings?.skipTranslation) {
          section.config[key] = config[key];
          return;
        }

        if (!translations?.[currentLanguage]) {
          translations[currentLanguage] = { [key]: {}, layout: {} };
        }

        // Translation update
        if (!translations?.[currentLanguage].layout) {
          translations[currentLanguage] = {
            ...translations[currentLanguage],
            layout: {},
          };
        }

        translations[currentLanguage][key] = config[key];

        if (key === 'inputFontSize' || key === 'formWidth') {
          config.layout[key] = parseInt(inputsSettings.value, 10);
          translations[currentLanguage].layout[key] = parseInt(
            inputsSettings.value,
            10,
          );
          delete config[key];
          delete translations[currentLanguage][key];
        }

        if (key === 'imageBoxPosition' || key === 'imageAlign') {
          config.layout[key] = inputsSettings.value;
          translations[currentLanguage].layout[key] = inputsSettings.value;
          delete config[key];
          delete translations[currentLanguage][key];
        }
      }
    });

    /* Save translations */
    if (
      currentLanguage &&
      translations &&
      translationTypes.includes(typeAdding)
    ) {
      section.translations = translations;
    } else {
      section.config = config;
    }

    if (coords) section.coords = coords;

    if (typeAdding !== GLOBAL_SETTINGS) {
      sections[sectionIndex] = section;
    } else {
      globSetts = section;
      const defaultLanguage =
        globSetts?.config?.defaultLanguage ||
        SettingsForms.globalSettings.config.defaultLanguage;
      if (defaultLanguage) {
        setCurrentLanguage(defaultLanguage);
      }
    }

    /* When current language changes in Connection Form edit window,
        windows settings should stay open. */
    if (event) {
      setLandingPageSettings((prevState) => ({
        ...prevState,
        settings: null,
      }));
      setOpenSettings(false);
      setSectionIndex(null);
      setTypeAdding(null);
    }
    saveSections(sections, globSetts);

    setDataChanged(false);
  };

  const coordsSettingsChangedHandler = useCallback(
    (coords) => {
      if (landingPageSettings) {
        setLandingPageSettings((prevState) => ({
          settings: {
            ...prevState.settings,
            coords,
          },
        }));
      }
      setShowImageLinker(false);
    },
    [landingPageSettings],
  );

  const onImageLinkerClose = useCallback(() => setShowImageLinker(false), []);
  useEffect(() => {
    if (scroll && window) {
      window.scrollTo(0, document.body.scrollHeight);
      setScroll(false);
    }
  }, [scroll]);

  const addSectionHandler = (type) => {
    setLandingPageSettings((prevState) => ({
      settings: getCreatorSettingsForType(
        SettingsForms,
        globalSettings,
        landingPageSections,
        type,
        prevState,
        fileChange,
        currentLanguage,
        globalBrandLogoUrl,
      ),
    }));
    setOpenSettings(true);
    setSectionIndex(landingPageSections.length);
    setTypeAdding(type);
  };

  const removeSectionHandler = (index) => {
    setShowModal(true);
    setRemovingIndex(index);
  };

  const removeSectionConfirmedHandler = () => {
    const sections = [...landingPageSections];
    sections.splice(removingIndex, 1);
    saveSections(sections);
    setShowModal(false);
    setRemovingIndex(null);
    if (removingIndex === sectionIndex) {
      setOpenSettings(false);
      setSectionIndex(-1);
    }
    if (removingIndex < sectionIndex) {
      setSectionIndex(sectionIndex - 1);
    }
  };

  const removeSectionCanceledHandler = () => {
    setShowModal(false);
    setRemovingIndex(null);
  };

  const validateForm = (formState) => {
    let isValid = true;
    Object.values(formState.settings.controls).forEach((field) => {
      if (!field.valid) {
        isValid = false;
      }
    });
    return isValid;
  };

  const inputSettingsChangedHandler = (
    event,
    inputIdentifier,
    additional = null,
  ) => {
    const translations = {};
    if (
      inputIdentifier === 'terms' ||
      inputIdentifier === 'inputs' ||
      inputIdentifier === 'textFields'
    ) {
      const isNotEmpty = Object.keys(additional).length > 0;
      if (!isNotEmpty) return;

      if (currentLanguage) {
        translations[currentLanguage] = {
          [inputIdentifier]: {
            ...additional[inputIdentifier],
            valid: additional.formIsValid,
          },
        };
      }

      setLandingPageSettings((prevState) => {
        const newState = {
          settings: {
            controls: {
              ...prevState.settings.controls,
              ...{
                [inputIdentifier]: {
                  ...additional[inputIdentifier],
                  valid: additional.formIsValid,
                },
              },
            },
            translations: {
              ...prevState.settings?.translations,
              ...translations,
            },
            formIsValid: additional.formIsValid,
          },
        };
        newState.settings.formIsValid = validateForm(newState);
        return newState;
      });
      setDataChanged(true);
      return;
    }
    const pageSettingsControls = landingPageSettings.settings.controls;
    if (pageSettingsControls) {
      if (pageSettingsControls.inputs) {
        const inputsLength = Object.keys(pageSettingsControls.inputs).length;
        if (inputsLength === 0) {
          delete landingPageSettings.settings.controls.inputs;
        }
      }
      if (pageSettingsControls.terms) {
        const termsLength = Object.keys(pageSettingsControls.terms).length;
        if (termsLength === 0) {
          delete landingPageSettings.settings.controls.terms;
        }
      }
      if (pageSettingsControls.textFields) {
        const textFieldsLength = Object.keys(
          pageSettingsControls.textFields,
        ).length;
        if (textFieldsLength === 0) {
          delete landingPageSettings.settings.controls.textFields;
        }
      }
    }
    const { value } = event.target || event;
    setLandingPageSettings((prevState) => {
      const localState = prevState;

      // Hard set to inline format
      // if (inputIdentifier === 'inlineForm') {
      //   const required = !value;
      //   // "inlineForm" (embed without banner) dictates
      //   //  if banner "slides" are required
      localState.settings.controls.slides = {
        ...prevState.settings.controls.slides,
        validation: {
          required: false,
          minCount: value ? 0 : 1,
        },
        valid: true,
      };
      // }

      const [updatedControls, formIsValid] = inputChangedHandler(
        value,
        localState.settings,
        inputIdentifier,
      );

      const newSettings = {
        settings: {
          controls: updatedControls,
          formIsValid,
        },
      };

      if (localState.settings?.coords) {
        newSettings.settings.coords = prevState.settings.coords;
      }

      if (currentLanguage) {
        translations[currentLanguage] = updatedControls;
      }

      if (localState.settings?.translations) {
        newSettings.settings.translations = {
          ...prevState.settings?.translations,
          ...translations,
        };
      }

      if (inputIdentifier === 'languages') {
        newSettings.settings.controls[inputIdentifier].elementConfig = {
          value: event.target.value,
          defaultLanguage: event.defaultLanguage,
        };
      }
      return newSettings;
    });

    setDataChanged(true);
  };

  const fileChange = (logoOrAnimationJsonUrl, inputIdentifier) => {
    setLandingPageSettings((prevState) => {
      const [updatedControls, formIsValid] = inputChangedHandler(
        logoOrAnimationJsonUrl,
        prevState.settings,
        inputIdentifier,
      );
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          controls: updatedControls,
          formIsValid,
        },
      };
    });
  };

  const sectionDivClass = (index) =>
    sectionIndex === index
      ? 'creator-section creator-section--in-edit'
      : 'creator-section';

  const assignedModuleKeys = landingPageSections.map((module) => module.type);

  const providerValue = useMemo(
    () => ({
      campaignData,
      assignedModuleKeys,
      langKey: currentLanguage,
      isPaywall,
    }),
    [campaignData, assignedModuleKeys, currentLanguage, isPaywall],
  );

  return (
    <CampaignCreatorContext.Provider value={providerValue}>
      <Row>
        <Col xl={12} md={12}>
          <Row>
            <Col className="d-inline-flex btnContainer">
              <AddSectionButton
                addSectionHandler={addSectionHandler}
                serviceType={serviceType}
              />
              <GlobalSettingsButton
                addSectionHandler={addSectionHandler}
                serviceType={GLOBAL_SETTINGS}
              />
            </Col>
          </Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={[
                    'creator',
                    openSettings ? 'with-settings' : '',
                  ].join(' ')}
                  data-test-id="creator-sections"
                >
                  {landingPageSections.map((section, index) => {
                    const globalStyle = globalSettings && globalSettings.config;

                    return (
                      <Draggable
                        key={section.id}
                        draggableId={section.id}
                        index={index}
                      >
                        {(provided_, snapshot_) => (
                          <div
                            ref={provided_.innerRef}
                            {...provided_.draggableProps}
                            {...provided_.dragHandleProps}
                            className={sectionDivClass(index)}
                            style={getItemStyle(
                              snapshot_.isDragging,
                              provided_.draggableProps.style,
                              globalStyle,
                            )}
                          >
                            <Section
                              {...section}
                              index={index}
                              openSettings={openSettingsHandler}
                              removeSection={removeSectionHandler}
                              globalBrandLogoUrl={globalBrandLogoUrl}
                              language={currentLanguage}
                              availableLanguages={getLanguages(globalSettings)}
                              onLanguageChange={setCurrentLanguage}
                              campaignId={campaignId}
                              sectionId={section.id}
                              videoProvider={videoProvider}
                              customStyle={sectionCustomStyle(globalStyle)}
                              globalSettings={globalStyle}
                              verificationMethod={verificationMethod}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
        {openSettings ? (
          <>
            <SettingsBox
              adding={!!landingPageSections[sectionIndex]}
              title={SettingsForms[typeAdding].name}
              type={typeAdding}
              description={SettingsForms[typeAdding].description}
              image={SettingsForms[typeAdding].image}
              onCloseModal={() => {
                setOpenSettings(false);
                setDataChanged(false);
              }}
              languages={getLanguages(globalSettings)}
              currentLanguage={currentLanguage}
              onChangeLanguage={(lng) => resetOpenedSettings(sectionIndex, lng)}
              onSaveProgress={saveSettingsHandler}
              changed={dataChanged}
            >
              <Settings
                sectionIndex={sectionIndex}
                settings={landingPageSettings.settings}
                saveSettingsHandler={saveSettingsHandler}
                inputChangedHandler={inputSettingsChangedHandler}
                fileChange={fileChange}
                settingsType={typeAdding}
                onChangeImageCoords={coordsSettingsChangedHandler}
                showImageLinker={() => setShowImageLinker(!showImageLinker)}
                setImgUrl={setImgUrl}
                serviceType={serviceType}
                changed={dataChanged}
              />
            </SettingsBox>
            {showImageLinker && imgUrl && (
              <ImageLinker
                config={landingPageSettings.settings?.coords}
                onInputChange={coordsSettingsChangedHandler}
                onClose={onImageLinkerClose}
                imgUrl={imgUrl}
              />
            )}
          </>
        ) : null}
      </Row>
      {showModal && (
        <Modal
          show
          title="Remove module confirmation"
          body="Are you sure you want to remove this module?
                                This action is irreversible!"
          cancel="No"
          save="Yes"
          handleClose={removeSectionCanceledHandler}
          handleSave={removeSectionConfirmedHandler}
          dataTestAttribute={{
            modal: 'modal-section-removal',
            action1: 'keep-section',
            action2: 'remove-section',
          }}
        />
      )}
    </CampaignCreatorContext.Provider>
  );
};

export default Creator;
