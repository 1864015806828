import createInput from '../../shared/Form/createInput';

export default {
  name: createInput(
    null,
    {
      type: 'text',
      placeholder: 'Name*',
    },
    {
      required: true,
    },
  ),
  email: createInput(
    null,
    {
      type: 'email',
      placeholder: 'Email Address*',
    },
    {
      required: true,
      isEmail: true,
    },
  ),
  phoneNumber: createInput(
    'Phone Number',
    {
      type: 'text',
      placeholder: 'Phone Number*',
    },
    {
      required: true,
      isPhone: true,
    },
    '',
    false,
    'phone',
  ),
  subject: createInput(
    null,
    {
      type: 'text',
      placeholder: 'Subject*',
    },
    {
      required: true,
    },
  ),
  message: createInput(
    null,
    {
      type: 'textarea',
      placeholder: 'Your Message*',
    },
    {
      required: true,
    },
    '',
    false,
    'textarea',
  ),
};
