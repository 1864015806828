import CampaignService from '../../api/Campaigns';
import { transformCampaign } from './CampaignDetail.transform';

// example server error format we cant use for inline errors

// {
//     "statusCode": 400,
//     "message": [
//         "budget must be an integer number",
//         "ageFrom must be an integer number",
//         "ageTo must be an integer number"
//     ],
//     "error": "Bad Request"
// }

// example of error we can use for inline error

// {
//     "statusCode": 400,
//     "message": "Input data validation failed",
//     "_errors": {
//         "name": "Duplicate campaign name"
//     }
// }

const numberTypeOrNull = ['budget', 'connectionTarget', 'ageFrom', 'ageTo'];

const transformPayload = (values) => {
  const payload = {
    ...values,
    videoProvider:
      values.videoProvider && Object.keys(values.videoProvider).length
        ? values.videoProvider
        : null,
    maxPerConnection: Number(values.maxPerConnection) ?? 0,
  };

  for (const key of numberTypeOrNull) {
    const number = Number(payload[key]);
    payload[key] = Number.isNaN(number) ? null : number;
  }

  return payload;
};

const sendCampaignData = async (action = 'update', values) => {
  let response;
  try {
    const payload = transformPayload(values);
    const args = action === 'update' ? [values.id, payload] : [payload];
    const data = await CampaignService[action](...args).then((r) => r.data);
    response = transformCampaign(data);
  } catch (err) {
    response = { exception: err };
  }
  return response;
};

export const handleSave = async (values, action = 'update') => {
  if (!['update', 'save'].includes(action)) {
    throw new Error('handleSaveCampaign: Invalid action provided');
  }

  return sendCampaignData(action, values);
};
