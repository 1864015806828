import instance from './Api';

const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
    limit: 20,
    order: 'name',
    order_direction: 'asc',
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });
  return instance.get(`/tags${query}`);
};

export default {
  list,
};
