import { useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import AutomaticRedemptionApi from '../../../api/AutomaticRedemption';

export const useAutomaticRedemption = (formData, setFormData) => {
  async function fetchArProviders() {
    try {
      const response = await AutomaticRedemptionApi.getArProviders();
      setFormData((state) => {
        const formDataState = _cloneDeep(state);
        formDataState.arProvider.elementConfig.options = response.data;
        return formDataState;
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchRewardTypes() {
    try {
      if (!formData.arProvider.value) {
        return;
      }
      const response = await AutomaticRedemptionApi.getRewardTypes(
        formData.arProvider.value,
      );
      setFormData((state) => {
        const formDataState = _cloneDeep(state);
        formDataState.rewardType.elementConfig.options = response.data;
        return formDataState;
      });
    } catch (error) {
      console.error(error);
    }
  }

  function setSameProductNameAsRewardType() {
    try {
      if (
        !formData.rewardType.value ||
        !formData.rewardType.elementConfig.options.length
      ) {
        return;
      }
      setFormData((state) => {
        const formDataState = _cloneDeep(state);
        const filtered = formDataState.rewardType.elementConfig.options.filter(
          (option) => {
            return option.value === formDataState.rewardType.value;
          },
        );
        const [firstOption] = filtered;
        formDataState.productName.value = firstOption.label;
        return formDataState;
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchArProviders();
    fetchRewardTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRewardTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.arProvider.value]);

  useEffect(() => {
    setSameProductNameAsRewardType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.rewardType.value]);
};
