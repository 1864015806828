import React from 'react';
import Input from '../../../../../../components/UI/Input/Input';
import FilterInputSelect from './FilterInputSelect';

export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_EQUALS = 'equals';
export const FILTER_TYPE_RANGE = 'range';
export const FILTER_TYPE_STRING = 'string';

const FilterInput = ({ filterConfig, filterValue, onFilterValueChange }) => {
  const filterType = filterConfig.allowedTypes[0];

  /**
   * On value change pass value or array of values
   * @param newValue
   */
  const filterValueChange = (newValue) => {
    onFilterValueChange({
      type:
        filterType === FILTER_TYPE_RANGE
          ? FILTER_TYPE_RANGE
          : FILTER_TYPE_EQUALS,
      value: newValue,
    });
  };

  /**
   * Determine input types for plain input based on field name
   * Supported: text, number, datetime-local
   * @returns {string}
   */
  const getInputType = () => {
    switch (filterConfig.name) {
      case 'date':
        return 'date';
      case 'age':
        return 'number';
      default:
        return 'text';
    }
  };

  const renderInputRange = (
    <div className="inputsRange">
      <Input
        elementConfig={{
          type: getInputType(),
          min: filterConfig.name === 'age' ? '0' : undefined,
          max: filterConfig.name === 'age' ? '199' : undefined,
        }}
        label={`${filterConfig.label} from`}
        keyName={`filter${filterConfig.name}-from`}
        value={filterValue?.value?.from || ''}
        changed={($event) =>
          filterValueChange({
            from: $event.target.value,
            to: filterValue?.value?.to,
          })
        }
        additionalClasses="formInput formInput--bordered formInput--medium"
        dataTestAttribute={`filters-input-${filterConfig.name}-min`}
      />
      <Input
        elementConfig={{
          type: getInputType(),
          min: filterConfig.name === 'age' ? '0' : undefined,
          max: filterConfig.name === 'age' ? '199' : undefined,
        }}
        label={`${filterConfig.label} to`}
        keyName={`filter${filterConfig.name}-to`}
        value={filterValue?.value?.to || ''}
        changed={($event) =>
          filterValueChange({
            from: filterValue?.value?.from,
            to: $event.target.value,
          })
        }
        additionalClasses="formInput formInput--bordered formInput--medium"
        dataTestAttribute={`filters-input-${filterConfig.name}-max`}
      />
    </div>
  );

  const renderInputSelect = (
    <FilterInputSelect
      filterConfig={filterConfig}
      filterValue={filterValue?.value?.values || []}
      onFilterValueChange={(newVal) =>
        filterValueChange({
          values: newVal || [],
        })
      }
    />
  );

  return (
    <div>
      {filterType === FILTER_TYPE_SELECT && renderInputSelect}
      {filterType === FILTER_TYPE_RANGE && renderInputRange}
    </div>
  );
};

export default FilterInput;
