import React from 'react';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import DataTable from '../../UI/DataTable/DataTable';
import Input from '../../UI/Input/Input';
import { productTypes } from '../../Providers/constants';

import styles from './ProviderTable.module.scss';

const gridStyle = { height: 'calc(100vh - 295px)' };

const ProviderTable = ({ dataSource, onDeleteProviderClick, search }) => {
  const navigate = useNavigate();

  const { isAdmin, isBountyAdmin, isCompanyAdmin } = useSelector(
    (state) => state.auth,
  );

  const columns = [
    { header: 'Offer Name', name: 'name', defaultFlex: 1 },
    {
      header: 'Redeem url',
      name: 'url',
      defaultFlex: 1,
    },
    {
      header: 'Product Type',
      name: 'productType',
      width: 140,
      render: ({ data }) => productTypes[data.productType],
    },
    {
      header: 'Available Rewards',
      name: 'campaignCount',
      type: 'number',
      defaultFlex: 1,
      render: ({ data }) => {
        const { codes, usedCodes } = data?.stats ?? {};
        const codeCountRemaining = codes - usedCodes;
        const validCount = !Number.isNaN(codeCountRemaining);
        const codeLinkText =
          validCount && codes > 0
            ? `${codeCountRemaining} available of ${codes} codes`
            : '(No codes/count available)';

        return data?.productType !== 'automaticRedemption' ? (
          <span>
            <Link
              className="link-bm-orange"
              to={`/codes/${data.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {codeLinkText}
            </Link>
          </span>
        ) : (
          <span>
            Up to {data?.stats?.totalAvailablePackages}{' '}
            {Math.trunc(data?.stats?.currentPackageInMegabytes)}MB Data Packs
          </span>
        );
      },
    },
    {
      header: 'Live/Linked Campaigns',
      name: 'stats.activeCampaigns',
      type: 'number',
      defaultFlex: 1,
      render: ({ data }) => {
        const { campaigns, activeCampaigns } = data?.stats ?? {};
        const linkedCampaigns = `${activeCampaigns ?? '0'} / `;
        return (
          <>
            {linkedCampaigns}
            {campaigns ? (
              <span>
                <Link
                  className="link-bm-orange"
                  to={`/campaigns?pid=${data.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {campaigns}
                </Link>
              </span>
            ) : (
              '0'
            )}
          </>
        );
      },
    },
    {
      header: 'Banners',
      name: 'paywallBannerUrl',
      width: 120,
      render: ({ data }) => {
        const { banners } = data?.stats ?? {};
        return (
          <Link
            className="link-bm-orange"
            to={`/offer-carousel-images/${data.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {banners}
          </Link>
        );
      },
    },
    ...(isAdmin || isBountyAdmin
      ? [
          {
            header: 'Linked Companies',
            name: 'linkedCompanies',
            width: 180,
            render: ({ data }) => {
              const { companies } = data?.stats ?? {};
              return companies;
            },
          },
        ]
      : []),
    {
      header: 'Active',
      name: 'active',
      width: 90,
      render: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      header: 'Actions',
      name: 'id',
      width: 175,
      sortable: false,
      render: ({ data }) => {
        const { codes, banners, campaigns } = data?.stats ?? {};

        /*
         * A companyAdmin is allowed to delete a provider with linked companies as they cannot unlink a provider from a company
         * See BE delete function for more info
         */
        const disableDelete = codes > 0 || banners > 0 || campaigns > 0;
        return (
          <div className="btnContainer">
            <Link
              className="btnDefault btnDefault--small"
              data-test-id="edit-provider-button"
              to={`/offers/edit/${data.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              Edit
            </Link>

            <button
              className="btnDefault btnDefault--small btnDefault--red"
              onClick={(e) => {
                onDeleteProviderClick(data);
                e.stopPropagation();
              }}
              data-test-id="delete-provider-button"
              disabled={disableDelete}
              title={
                disableDelete
                  ? `Cannot delete provider with active campaigns, codes${
                      isCompanyAdmin ? ' or banners' : ', banners or companies'
                    }`
                  : null
              }
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];
  const rolesOptions = {
    options: [
      {
        value: '',
        displayValue: 'Search by Type',
      },
      {
        value: 'smsCode',
        displayValue: 'SMS Code',
      },
      {
        value: 'uniqueCode',
        displayValue: 'Unique Code',
      },
      {
        value: 'accountUpdate',
        displayValue: 'Account Update',
      },
    ],
  };

  return (
    <>
      <div className={`${styles.filterContainer} mb-2 p-2 d-flex gap-3`}>
        <Col md={2}>
          <Input
            elementType="text"
            label="Search by Offer Name"
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'provider')}
            dataTestAttribute="search-by-provider-input"
          />
        </Col>
        <Col md={2}>
          <Input
            elementType="select"
            elementConfig={rolesOptions}
            additionalClasses="formInput listInput"
            changed={(event) => search(event, 'type')}
            dataTestAttribute="search-by-provider.type-input"
            label="Search by Product Type"
          />
        </Col>
      </div>
      <div>
        <DataTable
          id="provider"
          columns={columns}
          dataSource={dataSource}
          style={gridStyle}
          columnOrderPersist
          pageSizes={[50, 100]}
          onRowClick={({ data }) => navigate(`/offers/edit/${data.id}`)}
        />
      </div>
    </>
  );
};

export default ProviderTable;
