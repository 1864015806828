import _cloneDeep from 'lodash/cloneDeep';
import { formatDateToISO } from '../dateTime';

export const checkIsStringEmpty = (val) => {
  if (typeof val !== 'string' && !(val instanceof String)) {
    return false;
  }
  return val.length === 0 || !val.trim();
};

export const checkMinLength = (val, rules) =>
  (!rules.required && val.length === 0) || val.length >= rules.minLength;

export const checkMaxLength = (val, rules) =>
  (!rules.required && val.length === 0) || val.length <= rules.maxLength;

export const urlPattern =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const checkIsUrl = (val) => {
  return urlPattern.test(val);
};

export const emailPattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const checkIsEmail = (val) => {
  return emailPattern.test(val);
};

export const checkIsInt = (val) => {
  const pattern = /^\d+$/;
  return pattern.test(val);
};
export const checkIsFloat = (val) => {
  const pattern = /^[+-]?([0-9]*[.,])?[0-9]+/;
  return pattern.test(val);
};

export const isMoney = (val) => {
  const pattern = /^\d+(\.\d{1,2})?$/;
  return pattern.test(val);
};

export const checkIsEqual = (val, compare) => val === compare;

export const checkMinCount = (val, rules) =>
  Array.isArray(val) && val.length >= rules.minCount;

export const formatToValid = (field, value) => {
  if (field === 'startDate' || field === 'endDate') {
    return formatDateToISO(value);
  }
  if (
    value &&
    (field === 'budget' || field === 'ageFrom' || field === 'ageTo')
  ) {
    return Number.isNaN(value) ? 0 : parseInt(value, 10);
  }
  if (value && field === 'maxPerConnection') {
    return Number.isNaN(value) ? 0 : parseFloat(value);
  }

  return value;
};

export const validateForm = (key, value, rules, prevState = null, data) => {
  if (!rules) {
    return null;
  }

  if (rules.required) {
    if (!value || (Array.isArray(value) && !value.length)) {
      return `${key} is required`;
    }
  } else if (value === '') {
    return null;
  }

  if (rules.isPhone) {
    const regex =
      /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,3}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}$/;

    // Test if the phone number matches the regular expression
    const passed = regex.test(value);
    if (!passed) {
      return `${key} is not a valid`;
    }
  }

  if (rules.minLength) {
    if (!checkMinLength(value, rules)) {
      return Array.isArray(value)
        ? `${key} must be at least ${rules.minLength} option`
        : `${key} must be at least ${rules.minLength} characters long and include combination of uppercase, lowercase, numbers and special characters`;
    }
  }
  if (rules.isStrongPassword) {
    const atLeastOneUpperCaseLetter = /[A-Z]/.test(value);
    const atLeastOneLowerCaseLetter = /[a-z]/.test(value);
    const atLeastOneDigit = /\d/.test(value);
    const atLeastOneSpecialCharacter = /[^a-zA-Z0-9]/.test(value);
    if (
      !checkMinLength(value, rules) ||
      !atLeastOneUpperCaseLetter ||
      !atLeastOneLowerCaseLetter ||
      !atLeastOneDigit ||
      !atLeastOneSpecialCharacter
    ) {
      return `${key} must be at least ${rules.minLength} characters long and include combination of uppercase, lowercase, numbers and special characters`;
    }
  }
  if (rules.maxLengthPerLine) {
    if (!value.split(/\r?\n/).every((line) => checkMaxLength(line, rules))) {
      return `Each ${key} must be no longer than ${rules.maxLength} characters long`;
    }
  }

  if (rules.maxLength && !rules.maxLengthPerLine) {
    if (!checkMaxLength(value, rules)) {
      return Array.isArray(value)
        ? `You cannot select more than ${rules.maxLength} ${key}`
        : `${key} must be no longer than ${rules.maxLength} characters long`;
    }
  }

  if (rules.isUrl) {
    if (!rules.required && value === '') return false;
    if (!checkIsUrl(value)) {
      return `${key} must be valid url address`;
    }
  }

  if (rules.isEmail) {
    if (!checkIsEmail(value)) {
      return `${key} must be correct email address`;
    }
  }

  if (rules.isInt) {
    if (!checkIsInt(value)) {
      return `${key} must be an integer`;
    }
  }

  if (rules.isFloat) {
    if (!checkIsFloat(value)) {
      return `${key} must be a float number`;
    }
  }

  if (rules.isMoney) {
    if (!isMoney(value)) {
      return `${key} is not valid`;
    }
  }

  if (rules.isEqual) {
    if (
      prevState &&
      !checkIsEqual(value, prevState.controls[rules.isEqual].value)
    ) {
      return `${key} must be equal to
                    ${
                      prevState.controls[rules.isEqual].elementConfig.label ||
                      prevState.controls[rules.isEqual].elementConfig
                        .placeholder
                    }`;
    }
  }

  if (rules.minCount) {
    if (!checkMinCount(value, rules)) {
      return `${key} should contain minimum ${rules.minCount} element(s)`;
    }
  }

  if (rules.lessThan) {
    const value2 = data[rules.lessThan];
    if (value > value2) {
      return `${key} should be before ${rules.lessThan}`;
    }
  }

  if (rules.greaterThan) {
    const value2 = data[rules.greaterThan];
    if (value < value2) {
      return `${key} should be before ${rules.greaterThan}`;
    }
  }

  if (rules.min !== undefined) {
    if (value < rules.min) {
      return `${key} should be bigger or equal than ${rules.max} %`;
    }
  }

  if (rules.max !== undefined) {
    if (value > rules.max) {
      return `${key} should be lower or equal than ${rules.max} %`;
    }
  }

  if (rules.regex !== undefined) {
    if (rules.regex.test.test(value)) {
      return `${key} ${rules.regex.message}.`;
    }
  }

  if (rules.unique) {
    if (Array.isArray(rules.unique)) {
      const validationValue =
        typeof rules.uniqueCaseInsensitive === 'boolean' &&
        rules.uniqueCaseInsensitive
          ? value.toLowerCase()
          : value;
      if (rules.unique.indexOf(validationValue) !== -1) {
        return `${key} should be unique. Reserved values are: ${rules.unique.join(
          ', ',
        )}`;
      }
    }
  }

  return null;
};

export const validateAllFormFields = (controls) => {
  let formIsValid = true;

  const updatedControls = Object.keys(controls).map((inputId) => {
    const updatedControl = _cloneDeep(controls[inputId]);

    const errorMessage = validateForm(
      updatedControl.label || updatedControl.elementConfig?.placeholder,
      updatedControl.value,
      updatedControl.validation,
    );

    updatedControl.valid = !errorMessage;
    updatedControl.error = errorMessage;

    formIsValid = updatedControl.valid && formIsValid;

    return updatedControl;
  });

  return { updatedControls, formIsValid };
};
