import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Input from '../../UI/Input/Input';

import ActionsNavbar from '../../ActionsNavbar/ActionsNavbar';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import Button from '../../UI/Button/Button';

import filterIcon from '../../../assets/icons/filter.svg';

const CampaignListHeader = ({
  queryFilter,
  activeFilter,
  fallbackFilter,
  setField,
  showFilters,
  selectedRow,
  showArchivedCampaigns,
  onArchiveClick,
}) => {
  const navigate = useNavigate();
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  return (
    <ActionsNavbar variant="light">
      {/* Create + Search */}
      <Col
        className=" mb-2 mb-md-0 d-flex flex-no-wrap align-items-center"
        xs={12}
        lg={6}
        xl={6}
        xxl={5}
      >
        <Button
          additionalClasses="_add-campaign me-4"
          clicked={() => navigate('/campaigns/add')}
        >
          Create campaign
        </Button>

        <Input
          elementType="text"
          label="Search by Campaign Name"
          containerClasses="Input--w-auto"
          additionalClasses="formInput formInput--bordered formInput--text-sm"
          elementConfig={{
            placeholder: 'Search by Brand, Campaign, Company',
          }}
          value={queryFilter}
          changed={(event) =>
            setField('query', event?.target?.value ?? event?.value ?? event)
          }
        />
      </Col>

      {/* Toggles */}
      <Col
        className=" d-flex justify-content-start flex-wrap flex-grow align-items-center "
        xs={12}
        lg={3}
        xl={3}
        xxl={5}
      >
        <ToggleSwitch
          darkMode
          label="Hide Inactive Campaigns"
          value={activeFilter}
          onCheck={() => setField('active', !activeFilter)}
          className="me-2"
          additionalLabelClasses="toggleSwitch__label--lower toggleSwitch__label--sm"
        />

        {(isAdmin || isBountyAdmin) && (
          <ToggleSwitch
            darkMode
            label="Fallback Campaigns"
            value={fallbackFilter}
            onCheck={() => setField('fallback', !fallbackFilter)}
            additionalLabelClasses="toggleSwitch__label--lower toggleSwitch__label--sm"
          />
        )}
      </Col>

      {/* Filter CTA */}
      <Col
        className=" d-flex justify-content-end align-items-center "
        xs={12}
        lg={3}
        xl={3}
        xxl={2}
      >
        <Button
          additionalClasses="btnFilter px-4"
          clicked={onArchiveClick}
          disabled={!Object.keys(selectedRow).length}
        >
          {showArchivedCampaigns ? 'Restore' : 'Archive'}
        </Button>
        <Button additionalClasses="btnFilter px-4" clicked={showFilters}>
          <span>
            <img src={filterIcon} alt="filter" />
          </span>{' '}
          <span className="ms-3">Filter</span>
        </Button>
      </Col>
    </ActionsNavbar>
  );
};

export default CampaignListHeader;
