import instance from './Api';

/**
 * Get industry list select options
 * @return {Promise<AxiosResponse<any>>}
 */
const options = () => instance.get('/industry/options');

export default {
  options,
};
