import { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';

import CampaignService from '../../api/Campaigns';
import CompanyService from '../../api/Companies';
import TagService from '../../api/Tags';

import { getFormControls } from './controls';
import { handleSave } from './CampaignDetail.handleSave';

import {
  transformCampaign,
  transformOptions,
} from './CampaignDetail.transform';

import { getFirstErrorSectionKey } from './CampaignDetail.getFirstErrorSectionKey';

const { validationSchema, displayFields, initialValues, sectionConfig } =
  getFormControls();

const scrollTo = (y) => document.querySelector('html').scrollTo(0, y);

export const useCampaignDetail = (campaignId) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(!!campaignId);
  const [isSaving, setSaving] = useState(false);
  const [saveTick, setSaveTick] = useState(0);
  const [openSectionKey, setOpenSectionKey] = useState(null);
  const [successTick, setSuccessTick] = useState(0);
  const [genericErrorTick, setGenericErrorTick] = useState(0);
  const [genericErrorMessage, setGenericErrorMessage] = useState('');
  const [pageState, setPageState] = useState({
    activeSectionKey: '1',
    // "sectionsUpdated" is a workaround for the save button disabled
    //  state when deeply nested properties are updated.
    //  This only needs to be updated for sections that contain/manage
    //  deeply nested properties
    sectionsUpdated: [
      /* Campaign section keys */
    ],
  });

  const [webhooks, setWebhooks] = useState([]);
  const [webhookManageModal, setWebhookManageModal] = useState({
    show: false,
    data: null,
    index: -1,
  });

  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const errorsRef = useRef(null);
  const companyIdRef = useRef(null);
  const debounceTouchRef = useRef(null);
  const debounceScrollTo = useRef(null);
  const sectionsRef = useRef({});
  const [touchQueue, setTouchQueue] = useState([]);

  /**
   * Campaign options must be pre-populated in
   *  existing campaign data, but may not necessary be
   *  available any more (eg. soft deleted)
   *
   *  These are interpolated with available options
   */
  const [campaignOptions, setCampaignOptions] = useState({
    tags: null,
    company: null,
    brand: null,
    provider: null,
  });

  const [campaignStatus, setCampaignStatus] = useState(null);

  const [selectOptions, setSelectOptions_] = useState({
    tags: [],
    company: [],
    brand: [],
    provider: [],
    paywallQuestions: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const isUpdate = !!values.id;
      setSaving(true);
      await saveCampaign(values, isUpdate);
      setSaving(false);
    },
  });

  window.formik = formik;

  /**
   * Helper to programmatically update a value
   *  and set it's state as "touched" since formik
   *  doesn't allow this simply under the hood
   */

  formik.updateFieldValue = (key, value, setTouched = true) => {
    formik.setFieldValue(key, value);
    if (setTouched) {
      setTouchQueue((arr) => [...arr, key]);
    }
  };

  formik.setTouchedFields = (touchFieldKeys) => {
    setTouchQueue((arr) => [...arr, ...touchFieldKeys]);
  };

  const incrementSuccessTick = () => setSuccessTick((n) => n + 1);
  const incrementSaveTick = () => setSaveTick((n) => n + 1);
  const incrementGenericErrorTick = () => setGenericErrorTick((n) => n + 1);

  const schemaBuild = validationSchema.builder(formik.values);
  const fieldValidationSchema = schemaBuild.describe();

  /**
   * Interpolate a pre-selected campaign option
   */
  const setSelectOptions = useCallback(
    async (key, options = [], preserveOption = true) => {
      const presetOption = campaignOptions[key] ?? null;
      const hasPreset = presetOption?.value !== undefined;
      const optionsIncludePreset =
        hasPreset && options.find((e) => e.value === presetOption.value);
      const updatedOptions =
        optionsIncludePreset || !hasPreset || !preserveOption
          ? options
          : [presetOption, ...options];

      setSelectOptions_((state) => ({
        ...state,
        [key]: updatedOptions,
      }));

      if (!preserveOption) {
        switch (key) {
          case 'provider':
            {
              const setTouchedIfHasValue = !!formik.values.videoProvider?.id;
              formik.updateFieldValue(
                'videoProvider',
                '',
                setTouchedIfHasValue,
              );
            }
            break;

          default: {
            const setTouchedIfHasValue = !!formik.values[key];
            formik.updateFieldValue(key, '', setTouchedIfHasValue);
          }
        }
      }
    },
    [campaignOptions, formik],
  );

  /**
   * All pre-existing modal-based form updates can
   *  be written directly back to formik, rather
   *  than being completely re-written for formik.
   */
  const setFieldsetValue = (key, value) => {
    formik.setFieldValue(key, value, true);
  };

  const getCampaign = async (id) => {
    setLoading(true);

    try {
      const campaignData = await CampaignService.get(id)
        .then((response) => response?.data)
        .catch(() => null);
      if (!campaignData?.id) {
        throw new Error('Failed to get campaign data');
      }

      await getOptions('company', null, setSelectOptions);
      await getOptions('tagOptions', null, setSelectOptions);

      /**
       * Campaign options must be pre-populated in
       * existing campaign data, however may not necessarily be
       * available any more (eg. soft deleted)
       */
      setCampaignOptions(transformOptions(campaignData));
      formik.resetForm({ values: transformCampaign(campaignData) });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const resetPage = async () => {
    formik.resetForm();
    await getOptions('company', null, setSelectOptions, false);
    await getOptions('tagOptions', null, setSelectOptions);
  };

  const addSectionUpdated = (sectionKey) => {
    setPageState((state) => {
      return {
        ...state,
        sectionsUpdated: [...new Set(...state.sectionsUpdated, sectionKey)],
      };
    });
  };

  const clearSectionsUpdated = () => {
    setPageState((state) => {
      return {
        ...state,
        sectionsUpdated: [],
      };
    });
  };

  const saveCampaign = async (values, isUpdate) => {
    setGenericErrorMessage('');
    const action = isUpdate ? 'update' : 'save';
    const response = await handleSave(values, action);

    const isSuccess = response?.id;
    const isErrored = response?.exception;

    if (isSuccess) {
      incrementSuccessTick();
      clearSectionsUpdated();

      const fieldKeys = Object.keys(displayFields);
      const touched = Object.assign(
        ...fieldKeys.map((key) => ({ [displayFields[key].key]: true })),
      );
      formik.resetForm({ values: formik.values, touched });

      /**
       * formik.dirty appears to take some time to update,
       *  since we have no formik promise to confirm completion
       *  a delay is necessary to avoid showing the "confirm
       *  navigation" modal
       */
      if (!isUpdate) {
        setTimeout(() => navigate('/campaigns/edit/' + response.id), 500);
      }

      scrollTo();
    } else if (isErrored) {
      console.error(response.exception);

      const inlineErrors = response.exception?.response?.data?._errors;
      const genericErrors = response.exception?.response?.data?.errors;

      incrementGenericErrorTick();
      setGenericErrorMessage(
        genericErrors ||
          (inlineErrors
            ? 'Missing or invalid required field(s)'
            : 'Sorry, the campaign couldn’t be saved, please try again'),
      );

      if (inlineErrors) {
        formik.setErrors(inlineErrors);
      }
    } else {
      console.warn('Campaign save had an unhandled response', response);
    }
  };

  const setActiveSection = (num) => {
    if (!num) {
      return;
    }

    setPageState((state) => ({
      ...state,
      activeSectionKey: num,
    }));
  };

  const showNextSection = (e) => {
    e.preventDefault();

    setPageState((state) => {
      return {
        ...state,
        activeSectionKey: `${Number(state.activeSectionKey) + 1}`,
      };
    });
  };

  /**
   * Detect error changes, scroll to first
   *  container with error
   */
  useEffect(() => {
    const ticker = genericErrorTick + saveTick;
    if (ticker && errorsRef.current !== ticker) {
      errorsRef.current = ticker;
      clearTimeout(debounceScrollTo.current);

      debounceScrollTo.current = setTimeout(() => {
        const errorKeys = Object.keys(formik.errors);

        if (errorKeys.length) {
          const section = getFirstErrorSectionKey(
            errorKeys,
            sectionConfig,
            sectionsRef,
          );

          if (section) {
            scrollTo(section.offsetTop);
            setOpenSectionKey(section.key);
          }
        }
      }, 1000);
    }
  }, [saveTick, genericErrorTick, formik]);

  /**
   * Update company-related option lists
   */
  useEffect(() => {
    if (companyIdRef.current !== formik.values.company) {
      if (formik.values.company) {
        // Preserve campaign-defined value at initial load only
        const preserveOption = !companyIdRef.current;

        getOptions(
          'companyOptions',
          formik.values.company,
          setSelectOptions,
          preserveOption,
        );

        companyIdRef.current = formik.values.company;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.company, setSelectOptions]);

  /**
   * Update "touched" state without a race condition
   *  that over-rides a previous update
   */
  useEffect(() => {
    clearTimeout(debounceTouchRef.current);
    debounceTouchRef.current = setTimeout(
      (formik_) => {
        if (touchQueue.length) {
          const touched = Object.assign(
            ...touchQueue.map((k) => ({ [k]: true })),
          );

          formik_.setTouched({ ...formik_.touched, ...touched }, true);
          setTouchQueue([]);
        }
      },
      300,
      formik,
    );
  }, [formik, touchQueue]);

  useEffect(() => {
    (async () => {
      const campaignWebhooks = await CampaignService.getWebhooks(campaignId)
        .then((response) => response?.data)
        .catch(() => null);

      setWebhooks(campaignWebhooks.data.list || []);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update campaign data
   */
  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
      CampaignService.getStatus(campaignId).then((result) => {
        setCampaignStatus(result.data);
      });
    } else {
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    CampaignService.getStatus(campaignId).then((result) => {
      setCampaignStatus(result.data);
    });
  }, [campaignId, formik.values.enabled]);

  const [countData, setCountData] = useState({
    pageViews: 0,
    cpdcs: 0,
    connections: 0,
    uniquePageViews: 0,
  });

  useEffect(() => {
    if (campaignId) {
      const getCountData = async () => {
        const { data } = await CampaignService.countEvents(campaignId);
        setCountData(data);
      };
      getCountData();
    }
  }, [campaignId]);

  const getters = {
    isSaving,
    loading: isLoading,
    pageState,
    displayFields,
    selectOptions,
    formik,
    validationSchema: fieldValidationSchema,
    sectionConfig,
    campaignStatus,
    saveTick,
    successTick,
    genericErrorTick,
    genericErrorMessage,
    openSectionKey,
    webhooks,
    webhookManageModal,
    countData,
    toast,
  };

  const actions = {
    setActiveSection,
    showNextSection,
    setFieldsetValue,
    onSaveAction: incrementSaveTick,
    setOpenSectionKey,
    addSectionUpdated,
    setWebhookManageModal,
    setWebhooks,
    setToast,
  };

  const refs = {
    sectionsRef,
  };

  return [getters, actions, refs];
};

/**
 * Helpers
 */

async function getOptions(
  type,
  id = null,
  setSelectOptions,
  preserveOption = true,
) {
  try {
    switch (type) {
      case 'tagOptions': {
        const options = await TagService.list()
          .then((response) =>
            response?.data?.data.map((res) => {
              return {
                value: res.id,
                label: res.name,
              };
            }),
          )
          .catch(() => null);
        if (options) {
          setSelectOptions('tags', options, preserveOption);
        }
        break;
      }
      case 'companyOptions': {
        const options = await CompanyService.companyOptions(id)
          .then((response) => response.data)
          .catch(() => null);
        if (options.providers) {
          setSelectOptions('provider', options.providers, preserveOption);
        }
        if (options.brands) {
          setSelectOptions('brand', options.brands, preserveOption);
        }
        break;
      }
      case 'company': {
        const options = await CompanyService.options(true)
          .then((response) => response?.data)
          .catch(() => null);
        if (options) {
          setSelectOptions('company', options, preserveOption);
        }
        break;
      }
      default:
        throw new Error('Invalid type provided to getOptions');
    }
  } catch (err) {
    console.error(err);
  }
}
