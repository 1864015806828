// eslint-disable-next-line import/no-cycle
import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import instance from './Api';

const login = async (email, password) =>
  instance.post('/user/login', { password, email });

/**
 * List users using filters
 * @param filters - example: {
 * email: 'test',
 * role: 'ROLE_ADMIN',
 * company: 'a',
 * name: 'Jake',
 * limit: 20,
 * page: 2,
 * order: 'name',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
const list = (filters) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/users${query}`);
};

const save = (user) => instance.post('/users', user);

const update = (user, id) => instance.put(`/users/${id}`, user);

const resetPassword = (password, passwordResetToken, id) =>
  axios.put(`${process.env.REACT_APP_API_URL}/api/users/${id}/reset-password`, {
    passwordResetToken,
    password,
  });

const get = (id) => instance.get(`/users/${id}`);

const activate = (password, repeatPassword) =>
  instance.put('/user/activate', {
    password,
    repeatPassword,
  });

const deactivate = (id) => instance.put(`/users/${id}/deactivate`);

const resend = (id) => instance.post(`/users/${id}/resend`);

const getByPasswordResetToken = async (passwordResetToken) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/users/passwordResetToken?passwordResetToken=${passwordResetToken}`,
  );
};
const sendResetPasswordViaEmail = (email) =>
  axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgotPassword`, {
    email,
  });

const contactAdmin = async ({ name, email, phoneNumber, subject, message }) =>
  instance.post(`/user/contact-admin`, {
    name,
    email,
    phoneNumber,
    subject,
    message,
  });

const signUp = async (userData) =>
  instance.post(`/user/sign-up`, {
    ...userData,
    subject: 'New user sign up request',
  });

const sendEmailOtp = (email) => instance.post(`/users/send-otp`, { email });

const validateEmailOtp = (email, code) =>
  instance.post(`/users/validate-otp`, { email, code });

const checkEmailOtp = (email) =>
  instance.post(`/users/check-email-otp`, { email });

export default {
  login,
  list,
  save,
  update,
  resetPassword,
  get,
  activate,
  deactivate,
  resend,
  contactAdmin,
  sendResetPasswordViaEmail,
  getByPasswordResetToken,
  sendEmailOtp,
  validateEmailOtp,
  checkEmailOtp,
  signUp,
};
