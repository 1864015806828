import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import Button from '../../UI/Button/Button';
import OfferStatus from './OfferStatus';

import styles from '../OfferDetail.module.scss';

const OfferHeader = ({
  provider,
  isFormValid,
  filePath,
  isLoading,
  setSaveTick,
  providerActive,
}) => {
  const { isAdvertiser } = useSelector((state) => state.auth);

  if (isLoading) return null;

  return (
    <div className={`${styles.container} ${styles.header}`}>
      <div className={styles.headerInner}>
        <Link className={styles.backButton} to="/offers">
          <FontAwesomeIcon
            size="xl"
            width="54"
            icon={faArrowLeftLong}
            className={styles.backIcon}
          />
        </Link>
        {provider ? (
          <div className="d-flex gap-3 align-items-center">
            <h3 className="mb-0 d-flex align-items-center gap-3">
              {provider?.name}
            </h3>
            <OfferStatus active={providerActive} />
          </div>
        ) : (
          <h3>Create Offer</h3>
        )}

        <div className={styles.headerControls}>
          {!isAdvertiser ? (
            <Button
              disabled={!isFormValid || isLoading || !filePath}
              clicked={() => setSaveTick((tick) => tick + 1)}
              additionalClasses={styles.buttonLastChild}
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OfferHeader;
