/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import _cloneDeep from 'lodash/cloneDeep';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import usePrompt from '../../../router/CustomUsePrompt';

// API Imports
import CampaignService from '../../../api/Campaigns';
import CompanyService from '../../../api/Companies';
import TagService from '../../../api/Tags';

// Component Imports
import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Toaster from '../../../components/UI/Toaster/Toaster';
import Button from '../../../components/UI/Button/Button';

// Local Imports
import CampaignForm from '../CampaignForm/CampaignForm';
import QualifyQuestions from '../QualifyQuestions/QualifyQuestions';

// Shared & Fixtures imports
import { inputFormatDate } from '../../../shared/dateTime';
import ActionsMenu from '../../../components/ActionsNavbar/ActionsMenu';

import './CampaignAddPage.scss';

const getSelectedLabels = (e, options = []) =>
  typeof e === 'object'
    ? e.label
    : options.find((o) => o.value === e).label || '';

const CampaignAddPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const campaignData = location?.campaignData || {};
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [destination, setDestination] = useState(undefined);
  const [landingPage, setLandingPage] = useState(null);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    data: {
      name: '',
      company: '',
      clientBrand: '',
      tags: [],
      fallbackUrl: '',
      budget: null,
      startDate: null,
      endDate: null,
      maxPerConnection: null,
      ageFrom: null,
      ageTo: null,
      gender: [],
      cities: [],
      countries: [],
      paywall: {
        enabled: true,
        qualificationRequirements: {
          questions: [],
        },
      },
    },
    errors: {},
    touched: {},
    isValid: false,
  });

  const closeToast = () => {
    setToast({ show: false, header: '', class: '', body: '' });
  };

  const onFormUpdated = (newData) => {
    setFormData((state) => {
      const companyChanged = state.data.company !== newData.data.company;
      if (companyChanged) {
        setCompanyBrands(newData.data.company);
      }
      return {
        ...state,
        ...newData,
      };
    });
  };

  const setCompanyBrands = async (companyId) => {
    const brandOptions = await CompanyService.companyOptions(companyId)
      .then((response) => response.data)
      .catch(() => null);
    if (brandOptions) {
      setBrands(brandOptions.brands);
    }
  };

  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (destination) {
      navigate(destination, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  useEffect(() => {
    async function pageInit() {
      setLoading(true);
      // Get Company options
      const companyOptions = await CompanyService.options(true)
        .then((response) => response?.data)
        .catch(() => null);

      // Return if required data is missing - error messaging?
      if (!companyOptions) {
        navigate(-1);
        return;
      }

      // setup data to copy campaign
      if (campaignData?.id) {
        const localFormData = { ...formData };
        Object.keys(localFormData.data).map((c) => {
          if (['budget', 'fallbackUrl'].indexOf(c) === -1) return null;
          if (campaignData[c]) {
            localFormData.data[c] = campaignData[c];
            localFormData.touched[c] = true;
          }
          return c;
        });
        setFormData(localFormData);
        CampaignService.get(campaignData.id)
          .then((result) => {
            if (!result || !result.data) {
              // eslint-disable-next-line no-alert
              alert(`${campaignData.name} could not be copied.`);
            }
            const { data } = result;
            const lp = {
              landingPageContent: data?.landingPageContent,
              globalSettings: data?.globalSettings,
              videoProvider: data?.videoProvider,
            };
            setLandingPage(lp);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            alert('Server error.'); // eslint-disable-line no-alert
          });
      }

      // Get All Tag options
      if (isAdmin || isBountyAdmin) {
        const res = await TagService.list();
        if (res) {
          const tagList = res.data.data.map((tag) => {
            return {
              value: tag.id,
              label: tag.name,
            };
          });
          setTags(tagList);
        }
      }

      setCompanies(companyOptions);
      setLoading(false);
    }
    pageInit();
    // eslint-disable-next-line
  }, []);

  const onCreateCampaign = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFormData({
      data: formData.data,
      errors: null,
      touched: formData.touched,
      isValid: true,
    });

    const newTags = formData.data.tags.map(getSelectedLabels);

    const data = {
      ...formData.data,
      countries: formData.data?.countries ?? null,
      gender: formData.data.gender?.length
        ? formData.data.gender?.map((gender) => gender.value)
        : null,
      maxPerConnection: formData.data.maxPerConnection
        ? parseFloat(formData.data.maxPerConnection)
        : null,
      ageFrom: formData.data.ageFrom ? formData.data.ageFrom : null,
      ageTo: formData.data.ageTo ? formData.data.ageTo : null,
      brand: formData.data.clientBrand,
      tags: newTags,
      ...landingPage,
    };

    try {
      await CampaignService.save(data)
        .then((response) => {
          setSaved(true);
          setDestination(`/campaigns/edit/${response.data.id}`);
        })
        .catch((err) => {
          /**
           * Show server error message
           *  do not change "isValid" state based on server side response
           *  (only for use with local validation)
           */
          const { response } = err;
          const errData = response.data;
          const serverFieldErrors = errData._errors ?? {};
          const getTouched = (prevTouched) => ({
            ...prevTouched,
            ...Object.keys(serverFieldErrors).reduce(
              (o, field) => ({ ...o, [field]: true }),
              {},
            ),
          });

          if (errData.statusCode === 400) {
            setToast({
              show: true,
              class: 'error',
              header:
                'We could not save the current campaign, ' +
                'see the error below',
            });
            setFormData((prevState) => ({
              ...prevState,
              errors: serverFieldErrors,
              touched: getTouched(prevState.touched),
            }));
          } else {
            setToast({
              show: true,
              class: 'error',
              header: errData?.message || 'System error',
            });
            if (errData?._errors) {
              setFormData((prevState) => ({
                ...prevState,
                errors: serverFieldErrors,
                touched: getTouched(prevState.touched),
              }));
            }
          }
        });
    } finally {
      setLoading(false);
    }
  };

  const onCancel = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const form = (
    <div>
      <CampaignForm
        {...{ isAdmin, isBountyAdmin, setFormData }}
        key="new-campaignForm"
        formTitle="Create campaign"
        data={formData}
        onFormChanged={onFormUpdated}
        campaignId="new-campaign"
        options={{
          dateFormat: inputFormatDate,
          usedBudget: 0,
          brands,
          companies,
          tags,
        }}
      />

      <QualifyQuestions {...{ formData, setFormData }} />

      <Row className="pt-3">
        <Col>
          <Button
            type="button"
            additionalClasses="btnDefault--light"
            clicked={(event) => onCancel(event)}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            disabled={!formData.isValid}
            clicked={(event) => onCreateCampaign(event)}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );

  usePrompt(
    "You'll lose all your data if you leave this view without saving",
    saved ? false : !!Object.keys(formData.touched).length,
  );

  return (
    <>
      <ActionsMenu>
        <Row>
          <Col xs={8} className="d-flex align-items-center gap-4">
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              onClick={() => navigate('/')}
              swapOpacity
              size="xl"
              type="button"
              className="return-btn"
            />
            <h1 className="mb-0">Create campaign</h1>
          </Col>
        </Row>
      </ActionsMenu>
      <Container className="userEdit">
        <div className="justify-content-md-center mt-5 pt-5">
          {loading ? <OverlaySpinner /> : ''}
          <div className={loading ? 'd-none' : ''}>{form}</div>
        </div>
      </Container>
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default CampaignAddPage;
