import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ProvidersService from '../../../api/Providers';
import CompanyService from '../../../api/Companies';
import IndustryService from '../../../api/Industries';
import inputChangedHandler from '../../../shared/inputChangeHandler';

import FormInput from '../../UI/FormInput/FormInput';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';
import Button from '../../UI/Button/Button';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';
import Input from '../../UI/Input/Input';

import FileUploadInput from '../../UI/FileUploadInput/FileUploadInput';
import formControls from './controls';

const getSelectedIds = (e) => (typeof e === 'object' ? e.value : e);
const getSelectedLabels = (e, options = []) =>
  typeof e === 'object'
    ? e.label
    : options.find((o) => o.value === e).label || '';

const CompanyForm = ({
  data,
  setCompany,
  companyId,
  saveTick,
  onCancelForm,
  onSubmitForm,
  isAddCompanyModal = false,
  setAccordionsFormSaveStatus,
  setAccordionActiveKey,
}) => {
  const [hasTermsAndConditions, setHasTermsAndConditions] = useState(
    data?.hasTermsAndConditions,
  );
  const [hasMultipleConnections, setHasMultipleConnections] = useState(
    data?.multipleConnectionsAllowed ?? true,
  );
  const [termsAndConditions, setTermsAndConditions] = useState(
    data?.termsAndConditions,
  );
  const { isAdvertiser, isAdmin, isBountyAdmin, isCompanyUser } = useSelector(
    (state) => state.auth,
  );
  const initialLoadRef = useRef(true);
  const [formData, setFormData] = useState(_cloneDeep(formControls));
  const [filePath, setFilePath] = useState(''); // maybe put to formControls later if there is time
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    if (saveTick) {
      submitForm();
    }
  }, [saveTick]);

  useEffect(() => {
    let _isFormValid = true;

    Object.keys(formData).forEach((inputName) => {
      if (formData[inputName]?.validation?.required && inputName !== 'brand') {
        if (!formData[inputName].valid) _isFormValid = false;
        if (!formData[inputName].value) _isFormValid = false;
      }
    });
    setIsFormValid(_isFormValid);
  }, [formData]);

  useEffect(() => {
    if (!initialLoadRef.current) return;
    setIsLoading(true);

    const createCompany = async () => {
      const providerOptions = await ProvidersService.options();
      const industryOptions = await IndustryService.options();
      setFormData((state) => {
        const updatedState = { ...state };
        updatedState.providers.elementConfig.options =
          providerOptions?.data ?? [];
        updatedState.industries.elementConfig.options =
          industryOptions?.data ?? [];
        updatedState.brands.elementConfig.options = [];
        return updatedState;
      });
      setIsLoading(false);
    };

    const initCompany = async () => {
      if (isAdvertiser && !data) {
        return;
      }

      const form = _cloneDeep(formData);
      let isValid = false;

      // If Advertiser then only display currently selected values
      const providerOptions = isAdvertiser
        ? form.providers?.value ?? null
        : await ProvidersService.options();
      // If Advertiser or Company User then only display currently selected values
      const industryOptions =
        isAdvertiser || isCompanyUser
          ? form.industries?.value ?? null
          : await IndustryService.options();

      Object.keys(data).map((el) => {
        if (form?.[el]) {
          form[el].value = data[el];
          form[el].valid = !!data[el];
          isValid = !!data[el];
        }

        if (el === 'brands') {
          form[el].value = form[el].value.map((e) => {
            e.isFixed = true;
            return e;
          });
        }

        if (el === 'providers') {
          form[el].value = form[el].value.map((e) => {
            return e;
          });
        }

        if (el === 'type' && !isAdvertiser) {
          if (!form[el].value) {
            form[el].touched = true;
            form[el].error = 'Company type is required';
          }
        }

        if (el === 'industries' && !isAdvertiser) {
          if (!form[el].value || !form[el].value.length) {
            form[el].touched = true;
            form[el].error = 'Industries type is required';
          }
        }

        return el;
      });

      form.brands.elementConfig.options = data?.brands ?? [];
      form.brands.elementConfig.disabled = isAdvertiser;
      form.industries.elementConfig.disabled = isAdvertiser || isCompanyUser;
      form.name.elementConfig.disabled = !isAdmin;
      form.type.elementConfig.disabled = !(isAdmin || isBountyAdmin);
      form.providers.elementConfig.disabled = !(isAdmin || isBountyAdmin);
      form.providers.elementConfig.options = providerOptions?.data ?? [];
      form.industries.elementConfig.options = industryOptions?.data ?? [];

      setFormData({ ...form });
      setIsFormValid(isValid);
      setIsLoading(false);
    };

    if (data) {
      initCompany();
    } else if (isAdmin || isBountyAdmin) {
      createCompany();
    }

    initialLoadRef.current = false;
  }, [data, formData, isAdmin, isAdvertiser, isBountyAdmin]);

  const setErrors = (errors) => {
    const updatedCampaignForm = {
      ...formData,
    };
    Object.keys(errors).forEach((param) => {
      const updatedFormElement = {
        ...updatedCampaignForm[param],
      };
      updatedFormElement.error = errors[param];
      updatedFormElement.touched = true;
      updatedCampaignForm[param] = updatedFormElement;
    });
    setFormData(updatedCampaignForm);
    setIsFormValid(false);
    setIsLoading(false);
  };

  const onInputChangeHandler = (event, inputIdentifier) => {
    !formTouched && setFormTouched(true);

    let value;

    if (Array.isArray(event)) {
      value = event;
    } else if (event) {
      value = event.currentTarget?.value || event.value;
    }

    const [updatedFormData, formIsValid] = inputChangedHandler(
      value,
      { controls: formData },
      inputIdentifier,
    );
    setFormData(updatedFormData);
    setIsFormValid(formIsValid);
  };

  const onCancel = (event) => {
    event.preventDefault();
    onCancelForm();
  };

  const onModifyCompany = async (companyData) => {
    CompanyService.update(companyId, companyData)
      .then(() => {
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            generalProfile: true,
          };
        });
      })
      .catch((err) => {
        const errors = err.response?.data?._errors;
        if (errors?.slug) {
          delete errors.slug;
        }
        if (errors?.newBrands) {
          errors.brands = errors.newBrands;
        }
        setAccordionActiveKey('0');
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            generalProfile: false,
          };
        });
        return { errors, data: null, action: () => {} };
      });
  };
  const submitForm = async (event) => {
    if (isAddCompanyModal) {
      event.preventDefault();
    }

    setIsLoading(true);

    const isError = Object.keys(formData)
      .map((key) => {
        return !!(formData[key].error || !formData[key].valid);
      })
      .includes(true);

    if (isError) {
      !isAddCompanyModal &&
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            generalProfile: false,
          };
        });
      setIsLoading(false);
      return;
    }

    const isEditCompany = !!data;

    const getNewBrands = () =>
      formData.brands.value.filter((e) => e.__isNew__).map(getSelectedLabels);

    const newBrands = isEditCompany
      ? getNewBrands() // Edit Company - Add brands only
      : formData.brands.value.map(getSelectedLabels); // Create Company - New brands only

    const terms = { hasTermsAndConditions };

    if (hasTermsAndConditions) {
      terms.termsAndConditions = termsAndConditions;
    }

    const company = {
      name: formData.name.value,
      type: formData.type.value,
      providers: Array.isArray(formData.providers.value)
        ? formData.providers.value.map(getSelectedIds)
        : [], // Existing providers [id, id, ...]
      industries: formData.industries.value.map(getSelectedIds),
      multipleConnectionsAllowed: hasMultipleConnections,
      newBrands,
      companyLogo: filePath,
      ...terms,
    };

    const result = isAddCompanyModal
      ? await onSubmitForm(company)
      : await onModifyCompany(company);

    if (result?.errors) {
      setErrors(result.errors);
      return;
    }

    if (!isAddCompanyModal) {
      setCompany((prevState) => ({ ...prevState, ...company })); // update company state so as for brand&demography accordion to use the correct updated state during formik onSubmit.
    }

    if (result && isAddCompanyModal) {
      result.action();
    }
    setIsLoading(false);
    setIsFormValid(false);
  };

  const onSuccessUpload = (uploadPath) => {
    !formTouched && setFormTouched(true);
    setFilePath(uploadPath);
  };

  return (
    <Row>
      <Col>
        {isLoading && <OverlaySpinner />}
        <form
          data-test-id="provider-form"
          onSubmit={isAddCompanyModal ? (event) => submitForm(event) : null}
        >
          <Row className="mb-4">
            <Col>
              <FormInput
                control={formData.name}
                inputKey="name"
                touched
                value={formData.name.value}
                inputChangedHandler={onInputChangeHandler}
                dataTestAttribute="company-name-input"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <FormInput
                control={formData.type}
                inputKey="type"
                touched
                value={formData.type.value}
                inputChangedHandler={onInputChangeHandler}
                dataTestAttribute="company-type-input"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <FormInput
                control={formData.industries}
                inputKey="industries"
                touched
                value={formData.industries.value}
                inputChangedHandler={onInputChangeHandler}
                dataTestAttribute="company-type-input"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <ToggleSwitchInput
                label="Allow Multiple Connections per person"
                text={hasMultipleConnections ? 'Yes' : 'No'}
                onCheck={(val) => {
                  setHasMultipleConnections(val);
                  !formTouched && setFormTouched(true);
                }}
                description={
                  hasMultipleConnections
                    ? 'Users can be matched to multiple campaigns associated to this company, and submit those campaigns'
                    : 'Users who have successfully submitted any campaign associated to this company will not be matched with any further campaign on any offer'
                }
                value={hasMultipleConnections}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <div className="mb-4">
                <FormInput
                  control={formData.brands}
                  inputKey="brands"
                  value={formData.brands.value}
                  inputChangedHandler={onInputChangeHandler}
                  dataTestAttribute="company-brands-input"
                />
              </div>
              {!!data && (
                <FormInput
                  control={formData.providers}
                  inputKey="providers"
                  value={formData.providers.value}
                  inputChangedHandler={onInputChangeHandler}
                  dataTestAttribute="company-providers-input"
                />
              )}
            </Col>
          </Row>
          {!isAdvertiser && !isAddCompanyModal ? (
            <div>
              <Row className="mb-4">
                <Col>
                  <ToggleSwitchInput
                    label="Set Terms & Conditions"
                    text="3rd party users (advertising agencies) must agree to these before getting access to your campaigns"
                    onCheck={(val) => {
                      setHasTermsAndConditions(val);
                      !formTouched && setFormTouched(true);
                    }}
                    value={hasTermsAndConditions}
                  />
                </Col>
              </Row>
              {hasTermsAndConditions && (
                <Row className="mb-4">
                  <Col>
                    <Input
                      elementType="textarea"
                      label="Terms and Conditions"
                      additionalClasses="formInput formInput--bordered formInput--smaller formInput-textarea--long"
                      required={hasTermsAndConditions}
                      value={termsAndConditions}
                      changed={(e) => {
                        if (e.target.value.length > 5000) {
                          return;
                        }
                        setTermsAndConditions(e.target.value);
                      }}
                      elementConfig={{
                        required: { hasTermsAndConditions },
                        placeholder: 'Enter your terms and conditions',
                      }}
                    />
                    {termsAndConditions.length}/5000
                  </Col>
                </Row>
              )}
            </div>
          ) : null}

          {isAddCompanyModal && (
            <Row className="mb-5">
              <Col>
                <FileUploadInput
                  buttonText={filePath ? 'Replace Logo' : 'Upload'}
                  label="File Upload"
                  withPreview
                  filePath={filePath}
                  onFileUrlChange={onSuccessUpload}
                  previewToRight
                  dataTestAttribute="image-companyLogo"
                />
              </Col>
            </Row>
          )}

          {!isAdvertiser && isAddCompanyModal ? (
            <Row>
              <Col className="col-auto">
                <Button
                  type="submit"
                  disabled={!isFormValid || isLoading || !formTouched}
                  additionalClasses="me-3"
                  dataTestAttribute="save-provider"
                >
                  Save
                </Button>
                {onCancelForm && (
                  <Button
                    type="button"
                    additionalClasses="btnDefault--light"
                    clicked={(event) => onCancel(event)}
                    dataTestAttribute="cancel-add-provider"
                  >
                    Cancel
                  </Button>
                )}
              </Col>
            </Row>
          ) : null}
        </form>
      </Col>
    </Row>
  );
};

export default CompanyForm;
