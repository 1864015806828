import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { defaults } from 'chart.js';
import TableChart from './TableChart/TableChart';
import PaywallService from '../../../../../api/Paywall';

import colors from './colors';
import ChartTypeSelect, {
  CHART_BARS,
  CHART_CSV,
  CHART_PIE,
  CHART_TABLE,
} from './ChartTypeSelect/ChartTypeSelect';
import './ReportWidget.scss';

defaults.font.size = 10;

const QualifierWidget = ({
  campaignId,
  questionId,
  questionName,
  filters,
  unit,
  countType,
  isAggregated,
}) => {
  const labelCharactersLimit = 20;
  const chartDataLimit = 10;

  const [formData, setFormData] = useState(null);
  const [chartType, setChartType] = useState(CHART_BARS);
  const [responsesCount, setResponsesCount] = useState(false);
  const [secondaryDimension] = useState(null);

  useEffect(() => {
    PaywallService.getQualifierReport(campaignId, questionId, {
      ...(secondaryDimension ? { compare: secondaryDimension } : null),
      filters: JSON.stringify(filters),
    }).then(
      (result) => {
        setFormData(result.data);
        setResponsesCount(
          result.data.values.reduce((sum, o) => {
            const value = o?.value ?? 0;
            return sum + value;
          }, 0),
        );
      },
      () => {},
    );
  }, [campaignId, questionId, secondaryDimension, filters]);

  const getChartData = useCallback(
    (type) => {
      let dataValues = [...formData.values];
      const endless = countType === 'endless';
      const isDateTypeData =
        questionName === 'pageVisits' || questionName === 'date';
      if (
        endless &&
        !isDateTypeData &&
        (type === CHART_BARS || type === CHART_PIE)
      ) {
        const sumByLabel = {};
        formData.values.forEach((record) => {
          if (!sumByLabel[record.label]) {
            sumByLabel[record.label] = 0;
          }
          sumByLabel[record.label] += record.value;
        });

        if (Object.keys(sumByLabel).length > 10) {
          const topKeys = Object.keys(sumByLabel)
            .sort((a, b) => {
              if (sumByLabel[a] === sumByLabel[b]) {
                return 0;
              }
              return sumByLabel[a] < sumByLabel[b] ? 1 : -1;
            })
            .slice(0, chartDataLimit)
            .reduce((acc, label) => {
              acc[label] = sumByLabel[label];
              return acc;
            }, {});

          const removedItems = dataValues
            .filter((v) => !topKeys[v.label])
            .reduce((acc, item) => {
              if (!acc[item.secondaryLabel]) {
                acc[item.secondaryLabel] = {
                  label: 'Other',
                  secondaryLabel: item.secondaryLabel,
                  value: 0,
                };
              }
              acc[item.secondaryLabel].value += item.value;
              return acc;
            }, {});

          dataValues = dataValues
            .filter((v) => !!topKeys[v.label])
            .sort((a, b) => {
              if (sumByLabel[a.label] === sumByLabel[b.label]) {
                return 0;
              }
              return sumByLabel[a.label] < sumByLabel[b.label] ? 1 : -1;
            });

          dataValues.push(...Object.values(removedItems));
        }
      }

      if (formData.dimensions.length === 1 || dataValues.length < 1) {
        return {
          labels: dataValues
            .map((v) => v.label || 'No answer given')
            .map((v) =>
              v.length > labelCharactersLimit && chartType === CHART_PIE
                ? `${v.substring(0, labelCharactersLimit)}…`
                : v,
            ),
          datasets: [
            {
              label: formData.captions?.[0],
              data: dataValues.map((v) => v.value),
              backgroundColor: type === CHART_BARS ? colors[0] : colors,
            },
          ],
        };
      }

      const labelIndices = [];

      dataValues.forEach((value) => {
        ['label', 'secondaryLabel'].forEach((dimKey, dimIdx) => {
          const labelValue = value[dimKey];
          if (!labelIndices[dimIdx]) {
            labelIndices[dimIdx] = {};
          }
          if (typeof labelIndices[dimIdx][labelValue] === 'undefined') {
            labelIndices[dimIdx][labelValue] = Object.keys(
              labelIndices[dimIdx],
            ).length;
          }
        });
      });

      const primaryLabels = Object.keys(labelIndices[0]);
      const secondaryLabels = Object.keys(labelIndices[1]);
      const datasets = secondaryLabels.map((secondaryLabel, idx) => ({
        label: `${
          type === CHART_BARS ? `${secondaryDimension}: ` : ''
        }${secondaryLabel}`,
        data: new Array(primaryLabels.length).fill(0),
        labels: primaryLabels.map(
          (formLabel) =>
            `${secondaryDimension}: ${secondaryLabel}` +
            ' | ' +
            `${questionName}: ${formLabel}`,
        ),
        backgroundColor: type === CHART_BARS ? colors[idx] : colors,
      }));
      dataValues.forEach((value) => {
        const primaryLabelIndex = labelIndices[0][value.label];
        const secondaryLabelIndex = labelIndices[1][value.secondaryLabel];
        const dataset = datasets[secondaryLabelIndex];
        dataset.data[primaryLabelIndex] = value.value;
      });
      datasets.sort((a, b) => {
        if (a.label.toLowerCase().match('no answer given')) {
          return 1;
        }
        if (b.label.toLowerCase().match('no answer given')) {
          return -1;
        }
        return a.label.localeCompare(b.label);
      });
      return {
        labels: primaryLabels.map((v) =>
          v.length > labelCharactersLimit && chartType === CHART_PIE
            ? `${v.substring(0, labelCharactersLimit)}…`
            : v,
        ),
        datasets,
      };
    },
    [formData, questionName, secondaryDimension, chartType, countType],
  );

  const generateCSV = useCallback(() => {
    const data = getChartData(CHART_CSV);
    const getCSVString = (str) => `"${str.replace(/"/g, '""')}"`;
    const csvLines = [
      [
        '\ufeff',
        ...data.datasets.map((dataset) => getCSVString(dataset.label)),
      ],
    ];
    data.labels.forEach((primaryLabel, dataIndex) => {
      csvLines.push([
        getCSVString(primaryLabel),
        ...data.datasets.map((dataset) => dataset.data[dataIndex]),
      ]);
    });
    const csvContent = csvLines.map((line) => line.join(';')).join('\n');

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(csvContent)}`,
    );
    element.setAttribute('download', `${questionName}.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, [getChartData, questionName]);

  const getEmptyStateOverlay = () => (
    <div
      className="emptyState text-muted text-center mt-4"
      data-test-id={`report-empty-state-${questionId}-${questionName}`}
    >
      {isAggregated
        ? 'No data available yet.'
        : 'There is no answer yet to this question.'}
    </div>
  );

  const getDurationFormat = (seconds) => {
    if (seconds > 3600) {
      return `${(seconds / 3600).toFixed(1)}h`;
    }
    if (seconds > 60) {
      return `${(seconds / 60).toFixed(1)}m`;
    }
    return `${seconds.toFixed(1)}s`;
  };

  const formatValue = useCallback(
    (value) => {
      if (unit === 'count') {
        if (value.toFixed) {
          return value.toFixed(1);
        }
        if (Number(value)) {
          return Number(value).toFixed(1);
        }
        return value;
      }
      if (unit === 'duration') {
        return getDurationFormat(value);
      }
      return value;
    },
    [unit],
  );

  const getLabel = useCallback(
    (item, data) => {
      const dataLabel =
        data.datasets[item.datasetIndex].labels?.[item.index] ||
        data.labels[item.index];

      const value = data.datasets[item.datasetIndex].data[item.index];
      return `${dataLabel}: ${formatValue(value)}`;
    },
    [formatValue],
  );

  const renderChart = (chart) => {
    switch (chart) {
      case CHART_BARS:
        return (
          <Bar
            key={secondaryDimension}
            options={{
              legend: {
                position: 'bottom',
              },
              tooltips: {
                callbacks: {
                  label: getLabel,
                },
              },
              scales: {
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      userCallback: formatValue,
                    },
                  },
                ],
                xAxes: [
                  {
                    stacked: true,
                    ticks: {
                      callback(v) {
                        return v.length > labelCharactersLimit
                          ? `${v.substring(0, labelCharactersLimit)}…`
                          : v;
                      },
                    },
                  },
                ],
              },
            }}
            data={getChartData(chart)}
          />
        );
      case CHART_PIE:
        return (
          <Pie
            key={secondaryDimension}
            options={{
              legend: {
                position: 'right',
              },
              tooltips: {
                callbacks: {
                  label: getLabel,
                },
              },
            }}
            data={getChartData(chart)}
          />
        );
      case CHART_TABLE:
        return (
          <TableChart data={getChartData(chart)} valueFormatter={formatValue} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="reportWidget">
      {formData && (
        <>
          <h4
            className="mb-1"
            data-test-id={`cpdc-report-question-${questionName}-title`}
          >
            {formData.dimensions[0] === 'date'
              ? 'Daily Connection Forms'
              : formData.captions?.[0] ?? questionName}
          </h4>
          {responsesCount === 0 ? (
            getEmptyStateOverlay()
          ) : (
            <>
              <ChartTypeSelect
                onTypeChange={(type) => {
                  if (type === CHART_CSV) {
                    generateCSV();
                  } else {
                    setChartType(type);
                  }
                }}
                selectedType={chartType}
              />
              <div className="reportCharts">{renderChart(chartType)}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default QualifierWidget;
