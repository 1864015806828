import React from 'react';
import './CampaignNotes.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

const CampaignNotes = ({
  campaign,
  campaignStatus,
  campaignStatistics,
  selectedVerificationMethod,
  verificationMethods,
  className,
}) => {
  const isCampaignChangedRecently =
    Date.now() - new Date(campaign.updatedAt) < 60 * 60 * 1000;

  return (
    <div className={className}>
      <div className="campaignNotes">
        <p className="campaignNotes_header">
          <FontAwesomeIcon
            icon={faInfoCircle}
            title="Move module"
            className="campaign-notes-info-icon"
          />
          Campaign Summary:
        </p>

        <ul>
          {!campaignStatus?.isRedirected && isCampaignChangedRecently ? (
            <li>
              <strong>
                You changed this campaign recently, but it might take up to 1
                hour for changes to be visible to everyone
              </strong>
            </li>
          ) : null}

          {campaign?.paywall?.enabled ? (
            <li>Accessible via Bounty Pay</li>
          ) : null}

          {!campaign?.paywall?.enabled ? (
            <li>
              {campaignStatus?.isRedirected ? (
                <>
                  The campaign will redirect to{' '}
                  <a href={campaign.fallbackUrl}>{campaign.fallbackUrl}</a>
                </>
              ) : (
                <>Anyone can visit this campaign</>
              )}
            </li>
          ) : null}

          {campaignStatus?.isRedirected && <li>{campaignStatus?.reason}</li>}

          {campaign.enabled ? (
            <li>
              Current campaign cost:
              <strong>
                {` ${formatCost(
                  campaignStatistics.cpdcs * campaign.cpdcCost,
                )} `}
              </strong>
              <div className="text-muted small">
                {getPerCostInfo(
                  campaign.cpdcCost,
                  selectedVerificationMethod,
                  verificationMethods,
                )}
              </div>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="campaignNotes">
        <p className="campaignNotes_header">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            title="Move module"
            className="campaign-notes-info-icon"
          />
          Campaign & Landing Page Builder Notes
        </p>

        <ol>
          <li>
            Use the Save button on top right of this page to keep any changes.
          </li>
          <li>
            To start or stop a campaign click the button next to the save button
            at top right of page
          </li>
          <li>
            The campaign and page will run until total budget is used, after
            which any visitor to the page will be redirected to the Fallback URL
            as specified.
          </li>
          <li>
            The multiple submissions button will allow any single user to
            register multiple times. Turning this off will ensure each user can
            only receive one reward.
          </li>
        </ol>
      </div>
    </div>
  );
};

function getPerCostInfo(
  cpdcCost,
  selectedVerificationMethod,
  verificationMethods,
) {
  const cost = formatCost(cpdcCost);
  const selectedVerificationMethodCost =
    verificationMethods.filter(
      (method) => method.name === selectedVerificationMethod,
    )[0]?.price ?? 0;

  if (selectedVerificationMethodCost === 0) {
    return `(${cost} per connection)`;
  }

  const formattedCost = formatCost(selectedVerificationMethodCost);

  return `(${cost} + ${formattedCost} verification cost per connection)`;
}

function formatCost(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

export default CampaignNotes;
