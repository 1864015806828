import instance from './Api';

const getQuestions = () => instance.get('/paywall/questions');

const addQuestions = (csvData, language) => {
  const formData = new FormData();
  const csvBob = new Blob([csvData], { type: 'text/csv' });
  formData.append('file', csvBob, 'paywallUpload.csv');
  return instance.post('/paywall/', formData, {
    headers: { 'x-language': language },
  });
};

const getQualifierReport = (campaignId, questionId, queryParams) => {
  let query = '';
  Object.keys(queryParams).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${queryParams[filter]}`;
  });
  return instance.get(
    `/paywall/campaign/${campaignId}/report/${questionId}${query}`,
  );
};

const getQualifierFieldsList = (campaignId) =>
  instance.get(`/paywall/campaigns/${campaignId}/qualifier-fields`);

export default {
  getQuestions,
  addQuestions,
  getQualifierReport,
  getQualifierFieldsList,
};
