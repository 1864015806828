import React, { useContext, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  CampaignDataContext,
  CampaignCallbackContext,
} from '../../CampaignContext';
import { formatDate } from '../../../../shared/dateTime';
import {
  formatToValid,
  validateForm,
} from '../../../../shared/Form/validation';

import FormInput from '../../../../components/UI/FormInput/FormInput';

const fallbackCampaignHiddenFields = ['fallbackUrl', 'startDate', 'endDate'];
const globalFallbackLockedFields = ['company'];

const FormInputs = ({
  controls,
  options,
  isPaywallEnabled,
  isFallbackCampaign,
  isGlobalFallback,
}) => {
  const onFormUpdated = useContext(CampaignCallbackContext);
  const values = useContext(CampaignDataContext);

  const validateInput = (name, value) => {
    const { data, errors, isValid } = values;
    let newErrors = errors;

    const errorMessage = validateForm(
      name,
      value,
      controls[name].validation,
      null,
      data,
    );

    if ((errors && errors[name]) || errorMessage) {
      newErrors = {
        ...errors,
        [name]: errorMessage,
      };
    }

    let isFormValid = isValid;

    if (isPaywallEnabled && newErrors?.fallbackUrl) {
      delete newErrors.fallbackUrl;
    }

    if (newErrors) {
      Object.keys(newErrors).forEach((el) => {
        isFormValid = !newErrors[el];
      });
    }

    return { isFormValid, newErrors };
  };

  const onFormChangeHandler = (event, name, action) => {
    let value;
    const isUserUpdate = action !== null;

    if (event && event.target) {
      value = event.target.value;
    } else if (event && event.value) {
      value = event.value;
    } else if (event) {
      value = event;
    } else if (event === null) {
      value = '';
    } else {
      value = action !== 'input-change' ? values.data[name] : '';
    }

    const { data, touched } = values;
    const { newErrors, isFormValid } = validateInput(name, value);

    if (isUserUpdate) {
      touched[name] = true;
    }

    const newData = { ...data, ...{ [name]: formatToValid(name, value) } };

    onFormUpdated(
      {
        data: newData,
        errors: newErrors,
        touched,
        isValid: isFormValid,
      },
      isUserUpdate,
    );
  };

  // set initial errors
  useEffect(() => {
    let formIsValid = values.isValid;
    const formTouched = values.touched;
    const formErrors = values.errors;
    // eslint-disable-next-line array-callback-return
    Object.keys(controls).map((e) => {
      if (!controls[e]?.validation?.required) return;
      const { newErrors, isFormValid } = validateInput(e, values.data[e]);
      if (!isFormValid) formIsValid = false;
      if (newErrors && newErrors[e]) formErrors[e] = newErrors[e];
      // show error if input has a value
      if (newErrors && newErrors[e] && values.data[e]) {
        formTouched[e] = true;
      }
    });

    onFormUpdated(
      {
        data: values.data,
        errors: formErrors,
        touched: values.touched,
        isValid: formIsValid,
      },
      null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPaywallEnabled && controls.fallbackUrl && values.data.fallbackUrl) {
      onFormChangeHandler('', 'fallbackUrl');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaywallEnabled]);

  const getInputs = () =>
    Object.keys(controls).map((e) => {
      const control = controls[e];
      const { errors } = values;

      if (isFallbackCampaign && fallbackCampaignHiddenFields.includes(e)) {
        return null;
      }

      let val = values.data[e];
      if (e === 'endDate' || e === 'startDate') {
        val = formatDate(val, options.dateFormat);
      }

      control.error = null;
      if (errors && errors[e]) {
        control.error = errors[e];
      }

      control.touched = values.touched[e] ?? false;

      if (e === 'budget') {
        control.elementConfig.min = Math.ceil(options.usedBudget);
      }

      if (e === 'startDate') {
        // eslint-disable-next-line no-param-reassign
        controls.endDate.elementConfig.min = val;
      }
      if (e === 'endDate') {
        // eslint-disable-next-line no-param-reassign
        controls.startDate.elementConfig.max = val;
      }

      if (isGlobalFallback && globalFallbackLockedFields.includes(e)) {
        control.elementConfig.disabled = true;
      }

      if (isPaywallEnabled && e === 'fallbackUrl') {
        return null;
      }

      return (
        <Col key={`${values.data.id}-formInput-${e}`}>
          <FormInput
            className="campaignFormInputs"
            control={control}
            inputChangedHandler={onFormChangeHandler}
            inputKey={e}
            value={val}
            dataTestAttribute="campaign-edit-page-row"
          />
        </Col>
      );
    });

  return <Row>{getInputs()}</Row>;
};

export default FormInputs;
