import React, { useContext } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { PostQuestionsContext } from '../PostQuestions.context';
import styles from './PostQuestion.module.scss';

const answersPlaceholderStringLiteral = `Option 1
Option 2
Option 3
...`;

const PostQuestion = ({
  categoryId,
  categoryIdx,
  questionIdx,
  question,
  handleAction,
  removeItem,
  createOnChangeHandler,
}) => {
  const { langKey, errors } = useContext(PostQuestionsContext);
  const isSelect = question.fieldType === 'select';

  const title = isSelect
    ? 'Question Multiple Choice'
    : 'Question Free-Text Field';

  const updateSelectValueHandler = createOnChangeHandler(
    categoryIdx,
    questionIdx,
    'values',
  );

  const disabled = removeItem.key === `${categoryId}-${question.questionId}`;

  const transformSelectValuesToString = (value) => value.join('\n');
  const transformValueToArray = (ev) =>
    (ev?.target?.value ?? ev?.value ?? ev).split('\n');

  return (
    <div
      className={`${styles.postQuestion} ${
        disabled ? styles.postQuestionDisabled : ''
      } mb-4`}
    >
      {/* Header */}
      <h5>
        {title}{' '}
        <span className="small text-muted">
          {question.questionId ? '' : '(new)'}
        </span>
      </h5>

      {/* Form */}
      <Row>
        <Col lg={6}>
          <div className="mt-3">
            <Input
              elementType="text"
              label="Question"
              required
              value={question.questionLabel?.[langKey] ?? ''}
              touched
              additionalClasses={`formInput formInput--bordered ${
                errors[
                  `categories[${categoryIdx}].questions[${questionIdx}].questionLabel`
                ]
                  ? styles.formInputInvalid
                  : ''
              }`}
              changed={createOnChangeHandler(
                categoryIdx,
                questionIdx,
                'questionLabel',
              )}
              error={
                errors[
                  `categories[${categoryIdx}].questions[${questionIdx}].questionLabel`
                ] ?? ''
              }
            />
          </div>
          <div className="mt-3">
            <Input
              elementType="text"
              label="Response placeholder"
              value={question.placeholder?.[langKey] ?? ''}
              additionalClasses={`formInput formInput--bordered ${
                errors[
                  `categories[${categoryIdx}].questions[${questionIdx}].placeholder`
                ]
                  ? styles.formInputInvalid
                  : ''
              }`}
              changed={createOnChangeHandler(
                categoryIdx,
                questionIdx,
                'placeholder',
              )}
              elementConfig={{
                description: `Text that will be visible before ${
                  isSelect ? 'any option is selected' : 'an answer is entered'
                }`,
              }}
              error={
                errors[
                  `categories[${categoryIdx}].questions[${questionIdx}].placeholder`
                ] ?? ''
              }
            />
          </div>
        </Col>
        <Col lg={6}>
          {isSelect && (
            <div className="mt-3">
              <Input
                elementType="textarea"
                label="Answers"
                required
                touched
                value={transformSelectValuesToString(
                  question.values?.[langKey] ?? '',
                )}
                additionalClasses={`formInput formInput--bordered ${
                  errors[
                    `categories[${categoryIdx}].questions[${questionIdx}].values`
                  ]
                    ? styles.formInputInvalid
                    : ''
                } ${styles.postQuestionAnswers}`}
                changed={(ev) =>
                  updateSelectValueHandler(transformValueToArray(ev))
                }
                elementConfig={{
                  placeholder: answersPlaceholderStringLiteral,
                  description: 'Every answer in a new line',
                }}
                error={
                  errors[
                    `categories[${categoryIdx}].questions[${questionIdx}].values`
                  ] ?? ''
                }
              />
            </div>
          )}
        </Col>
        <Col lg={6} className="mt-3">
          <div>
            <Input
              elementType="boolean"
              label="Require Response"
              value={question.required}
              additionalLabelClasses="small"
              changed={createOnChangeHandler(
                categoryIdx,
                questionIdx,
                'required',
                false,
              )}
            />
          </div>
          {isSelect && (
            <div>
              <Input
                elementType="boolean"
                label="Allow multiple answers to be selected"
                value={question.multiple}
                additionalLabelClasses="small"
                changed={createOnChangeHandler(
                  categoryIdx,
                  questionIdx,
                  'multiple',
                  false,
                )}
              />
            </div>
          )}
        </Col>

        {/* Footer */}
        <Col lg={6} className="d-flex flex-fill flex-wrap align-items-end">
          <Button
            additionalClasses="btnDefault btnDefault--medium btnDefault--red my-2 ms-auto me-0"
            dataTestAttribute="button-category-remove"
            clicked={(ev) =>
              handleAction(ev, 'remove-question', categoryIdx, questionIdx)
            }
            isLoading={disabled}
            disabled={removeItem.inProgress}
          >
            Remove Item
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PostQuestion;
