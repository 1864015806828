import React, { useEffect, useRef, useState } from 'react';
import { Routes } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getAuth, multiFactor } from 'firebase/auth';
import Modal from './components/UI/Modal/Modal';
import TermsAndConditionService from './api/TermsAndConditions';

import * as actions from './store/actions/index';
import MainRouter from './router/MainRouter';

import { useSockets } from './App.sockets';
import { useSessionExpiration } from './App.session';

import UserService from './api/Users';

import '@inovua/reactdatagrid-community/index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

let initTermsAndConditions = false;

function handleDispatchUser(user, dispatch) {
  if (!user) {
    initTermsAndConditions = false;
    dispatch(actions.authState(user));
    return false;
  }

  const multiFactorUser = multiFactor(this.currentUser);
  return this.currentUser.getIdTokenResult().then((idTokenResult) => {
    const { role } = idTokenResult.claims;
    /**
     * All users without MFA enabled
     * will be forced to set MFA on any auth event
     */
    if (!multiFactorUser.enrolledFactors.length) {
      dispatch(actions.setMultiFactor(true));
    }

    dispatch(actions.claimsState({ role }));
    dispatch(actions.authState(user));
  });
}

const App = () => {
  const appEl = useRef(null);

  useSockets();
  useSessionExpiration(appEl);

  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [termsAndConditionsDisplayOrder, setTermsAndConditionsDisplayOrder] =
    useState(0);
  const initialLoadRef = useRef(true);
  const firebaseAuth = getAuth();
  const dispatch = useDispatch();
  const {
    setPassword,
    setResetPassword,
    setMultiFactor,
    isAuthenticated,
    isAdmin,
    isBountyAdmin,
    isCompanyAdmin,
    isCompanyUser,
    isAdvertiser,
    isAdvertisingPartner,
    isProviderPartner,
    loading,
    userId,
  } = useSelector((state) => state.auth);

  const increaseTermsAndConditionsDisplayOrder = () => {
    setTermsAndConditionsDisplayOrder((state) => {
      if (state === termsAndConditions.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const closeTermsAndConditionModal = (index) => {
    setTermsAndConditions((state) => {
      const newState = [...state];
      newState[index].showModal = false;
      return newState;
    });
    termsAndConditions[index].showModal = false;
  };

  const acceptTermsAndConditions = async (index, modalData) => {
    closeTermsAndConditionModal(index);
    await TermsAndConditionService.acceptTermsAndConditions({
      companyId: modalData.companyId,
    });
    increaseTermsAndConditionsDisplayOrder();
    dispatch(actions.termsAndConditionsState(true, true));
  };

  const rejectTermsAndConditions = async (index, modalData) => {
    closeTermsAndConditionModal(index);
    await TermsAndConditionService.rejectTermsAndConditions({
      companyId: modalData.companyId,
    });
    increaseTermsAndConditionsDisplayOrder();
    dispatch(actions.termsAndConditionsState(true, true));
  };

  const closeTermsAndConditionsModal = (index) => {
    closeTermsAndConditionModal(index);
    increaseTermsAndConditionsDisplayOrder();
    dispatch(actions.termsAndConditionsState(true, true));
  };

  const getPendingTermsAndConditions = async () => {
    const response = await TermsAndConditionService.pendingTermsAndConditions(
      firebaseAuth.currentUser.uid,
    );
    return response;
  };

  const setTermsAndConditionsModal = async (companyId = null) => {
    getPendingTermsAndConditions().then(({ data }) => {
      const modalData = data.map((term, index) => {
        setTermsAndConditionsDisplayOrder(
          term.companyId === companyId ? index : 0,
        );
        return {
          ...term,
          showModal:
            // eslint-disable-next-line no-unneeded-ternary
            companyId === null || term.companyId === companyId ? true : false,
        };
      });
      setTermsAndConditions(modalData);
    });
  };

  const getUserData = async (id) => {
    const res = await UserService.get(id);
    if (res?.data?.user) {
      dispatch(actions.setProfileDetails(res.data.user));
    }
  };

  useEffect(() => {
    if (isAdvertiser && !initTermsAndConditions) {
      initTermsAndConditions = true;
      setTermsAndConditionsModal();
    }

    /**
     *  TODO: WebSocket Implementation with Socket IO and Nest.js. Commented out for now. Kept here for potential use future implementation.
     *  Initially was planed to be used with the Terms and Conditions implementation.
     *  We decided to use different approach due to the complexity of the implementation with web-sockets and cluster based architecture on AWS.
     *  For future implementation, we might need to use Redis to solve the cluster based architecture issue.
     */
    // if (!socketOnMessage && isAdvertiser) {
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   socketOnMessage = socket.on(
    //     firebaseAuth.currentUser.uid,
    //     (companyId, isModal) => {
    //       if (isModal) {
    //         setTermsAndConditionsModal(companyId);
    //       }
    //       if (!isModal) {
    //         dispatch(actions.termsAndConditionsState(true, false));
    //       }
    //     },
    //   );
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdvertiser]);

  useEffect(() => {
    if (!initialLoadRef.current) return;
    firebaseAuth.onAuthStateChanged((user) =>
      handleDispatchUser.call(firebaseAuth, user, dispatch),
    );
    initialLoadRef.current = false;
  }, [firebaseAuth, dispatch]);

  useEffect(() => {
    if (userId) getUserData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const routerProps = {
    setPassword,
    setResetPassword,
    setMultiFactor,
    isAuthenticated,
    isAdmin,
    isCompanyAdmin,
    isAdvertiser,
    isBountyAdmin,
    isCompanyUser,
    isAdvertisingPartner,
    isProviderPartner,
  };

  return (
    <div ref={appEl}>
      {/* App */}
      {loading ? <Routes /> : <MainRouter {...routerProps} />}

      {/* Terms -- TODO: Should be moved to a component */}
      {termsAndConditions.length &&
      termsAndConditions[termsAndConditionsDisplayOrder].showModal ? (
        <Modal
          termsAndConditions
          key={termsAndConditions[termsAndConditionsDisplayOrder].companyName}
          title="Terms and Conditions"
          body={
            `Please accept the Terms and Conditions by ${termsAndConditions[termsAndConditionsDisplayOrder].companyName} to have access to their campaigns \n\n` +
            termsAndConditions[termsAndConditionsDisplayOrder]
              .termsAndConditions
          }
          cancel="No"
          save="Yes"
          handleSave={() =>
            acceptTermsAndConditions(
              termsAndConditionsDisplayOrder,
              termsAndConditions[termsAndConditionsDisplayOrder],
            )
          }
          handleClose={(e) => {
            if (e) {
              rejectTermsAndConditions(
                termsAndConditionsDisplayOrder,
                termsAndConditions[termsAndConditionsDisplayOrder],
              );
            } else {
              closeTermsAndConditionsModal(
                termsAndConditionsDisplayOrder,
                termsAndConditions[termsAndConditionsDisplayOrder],
              );
            }
          }}
          show
        />
      ) : null}
    </div>
  );
};

export default App;
