import React from 'react';

import FormikInput from '../../../../components/UI/FormikInput/FormikInput';
import Modal from '../../../../components/UI/Modal/Modal';

import { useGlobalTranslationsForm } from './GlobalTranslationsForm.hooks';

import styles from './GlobalTranslationsForm.module.scss';

const GlobalTranslationsForm = ({
  initialData = {},
  setShowForm,
  onSuccess,
  showForm,
  setToast,
}) => {
  const [getters, actions] = useGlobalTranslationsForm({
    initialData,
    setShowForm,
    onSuccess,
    setToast,
  });

  const { isLoading, formik } = getters;

  const { handleClose, submitForm } = actions;

  return (
    <Modal
      title={`Update Translation ${initialData.language}`}
      handleClose={handleClose}
      handleSave={submitForm}
      size="xl"
      show={showForm}
      cancel="Cancel"
      save="Save"
      loading={isLoading}
    >
      <form onSubmit={formik.handleSubmit}>
        {initialData.originalContents &&
          initialData.originalContents.length &&
          initialData.originalContents.map((field) => {
            return (
              <div className="position-relative" key={field.key}>
                <FormikInput field={field} formik={formik} />
              </div>
            );
          })}
      </form>
    </Modal>
  );
};

export default GlobalTranslationsForm;
