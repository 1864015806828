import React from 'react';
import {
  FILTER_TYPE_EQUALS,
  FILTER_TYPE_RANGE,
  FILTER_TYPE_STRING,
} from '../FilterInput/FilterInput';
import './SelectedFilters.scss';

const SelectedFilters = ({ selectedFilters }) => {
  const upperCase = (name) => name.charAt(0).toUpperCase() + name.slice(1);
  const isType = (filter, filterType) => filter.type === filterType;

  const renderEqualsValue = (filter) => {
    if (!filter?.value?.values) {
      return '';
    }
    if (filter.value.values.length <= 3) {
      return filter.value.values.join(', ');
    }
    const trimmedArray = filter.value.values.slice(0, 2);
    trimmedArray.push(`${filter.value.values.length - 2} more...`);
    return trimmedArray.join(', ');
  };

  const renderRangeValue = (filter) => {
    const minVal = filter?.value?.from;
    const maxVal = filter?.value?.to;
    const isNotEmpty = (val) => val !== undefined && val !== '';

    if (isNotEmpty(minVal)) {
      if (isNotEmpty(maxVal)) {
        return `${minVal} - ${maxVal}`;
      }
      return `≥${minVal}`;
    }
    if (isNotEmpty(maxVal)) {
      return `≤${maxVal}`;
    }
    return '';
  };

  return (
    <div className="selectedFilters">
      {Object.keys(selectedFilters).map((filterName) => (
        <span key={filterName} className="badge badge-dark">
          {`${upperCase(filterName)}: `}
          <span className="badge-value">
            {isType(selectedFilters?.[filterName], FILTER_TYPE_EQUALS) &&
              renderEqualsValue(selectedFilters[filterName])}

            {isType(selectedFilters?.[filterName], FILTER_TYPE_RANGE) &&
              renderRangeValue(selectedFilters[filterName])}

            {isType(selectedFilters?.[filterName], FILTER_TYPE_STRING) &&
              selectedFilters[filterName].value}
          </span>
        </span>
      ))}
    </div>
  );
};

export default SelectedFilters;
