import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';

import inputChangedHandler from '../../../../shared/inputChangeHandler';

import { convertInitialData } from '../../../../shared/vidioSectionData';

import './VidioSection.scss';

import initControls from './controls';
import Cpdcs from '../../../../api/Cpdcs';

const VidioSection = ({ config, dataChanged, onInputChange }) => {
  const [terms, setTerms] = useState({ terms: {} });
  const [inputs, setInputs] = useState({ inputs: {} });
  const [textFields, setTextFields] = useState({ textFields: {} });
  const [reservedNames, setReservedNames] = useState([]);

  useEffect(() => {
    if (config) {
      const transformed = convertInitialData(
        [...config],
        initControls.formInput,
        initControls.term,
        initControls.textField,
      );

      if (transformed) {
        const { newInputs, newTerms, newTextFields } = transformed;
        setInputs((prevState) => {
          const prevData = dataChanged ? prevState.inputs : {};
          return {
            inputs: {
              ...prevData,
              ...newInputs?.data,
            },
            formIsValid: newInputs?.formIsValid,
          };
        });

        setTerms((prevState) => {
          const prevData = dataChanged ? prevState.terms : {};
          return {
            terms: {
              ...prevData,
              ...newTerms?.data,
            },
            formIsValid: newTerms?.formIsValid,
          };
        });

        setTextFields((prevState) => {
          const prevData = dataChanged ? prevState.textFields : {};
          return {
            textFields: {
              ...prevData,
              ...newTextFields?.data,
            },
            formIsValid: newTextFields?.formIsValid,
          };
        });
      }
    }
  }, [config, dataChanged]);

  useEffect(() => {
    Cpdcs.getMandatoryFieldsList(true)
      .then((result) => {
        if (result?.data) {
          setReservedNames(
            result.data.fields.map((field) => field.toLowerCase()),
          );
        }
      })
      .catch((err) => {
        setReservedNames([]);
        // eslint-disable-next-line no-console
        console.error('Fetch mandatory fields error', err?.message);
      });
  }, []);

  const onAddTermHandler = (event) => {
    event.preventDefault();
    const arrLength = Object.keys(terms.terms).length;

    const termConf = initControls.term;
    let keyIndex = arrLength;
    if (arrLength > 0) {
      const lastKey = Object.keys(terms.terms).pop();
      const lastKeyNr = lastKey.split('_');
      // eslint-disable-next-line radix
      keyIndex = parseInt(lastKeyNr[1]) + 1;
    }

    setTerms((prevState) => {
      const newTerms = {
        terms: {
          ...prevState.terms,
          [`term_${keyIndex}`]: termConf,
        },
      };

      onInputChange(event, 'terms', newTerms);
      return newTerms;
    });
  };

  const onDeleteTermHandler = (event, idKey) => {
    event.preventDefault();

    const currTerms = terms.terms;
    if (currTerms && currTerms[idKey]) delete currTerms[idKey];

    const newTerms = {
      terms: currTerms,
      formIsValid: true,
    };
    onInputChange(event, 'terms', newTerms);
  };

  const onLocalTermChange = (event, fieldId) => {
    const { value } = event.currentTarget;

    const termsData = terms.terms;
    const key = fieldId;
    const elementToUpdate = termsData[key];

    if (elementToUpdate) {
      const fieldToUpdate = elementToUpdate;

      const [updatedControls, formIsValid] = inputChangedHandler(
        value,
        { controls: termsData },
        key,
      );

      if (fieldToUpdate) {
        const newTerms = {
          terms: {
            ...terms.terms,
            ...updatedControls,
          },
          formIsValid,
        };
        setTerms(newTerms);
        onInputChange(event, 'terms', newTerms);
        return updatedControls;
      }
    }
    return null;
  };

  const renderTerms = () => {
    const termsArr = Object.keys(terms.terms);
    if (!termsArr || termsArr.length === 0) return null;
    const termD = terms.terms;

    return termsArr.map((el) => {
      const termEl = termD[el];
      return (
        <div key={`${el}`}>
          <Input
            additionalClasses="formInput formInput--bordered"
            keyName={`${el}`}
            elementType={termEl.elementType}
            elementConfig={termEl.elementConfig}
            label={termEl.label}
            value={termEl.value}
            invalid={!termEl.valid}
            shouldValidate={termEl.validation}
            touched={termEl.touched}
            changed={(event) => onLocalTermChange(event, `${el}`)}
            error={termEl.error}
            required={termEl?.validation?.required}
          />
          <div className="text-right">
            <button
              className="btnDefault btnDefault--small btnDefault--red mb-3 me-0"
              onClick={(event) => onDeleteTermHandler(event, `${el}`)}
            >
              remove item
            </button>
          </div>
        </div>
      );
    });
  };

  const onAddInputHandler = (event) => {
    event.preventDefault();
    const arrLength = Object.keys(inputs.inputs).length;

    const inputConf = initControls.formInput;
    let keyIndex = arrLength;
    if (arrLength > 0) {
      const lastKey = Object.keys(inputs.inputs).pop();
      const lastKeyNr = lastKey.split('_');
      // eslint-disable-next-line radix
      keyIndex = parseInt(lastKeyNr[1]) + 1;
    }
    const newInputs = {
      inputs: {
        ...inputs.inputs,
        [`input_${keyIndex}`]: inputConf,
      },
      formIsValid: false,
    };
    setInputs(newInputs);
    onInputChange(event, 'inputs', newInputs);
  };

  const onDeleteInputHandler = (event, idKey) => {
    event.preventDefault();

    const currInputs = inputs.inputs;
    if (currInputs && currInputs[idKey]) delete currInputs[idKey];

    const newInputs = {
      inputs: currInputs,
      formIsValid: true,
    };
    onInputChange(event, 'inputs', newInputs);
  };

  const getFormInputNames = (allInputsData, currentInputKey) => {
    const otherInputs = { ...allInputsData };
    delete otherInputs[currentInputKey];
    const usedNames = Object.values(otherInputs).map((el) => el.name.value);
    return [...usedNames, ...reservedNames];
  };

  const onLocalInputChange = (event, fieldId, isCheckbox = false) => {
    const { value, checked } = event.currentTarget;
    const val = isCheckbox ? checked : value;

    const inputsData = inputs.inputs;
    let key = fieldId.split('_');
    key.pop();
    key = key.join('_');
    const field = fieldId.split('_').reverse();
    const namesToValidation = getFormInputNames(inputsData, key);

    const elementToUpdate = inputsData[key][field[0]];
    if (elementToUpdate) {
      const [updatedControls, formIsValid] = inputChangedHandler(
        val,
        {
          controls: {
            ...inputsData[key],
            name: {
              ...inputsData[key].name,
              validation: {
                ...inputsData[key].name.validation,
                unique: namesToValidation,
                uniqueCaseInsensitive: true,
              },
            },
          },
        },
        field[0],
      );

      if (updatedControls) {
        const newInputs = {
          inputs: {
            ...inputsData,
            [key]: updatedControls,
          },
          formIsValid,
        };
        setInputs(newInputs);
        onInputChange(event, 'inputs', newInputs);
        return updatedControls;
      }
    }
    return null;
  };

  const renderInputs = () => {
    const inputsArr = Object.keys(inputs.inputs);
    if (!inputsArr || inputsArr.length === 0) return null;
    const inputD = inputs.inputs;

    return (
      <>
        <span key="formInputsLabel">Form question</span>
        {inputsArr.map((el) => {
          const idKey = el;

          const fields = Object.keys(inputD[el]).map((e) => {
            const inputEl = inputD[el][e];

            const inputField = inputEl;
            if (e === 'name' && inputField.value) {
              inputField.value = inputField.value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase();
            }
            if (e === 'answers' && inputField?.value?.length > 0) {
              if (Array.isArray(inputField.value)) {
                inputField.value =
                  inputField.value && inputField.value.join('\n');
              }
            }
            let field = (
              <Input
                additionalClasses="formInput formInput--bordered"
                keyName={`${idKey}_${e}`}
                key={`${idKey}_${e}`}
                elementType={inputField.elementType}
                elementConfig={inputField.elementConfig}
                label={inputField.label}
                value={inputField.value}
                invalid={!inputField.valid}
                shouldValidate={inputField.validation}
                touched={inputField.touched}
                changed={(event) => onLocalInputChange(event, `${idKey}_${e}`)}
                error={inputField.error}
                required={inputField?.validation?.required}
              />
            );

            if (inputField.elementType === 'checkbox') {
              const isChecked = inputEl.value || false;
              field = (
                <Form.Group
                  key={`${idKey}_${e}`}
                  controlId={`formBasicCheckbox_${idKey}_${e}`}
                >
                  <Form.Check
                    type="checkbox"
                    label={inputField.label}
                    checked={isChecked}
                    onChange={(event) =>
                      onLocalInputChange(event, `${idKey}_${e}`, true)
                    }
                  />
                </Form.Group>
              );
            }
            return field;
          });

          return (
            <div key={`${idKey}`} className="vidioInputGroup">
              {fields}
              <div className="text-right">
                <button
                  className="btnDefault btnDefault--small btnDefault--red mt-3 me-0"
                  onClick={(event) => onDeleteInputHandler(event, idKey)}
                >
                  remove item
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const onAddTextFieldHandler = (event) => {
    event.preventDefault();
    const arrLength = Object.keys(textFields.textFields).length;
    const textFieldConfig = initControls.textField;
    let keyIndex = 0;

    if (arrLength > 0) {
      keyIndex =
        parseInt(Object.keys(textFields.textFields).pop().split('_')[1], 10) +
        1;
    }

    const newTextFields = {
      textFields: {
        ...textFields.textFields,
        [`textFields_${keyIndex}`]: textFieldConfig,
      },
      formIsValid: false,
    };
    setTextFields(newTextFields);
    onInputChange(event, 'textFields', newTextFields);
  };

  const onDeleteTextFieldHandler = (event, idKey) => {
    event.preventDefault();

    const currInputs = textFields.textFields;
    if (currInputs && currInputs[idKey]) delete currInputs[idKey];

    const newTextFields = {
      textFields: currInputs,
      formIsValid: true,
    };
    onInputChange(event, 'textFields', newTextFields);
  };

  const onLocalTextFieldChange = (event, fieldId, isCheckbox = false) => {
    const { value, checked } = event.currentTarget;
    const val = isCheckbox ? checked : value;

    const inputsData = textFields.textFields;
    let key = fieldId.split('_');
    key.pop();
    key = key.join('_');
    const field = fieldId.split('_').reverse();
    const namesToValidation = getFormInputNames(inputsData, key);

    const elementToUpdate = inputsData[key][field[0]];
    if (elementToUpdate) {
      const [updatedControls, formIsValid] = inputChangedHandler(
        val,
        {
          controls: {
            ...inputsData[key],
            name: {
              ...inputsData[key].name,
              validation: {
                ...inputsData[key].name.validation,
                unique: namesToValidation,
                uniqueCaseInsensitive: true,
              },
            },
          },
        },
        field[0],
      );

      if (updatedControls) {
        const newTextFields = {
          textFields: {
            ...inputsData,
            [key]: updatedControls,
          },
          formIsValid,
        };
        setTextFields(newTextFields);
        onInputChange(event, 'textFields', newTextFields);
        return updatedControls;
      }
    }
    return null;
  };

  const renderTextFields = () => {
    const textFieldsArr = Object.keys(textFields.textFields);
    if (!textFieldsArr || textFieldsArr.length === 0) return null;
    const inputD = textFields.textFields;

    return (
      <>
        <span key="formTextFieldsLabel">Form text field</span>
        {textFieldsArr.map((el) => {
          const idKey = el;

          const fields = Object.keys(inputD[el]).map((e) => {
            const inputEl = inputD[el][e];

            const inputField = inputEl;
            if (e === 'name' && inputField.value) {
              inputField.value = inputField.value
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase();
            }
            let field = (
              <Input
                additionalClasses="formInput formInput--bordered"
                keyName={`${idKey}_${e}`}
                key={`${idKey}_${e}`}
                elementType={inputField.elementType}
                elementConfig={inputField.elementConfig}
                label={inputField.label}
                value={inputField.value}
                invalid={!inputField.valid}
                shouldValidate={inputField.validation}
                touched={inputField.touched}
                changed={(event) =>
                  onLocalTextFieldChange(event, `${idKey}_${e}`)
                }
                error={inputField.error}
                required={inputField?.validation?.required}
              />
            );

            if (inputField.elementType === 'checkbox') {
              const isChecked = inputEl.value || false;
              field = (
                <Form.Group
                  key={`${idKey}_${e}`}
                  controlId={`formBasicCheckbox_${idKey}_${e}`}
                >
                  <Form.Check
                    type="checkbox"
                    label={inputField.label}
                    checked={isChecked}
                    onChange={(event) =>
                      onLocalTextFieldChange(event, `${idKey}_${e}`, true)
                    }
                  />
                </Form.Group>
              );
            }
            return field;
          });

          return (
            <div key={`${idKey}`} className="vidioInputGroup">
              {fields}
              <div className="text-right">
                <button
                  className="btnDefault btnDefault--small btnDefault--red mt-3 me-0"
                  onClick={(event) => onDeleteTextFieldHandler(event, idKey)}
                >
                  remove item
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {renderTerms()}

      {renderInputs()}

      {renderTextFields()}
      <div className="btnContainer">
        <Button
          clicked={onAddTermHandler}
          additionalClasses="btn mt-4"
          dataTestAttribute="section-settings-vidio-term"
        >
          Add term
        </Button>
        <Button
          clicked={onAddInputHandler}
          additionalClasses="btn mt-4"
          dataTestAttribute="section-settings-vidio-input"
        >
          Add question
        </Button>
        <Button
          clicked={onAddTextFieldHandler}
          additionalClasses="btn mt-4"
          dataTestAttribute="section-settings-vidio-input"
        >
          Add text field
        </Button>
      </div>
    </>
  );
};

export default VidioSection;
