import { useFormik } from 'formik';
import { object, string, number, array } from 'yup';
import { urlPattern, emailPattern } from '../../../shared/Form/validation';
import CompanyService from '../../../api/Companies';

const getSelectedIds = (e) => (typeof e === 'object' ? e.value : e);
export const useCompanyBrandAndDemography = (
  company,
  companyId,
  setAccordionsFormSaveStatus,
) => {
  const validationSchema = object({
    companyPrimaryColor: string(),
    countries: array()
      .of(string())
      .max(5, 'Must be up to 5 countries only')
      .nullable(),
    website: string().matches(urlPattern, 'Must be valid url').nullable(),
    contactEmail: string()
      .matches(emailPattern, 'Must be valid email address')
      .nullable(),
    audienceSizeUpTo: number()
      .typeError('Must be a number')
      .integer('Must be an integer')
      .min(0, 'Value must be greater than or equal to 0')
      .max(
        9999999999,
        'Value must be less than or equal to 9999999999(10 digits)',
      )
      .nullable(),
    audienceGenderMale: number()
      .integer('Must be an integer')
      .min(0, 'Value must be greater than or equal to 0')
      .max(100, 'Distributions should add up to maximum 100')
      .nullable(),
    audienceGenderFemale: number()
      .integer('Must be an integer')
      .min(0, 'Value must be greater than or equal to 0')
      .max(100, 'Value must be less than or equal 100')
      .nullable(),

    audienceTechRating: number()
      .typeError('Must be a number')
      .integer('Must be an integer')
      .min(0, 'Value must be greater than or equal to 0')
      .max(10, 'Value must be less than or equal 10')
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      companyPrimaryColor: company.companyPrimaryColor ?? '#ffffff',
      countries: company.countries ?? null,
      website: company.website ?? null,
      contactPerson: company.contactPerson ?? null,
      contactEmail: company.contactEmail ?? null,
      contactPhone: company.contactPhone ?? null,
      address: company.address ?? null,
      audienceSizeUpTo: company.audienceSizeUpTo ?? null,
      audienceGenderMale: company.audienceGenderMale ?? null,
      audienceGenderFemale: company.audienceGenderFemale ?? null,
      audienceTechRating: company.audienceTechRating ?? null,
      companyLogo: company.companyLogo ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await CompanyService.update(companyId, {
          ...company,
          ...values,
          filePath: values.companyLogo,
          newBrands: [], // this is handled in general profile, should set it to empty array
          providers: company.providers.map(getSelectedIds),
        });
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            brandingAndDemography: true,
          };
        });
      } catch (err) {
        setAccordionsFormSaveStatus((prevState) => {
          return {
            ...prevState,
            brandingAndDemography: false,
          };
        });
      }
    },
  });

  formik.updateFieldValue = (key, value) => {
    const enableTouched = key === 'countries';
    enableTouched && formik.setFieldTouched(key);
    formik.setFieldValue(key, value);
  };

  const getters = { formik, validationSchema };

  return [getters];
};
