import React from 'react';
import './OverlaySpinner.scss';
import bountyMediaLogoBg from '../../../assets/images/SpinnerLogo/bm-logo-foreground.svg';
import bountyMediaLogoFg from '../../../assets/images/SpinnerLogo/bm-logo-background.svg';

const OverlaySpinner = () => (
  <div className="overlaySpinner">
    <SpinnerIcon />
  </div>
);

export default OverlaySpinner;

export const SpinnerIcon = () => (
  <div className="overlaySpinner__icon">
    <img src={bountyMediaLogoFg} className="icon icon--background" alt="" />
    <img src={bountyMediaLogoBg} className="icon icon--foreground" alt="" />
  </div>
);
