import React from 'react';

import TableData from '../../../components/UI/TableData/TableData';
import { SpinnerIcon } from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Toaster from '../../../components/UI/Toaster/Toaster';

import { useGlobalTranslations } from './GlobalTranslations.hooks';
import GlobalTranslationsForm from './GlobalTranslationsForm/GlobalTranslationsForm';

const GlobalTranslations = () => {
  const [getters, actions] = useGlobalTranslations();
  const {
    contentsByLanguage,
    originalContents,
    formData,
    showForm,
    toast,
    loading,
  } = getters;
  const { onFormSuccess, closeToast, setShowForm, setToast, editLanguage } =
    actions;

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-2 mb-2">
        <SpinnerIcon />
      </div>
    );

  return (
    <>
      <div className="flex-column flex">
        <div className="mt-4">
          <TableData
            tableData={contentsByLanguage}
            tableColumns={[
              { key: 'languageName', label: 'Languages' },
              { key: 'code', label: 'Code' },
              { key: 'missingContent', label: 'Status' },
            ]}
            actionWidth={95}
            actions={[
              {
                key: 'edit',
                label: 'Edit',
                onClick: editLanguage,
              },
            ]}
          />
        </div>
      </div>
      <GlobalTranslationsForm
        initialData={formData}
        onSuccess={onFormSuccess}
        showForm={showForm}
        setShowForm={setShowForm}
        setToast={setToast}
      />
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default GlobalTranslations;
