import React from 'react';
import { CompanyAccordionItem } from '../CompanyAccordion/CompanyAccordion';
import CompanyForm from '../../CompaniesList/CompanyForm/CompanyForm';

const CompanySectionGeneralProfile = ({
  step,
  company,
  companyId,
  saveTick,
  setCompany,
  setAccordionsFormSaveStatus,
  setAccordionActiveKey,
}) => {
  return (
    <CompanyAccordionItem
      step={step}
      title="General Profile"
      body={
        <div>
          <CompanyForm
            data={company}
            companyId={companyId}
            saveTick={saveTick}
            onCancelForm={null}
            setCompany={setCompany}
            setAccordionActiveKey={setAccordionActiveKey}
            setAccordionsFormSaveStatus={setAccordionsFormSaveStatus}
          />
        </div>
      }
    />
  );
};

export default CompanySectionGeneralProfile;
