import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
// eslint-disable-next-line import/no-cycle
import UserService from '../../api/Users';

const initialState = {
  user: null,
  userId: undefined,
  token: undefined,
  tokenExp: undefined,
  error: null,
  loading: true,
  setPassword: false,
  setResetPassword: false,
  setMultiFactor: false,
  isAdmin: false,
  isAdvertiser: false,
  isCompanyAdmin: false,
  isCompanyUser: false,
  isBountyAdmin: false,
  isAuthenticated: false,
  isNewSession: true,
  lastInteraction: null,
  lastSignInTime: null,
  profileImgUrl: null,
  isAdvertisingPartner: false,
  isProviderPartner: false,
};

const authStart = (state) =>
  updateObject(state, {
    error: null,
    firstTimeLogin: true,
    loading: true,
  });

const authSuccess = (state, action) => {
  if (
    state.setMultiFactor === false &&
    !sessionStorage.getItem('lastSignInTime')
  ) {
    const user = {
      lastSignInTime: action.user.metadata.lastSignInTime,
    };

    UserService.update(user, action.user.uid)
      .then(() => {
        sessionStorage.setItem(
          'lastSignInTime',
          action.user.metadata.lastSignInTime,
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return updateObject(state, {
    user: action.user,
    userId: action.user.uid,
    lastSignInTime: action.user.metadata.lastSignInTime,
    isAuthenticated: action.user && action.user.accessToken,
    token: action.user.accessToken,
    tokenExp: action.user.stsTokenManager.expirationTime,
    lastInteraction: +new Date(),
    error: null,
    loading: false,
    profileImgUrl: action.user.profileImgUrl,
  });
};

const setToken = (state, action) =>
  updateObject(state, {
    token: action.tokenDetails.token,
    tokenExp: action.tokenDetails.tokenExp,
  });

const claimsSuccess = (state, action) =>
  updateObject(state, {
    isAdmin: action.claims.role === 'ROLE_ADMIN',
    isCompanyAdmin: action.claims.role === 'ROLE_COMPANY_ADMIN',
    isAdvertiser: action.claims.role === 'ROLE_ADVERTISER',
    isCompanyUser: action.claims.role === 'ROLE_COMPANY_USER',
    isBountyAdmin: action.claims.role === 'ROLE_BOUNTY_ADMIN',
  });

const setPassword = (state, action) =>
  updateObject(state, {
    setPassword: action.bool,
  });

const setResetPassword = (state, action) =>
  updateObject(state, {
    setResetPassword: action.bool,
  });

const setMultiFactor = (state, action) =>
  updateObject(state, {
    setMultiFactor: action.bool,
  });

const authFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false,
  });

const authLogout = (state, action) => {
  sessionStorage.removeItem('lastSignInTime');
  return updateObject(initialState, {
    error: action.error ?? null,
    loading: false,
  });
};

const phoneVerified = (state, action) => {
  const user = {
    phoneNumber: '+' + action.phone,
    skipMultiFactorUpdate: true,
  };

  UserService.update(user, state.user.uid).catch((err) => {
    if (process.env.NODE_ENV !== 'production') console.error(err);
  });

  return updateObject(state, {
    user: { ...state.user, phone: action.phone },
  });
};

const authUpdate = (state, action) => {
  return updateObject(state, {
    ...(action.values ?? {}),
  });
};

const setProfileDetails = (state, action) => {
  return updateObject(state, {
    profileImgUrl: action.data.profileImgUrl,
    isAdvertisingPartner: action.data.company?.some(
      (company) => !company.type || company.type === 'ADVERTISER',
    ),
    isProviderPartner: action.data.company?.some(
      (company) => company.type === 'PROVIDER',
    ),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    case actionTypes.CLAIMS_SUCCESS:
      return claimsSuccess(state, action);
    case actionTypes.LOCK_ACTIVATION:
      return setPassword(state, action);
    case actionTypes.RESET_PASSWORD:
      return setResetPassword(state, action);
    case actionTypes.MULTI_FACTOR:
      return setMultiFactor(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.PHONE_VERIFIED:
      return phoneVerified(state, action);
    case actionTypes.GENERIC_UPDATE:
      return authUpdate(state, action);
    case actionTypes.SET_PROFILE_DETAILS:
      return setProfileDetails(state, action);
    default:
      return state;
  }
};

export default reducer;
