export const transposeQuestions = (questions) =>
  questions
    .map((question) => ({
      id: question?.questionId || '',
      label: question?.questionLabel?.en || '',
      questionTranslations: question?.questionLabel || {},
      answers: transposeAnswers(question?.answers) || [],
      questionType: question?.questionType || 'select',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const transposeAnswers = (answers) =>
  answers
    .sort((a, b) => a.answerUid - b.answerUid)
    .map((answer) => ({
      id: answer?.answerId || '',
      answerTranslations: answer?.answerLabel || {},
    }));
