import React, { useEffect, useState } from 'react';

import ConnectionsDataRequestPending from '../ConnectionsDataRequestPending';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Modal from '../../UI/Modal/Modal';

import filterIcon from '../../../assets/icons/filter.svg';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';

import styles from './ConnectionsFilterModal.module.scss';

const inputClass = 'formInput formInput--medium formInput--bordered';

const ConnectionsFilterModal = ({
  filters,
  filterOptions,
  filterLoading,
  isAdmin,
  onFilterUpdated,
  loadConnections,
  onResetAllFilters,
  isLoading,
  hasPendingDeletions = false,
  openFiltersTick,
}) => {
  const {
    multiQuery,
    ageFrom,
    ageTo,
    city,
    country,
    dateFrom,
    dateTo,
    gender,
    company,
    brand,
    campaign,
    dataManagement,
  } = filters;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    openFiltersTick && setOpen(true);
  }, [openFiltersTick]);

  const handleClose = () => setOpen(false);
  const handleReset = () => onResetAllFilters();

  const handleSearch = () => {
    loadConnections();
    setOpen(false);
  };

  const handleUpdate = (key, event) =>
    onFilterUpdated(key, event?.target?.value ?? event?.value ?? event);

  const toggleReturningConnections = () =>
    onFilterUpdated('returningConnections', !filters.returningConnections);

  /**
   * When selected, the dataManagementOptions
   *  will return from BE as empty array
   */
  const dataLabelSelected =
    dataManagement === 'pending' ? 'Pending' : 'Deleted';

  const dataOptions = [
    ...(dataManagement && [
      { value: '', label: 'Clear data management filter' },
      ...(!filterOptions.dataManagement?.length
        ? [{ value: dataManagement, label: dataLabelSelected }]
        : []),
    ]),
    ...filterOptions.dataManagement,
  ];

  return (
    <Modal
      show={open}
      title={
        <div className="d-flex align-items-center">
          <h4 className="my-0 d-flex align-items-center">
            <span>
              <img src={filterIcon} alt="filter" />
            </span>
            <span className="mx-4">Advance Filter</span>
          </h4>

          <div className="mx-4">
            <ToggleSwitch
              label="Show Return Connections"
              value={filters.returningConnections}
              onCheck={toggleReturningConnections}
              additionalLabelClasses={styles.switchLabel}
            />
          </div>

          <div className="ms-auto me-4">
            <Input
              elementType="text"
              containerClasses={styles.inputMultiQuery}
              additionalClasses="formInput formInput--bordered formInput--text-sm"
              elementConfig={{
                placeholder: 'Search by Name, Email, Number',
              }}
              value={multiQuery}
              changed={(ev) => handleUpdate('multiQuery', ev)}
            />
          </div>
        </div>
      }
      dialogClassName={styles.advanceFilterDialog}
      save="Apply"
      cancel="Reset"
      headerClassName={styles.advanceFilterAction}
      footerClassName={styles.advanceFilterAction}
      handleClose={handleClose}
      customCloseEvent={handleReset}
      handleSave={handleSearch}
    >
      <div className="filtersList mt-4">
        {isAdmin && (
          <Input
            additionalClasses={inputClass}
            elementType="multiselect"
            value={company}
            label="Company"
            isLoading={filterLoading.company}
            elementConfig={{
              options: filterOptions.company,
              type: 'multiselect',
            }}
            changed={(event) => handleUpdate('company', event)}
          />
        )}
        <Input
          additionalClasses={inputClass}
          elementType="multiselect"
          value={brand}
          label="Brand"
          isLoading={filterLoading.brand}
          elementConfig={{
            options: filterOptions.brand,
            type: 'multiselect',
          }}
          changed={(event) => handleUpdate('brand', event)}
        />
        <Input
          additionalClasses={inputClass}
          elementType="multiselect"
          value={campaign}
          label="Campaign"
          isLoading={filterLoading.campaign}
          elementConfig={{
            options: filterOptions.campaign,
            type: 'multiselect',
          }}
          changed={(event) => handleUpdate('campaign', event)}
        />
      </div>

      <div className="filtersList mt-4">
        <div className="inputsRange">
          <Input
            additionalClasses={inputClass}
            elementType="text"
            value={ageFrom}
            label="Age From"
            elementConfig={{
              type: 'number',
            }}
            changed={(event) => handleUpdate('ageFrom', event)}
          />
          <Input
            additionalClasses={inputClass}
            elementType="text"
            value={ageTo}
            label="Age To"
            elementConfig={{
              type: 'number',
            }}
            changed={(event) => handleUpdate('ageTo', event)}
          />
        </div>
        <Input
          additionalClasses={inputClass}
          elementType="multiselect"
          value={city}
          label="City"
          isLoading={filterLoading.city}
          elementConfig={{
            options: filterOptions.city,
            type: 'multiselect',
          }}
          changed={(event) => handleUpdate('city', event)}
        />
        <Input
          additionalClasses={inputClass}
          elementType="multiselect"
          value={country}
          label="Country"
          isLoading={filterLoading.country}
          elementConfig={{
            options: filterOptions.country,
            type: 'multiselect',
          }}
          changed={(event) => handleUpdate('country', event)}
        />
        <div className="inputsRange">
          <Input
            additionalClasses={inputClass}
            elementType="text"
            value={dateFrom}
            label="Date From"
            elementConfig={{
              type: 'date',
            }}
            changed={(event) => handleUpdate('dateFrom', event)}
          />
          <Input
            additionalClasses={inputClass}
            elementType="text"
            value={dateTo}
            label="Date To"
            elementConfig={{
              type: 'date',
            }}
            changed={(event) => handleUpdate('dateTo', event)}
          />
        </div>
        <Input
          additionalClasses={inputClass}
          elementType="multiselect"
          value={gender}
          label="Gender"
          elementConfig={{
            options: [
              { value: 'Male', label: 'Male' },
              {
                value: 'Female',
                label: 'Female',
              },
              {
                value: 'Other',
                label: 'Other',
              },
            ],
            type: 'multiselect',
          }}
          changed={(event) => handleUpdate('gender', event)}
        />
        {dataManagement || filterOptions.dataManagement?.length ? (
          <div>
            <Input
              additionalClasses={inputClass}
              elementType="modern-select"
              value={dataManagement}
              label="Data Management Requests"
              elementConfig={{
                options: dataOptions,
              }}
              changed={(event) => handleUpdate('dataManagement', event)}
            />
            {hasPendingDeletions && (
              <div className="mt-3">
                <ConnectionsDataRequestPending />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ConnectionsFilterModal;
