/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { useBlocker } from '../../../router/CustomUsePrompt';
import ModalConfirm from '../../Modal/ModalConfirm/ModalConfirm';

/**
 * A "Prompt" modal is purely for confirming navigation
 *  away from an unsaved state
 */
const PromptModal = ({ when }) => {
  const [modalVisibleTick, setModalVisibleTick] = useState(0);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (confirmedNavigation) {
        tx.retry();
      } else {
        setModalVisibleTick((i) => i + 1);
      }
    },
    [confirmedNavigation],
  );

  const retryNavigation = useBlocker(blocker, when);

  const handleConfirmNavigationClick = () => {
    setConfirmedNavigation(true);

    if (retryNavigation) {
      retryNavigation();
    }
  };

  return (
    <>
      <ModalConfirm
        title="You have unsaved changes"
        body="Are you sure you want to leave this page without saving?"
        onConfirm={handleConfirmNavigationClick}
        openTick={modalVisibleTick}
      />
    </>
  );
};

export default PromptModal;
