import React, { useState, useEffect, useMemo } from 'react';

import Modal from '../../UI/Modal/Modal';
import CompanyWebhookForm from './CompanyWebhook/CompanyWebhookForm';

const component = {
  webhook: CompanyWebhookForm,
};

const getTitle = {
  webhook: (isUpdate, companyName) => {
    const action = isUpdate ? 'Update' : 'Create';
    return `${action} Webhook for "${companyName}"`;
  },
};

const supportedVariants = Object.keys(component);

/**
 * Multi-component modal design
 *  so that only a single Modal component
 *  need be inserted into the main page.
 *
 * Steps to add another modal:
 *
 * 1. Build the modal content as a new
 *  component, using the existing form
 *  component as a template.
 * 2. Add the component to the
 *  "component" variable above - the new key
 *  will become the required "variant" prop.
 * 3. Add title for the component key
 *  above, in "getTitle"
 * 5. Set the required modal data based on a
 *  user event, using the setCompanyModalProps
 *  helper, within the useCompanyModal hook.
 */

const CompanyModal = ({
  showTick,
  companyId,
  companyName,
  variant = '',
  formData,
  onSuccess,
}) => {
  const [show, setShow] = useState(false);
  const [saveTick, setSaveTick] = useState(0);

  const title = useMemo(() => {
    return getTitle[variant](formData?.id, companyName);
  }, [variant, formData, companyName]);

  const save = () => setSaveTick((n) => n + 1);
  const close = () => setShow(false);
  const onComplete = (response) => {
    onSuccess(response);
    close();
  };

  const ModalComponent = component[variant];

  useEffect(() => {
    if (showTick) {
      setSaveTick(0);
      setShow(true);
    }
  }, [showTick]);

  if (!supportedVariants.includes(variant)) {
    throw new TypeError('CompanyModal does not support variant ' + variant);
  }

  return (
    <Modal
      title={title}
      handleClose={close}
      handleSave={save}
      size="md"
      show={show}
      cancel="Cancel"
      save="Save"
    >
      <ModalComponent
        resetTick={showTick}
        saveTick={saveTick}
        companyId={companyId}
        formData={formData}
        onSuccess={onComplete}
      />
    </Modal>
  );
};

export default CompanyModal;
