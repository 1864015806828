export const createCategory = () => {
  return {
    categoryId: null,
    categoryLabel: {
      en: '',
    },
    categoryImgUrl: '',
    questions: [],
    // terms: [],
  };
};

export const createQuestion = (fieldType = 'select') => {
  const isSelect = fieldType === 'select';
  return {
    questionId: null,
    questionLabel: {
      en: '',
    },
    placeholder: {
      en: '',
    },
    ...(isSelect && {
      values: {
        en: [],
      },
    }),
    fieldType,
    required: false,
    ...(isSelect && {
      multiple: false,
    }),
  };
};

// newTerms() {
//     return '';
// },
