import instance from './Api';

const pendingTermsAndConditions = (userId) =>
  instance.get('/terms-and-conditions/pending/' + userId);
const rejectedTermsAndConditions = (userId) =>
  instance.get('/terms-and-conditions/rejected/' + userId);
const resendTermsAndConditions = (companyId, userId) =>
  instance.get(`/terms-and-conditions/resend/${companyId}/${userId}`);
const acceptTermsAndConditions = (data) =>
  instance.put('/terms-and-conditions/accept', data);
const rejectTermsAndConditions = (data) =>
  instance.put('/terms-and-conditions/reject', data);

export default {
  pendingTermsAndConditions,
  acceptTermsAndConditions,
  rejectTermsAndConditions,
  resendTermsAndConditions,
  rejectedTermsAndConditions,
};
