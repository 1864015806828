import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// API Imports
import PaywallApi from '../../../api/Paywall';

// Component Imports
import FormInput from '../../../components/UI/FormInput/FormInput';
import FormSelectAsync from '../../../components/UI/FormSelectAsync/FormSelectAsync';
import ToggleSwitchInput from '../../../components/UI/ToggleSwitchInput/ToggleSwitchInput';

// Local Imports
import controls from './controls';
import './QualifyQuestions.scss';

// Shared & Fixtures imports
import genders from '../../../fixtures/genders';

const getControl = (control, disabled) => {
  return {
    ...control,
    elementConfig: {
      ...control.elementConfig,
      disabled,
    },
  };
};

const QualifyQuestions = ({
  formData,
  setFormData,
  setServiceType,
  className = '',
  campaignWasStarted = false,
}) => {
  const [qualifyingQuestions, setQualifyingQuestions] = useState([]);
  const [qualifyingAnswers1, setQualifyingAnswers1] = useState([]);
  const [qualifyingAnswers2, setQualifyingAnswers2] = useState([]);
  const [qualifyIdxs] = useState({
    qualifyingAnswers1: 0,
    qualifyingQuestions1: 0,
    qualifyingQuestions2: 1,
    qualifyingAnswers2: 1,
  });
  const qualReqQuestions =
    formData.data.paywall?.qualificationRequirements?.questions;

  useEffect(() => {
    PaywallApi.getQuestions()
      .then((response) => {
        const formattedQuestions = response.data.questions.map((question) => {
          return {
            label: question.questionLabel.en,
            value: question.questionId,
            answers: question.answers.map((answer) => {
              return {
                label: answer.answerLabel.en,
                value: answer.answerId,
              };
            }),
          };
        });
        setQualifyingQuestions(formattedQuestions);
      })
      .catch(() => {
        console.error('error');
      });
  }, []);

  useEffect(() => {
    const findAnswers = (questionIdx) => {
      const question = qualifyingQuestions.filter((questions) => {
        return questions.value === qualReqQuestions[questionIdx]?.questionId;
      })[0];

      return question?.answers?.map((answer) => {
        return { label: answer.label, value: answer.value };
      });
    };

    setQualifyingAnswers1(findAnswers(qualifyIdxs.qualifyingQuestions1));
    setQualifyingAnswers2(findAnswers(qualifyIdxs.qualifyingQuestions2));
  }, [formData, qualifyingQuestions, qualifyIdxs, qualReqQuestions]);

  const onSelectedHandler = (event, name) => {
    const getValue = (ev) => {
      const isArray = Array.isArray(ev);
      const isArrayWithLen = isArray && ev.length;
      const isEmpty = (isArray && !isArrayWithLen) || !ev;

      if (isEmpty) {
        return { value: '' };
      }
      if (isArrayWithLen) {
        return ev[0];
      }
      return ev;
    };

    const { value } = getValue(event);

    // value === number handler
    if (name === 'ageFrom' || name === 'ageTo') {
      event.persist();
      setFormData((state) => {
        const touched = { ...state.touched, [name]: true };
        const data = { ...state.data, [name]: Number(event.target.value) };
        return {
          ...state,
          touched,
          data,
        };
      });
    }

    // value === array handler
    if (name === 'gender') {
      setFormData((state) => {
        const touched = { ...state.touched, [name]: true };
        const data = { ...state.data, [name]: event ?? [] };
        return {
          ...state,
          touched,
          data,
        };
      });
    }

    if (name === 'cities') {
      setFormData((state) => {
        const touched = { ...state.touched, [name]: true };
        const data = { ...state.data, cities: event ?? null };
        return {
          ...state,
          data,
          touched,
        };
      });
    }

    if (name === 'countries') {
      if (value) {
        setFormData((state) => {
          const touched = { ...state.touched, [name]: true };
          const data = { ...state.data, countries: event ?? null, cities: [] };
          return {
            ...state,
            data,
            touched,
          };
        });
      }
    }

    const setQualifyingQuestionsFormData = (questionIdx) => {
      setFormData((state) => {
        const touched = { ...state.touched, [name]: true };
        const data = { ...state.data };
        data.paywall.qualificationRequirements.questions[questionIdx] = {
          questionId: event.value,
          questionLabel: {
            en: event.label,
          },
          answers: [],
        };
        return {
          ...state,
          touched,
          data,
        };
      });
    };

    const setQualifyingAnswers = (setQualifyingAnswersCallback) => {
      const findQuestion = qualifyingQuestions.filter((questions) => {
        return questions.value === event.value;
      })[0];

      setQualifyingAnswersCallback(
        findQuestion.answers.map((answer) => {
          return { label: answer.label, value: answer.value };
        }),
      );
    };

    if (name === 'qualifyingQuestions1') {
      setQualifyingQuestionsFormData(qualifyIdxs.qualifyingQuestions1);
      setQualifyingAnswers(setQualifyingAnswers1);
    }

    if (name === 'qualifyingQuestions2') {
      if (qualReqQuestions.length === 1) {
        qualReqQuestions.push({
          questionId: event.value,
          questionLabel: {
            en: event.label,
          },
          answers: [],
        });
      }
      if (qualReqQuestions.length === 2) {
        setQualifyingQuestionsFormData(qualifyIdxs.qualifyingQuestions2);
        setQualifyingAnswers(setQualifyingAnswers2);
      }
    }

    if (name === 'qualifyingAnswers1' || name === 'qualifyingAnswers2') {
      setFormData((state) => {
        const touched = { ...state.touched, paywall: true };
        const data = { ...state.data };
        data.paywall.qualificationRequirements.questions[
          qualifyIdxs[name]
        ].answers =
          event?.map((answer) => {
            return {
              answerId: answer.value,
              answerLabel: { en: answer.label },
            };
          }) ?? [];
        return {
          ...state,
          data,
          touched,
        };
      });
    }
  };

  const createColClass = (lgColSize) => `col-12 col-lg-${lgColSize}`;

  return (
    <Row className={`qualifyQuestions ${className}`}>
      <Col className="mt-4">
        <ToggleSwitchInput
          label="Is this campaign on the Paywall?"
          text={formData.data.paywall.enabled ? 'Yes' : 'No'}
          disabled={campaignWasStarted}
          onCheck={(bool) => {
            setServiceType &&
              setServiceType(bool ? null : formData?.data?.videoProvider);

            setFormData((state) => {
              const data = { ...state.data };
              data.fallbackUrl = '';
              data.paywall.enabled = bool;
              data.paywall.qualificationRequirements.questions = [];

              const errors = { ...state.errors };
              errors.maxPerConnection =
                bool && !state.data.maxPerConnection
                  ? 'maxPerConnection is required'
                  : '';

              return {
                ...state,
                data,
                errors,
              };
            });
          }}
          value={formData.data.paywall.enabled}
        />
        <h1 className="mb-3">Qualifying questions</h1>
        <Row>
          <Col className={createColClass(3)}>
            <FormInput
              control={controls.ageFrom}
              inputKey="ageFrom"
              inputChangedHandler={onSelectedHandler}
              value={formData.data.ageFrom}
            />
          </Col>
          <Col className={createColClass(3)}>
            <FormInput
              control={controls.ageTo}
              inputKey="ageTo"
              inputChangedHandler={onSelectedHandler}
              value={formData.data.ageTo}
            />
          </Col>
          <Col className={createColClass(6)}>
            <FormInput
              control={controls.gender(genders)}
              inputKey="gender"
              inputChangedHandler={onSelectedHandler}
              value={formData.data.gender}
            />
          </Col>
        </Row>
        <Row>
          <Col className={createColClass(6)}>
            <FormSelectAsync
              control={controls.countries}
              inputKey="countries"
              inputChangedHandler={onSelectedHandler}
              optionsApiUrl="/dictionary/countries/search?query=:query"
              apiDataKey="countries"
              defaultValue={formData.data.countries}
              value={formData.data.countries}
            />
          </Col>
          <Col className={createColClass(6)}>
            <FormSelectAsync
              control={controls.cities(!formData.data.countries?.value)}
              inputKey="cities"
              inputChangedHandler={onSelectedHandler}
              optionsApiUrl={`/dictionary/cities/search?countryCode=${formData.data.countries?.value}&query=:query`}
              apiDataKey="cities"
              defaultValue={formData.data.cities}
              value={formData.data.cities}
              isMulti
            />
          </Col>
        </Row>
        {formData.data.paywall.enabled && (
          <div>
            {/* Question 1 */}
            <Row>
              <Col className={createColClass(6)}>
                <FormInput
                  control={getControl(
                    controls.qualifierQuestion(
                      // remove question selected by question 2
                      qualifyingQuestions.filter((question) => {
                        return (
                          question.value !==
                          qualReqQuestions[qualifyIdxs.qualifyingQuestions2]
                            ?.questionId
                        );
                      }),
                    ),
                    campaignWasStarted,
                  )}
                  inputChangedHandler={onSelectedHandler}
                  inputKey="qualifyingQuestions1"
                  value={
                    qualReqQuestions[qualifyIdxs.qualifyingQuestions1]
                      ?.questionId || []
                  }
                />
              </Col>
              <Col className={createColClass(6)}>
                <FormInput
                  control={controls.qualifierAnswer(qualifyingAnswers1, 1)}
                  inputChangedHandler={onSelectedHandler}
                  inputKey="qualifyingAnswers1"
                  value={
                    qualReqQuestions[
                      qualifyIdxs.qualifyingAnswers1
                    ]?.answers?.map((answer) => answer.answerId) || []
                  }
                />
              </Col>
            </Row>

            {/* Question 2 */}
            <Row>
              <Col className={createColClass(6)}>
                <FormInput
                  control={getControl(
                    controls.qualifierQuestion(
                      // remove question selected by question 1
                      qualifyingQuestions.filter((question) => {
                        return (
                          question.value !==
                          qualReqQuestions[qualifyIdxs.qualifyingQuestions1]
                            ?.questionId
                        );
                      }),
                      2,
                    ),
                    campaignWasStarted,
                  )}
                  inputChangedHandler={onSelectedHandler}
                  inputKey="qualifyingQuestions2"
                  value={
                    qualReqQuestions[qualifyIdxs.qualifyingQuestions2]
                      ?.questionId || []
                  }
                />
              </Col>
              <Col className={createColClass(6)}>
                <FormInput
                  control={controls.qualifierAnswer(qualifyingAnswers2, 2)}
                  inputChangedHandler={onSelectedHandler}
                  inputKey="qualifyingAnswers2"
                  value={
                    qualReqQuestions[
                      qualifyIdxs.qualifyingAnswers2
                    ]?.answers?.map((answer) => answer.answerId) || []
                  }
                />
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default QualifyQuestions;
