import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faFileDownload,
  faArrowLeft,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '../../components/UI/Button/Button';

// API Imports
import ProviderService from '../../api/Providers';
import CodesService from '../../api/Codes';

// Component Imports
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import FileUploadButton from '../../components/UI/FileUploadButton/FileUploadButton';
import ToggleSwitch from '../../components/UI/ToggleSwitch/ToggleSwitch';
import Toaster from '../../components/UI/Toaster/Toaster';
import Modal from '../../components/UI/Modal/Modal';
import CodesAdd from '../../components/Codes/CodesAdd/CodesAdd';

// Local Imports
import './CodesPage.scss';
import CodesTable from '../../components/Codes/CodesTable/CodesTable';
import AddCodeModal from '../../components/Codes/AddCodeModal/AddCodeModal';

const CodesPage = () => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const { isAdmin, isBountyAdmin, isCompanyAdmin, isCompanyUser } = useSelector(
    (state) => state.auth,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [availableCodesNumber, setAvailableCodesNumber] = useState(0);
  const [filters, setFilters] = useState(null);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [addCodeTick, setAddCodeTick] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [modal, setModal] = useState({
    show: false,
    header: '',
    body: '',
    handleClose: null,
    handleSave: null,
  });

  useEffect(() => {
    // Apply the CSS animation after the component mounts
  }, []);

  const [fetchTick, setFetchTick] = useState(0);

  const fetchProvider = useCallback(() => {
    ProviderService.findOne(providerId)
      .then((result) => {
        const data = result.data ?? null;
        if (data) {
          setCurrentProvider(data);
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, [navigate, providerId]);

  useEffect(() => {
    fetchProvider();
  }, [fetchProvider]);

  const getCodes = async (config, newFilters = filters) => {
    if (!currentProvider)
      return {
        data: [],
        count: 0,
      };

    const filter = {
      ...newFilters,
      provider: currentProvider?.id,
    };

    const codesServiceResultPm = CodesService.list({
      limit: config.limit,
      page: config.skip / config.limit + 1,
      order: config.sortInfo ? config.sortInfo.name : '',
      order_direction:
        config.sortInfo && config.sortInfo.dir === 1 ? 'ASC' : 'DESC',
      ...(isChecked ? { status: 'Available' } : {}),
      ...filter,
    });

    const codesServiceCountResultPm = CodesService.countAvailable(
      currentProvider?.id,
    );

    const [codesServiceResult, codesServiceCountResult] = await Promise.all([
      codesServiceResultPm,
      codesServiceCountResultPm,
    ]);

    setAvailableCodesNumber(codesServiceCountResult.data);

    return {
      data: codesServiceResult?.data?.data ?? [],
      count: codesServiceResult?.data?.total_count ?? 0,
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  const search = async (event, type) => {
    const phrase = event.target.value;
    const newFilters = {
      ...filters,
      [type]: phrase.substring(0, 100),
    };
    debouncedCall();
    setFilters(newFilters);
  };

  const fileUploadSuccessHandler = (file, response) => {
    const counted = response.countUploaded;

    setToast({
      show: true,
      class: 'success',
      header: `${counted} codes imported`,
    });
    if (counted) {
      setCurrentPage(1);
      setFilters(null);
      setFetchTick((tick) => tick + 1);
    }
  };

  const fileUploadErrorHandler = (file, message) => {
    // Trigger the CSS animation by adding a class
    document.querySelector('#template-btn').classList.add('attention-pulse');

    const timer = setTimeout(() => {
      document
        .querySelector('#template-btn')
        .classList.remove('attention-pulse');
    }, 2000); // Wait for 2 seconds before removing the class

    setToast({
      show: true,
      class: 'error',
      header: message.errors,
    });
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const deleteClickHandler = (id) => {
    setModal({
      show: true,
      header: 'Delete confirmation',
      body: 'Are you sure you want to delete the code?',
      handleSave: () => deleteHandler(id),
      handleClose: () => deleteCancelHandler(),
    });
  };

  const deleteCancelHandler = () => {
    setModal({
      show: false,
      header: '',
      body: '',
      handleClose: null,
      handleSave: null,
    });
  };

  const deleteHandler = (id) => {
    CodesService.remove(id)
      .then(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        setFetchTick((tick) => tick + 1);
      })
      .catch(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
      });
  };

  const statusChangeClickHandler = (id, status) => {
    setModal({
      show: true,
      header: 'Change status confirmation',
      body: `Are you sure you want to change status of the the code to ${status}?`,
      handleClose: () => statusChangeCancelHandler(),
      handleSave: () => statusChangeHandler(id, status),
    });
  };

  const statusChangeCancelHandler = () => {
    setModal({
      show: false,
      header: '',
      body: '',
      handleClose: null,
      handleSave: null,
    });
  };

  const statusChangeHandler = (id, status) => {
    CodesService.changeStatus(id, status)
      .then(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        setToast({
          show: true,
          header: 'Code status changed',
          class: 'success',
        });
        setFetchTick((tick) => tick + 1);
      })
      .catch(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        setToast({
          show: true,
          header: 'We could not change the status of the code',
          class: 'error',
        });
      });
  };

  const generateCsvTemplate = async (e) => {
    e.preventDefault();

    const csvData = [
      ['Code', 'Consumer Note'],
      ['abcdef123', ''],
      ['abcdef456', ''],
      ['abcdef789', ''],
    ];

    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);

    a.href = url;
    a.download = 'codes_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCsvHandler = async (e) => {
    e.preventDefault();
    await CodesService.generateCsv(providerId, () => {
      setToast({
        show: true,
        class: 'error',
        header:
          'There was a problem downloading the codes csv. please try again',
      });
    });
  };

  const dataSource = useCallback(
    (config) => {
      return getCodes(config, filters);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, fetchTick, isChecked, currentProvider],
  );
  return (
    <Fragment>
      <ActionsNavbar>
        <Col
          className="
                mb-2
                mb-md-0
                d-flex
                flex-wrap
                align-items-center"
          md={7}
        >
          <Button className="btnDefault" clicked={() => navigate(-1)}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              className="me-3"
            />
            Return
          </Button>

          {(isAdmin || isBountyAdmin || isCompanyAdmin || isCompanyUser) && (
            <Dropdown id="action-csv">
              <Dropdown.Toggle
                className="btnDefault btnDefault--with-icon btnDefault--with-icon-add"
                id="download-provider-csv"
                data-test-id="download-provider-csv-button"
              >
                Actions
                <FontAwesomeIcon
                  icon={faArrowDown}
                  title="Invite"
                  className=" btnDefault--with-icon__icon"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <FileUploadButton
                  buttonText="Import codes from CSV file"
                  multiple
                  dataTestAttribute="csv-file-upload"
                  onFileUploadSuccess={fileUploadSuccessHandler}
                  onFileUploadError={fileUploadErrorHandler}
                  fileService={CodesService}
                  uploadUrl={currentProvider?.id}
                />

                {(isAdmin || isBountyAdmin) && (
                  <Button
                    id="template-btn"
                    clicked={generateCsvTemplate}
                    additionalClasses="btnDefault"
                  >
                    Download Template
                    <div
                      style={{
                        'padding-left': '8px',
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={faFileDownload} />
                    </div>
                  </Button>
                )}

                <Button
                  clicked={generateCsvHandler}
                  dataTestAttribute="export-codes-csv"
                  additionalClasses="btnDefault"
                >
                  Export Codes as CSV
                </Button>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
        <Col
          className="
                d-flex
                justify-content-end
                justify-content-sm-end
                flex-wrap
                align-content-center
                mt-2 mt-md-0
                align-items-center
                providers-settings"
          xs={12}
          md={5}
        >
          <ToggleSwitch
            label="Hide used codes"
            onCheck={() => {
              setCurrentPage(1);
              setIsChecked(!isChecked);
            }}
          />
          <Link
            className="btnDefault"
            to={`/offer-carousel-images/${providerId}`}
          >
            Banners
          </Link>
          <Link className="btnDefault" to={`/offers/edit/${providerId}`}>
            Details
          </Link>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-3">
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h2 className="m-0">
                {currentProvider?.name || ''} Codes (Available:{' '}
                {availableCodesNumber})
              </h2>
              <Button clicked={() => setAddCodeTick((n) => n + 1)}>Add</Button>
            </div>
            <CodesTable
              dataSource={dataSource}
              currentProvider={currentProvider}
              availableCodesNumber={availableCodesNumber}
              onDelete={deleteClickHandler}
              onStatusChange={statusChangeClickHandler}
              search={search}
            />
          </Col>
        </Row>
      </Container>
      <Toaster toast={toast} closeToast={closeToast} />

      <AddCodeModal
        openTick={addCodeTick}
        providerId={currentProvider?.id}
        onSave={() => {
          setFetchTick((tick) => tick + 1);
          setToast({
            show: true,
            header: 'Code added',
            class: 'success',
          });
        }}
      />

      <Modal
        show={modal.show}
        title={modal.header}
        body={modal.body}
        cancel="No"
        save="Yes"
        handleClose={modal.handleClose}
        handleSave={modal.handleSave}
        dataTestAttribute={{
          modal: 'modal-code-confirmation',
          action1: 'no-code-confirmation',
          action2: 'yes-code-confirmation',
        }}
      />
    </Fragment>
  );
};

export default CodesPage;
