import { getCampaignSections } from './CampaignDetail.sections';

export const getFirstErrorSectionKey = (
  errorKeys,
  sectionConfig,
  sectionsRef,
) => {
  const campaignSections = getCampaignSections();

  for (const section of campaignSections) {
    const { key } = section;
    let sectionHasError = sectionConfig[key].fieldKeys.some((key_) =>
      errorKeys.includes(key_),
    );

    if (key === 'qualifications' && errorKeys.includes('paywall')) {
      sectionHasError = true;
    }

    if (sectionHasError && sectionsRef?.current?.[key]?.offsetTop) {
      return {
        key,
        offsetTop: sectionsRef.current[key].offsetTop - 180,
      };
    }
  }

  return null;
};
