import { array, number, object, string } from 'yup';

export const getSchema = () => ({
  ageFrom: number().required('Required'),
  ageTo: number().required('Required'),
  gender: array().min(1, 'Required').required('Required'),
  countries: object()
    .shape({
      value: string().required('Required'),
      label: string(),
    })
    .required('Required'),
  cities: array()
    .of(
      object().shape({
        value: string(),
        label: string(),
      }),
    )
    .nullable(),
});

const displayFields = {
  ageFrom: {
    key: 'ageFrom',
    label: 'Age from',
    elementConfig: {
      placeholder: 'Enter age from',
      type: 'number',
    },
  },
  ageTo: {
    key: 'ageTo',
    label: 'Age to',
    elementConfig: {
      placeholder: 'Enter age to',
      type: 'number',
    },
  },
  gender: {
    key: 'gender',
    label: 'Gender',
    type: 'multiselect',
    defaultValue: [],
    elementConfig: {
      type: 'number',
      options: [
        {
          label: 'Female',
          value: 'Female',
        },
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    formatOutput: (arr) => {
      if (!arr) {
        return null;
      }
      return arr.map((e) => e.value);
    },
    formatInputValue: (value) => {
      if (Array.isArray(value)) {
        return value.map((selection) => {
          return {
            value: selection,
            label: selection,
          };
        });
      }
      return null;
    },
  },
  countries: {
    key: 'countries',
    label: 'Country',
    type: 'autocomplete',
    defaultValue: null,
    elementConfig: {
      placeholder: 'Please select one',
    },
  },
  cities: {
    key: 'cities',
    label: 'Cities',
    type: 'autocomplete',
    defaultValue: [],
  },
};

export default displayFields;
