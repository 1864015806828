import instance from './Api';

const getLanguages = () => instance.get('/translation/languages');

const getTranslationByCode = (code) =>
  instance.get(`/translation/code/${code}`);

const getContentByTranslationId = (id) =>
  instance.get(`/translation/content/${id}`);

const getContentByLanguageCode = (languageCode) =>
  instance.get(`translation/content/langaugeCode/${languageCode}`);

const getContentByLanguageId = (languageId) =>
  instance.get(`translation/content/langaugeId/${languageId}`);

const getOriginalContent = () => instance.get(`translation/content`);

const getContentByTranslationCode = (translationCode) =>
  instance.get(`translation/content/translationCode/${translationCode}`);

const addTranslationContent = (formData, translationId, languageId) =>
  instance.post(
    `/translation/content/${translationId}/${languageId}`,
    formData,
  );

export default {
  getLanguages,
  getTranslationByCode,
  getContentByTranslationId,
  getContentByLanguageCode,
  getContentByLanguageId,
  addTranslationContent,
  getContentByTranslationCode,
  getOriginalContent,
};
