import React, { useState, useEffect, useMemo } from 'react';
import _set from 'lodash/set';
import { useFormik } from 'formik';
import axios from 'axios';

import UserService from '../../../api/Users';

import PhoneInput from '../../../components/UI/PhoneInput/PhoneInput';
import FormikSelectAsync from '../../../components/UI/FormikSelectAsync/FormikSelectAsync';
import FormikInput from '../../../components/UI/FormikInput/FormikInput';
import Button from '../../../components/UI/Button/Button';
import ToasterBottom from '../../../components/UI/Toaster/ToasterBottom';

import displayFields, { getSchema } from './controls';

import styles from '../SignUpPage.module.scss';

const DEFAULT_COUNTRY_CODE = 'ID';

const SignUpForm = ({ setFormSuccess }) => {
  const [toast, setToast] = useState('');
  const [isSaving, setSaving] = useState(false);
  const [validationSchema] = useState(getSchema());
  const [geoLocationCountry, setGeoLocationCountry] = useState(null);
  const [initialValues] = useState(
    Object.keys(displayFields).reduce((values, key) => {
      const newValues = { ...values };
      _set(
        newValues,
        displayFields[key].key,
        displayFields[key].defaultValue ?? '',
      );
      return newValues;
    }, {}),
  );

  const closeToast = () => {
    setToast((state) => ({ ...state, show: false }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      await sendToAdmin(values);
      setSaving(false);
    },
  });

  const sendToAdmin = async (values) => {
    try {
      const { name, email, company, country, phoneNumber } = values;
      const response = await UserService.signUp({
        name,
        email,
        company,
        country: country.label,
        phoneNumber,
      });

      if (response.status === 201) {
        setFormSuccess(true);
        return;
      }

      if (response.data._errors) {
        formik.setErrors(response.data._errors);
      }

      setToast({
        show: true,
        success: false,
        body: 'Something went wrong, please try again',
      });
    } catch (err) {
      if (err.response.data._errors) {
        formik.setErrors(err.response.data._errors);
      }
      process.env.NODE_ENV !== 'production' && console.error(err);
      setToast({
        show: true,
        success: false,
        body: err.message ?? 'Something went wrong, please try again',
      });
    }
  };

  useEffect(() => {
    const getGeoLocationInfo = async () => {
      try {
        const { data } = await axios.get('https://js.instantgeo.info/json');
        setGeoLocationCountry(data.country.toLowerCase());
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') console.error(err);
        // non-critical error
      }
    };

    getGeoLocationInfo();
  }, []);

  const errorMessage = useMemo(() => {
    const errorsKeys = Object.keys(formik.errors);
    const touchedKeys = Object.keys(formik.touched);
    const commonKeys = errorsKeys.filter((key) => touchedKeys.includes(key));

    if (
      errorsKeys.length === 0 ||
      touchedKeys.length === 0 ||
      commonKeys.length === 0
    )
      return '';

    if (commonKeys.length === 1) {
      const [commonKey] = commonKeys;
      const formikError = formik.errors[commonKey];
      if (formikError) return formikError.value;
    }

    return 'These fields cannot be empty.';
  }, [formik.errors, formik.touched]);

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        {['name', 'email', 'company'].map((fieldKey) => {
          return (
            <FormikInput
              key={fieldKey}
              field={displayFields[fieldKey]}
              formik={formik}
              validationSchema={validationSchema}
              hideLabel
            />
          );
        })}
        <div className="mb-2">
          <FormikSelectAsync
            key="country"
            field={displayFields.country}
            formik={formik}
            validationSchema={validationSchema}
            optionsApiUrl="/dictionary/countries/search?query=:query"
            apiDataKey="countries"
            hideLabel
          />
        </div>
        <PhoneInput
          key="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={(val) => {
            /**
             * when val = undefined, it clears the form key
             *   we dont wnat this, so we use a string default
             *   so that the error can persist
             */
            formik.setFieldValue('phoneNumber', val ?? '', true);
          }}
          onBlur={() => formik.setFieldTouched('phoneNumber')}
          required
          placeholder="Enter Phone Number*"
          country={geoLocationCountry ?? DEFAULT_COUNTRY_CODE}
          invalid={formik.errors.phoneNumber && formik.touched.phoneNumber}
          error={formik.touched.phoneNumber ? formik.errors.phoneNumber : null}
          hideLabel
        />
        {errorMessage ? (
          <p className="authFormErrorText text-center mt-4 mb-0">
            {errorMessage}
          </p>
        ) : null}
        <Button
          additionalClasses="btnDefault w-100 mt-4"
          dataTestAttribute="send-button"
          isLoading={isSaving}
          type="submit"
        >
          Sign Up
        </Button>
      </form>

      <ToasterBottom toast={toast} closeToast={closeToast} />
    </div>
  );
};

export default SignUpForm;
