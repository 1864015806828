const getSwiperStyles = (numberOfSlides) => {
  const swiperButtonOffset = (numberOfSlides * 20 + 12) / 2 + 20;

  return `
.swiper {
    padding-bottom: 42px;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      opacity: 1;
      background: #d1d1d1;
      border-radius: 20px;
      transition: 0.3s ease width;
    }

    .swiper-pagination-bullet-active {
      background: var(--bs-orange);
      width: 24px;
    }

    ::slotted(swiper-slide) {
        display: flex;
        flex-direction: column;
    }

    .swiper-pagination {
      bottom: 0;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      max-width: 212px;
      overflow: hidden;
      max-height: 24px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      stroke: black;
      width: 16px;
      height: 16px;
    }

    .swiper-button-next {
      bottom: 0;
      top: auto;
      left: calc(50% + ${swiperButtonOffset}px);
      margin-bottom: 4px;
    }

    .swiper-button-prev {
      bottom: 0;
      top: auto;
      left: auto;
      right: calc(50% + ${swiperButtonOffset}px);
      margin-bottom: 4px;
    }
  }
`;
};

export default getSwiperStyles;
