import { formatDate } from '../../shared/dateTime';

export const transformCampaign = (campaignData) => {
  const paywallData = campaignData.paywall;
  return {
    ...campaignData,
    ageFrom: !campaignData.ageFrom ? null : campaignData.ageFrom,
    ageTo: !campaignData.ageTo ? null : campaignData.ageTo,
    countries: Array.isArray(campaignData.countries)
      ? null
      : campaignData.countries,
    tags: campaignData.tags?.map((tag) => tag.label) ?? undefined,
    company: campaignData.company?.value ?? undefined,
    brand: campaignData.brand?.value ?? undefined,
    maxPerConnection: campaignData.maxPerConnection ?? 0,
    startDate: formatDate(campaignData.startDate),
    endDate: formatDate(campaignData.endDate),
    paywall: {
      ...paywallData,
      qualificationRequirements: {
        ...paywallData?.qualificationRequirements,
        questions: paywallData?.qualificationRequirements?.questions?.length
          ? paywallData.qualificationRequirements.questions
          : Array.from({ length: 2 }, () => ({
              questionId: '',
              questionLabel: {
                en: '',
              },
              answers: [],
              isNewQuestion: true,
            })),
      },
    },
    // If campaignData.globalSettings is null, set it to a default value
    globalSettings: campaignData.globalSettings
      ? campaignData.globalSettings
      : {
          id: Math.floor(Math.random() * 1000000).toString(),
          type: 'globalSettings',
          config: {
            layout: {},
            defaultLanguage: 'en',
            mainColor: '#000000',
            backgroundColor: '#ffffff',
            imageBoxColor: '#000000',
            paddingTop: '0',
            paddingBottom: '0',
            languages: ['en'],
            htmlCode: '',
          },
        },
  };
};

export const transformOptions = (campaignData) => ({
  tags: campaignData.tags ?? null,
  company: campaignData.company ?? null,
  brand: campaignData.brand ?? null,
  provider: campaignData.provider ?? null,
});
