import React from 'react';
import Row from 'react-bootstrap/Row';

import './Tile.scss';

const Tile = ({ data }) => {
  const renderItem = () =>
    Object.keys(data).map((el) => {
      const { title, value, prefix, sufix } = data[el];
      return (
        <div key={`${el}-tile-${value}`} className="displayNumbers col">
          <span>{title}</span>
          <span>
            <strong>{prefix}</strong>
          </span>
          <span className="mt-1">
            <strong>
              {value}
              {sufix}
            </strong>
          </span>
        </div>
      );
    });

  return <Row className="displayNumbersWrapper px-2 py-3">{renderItem()}</Row>;
};

export default Tile;
