import React, { useEffect, useMemo, useState } from 'react';
import './ReportFilters.scss';
import FilterInput, {
  FILTER_TYPE_RANGE,
  FILTER_TYPE_SELECT,
} from './FilterInput/FilterInput';
import Button from '../../../../../components/UI/Button/Button';
import Cpdcs from '../../../../../api/Cpdcs';
import SelectedFilters from './SelectedFilters/SelectedFilters';

const ReportFilters = ({
  onFiltersChange,
  currentFilters,
  fields,
  campaignId,
  formId,
  showFilters = false,
}) => {
  const filterFields = useMemo(
    () =>
      fields.map((field) => {
        const fieldConfig = {
          name: field.name,
          label: field.label,
          answers: field.answers,
          allowedTypes: [],
        };

        // Setup known mandatory fields
        if (field.name === 'gender') {
          fieldConfig.answers = ['Male', 'Female', 'Other'];
        }
        if (field.name === 'age' || field.name === 'date') {
          fieldConfig.allowedTypes.push(FILTER_TYPE_RANGE);
        }
        if (field.name === 'city') {
          fieldConfig.answersFn = () =>
            Cpdcs.getAnswers(campaignId, 'city').then(
              (response) => response.data,
            );
        }
        if (fieldConfig.answers || fieldConfig.answersFn) {
          fieldConfig.allowedTypes.push(FILTER_TYPE_SELECT);
        }
        return fieldConfig;
      }),
    [fields, campaignId],
  );

  // Clear filters on form change
  useEffect(() => {
    setFilterValues({});
  }, [formId]);

  const [filterValues, setFilterValues] = useState({});

  const onFilterValueChange = (fieldName, value) => {
    const isEmpty = (val) => val === '' || typeof val === 'undefined';

    setFilterValues((oldValues) => {
      const newState = {
        ...oldValues,
        [fieldName]: value,
      };
      if (value.type === 'equals' && !value.value.values.length) {
        delete newState[fieldName];
      }
      if (
        value.type === 'range' &&
        isEmpty(value.value?.from) &&
        isEmpty(value.value?.to)
      ) {
        delete newState[fieldName];
      }
      return newState;
    });
  };

  const clearFilters = () => {
    setFilterValues({});
    onFiltersChange({});
  };

  return (
    <div className="px-5">
      {showFilters && (
        <>
          <div className="filtersList mt-4">
            {filterFields
              // Remove unsupported filters
              .filter(
                (filter) =>
                  ['targetUrls', 'pageVisits', 'interactions'].indexOf(
                    filter.name,
                  ) === -1,
              )
              .map((filter) => (
                <FilterInput
                  key={filter.name}
                  filterConfig={filter}
                  filterValue={filterValues[filter.name]}
                  onFilterValueChange={(newValue) =>
                    onFilterValueChange(filter.name, newValue)
                  }
                />
              ))}
          </div>
          <Button
            additionalClasses="btnDefault--medium mt-4 me-3"
            clicked={() => onFiltersChange(filterValues)}
            dataTestAttribute="cpdc-report-filter"
          >
            Submit filters
          </Button>
          <Button
            additionalClasses="btnDefault--medium btnDefault--gray mt-4"
            clicked={() => clearFilters()}
            dataTestAttribute="cpdc-report-reset"
          >
            Clear filters
          </Button>
        </>
      )}
      {currentFilters && (
        <div className="mt-3">
          <SelectedFilters selectedFilters={currentFilters} />
        </div>
      )}
    </div>
  );
};

export default ReportFilters;
