import { lazy, object } from 'yup';
import _set from 'lodash/set';

import informationFields, {
  getSchema as getInformationSchema,
} from './controls.information';
import protectedFields, {
  getSchema as getProtectedSchema,
} from './controls.protected';
import qualificationFields, {
  getSchema as getQualificationSchema,
} from './controls.qualifications';
import serviceFields, {
  getSchema as getServiceSchema,
} from './controls.serviceType';
import targetFields, { getSchema as getTargetSchema } from './controls.target';
import callbackFields, {
  getSchema as getCallbackSchema,
} from './controls.callback';
import builderFields, {
  getSchema as getBuilderSchema,
} from './controls.builder';
import emailFields, { getSchema as getEmailSchema } from './controls.email';

export const getFormControls = () => {
  const validationSchema = lazy((values) => {
    const informationSchema = getInformationSchema(values);
    const protectedSchema = getProtectedSchema(values);
    const qualificationSchema = getQualificationSchema(values);
    const serviceSchema = getServiceSchema(values);
    const targetSchema = getTargetSchema(values);
    const callbackSchema = getCallbackSchema(values);
    const builderSchema = getBuilderSchema(values);
    const emailSchema = getEmailSchema(values);

    return object({
      ...informationSchema,
      ...protectedSchema,
      ...qualificationSchema,
      ...serviceSchema,
      ...targetSchema,
      ...callbackSchema,
      ...builderSchema,
      ...emailSchema,
    });
  });

  const displayFields = {
    ...informationFields,
    ...protectedFields,
    ...qualificationFields,
    ...serviceFields,
    ...targetFields,
    ...callbackFields,
    ...builderFields,
    ...emailFields,
  };

  const initialValues = Object.keys(displayFields).reduce((values, key) => {
    const newValues = { ...values };
    _set(
      newValues,
      displayFields[key].key,
      displayFields[key].defaultValue ?? '',
    );
    return newValues;
  }, {});

  const sectionConfig = {
    information: { fieldKeys: getFieldKeys(informationFields) },
    protected: { fieldKeys: getFieldKeys(protectedFields) },
    qualifications: { fieldKeys: getFieldKeys(qualificationFields) },
    serviceType: { fieldKeys: getFieldKeys(serviceFields) },
    target: { fieldKeys: getFieldKeys(targetFields) },
    callback: { fieldKeys: getFieldKeys(callbackFields) },
    builder: { fieldKeys: getFieldKeys(builderFields) },
    email: { fieldKeys: getFieldKeys(emailFields) },
  };

  return { validationSchema, displayFields, initialValues, sectionConfig };
};

function getFieldKeys(fieldsConfig) {
  return Object.keys(fieldsConfig)
    .map((e) => fieldsConfig[e].key)
    .filter((e) => e);
}
