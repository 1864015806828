import React, { useState, useRef } from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import iconExclamation from '../../assets/icons/icon-exclamation.png';

import styles from './Connections.module.scss';

const ConnectionsDataRequestPending = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleTooltipClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div ref={ref}>
      <button
        className={styles.dataDeletePendingNotice}
        onClick={handleTooltipClick}
      >
        <img
          className="me-2"
          width="16"
          alt="Pending Deletion"
          src={iconExclamation}
        />
        <small>Data Request(s) Pending</small>
        <FontAwesomeIcon className="ms-2" icon={faQuestionCircle} />
      </button>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <p>
              People have the right to request that their personally identifying
              information (PII) be deleted from Bounty. From the date of the
              request you have 30 days to action the deletion within Bounty and
              from your own databanks. Actioning the deletion through Bounty
              will remove their: name, phone number, and email.
            </p>
            <p className="mb-0">
              The connection’s answers and non-identifying information do not
              need to be deleted.
            </p>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ConnectionsDataRequestPending;
