import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import _pick from 'lodash/pick';

import { useModalConfirm as useConfirmDeleteModal } from '../../../components/Modal/ModalConfirm/ModalConfirm';

import ConnectionsService from '../../../api/Connections';

const userDataColumns = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'email', label: 'Email' },
  { key: 'phoneNumber', label: 'Phone' },
  { key: 'age', label: 'Age' },
  { key: 'gender', label: 'Gender' },
  { key: 'city', label: 'City' },
];

const campaignDataColumns = [
  { key: 'q', label: 'Question' },
  { key: 'a', label: 'Answer' },
];

const useConnectionDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const mountedRef = useRef(true);

  const { isAdmin, isCompanyAdmin } = useSelector((state) => state.auth);
  const [allowDetailView] = useState(isAdmin || isCompanyAdmin);

  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [qualifiers, setQualifiers] = useState([]);

  const getConnection = useCallback(async (showSpinner = true) => {
    if (showSpinner) {
      setLoading(true);
    }

    const cpdcId = params.id;

    ConnectionsService.detail(cpdcId).then((result) => {
      if (!mountedRef.current) return;
      setUserData([
        _pick(result.data, [
          'cpdcId',
          'firstName',
          'lastName',
          'email',
          'age',
          'phoneNumber',
          'gender',
          'city',
          'pendingDelete',
          'requestDeletedDate',
        ]),
      ]);
      setCampaignData(result.data.campaigns);
      if (result.data.qualifiers) {
        setQualifiers(result.data.qualifiers);
      }
      if (showSpinner) {
        setLoading(false);
      }
    });
  }, []);

  const [confirmDeleteProps, setConfirmDeleteProps, resetDeleteProps] =
    useConfirmDeleteModal({
      title: 'Delete PII?',
      supplementalErrorKey: 'records',
      apiRequest: () => {},
    });

  const openDeleteConnectionModal = (rowData) => {
    setConfirmDeleteProps({
      saveText: 'Delete PII',
      apiId: rowData.cpdcId,
      apiRequest: ConnectionsService.deletePii,
      onSuccess: () => getConnection(false),
    });
  };

  useEffect(() => {
    if (!allowDetailView) {
      navigate('/connections');
    } else {
      getConnection();
    }
  }, [getConnection, allowDetailView, navigate]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [
    {
      userData,
      userDataColumns,
      qualifiers,
      campaignData,
      campaignDataColumns,
      isLoading,
      confirmDeleteProps,
    },
    {
      openDeleteConnectionModal,
    },
  ];
};

export default useConnectionDetailPage;
