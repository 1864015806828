import React, { useState, useMemo } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FormInput from '../../UI/FormInput/FormInput';
import FileUploadInput from '../../UI/FileUploadInput/FileUploadInput';
import Button from '../../UI/Button/Button';
import BrandService from '../../../api/Brands';

import formControls from './controls';

const BrandEditForm = ({ id, brandName, brandLogo, hideModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setError] = useState('');
  const [filePath, setFilePath] = useState(brandLogo || null);
  const [newBrandName, setBrandName] = useState(brandName);
  const isFormValid = useMemo(
    () =>
      (newBrandName.length && brandName !== newBrandName) ||
      (brandLogo || null) !== filePath,
    [brandLogo, brandName, newBrandName, filePath],
  );

  const submitUpdate = () => {
    setIsLoading(true);
    setError('');
    BrandService.update(id, { name: newBrandName, brandLogo: filePath })
      .then(() => {
        hideModal(true);
      })
      .catch((err) => {
        let _errorMessage = '';
        Object.keys(err?.response?.data?._errors ?? []).forEach((param) => {
          const msg = err.response.data._errors[param];
          if (!_errorMessage.includes(msg)) {
            _errorMessage += ` ${msg}`;
          }
        });
        setError(_errorMessage);
        setIsLoading(false);
      });
  };

  const onSuccessUpload = (uploadPath) => {
    setFilePath(uploadPath);
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          <FormInput
            control={formControls.name}
            inputKey="name"
            value={newBrandName}
            inputChangedHandler={(e) => setBrandName(e.currentTarget.value)}
            error={errorMessage}
            dataTestAttribute="company-providers-input"
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <FileUploadInput
            buttonText={filePath ? 'Replace Logo' : 'Upload Logo'}
            label="Brand Logo"
            withPreview
            filePath={filePath}
            onFileUrlChange={onSuccessUpload}
            previewToRight
            dataTestAttribute="image-brandLogo"
            uploadUrl="/file/upload/brand-logo"
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <Button
            type="submit"
            disabled={!isFormValid || isLoading}
            additionalClasses="me-3"
            clicked={submitUpdate}
            dataTestAttribute="save-provider"
          >
            Save
          </Button>
          <Button
            type="button"
            additionalClasses="btnDefault--light"
            clicked={(event) => hideModal(event)}
            dataTestAttribute="cancel-add-provider"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BrandEditForm;
