import instance from './Api';

const create = (cost) => instance.post('/connection-cost', cost);

/**
 * Get current price for provider
 * @param {number} provider Provider Id
 */
const getLast = (provider) => instance.get(`/connection-cost/${provider}`);

/**
 * List providers using filters
 * @param {number} provider Provider Id
 * @param filters - example: {
 * limit: 20,
 * page: 2,
 * order: 'code',
 * order_direction: 'asc'}
 * @return {Promise<AxiosResponse<any>>}
 */
const list = (provider, filters = {}) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/connection-cost/${provider}/list${query}`);
};

const listTermsBase = (filters) => {
  const query = new URLSearchParams({
    ...filters,
  }).toString();
  return instance.get(`/campaigns/terms?${query}`);
};
const listTermsCampaign = (filters, campaignId) => {
  const query = new URLSearchParams({
    ...filters,
  }).toString();
  return instance.get(`/campaigns/${campaignId}/terms?${query}`);
};

const getTermsBase = (_, termId) => instance.get(`/campaigns/terms/${termId}`);
const getTermsCampaign = (campaignId, termId) =>
  instance.get(`/campaigns/${campaignId}/terms/${termId}`);

const createTermsBase = () => instance.get('/campaigns/terms/draft');
const createTermsCampaign = (campaignId) =>
  instance.get(`/campaigns/${campaignId}/terms/draft`);

const updateTermsBase = (_, termId, payload) =>
  instance.put(`/campaigns/terms/${termId}`, payload);
const updateTermsCampaign = (campaignId, termId, payload) =>
  instance.put(`/campaigns/${campaignId}/terms/${termId}`, payload);

const publishTermsBase = (_, termId, descriptor) =>
  instance.put(`/campaigns/terms/${termId}/publish`, descriptor);
const publishTermsCampaign = (campaignId, termId, descriptor) =>
  instance.put(`/campaigns/${campaignId}/terms/${termId}/publish`, descriptor);

export default {
  create,
  getLast,
  list,
  listTermsBase,
  listTermsCampaign,
  getTermsBase,
  getTermsCampaign,
  createTermsBase,
  createTermsCampaign,
  updateTermsBase,
  updateTermsCampaign,
  publishTermsBase,
  publishTermsCampaign,
};
