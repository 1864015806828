import React from 'react';

import CoordsInputs from '../CoordsInputs/CoordsInputs';
import Button from '../../../UI/Button/Button';

import './ImageLinkerForm.scss';

const ImageLinkerForm = ({
  editedArea,
  formIsValid,
  inputs,
  isTargetUrlEmpty,
  onAddInputHandler,
  onInputChangeHandler,
  onLocalInputChange,
  onDeleteInputHandler,
}) => (
  <form className="image-linker-form" onSubmit={onInputChangeHandler}>
    <h4 key="coordsLabel">Links coordinates</h4>
    <div className="linker-instructions mb-3">
      <p>You can add clickable links.</p>
      <ul>
        <li>
          The width and height of the link describe the space of the clickable
          area.
        </li>
        <li>
          The left and top describe the link&apos;s position according to the
          image&apos;s edges.
        </li>
        <li>
          All values are expressed as <b>a percentage</b> of the total image
          size.
        </li>
        <li>
          The highlighted areas won&apos;t be visible on the generated page.
        </li>
      </ul>
    </div>
    {inputs?.[editedArea] && (
      <CoordsInputs
        editedInput={editedArea}
        input={inputs?.[editedArea]}
        isFocused={isTargetUrlEmpty}
        onLocalInputChange={onLocalInputChange}
        onDeleteInputHandler={onDeleteInputHandler}
      />
    )}
    <div className="image-linker-buttons">
      <Button
        clicked={onAddInputHandler}
        additionalClasses="btn mt-4"
        dataTestAttribute="image-linker-map-add-coord"
      >
        Add link
      </Button>
      <Button
        additionalClasses="btn mt-4"
        disabled={!formIsValid}
        dataTestAttribute="image-linker-map-accept-coords"
      >
        Save links
      </Button>
    </div>
  </form>
);

export default ImageLinkerForm;
