import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Button = (props) => {
  const onClick = props.clicked ?? props.onClick;

  return (
    <button
      id={props.id ?? null}
      type={props.type}
      disabled={props.disabled || props.isLoading}
      className={['btnDefault', props.additionalClasses].join(' ')}
      onClick={onClick}
      data-testid={props.testid || null}
      title={props.title ?? null}
    >
      {props.isLoading ? (
        <Spinner animation="grow" variant="light" />
      ) : (
        props.children
      )}
    </button>
  );
};

export default Button;
