import React from 'react';

const Error = ({ children, msg, fullWidth }) => {
  return (
    <div style={style(fullWidth)}>
      <p>{msg}</p>
      {children}
    </div>
  );
};

const style = (fullWidth) => ({
  backgroundColor: '#ffe1d1',
  padding: '5px 10px',
  borderRadius: '6px',
  color: '#d10000',
  fontSize: '16px',
  maxWidth: fullWidth ? '100%' : '286px',
});

export default Error;
