import * as actionTypes from './actionTypes';

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccess = (user) => ({
  type: actionTypes.AUTH_SUCCESS,
  user,
});

export const setToken = (tokenDetails) => ({
  type: actionTypes.SET_TOKEN,
  tokenDetails,
});

export const claimsSuccess = (claims) => ({
  type: actionTypes.CLAIMS_SUCCESS,
  claims,
});

export const lockActivation = (bool) => ({
  type: actionTypes.LOCK_ACTIVATION,
  bool,
});

export const resetPassword = (bool) => ({
  type: actionTypes.RESET_PASSWORD,
  bool,
});

export const setMultiFactor = (bool) => ({
  type: actionTypes.MULTI_FACTOR,
  bool,
});

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const authUpdate = (values = {}) => ({
  type: actionTypes.GENERIC_UPDATE,
  values,
});

export const logout = (error) => {
  return {
    type: actionTypes.AUTH_LOGOUT,
    error,
  };
};

export const authState = (user) => (dispatch) => {
  if (!user) {
    dispatch(logout());
  } else {
    dispatch(authSuccess(user));
  }
};

export const claimsState = (claims) => (dispatch) => {
  if (claims) {
    dispatch(claimsSuccess(claims));
  }
};

export const phoneVerified = (phone) => ({
  type: actionTypes.PHONE_VERIFIED,
  phone,
});

export const setProfileDetails = (data) => {
  return {
    type: actionTypes.SET_PROFILE_DETAILS,
    data,
  };
};
