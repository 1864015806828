import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CompanyAccordionBootstrap from 'react-bootstrap/Accordion';

import Tooltip from '../../UI/Tooltip/Tooltip';

import styles from './CompanyAccordion.module.scss';

export const CompanyAccordionItem = ({ step, title, body }) => {
  return (
    <div className={`${styles.accordionItem} mb-4 position-relative`}>
      <Row>
        <Col className={styles.accordionContent}>
          <CompanyAccordionBootstrap.Item eventKey={`${step}`}>
            <CompanyAccordionBootstrap.Header>
              <div className="d-flex gap-3 align-items-center">
                <span className="h3 mb-0">{title}</span>
              </div>
            </CompanyAccordionBootstrap.Header>
            <CompanyAccordionBootstrap.Body className={styles.accordionBody}>
              {body}
            </CompanyAccordionBootstrap.Body>
          </CompanyAccordionBootstrap.Item>
        </Col>
      </Row>
    </div>
  );
};

CompanyAccordionItem.Tooltip = ({
  children: tooltipContent,
  sectionKey,
  title,
}) => {
  return (
    <Tooltip id={sectionKey} title={title}>
      {tooltipContent}
    </Tooltip>
  );
};

const CompanyAccordion = ({ children, loading, ...props }) => {
  if (loading) {
    return null;
  }

  return (
    <CompanyAccordionBootstrap {...props}>{children}</CompanyAccordionBootstrap>
  );
};

export default CompanyAccordion;
