import { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLogout } from './App.logout';
import * as actions from './store/actions/index';

const eventStopPropagation = Event.prototype.stopPropagation;

const ONEMINUTE = 1000 * 60; // ms; interval
const TWENTYMINUTES = 1000 * 60 * 20; // ms; expiration length

const isDevelopment = process.env.NODE_ENV !== 'production';

/**
 * Bug: A global listener on 'mousedown' event
 *  somehow breaks react router on some pages
 *  eg. tested on connections detail nav links
 *    (stays on current page)
 */
const interactionEvents = [
  'click',
  'keydown',
  'touchstart',
  'gesturestart',
  'focus',
];

export const useSessionExpiration = (appEl) => {
  const debounceInteraction = useRef(null);
  const interval = useRef(null);
  const dispatch = useDispatch();
  const logout = useLogout();

  /**
   * lastInteraction is set here
   *  and immediately after sign in (otherwise
   *  timeout would not occur without an interaction)
   */
  const { userId, lastInteraction } = useSelector((state) => state.auth);
  const userId_ = useRef(userId);

  const setInteraction = useCallback((ev) => {
    if (!userId_.current) {
      return;
    }

    /**
     * Exit if target was not interactable
     *  otherwise state will update and cause
     *  unnecessary re-render
     */
    const el = ev?.target;
    if (!el && !['a', 'button', 'input'].includes(ev?.nodeName)) {
      return;
    }

    clearTimeout(debounceInteraction.current);
    debounceInteraction.current = setTimeout(() => {
      dispatch(
        actions.authUpdate({
          lastInteraction: +new Date(),
        }),
      );
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeListeners = useCallback((el) => {
    if (el.current) {
      for (const event of interactionEvents) {
        el.current.removeEventListener(event, setInteraction);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userId_.current = userId;
  }, [userId]);

  /**
   * Every minute, check if the TS has expired
   */
  useEffect(() => {
    // Temporary for debugging
    window.lastInteraction = {
      get date() {
        return new Date(lastInteraction);
      },
    };
    // END Temporary for debugging

    clearInterval(interval.current);

    interval.current = setInterval(() => {
      if (!userId || !lastInteraction) {
        return;
      }

      const now = +new Date();
      const expirationTs = lastInteraction + TWENTYMINUTES;
      const isExpired = now > expirationTs;
      if (isExpired && !isDevelopment) {
        logout();
      }
    }, ONEMINUTE);

    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, lastInteraction]);

  useEffect(() => {
    removeListeners(appEl);

    /**
     * Detect any primary user events
     */
    for (const event of interactionEvents) {
      appEl.current.addEventListener(event, setInteraction);
    }

    /**
     * stopPropagation is intertwined throughout the app to avoid
     *  parent container events from triggering within table rows etc,
     *  however we still need to access this event to register
     *  an interaction.
     */
    Event.prototype.stopPropagation = function _stopPropagation(ev) {
      setInteraction(ev);
      eventStopPropagation.call(this, ev);
    };

    return () => {
      removeListeners(appEl);
      Event.prototype.stopPropagation = eventStopPropagation;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appEl]);
};
