import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import * as actions from './store/actions/auth';

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        delete sessionStorage.dashboardRedirect;
        dispatch(actions.logout());
        dispatch(actions.authUpdate({ lastInteraction: null }));
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return logout;
};
