import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import Button from '../../UI/Button/Button';

import styles from '../CompanyDetail.module.scss';

const CompanyHeader = ({ title, loading, setSaveTick }) => {
  const { isAdvertiser } = useSelector((state) => state.auth);

  if (loading) {
    return null;
  }

  const handleSave = async () => {
    setSaveTick((n) => n + 1);
  };

  return (
    <div className={`${styles.container} ${styles.header}`}>
      <div className={styles.headerInner}>
        <Link className={styles.backButton} to="/companies">
          <FontAwesomeIcon
            size="xl"
            width="54"
            icon={faArrowLeftLong}
            className={styles.backIcon}
          />
        </Link>

        <div>
          <h3 className="mb-0 d-flex align-items-center gap-3">{title}</h3>
        </div>

        <div className={styles.headerControls}>
          {!isAdvertiser ? (
            <Button
              clicked={handleSave}
              additionalClasses={styles.buttonLastChild}
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
