import { string, mixed, number, date, object, bool, array } from 'yup';
import { formatHttps } from '../../../shared/inputFormatting';

export const getSchema = (values) => {
  return {
    tag: array().nullable(true),
    name: string()
      .required('Required')
      .max(40, 'Must be less than 40 characters'),
    company: string().required('Required').typeError('Required'),
    brand: string().required('Required').typeError('Required'),
    budget: number().required('Required').typeError('Must be a number'),
    connectionTarget: number().typeError('Must be a number'),
    startDate: date().required('Required'),
    endDate: date()
      .required('Required')
      .typeError('Required')
      .when('startDate', ([startDate], schema) => {
        if (startDate) {
          return schema.min(startDate, 'Must be after Start date');
        }
      }),
    fallbackUrl: values.paywall?.enabled
      ? string()
      : string().required('Required'),
    maxPerConnection: values.paywall?.enabled
      ? number().required('Required').typeError('Must be a number')
      : number().typeError('Must be a number'),
    paywall: object().shape({
      enabled: bool(),
    }),
    globalSettings: mixed().nullable(true),
    landingPageContent: mixed().nullable(true),
  };
};

/**
 * Field configurations
 *
 * type: Refers to the switch logic in Input.js
 * elementConfig.type is required to pass native type
 *
 * New options --
 *
 *  defaultValue: any                         -   default value
 *  formatOutput(value, opts): func           -   preprocess the returned selection value
 *  formatInputValue(value): func             -   preprocess "value" into Input component
 *  transposeOptions(opts): func              -   get (value, label) from an unshaped list of options
 *  valueKey: string                          -   match this property key against the options list "value"
 *                                                  property, to display the selection
 */

const displayFields = {
  tags: {
    key: 'tags',
    label: 'Campaign Tags',
    type: 'create-multi',
    defaultValue: [],
    elementConfig: {},
    formatOutput: (arr) => {
      if (!arr) {
        return null;
      }
      return arr.map((e) => e.label);
    },
    formatInputValue: (value) => {
      if (Array.isArray(value)) {
        return [...new Set(value)].map((selection) => {
          return {
            value: selection,
            label: selection,
          };
        });
      }
      return null;
    },
  },
  name: {
    key: 'name',
    label: 'Campaign Name',
    type: 'text',
    elementConfig: {},
  },
  company: {
    key: 'company',
    label: 'Company',
    type: 'modern-select',
    elementConfig: {},
  },
  brand: {
    key: 'brand',
    label: 'Brand',
    type: 'modern-select',
    elementConfig: {},
  },
  budget: {
    key: 'budget',
    label: 'Campaign Budget',
    type: 'number',
    defaultValue: 0,
    elementConfig: {},
  },
  connectionTarget: {
    key: 'connectionTarget',
    label: 'Total Connections Target',
    type: 'number',
    defaultValue: '',
    elementConfig: {},
  },
  maxPerConnection: {
    key: 'maxPerConnection',
    label: 'Max per connection',
    type: 'number',
    defaultValue: 0,
    elementConfig: {},
  },
  fallbackUrl: {
    key: 'fallbackUrl',
    label: 'Fallback URL',
    type: 'text',
    elementConfig: {
      placeholder: 'https://',
      description:
        'Visitor will be redirected to the Fallback URL if campaign ends',
    },
    formatOutput: formatHttps,
  },
  startDate: {
    key: 'startDate',
    label: 'Start Date',
    type: 'default',
    elementConfig: {
      type: 'datetime-local',
    },
  },
  endDate: {
    key: 'endDate',
    label: 'End Date',
    type: 'default',
    elementConfig: {
      type: 'datetime-local',
    },
  },
  paywall: {
    key: 'paywall.enabled',
    defaultValue: true,
  },
};

export default displayFields;
