import { mixed } from 'yup';

export const getSchema = () => ({
  landingPageContent: mixed().nullable(true),
  globalSettings: mixed().nullable(true),
});

const displayFields = {
  landingPageContent: {
    key: 'landingPageContent',
  },
  globalSettings: {
    key: 'globalSettings',
  },
};

export default displayFields;
