import React, { useState, useCallback, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';

const ProviderPriceForm = ({ cost, onSave }) => {
  const [currentCost, setCurrentCost] = useState(null);

  const [modal, setModal] = useState({
    show: false,
    header: '',
    body: '',
    handleClose: null,
    handleSave: null,
  });

  const changeCostHandler = useCallback((e) => {
    setCurrentCost(e.target.value);
  }, []);

  useEffect(() => {
    if (cost && !currentCost) {
      setCurrentCost(cost);
    }
  }, [cost, currentCost]);

  const saveCostsHandler = useCallback(() => {
    setModal({
      show: true,
      header: 'Cost per connection change confirmation',
      body:
        'Are you sure wou want to change the cost per connection?' +
        ' Only newly started campaign will use this cost,' +
        ' campaigns started before the change will still use old cost.',
      handleClose: () => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        setCurrentCost(cost);
      },
      handleSave: () => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        onSave(currentCost);
      },
    });
  }, [cost, currentCost, onSave]);

  const isChanged =
    Number.parseFloat(currentCost).toFixed(2) !==
    Number.parseFloat(cost).toFixed(2);

  return (
    <>
      <Row className="mb-4">
        <Col>
          <Input
            keyName="cost"
            type="input"
            elementConfig={{ type: 'number', step: 0.01, min: 0 }}
            required
            dataTestAttribute="provider-connection-price"
            changed={changeCostHandler}
            value={currentCost || ''}
            label="Cost per connection"
            additionalClasses="formInput formInput--bordered"
          />
          <div className="d-flex justify-content-end">
            <Button
              clicked={saveCostsHandler}
              disabled={!currentCost || !isChanged}
              additionalClasses="mt-3"
              dataTestAttribute="save-connection-price"
            >
              Save costs
            </Button>
          </div>
        </Col>
      </Row>
      {modal.show && (
        <Modal
          show
          title={modal.header}
          body={modal.body}
          cancel="No"
          save="Yes"
          handleClose={modal.handleClose}
          handleSave={modal.handleSave}
          dataTestAttribute={{
            modal: 'modal-cost-confirmation',
            action1: 'no-cost-confirmation',
            action2: 'yes-cost-confirmation',
          }}
        />
      )}
    </>
  );
};

export default ProviderPriceForm;
