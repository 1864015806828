/* eslint-disable import/no-cycle */
import ProviderService from '../../api/Providers';

import * as actionTypes from './actionTypes';

export const fetchProvidersStart = () => ({
  type: actionTypes.FETCH_PROVIDERS_START,
});

export const fetchProvidersSuccess = (providers) => ({
  type: actionTypes.FETCH_PROVIDERS_SUCCCESS,
  providers,
});

export const fetchProvidersFail = (error) => ({
  type: actionTypes.FETCH_PROVIDERS_SUCCCESS,
  error,
});

export const fetchProviders =
  (pageNr = 1, filters = {}) =>
  (dispatch) => {
    dispatch(fetchProvidersStart());

    ProviderService.list({
      page: pageNr,
      ...filters,
    })
      .then((response) => {
        dispatch(fetchProvidersSuccess(response?.data));
      })
      .catch((err) => {
        const errMessage = err.response?.data?.message || 'There was an error';
        dispatch(fetchProvidersFail(errMessage));
      });
  };

export const setProviders = (providers) => ({
  type: actionTypes.SET_PROVIDERS,
  providers,
});
