import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CampaignHeader from './CampaignHeader/CampaignHeader';
import CampaignAccordion from './CampaignAccordion/CampaignAccordion';

import { getCampaignSections } from './CampaignDetail.sections';

import { useCampaignDetail } from './CampaignDetail.hooks';

import OverlaySpinner from '../UI/OverlaySpinner/OverlaySpinner';

import styles from './CampaignDetail.module.scss';
import StatisticsPanel from './StatisticsPanel/StatisticsPanel';

/**
 * One component to manage both (Add) and (Edit)
 */
const CampaignDetail = ({ campaignId }) => {
  const [getters, actions, refs] = useCampaignDetail(campaignId);
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  const {
    isSaving,
    loading,
    saveTick,
    pageState,
    displayFields,
    selectOptions,
    formik,
    validationSchema,
    sectionConfig,
    campaignStatus,
    successTick,
    genericErrorTick,
    genericErrorMessage,
    openSectionKey,
    webhooks,
    webhookManageModal,
    countData,
    toast,
  } = getters;

  const {
    setActiveSection,
    showNextSection,
    setFieldsetValue,
    onSaveAction,
    setOpenSectionKey,
    addSectionUpdated,
    setWebhookManageModal,
    setWebhooks,
    setToast,
  } = actions;

  const { sectionsRef } = refs;

  const getProps = (key) => {
    return {
      key,
      sectionsRef,
      pageState,
      onSaveAction,
      displayFields,
      selectOptions,
      setFieldsetValue,
      showNextSection,
      formik,
      validationSchema,
      addSectionUpdated,
      fieldKeys: sectionConfig[key].fieldKeys,
      webhooks,
      setWebhookManageModal,
      setWebhooks,
      setActiveSection,
      toast,
      setToast,
    };
  };

  const displayedSections = getCampaignSections({ isAdmin, isBountyAdmin })
    .map((section) => {
      if (section.displayTest(formik.values)) {
        const { Component, key } = section;
        return { key, CampaignSection: Component, props: getProps(key) };
      }

      return null;
    })
    .filter((e) => e)
    .map((section, idx) => ({
      step: idx + 1, // accordion "eventKey"
      ...section,
    }));

  /**
   * Open first section with an error
   *  on submit
   */
  useEffect(() => {
    if (openSectionKey) {
      const openSectionEventKey = displayedSections.find(
        (s) => s.key === openSectionKey,
      );
      setActiveSection(openSectionEventKey.step);
      setOpenSectionKey(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSectionKey]);

  return (
    <>
      <div className={`${styles.campaignDetailSmallScreenAlert}`}>
        <p>
          <small>
            This page is built for <strong>desktop use only</strong>
          </small>
        </p>
      </div>

      <div className={`${styles.campaignDetail} py-5`}>
        {(loading || isSaving) && <OverlaySpinner />}
        <CampaignHeader
          loading={loading}
          formik={formik}
          campaignStatus={campaignStatus}
          pageState={pageState}
          onSaveAction={onSaveAction}
          saveTick={saveTick}
          successTick={successTick}
          genericErrorTick={genericErrorTick}
          genericErrorMessage={genericErrorMessage}
          setWebhookManageModal={setWebhookManageModal}
          webhookManageModal={webhookManageModal}
          setWebhooks={setWebhooks}
          toast={toast}
          setToast={setToast}
        />

        <StatisticsPanel
          loading={loading}
          hasStarted={!!formik.values?.enabledAt}
          cpdcCost={formik.values?.cpdcCost}
          usedBudget={formik.values?.usedBudget}
          budget={formik.values?.budget}
          connectionTarget={formik.values?.connectionTarget}
          campaignId={formik.values?.id}
          countData={countData}
        />

        <CampaignAccordion
          activeKey={`${pageState.activeSectionKey}`}
          onSelect={setActiveSection}
          loading={loading}
        >
          {/*  Some components contain forms,
                so we cannot nest in
                another form element  */}
          {displayedSections.map(({ CampaignSection, props, step }) => (
            <CampaignSection
              {...props}
              step={step}
              isLastStep={displayedSections.length === step}
              countData={countData}
            />
          ))}
        </CampaignAccordion>
      </div>
    </>
  );
};

export default CampaignDetail;
