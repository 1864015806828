import React, { useRef, useState } from 'react';
import Modal from '../../../components/UI/Modal/Modal';

import './VidioFormCodeSnippetModal.scss';

const VidioFormCodeSnippetModal = ({ handleClose, campaignId, formId }) => {
  const publicPagesUrl = process.env.REACT_APP_PUBLIC_PAGES_URL.replace(
    /\/$/,
    '',
  );
  const bannersSDK = `${publicPagesUrl}/api/banners-sdk.js`;
  const [isCopied1, setCopied1] = useState(false);
  const [isCopied2, setCopied2] = useState(false);
  const codeRef1 = useRef(null);
  const codeRef2 = useRef(null);
  function onClick(codeRef, setCopied, setUncopied) {
    navigator.clipboard.writeText(codeRef.current.innerText);
    setCopied(true);
    setUncopied(false);
  }
  const snippet = (
    <div>
      <p>Put this code where the slider should be displayed:</p>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <p
        className="codeSnippet"
        ref={codeRef1}
        onClick={() => onClick(codeRef1, setCopied1, setCopied2)}
      >
        {`<div data-banner="${formId}" data-campaign-id="${campaignId}"` +
          ' style="position: relative"></div>'}
      </p>
      <span className="codeSnippetCopyText">
        {isCopied1 ? 'Copied!' : 'Click the above code to copy it'}
      </span>
      <p>{'Put this scripts on the end of your site before </body> tag:'}</p>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <p
        className="codeSnippet"
        ref={codeRef2}
        onClick={() => onClick(codeRef2, setCopied2, setCopied1)}
      >
        {`<script src="${bannersSDK}"></script>`}
        <br />
        {`<script>initBMBanners('${publicPagesUrl}');</script>`}
      </p>
      <span className="codeSnippetCopyText">
        {isCopied2 ? 'Copied!' : 'Click the above code to copy it'}
      </span>
      <p>
        Please be advised that if you will set more than one Connection form on
        the one page you have to put the scripts only once!
      </p>
    </div>
  );
  return (
    <Modal
      title="Code snippet for Connection Form"
      body={snippet}
      cancel={false}
      save="OK"
      handleSave={handleClose}
      show
      handleClose={handleClose}
      dataTestAttribute={{
        modal: 'modal-vidio-code-snippet-section',
        action2: 'ok-modal-vidio-code-snippet-section',
      }}
    />
  );
};

export default VidioFormCodeSnippetModal;
