import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  providersRaw: {},
  providersList: [],
  error: null,
  loading: false,
};

const fetchStart = (state) =>
  updateObject(state, {
    error: null,
    loading: true,
  });

const fetchSuccess = (state, action) =>
  updateObject(state, {
    providersRaw: action.providers,
    providersList: action.providers?.data,
    error: null,
    loading: false,
  });

const fetchFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false,
  });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROVIDERS_START:
      return fetchStart(state, action);
    case actionTypes.FETCH_PROVIDERS_SUCCCESS:
    case actionTypes.SET_PROVIDERS:
      return fetchSuccess(state, action);
    case actionTypes.FETCH_PROVIDERS_FAIL:
      return fetchFail(state, action);
    default:
      return state;
  }
};

export default reducer;
