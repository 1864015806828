import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import CampaignStatus from '../../CampaignsList/CampaignListRow/CampaignStatus/CampaignStatus';
import { CampaignFilterContext } from '../../../pages/CampaignsPage/CampaignContext';
import { formatDate } from '../../../shared/dateTime';
import DataTable from '../../UI/DataTable/DataTable';
import Button from '../../UI/Button/Button';
import { campaignStatus } from '../../../shared/campaignStatus';
import styles from './CampaignTable.module.scss';
import CampaignTableManager from './CampaignTableManager';

const dateFormat = 'dd-MM-yyyy';

const gridStyle = { height: 'calc(100vh - 335px)' };

const allowedCampaignStatus = [
  campaignStatus.STATE_DRAFT,
  campaignStatus.STATE_INACTIVE,
  campaignStatus.STATE_DEACTIVATED,
];

const CheckBox = ({ value, onClick }) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (value === true) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (value === false) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (value === null) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [value]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <input
        ref={checkboxRef}
        checked={value}
        type="checkbox"
        onClick={onClick}
        className={styles.checkBox}
      />
    </div>
  );
};

const checkboxColumn = {
  renderCheckbox: (checkboxProps, cellProps) => {
    const { onChange, checked } = checkboxProps;

    if (
      Array.isArray(cellProps.data) ||
      allowedCampaignStatus.includes(cellProps.data.status)
    ) {
      const inactiveCampaignLength = Array.isArray(cellProps.data)
        ? cellProps.data.filter((campaign) =>
            allowedCampaignStatus.includes(campaign.status),
          ).length
        : 0;
      const isChecked =
        checked === null && inactiveCampaignLength === cellProps.selectedCount
          ? true
          : checked;
      return (
        <CheckBox
          value={isChecked}
          onClick={(e) => {
            e.stopPropagation();
            onChange(!checked);
          }}
        />
      );
    }

    return null;
  },
};

const CampaignTable = ({
  dataSource,
  search,
  tags,
  selectedRow,
  setSelectedRow,
  activeFilter,
  fallbackFilter,
  setActiveToggle,
  setFallbackToggle,
  showFilters,
}) => {
  const filters = useContext(CampaignFilterContext);
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const searchDebounce = useRef(null);
  const [queryFilter, setQueryFilter] = useState('');

  const [tableColumnSettings, setTableColumnSettings] = useState({
    name: {
      visible: true,
    },
    brand: {
      visible: true,
    },
    companyName: {
      visible: true,
    },
    tags: {
      visible: true,
    },
    status: {
      visible: true,
    },
    startDate: {
      visible: true,
    },
    endDate: {
      visible: true,
    },
    pageUniqueVisits: {
      visible: true,
    },
    pageCpdcCount: {
      visible: true,
    },
    budget: {
      visible: true,
    },
  });

  const columns = useMemo(() => {
    return [
      {
        header: 'Campaign name',
        name: 'name',
        width: 150,
        defaultFlex: 1,
        visible: tableColumnSettings.name.visible,
      },
      {
        header: 'Campaign brand',
        name: 'brand',
        width: 150,
        defaultFlex: 1,
        visible: tableColumnSettings.brand.visible,
      },
      {
        header: 'Company name',
        name: 'companyName',
        width: 150,
        defaultFlex: 1,
        visible: tableColumnSettings.companyName.visible,
      },
      {
        header: 'Tags',
        name: 'tags',
        width: 100,
        defaultFlex: 1,
        visible: tableColumnSettings.tags.visible && (isAdmin || isBountyAdmin),
        render: ({ value }) => (value ? value.join(', ') : ''),
      },
      {
        header: 'Delivery status',
        name: 'status',
        width: 150,
        defaultFlex: 1,
        type: 'number',
        visible: tableColumnSettings.status.visible,
        render: ({ value }) => <CampaignStatus statusCode={value} />,
      },
      {
        header: 'Start date',
        name: 'startDate',
        width: 150,
        defaultFlex: 1,
        visible: tableColumnSettings.startDate.visible,
        render: ({ value }) => formatDate(value, dateFormat),
      },
      {
        header: 'End date',
        name: 'endDate',
        width: 150,
        defaultFlex: 1,
        visible: tableColumnSettings.endDate.visible,
        render: ({ value }) => formatDate(value, dateFormat),
      },
      {
        header: 'Unique/Total visits',
        name: 'pageUniqueVisits',
        width: 150,
        type: 'number',
        defaultFlex: 1,
        visible: tableColumnSettings.pageUniqueVisits.visible,
        render: ({ value, data }) => `${value} / ${data.pageVisits}`,
      },
      {
        header: 'Connections cost',
        name: 'pageCpdcCount',
        width: 150,
        defaultFlex: 1,
        type: 'number',
        visible: tableColumnSettings.pageCpdcCount.visible,
        render: ({ value, data }) => (
          <>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value)}{' '}
            (
            <span className="text-muted small">
              (
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(data.cpdcCost)}
              /connection)
            </span>
            )
          </>
        ),
      },
      {
        header: 'Budget',
        name: 'budget',
        width: 100,
        type: 'number',
        defaultFlex: 1,
        visible: tableColumnSettings.budget.visible,
        render: ({ value }) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(value),
      },
      {
        header: 'Duplicate',
        name: 'id',
        width: 150,
        defaultFlex: 1,
        visible: !(isAdmin || isBountyAdmin),
        render: ({ data }) => (
          <Button
            additionalClasses="_copy-campaign btnDefault--small"
            clicked={() => onCopyCampaign(data)}
          >
            Copy
          </Button>
        ),
      },
    ];
  }, [tableColumnSettings]);

  const onCopyCampaign = (data) => {
    navigate('/campaigns/add', {
      state: {
        campaignData: data,
      },
    });
  };

  const onRowClick = ({ data }) => {
    navigate(`/campaigns/edit/${data.id}`);
  };

  const onSelectionChange = useCallback(
    (row) => {
      if (typeof setSelectedRow === 'function')
        if (row.selected && typeof row.selected === 'boolean') {
          setSelectedRow(
            Object.fromEntries(
              row.originalData
                .filter((item) => allowedCampaignStatus.includes(item.status))
                .map((data) => [[data.id], data]),
            ),
          );
        } else {
          setSelectedRow(row.selected);
        }
    },
    [setSelectedRow],
  );

  return (
    <>
      <CampaignTableManager
        tableColumnSettings={tableColumnSettings}
        columns={columns}
        setTableColumnSettings={setTableColumnSettings}
      />

      <div>
        <DataTable
          id="campaign"
          columns={columns}
          dataSource={dataSource}
          style={gridStyle}
          columnOrderPersist
          onRowClick={onRowClick}
          pageSizes={[50, 100]}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          checkboxColumn={checkboxColumn}
          onSelectionChange={onSelectionChange}
          enableSelection
        />
      </div>
    </>
  );
};

export default CampaignTable;
