import React from 'react';
import { formatDate } from '../../shared/dateTime';
import Button from '../UI/Button/Button';

const dateFormat = 'dd-MM-yyyy';

const standardCell = {
  padding: 10,
};

const bigCellStyle = {
  position: 'relative',
  minHeight: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  backgroundColor: 'rgba(233, 2, 2, 0.05)',
  padding: 10,
};

const deleteButtonStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  bottom: 10,
  color: '#dd5500',
};

const getCellValue = (key, value) => {
  switch (key) {
    case 'requestDeletedDate': {
      if (!value) {
        return '';
      }
      const d = new Date(value);
      return d.toLocaleDateString();
    }

    case 'dueDate': {
      const d = new Date(value);
      d.setDate(d.getDate() + 30);
      return d.toLocaleDateString();
    }

    default: {
      return value;
    }
  }
};

const DeleteButton = ({ onDelete }) => {
  const handlePress = (e) => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <Button
      clicked={handlePress}
      additionalClasses="btnDefault--small btnDefault--red"
    >
      Delete PII Data
    </Button>
  );
};

const TableCellName = ({ value, data, onDelete }) => {
  if (!data.pendingDelete) {
    return <div style={standardCell}>{value}</div>;
  }
  return (
    <div style={bigCellStyle}>
      <span>{value}</span>
      <div style={deleteButtonStyle}>
        <DeleteButton onDelete={onDelete} />
        <small className="mt-1 text-danger">
          Due: {getCellValue('dueDate', data.requestDeletedDate)}
        </small>
      </div>
    </div>
  );
};

const TableCell = ({ value, data, children }) => {
  if (!data.pendingDelete) {
    return <div style={standardCell}>{children ?? value}</div>;
  }
  return (
    <div style={bigCellStyle}>
      <div>{children ?? value}</div>
    </div>
  );
};

const style = {
  padding: 0,
};

const render = ({ value, data }) => {
  return <TableCell value={value} data={data} />;
};

export const getColumns = ({ openDeleteConnectionModal: onDelete } = {}) => [
  {
    name: 'firstName',
    header: 'First Name',
    defaultWidth: 175,
    render: ({ value, data }) => {
      return (
        <TableCellName
          value={value}
          data={data}
          onDelete={() => onDelete({ id: data.id })}
        />
      );
    },
    style,
  },
  { name: 'lastName', header: 'Last Name', render, style },
  { name: 'email', header: 'Email', render, style },
  { name: 'phoneNumber', header: 'Phone', render, style },
  { name: 'age', header: 'Age', render, style, type: 'number' },
  { name: 'gender', header: 'Gender', render, style },
  { name: 'city', header: 'City', render, style },
  { name: 'country', header: 'Country', render, style },
  { name: 'campaignsAccepted', header: 'Campaigns', render, style },
  { name: 'questionsAnswered', header: 'Answers', render, style },
  {
    name: 'requestDeletedDate',
    header: 'Deletion Requested',
    render: ({ value, data }) => (
      <TableCell data={data}>
        <span>{formatDate(value, dateFormat)}</span>
      </TableCell>
    ),
    style,
  },
  {
    name: 'createdAt',
    header: 'Date Created',
    render: ({ value, data }) => (
      <TableCell data={data}>
        <span>{formatDate(value, dateFormat)}</span>
      </TableCell>
    ),
    style,
  },
];
