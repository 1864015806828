import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import englishLabels from 'react-phone-number-input/locale/en';

import createInput from '../../../shared/Form/createInput';
import { productTypes, getKey } from '../constants';

export default {
  name: createInput('Offer name', { type: 'text' }, { required: true }),
  isTestPhoneEnabled: {
    elementType: 'toggle',
    value: false,
    label: 'ENABLE TEST PHONE?',
    description:
      'Enabling test phone means test phone numbers can bypass OTP flow on this offer.',
  },
  runningLowNotificationEnabled: {
    elementType: 'toggle',
    value: false,
    label: 'RECIEVE LOW CODE NOTIFICATION?',
    description:
      'Enabling this will send you a Slack and SMS notification when the offer is running low on codes.',
  },
  productName: createInput(
    'Product Name',
    {
      type: 'text',
    },
    {
      required: true,
    },
  ),
  urlSlug: createInput(
    'Unique URL Slug',
    {
      type: 'text',
    },
    {
      required: true,
    },
  ),
  productType: createInput(
    'Product Type',
    {
      type: 'select',
      options: [
        {
          value: getKey(productTypes, 'Unique Code'),
          label: productTypes.uniqueCode,
        },
        {
          value: getKey(productTypes, 'Account Update'),
          label: productTypes.accountUpdate,
        },
        {
          value: getKey(productTypes, 'SMS Code'),
          label: productTypes.smsCode,
        },
        {
          value: getKey(productTypes, 'Automatic Redemption'),
          label: productTypes.automaticRedemption,
        },
      ],
    },
    { required: true },
    'uniqueCode',
    true,
    'modern-select',
  ),
  company: createInput(
    'Company',
    {
      type: 'select',
      options: [],
    },
    { required: true },
    'active',
    true,
    'modern-select',
  ),
  brand: createInput(
    'Brand',
    {
      type: 'select',
      options: [],
    },
    { required: true },
    'active',
    true,
    'modern-select',
  ),
  arProvider: createInput(
    'AR Offer',
    {
      type: 'select',
      options: [],
    },
    { required: true },
    '',
    true,
    'modern-select',
  ),
  rewardType: createInput(
    'Reward Type',
    {
      type: 'select',
      options: [],
    },
    { required: true },
    '',
    true,
    'modern-select',
  ),
  redemptionDetails: createInput(
    'Redemption Details',
    { type: 'text' },
    { required: true },
    '',
    true,
  ),
  url: createInput(
    'Redeem URL',
    {
      type: 'text',
      placeholder: 'https://',
    },
    {
      isUrl: true,
      required: true,
    },
  ),
  verificationMethods: createInput(
    'User verification methods',
    {
      type: 'multiselect',
      description: 'Verification by email is enabled by default',
    },
    { required: false },
    '',
    true,
    'multiselect',
  ),
  minimumAge: createInput(
    'Minimum Age',
    {
      type: 'number',
      description: 'Accepting between 0 to 120',
    },
    { required: false },
  ),
  defaultPhoneCountryKey: {
    value: '',
  },
  /**
   * Value needs to be derived from two
   *  separately stored values in backend:
   *  - {code}-{key} eg. 1-US
   */
  defaultPhoneCountryCode: createInput(
    'Default Phone Country Code',
    {
      type: 'select',
      placeholder: 'Select Country',
      options: [
        { value: '', label: 'Select None' },
        ...getCountries().map((countryKey) => {
          return {
            /**
             * The countryKey will allow a unique selection
             *  it must be separated during BE save
             */
            value: `${getCountryCallingCode(countryKey)}-${countryKey}`,
            label: `${englishLabels[countryKey]} +${getCountryCallingCode(
              countryKey,
            )}`,
          };
        }),
      ],
    },
    { required: false },
    '',
    true,
    'modern-select',
  ),
  excludedIndustries: createInput(
    'Exclude Advertising Industries',
    {
      type: 'multiselect',
      placeholder: 'Select Industries',
    },
    { required: false },
    [],
    true,
    'create-multi',
  ),
  active: createInput(
    'Active',
    {
      type: 'select',
      options: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
    },
    { required: false },
    'active',
    true,
    'modern-select',
  ),
};
