import React, { useState } from 'react';

import Modal from '../../../UI/Modal/Modal';
import LanguageDropdown from '../../../UI/LanguageDropdown/LanguageDropdown';

const modalMessage = 'Do you want to save changes before continue?';

const SwitchLanguageVersion = ({
  currentLanguage,
  dataChanged,
  languages,
  onClickAction,
  onSwitchLanguage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState(null);

  const onShowModal = (lang) => {
    setCurrent(lang);
    if (current !== lang && dataChanged) {
      setShowModal(true);
    } else {
      onSwitchLanguage(lang);
    }
  };

  const onChangeLanguage = () => {
    onSwitchLanguage(current);
    onClickAction(null);
    setShowModal(false);
  };

  const onCancel = () => {
    onSwitchLanguage(current);
    setShowModal(false);
  };

  return (
    <>
      {languages?.length && (
        <LanguageDropdown
          current={currentLanguage}
          availableLanguages={languages}
          switchLanguage={(lang) => onShowModal(lang)} // languageDropdown onclick
          type="NORMAL"
          toggleClassName="mb-2"
        />
      )}
      {showModal && (
        <Modal
          title="Save progress"
          body={modalMessage}
          cancel="No"
          save="Yes"
          handleSave={() => onChangeLanguage()}
          show
          handleClose={() => onCancel()}
          dataTestAttribute={{
            modal: 'modal-vidio-section-translation-progress',
            action1: 'discard-translation-progress-vidio-section',
            action2: 'save-translation-progress-vidio-section',
          }}
        />
      )}
    </>
  );
};

export default SwitchLanguageVersion;
