import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import FormikInput from '../../UI/FormikInput/FormikInput';
import TextLink from '../../UI/Button/TextLink';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';

const CampaignSectionServiceType = ({
  step,
  displayFields,
  selectOptions,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  sectionsRef,
  setActiveSection,
}) => {
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  const paywallEnabled = !!formik.values.paywall?.enabled;
  const isGlobalFallback = !!formik.values.isGlobalFallback;
  const campaignNotStarted = !formik.values.enabled;
  const noServiceProviderSet = !formik.values.videoProvider?.id;
  const hasServiceProvider = !noServiceProviderSet;

  const showBroadSpectrumServicePaywallMessage =
    paywallEnabled && campaignNotStarted && noServiceProviderSet;

  const showVerificationMethodInput = !paywallEnabled && hasServiceProvider;

  const showProviderFallbackToggle =
    (isAdmin || isBountyAdmin) && paywallEnabled && hasServiceProvider;

  const verificationOptions =
    formik.values.videoProvider?.verificationMethods ?? [];

  const isFallbackCampaign =
    formik.values.isProviderFallback || isGlobalFallback;

  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="servicetype"
      title="Offer Type"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      sectionsRef={sectionsRef}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          The offer selected in this section will send consumers to this offer
          based on the Target Group and Qualifying Questions
        </p>
      </CampaignAccordionItem.Tooltip>

      <Row>
        <Col xs={4}>
          <FormikInput
            key="videoProvider"
            containerClass="mb-2"
            field={displayFields.videoProvider}
            options={selectOptions.provider}
            formik={formik}
            validationSchema={validationSchema}
          />

          {showBroadSpectrumServicePaywallMessage && (
            <p className="mt-2 text-muted ml-1 cpep-short-paragraph">
              <small>
                If no specific offer is selected, This campaign will receive
                consumers from any running offer in the platform that matches
                the offer provider industry targeting as well as the Target
                Group and Qualifying Questions.
              </small>
            </p>
          )}

          {hasServiceProvider ? (
            <TextLink
              muted
              clicked={() => {
                formik.updateFieldValue(
                  'videoProvider',
                  displayFields.videoProvider.defaultValue,
                );
                formik.updateFieldValue('isProviderFallback', false);
                setActiveSection('6');
              }}
            >
              <small>Unset {formik.values.videoProvider.name}</small>
            </TextLink>
          ) : null}
        </Col>

        {showVerificationMethodInput && (
          <Col xs={4}>
            <FormikInput
              key="verificationMethod"
              field={displayFields.verificationMethod}
              options={verificationOptions}
              formik={formik}
              validationSchema={validationSchema}
            />
          </Col>
        )}

        {showProviderFallbackToggle ? (
          <Col xs={4}>
            <ToggleSwitchInput
              id="isProviderFallback"
              label="Enable fallback-only mode"
              text="Paywall Fallback"
              description="This campaign will be displayed to the consumer by default as a fallback campaign. And only if the consumer didn’t match with any other campaign’s Target Group and Qualifying Questions for the offer"
              onCheck={(isProviderFallback) => {
                if (isProviderFallback) {
                  setActiveSection('5');
                } else {
                  setActiveSection('6');
                }
                formik.updateFieldValue(
                  'isProviderFallback',
                  isProviderFallback,
                );
              }}
              value={formik.values.isProviderFallback}
            />
          </Col>
        ) : null}

        {!isFallbackCampaign && (
          <Col xs={4}>
            <ToggleSwitchInput
              label="Multiple access to the same user"
              text="Multiple submissions"
              description="If turned ON, this campaign allows the same consumer to claim the offer more than once"
              onCheck={(bool) => {
                formik.updateFieldValue('multipleSubscriptionsAllowed', bool);
              }}
              value={
                !formik.values.protected &&
                formik.values.multipleSubscriptionsAllowed
              }
              disabled={formik.values.protected}
            />
          </Col>
        )}
      </Row>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionServiceType;
