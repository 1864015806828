import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './StatisticsPanel.module.scss';

const formatNumber = (num) => (num ? num.toLocaleString() : 0);

const StatisticsPanel = ({
  loading,
  hasStarted,
  cpdcCost,
  usedBudget,
  budget,
  connectionTarget,
  campaignId,
  countData,
}) => {
  const stat = useMemo(
    () => ({
      connections: formatNumber(countData.connections),
      spent: formatNumber(usedBudget),
      budget: formatNumber(budget),
      connectionTarget: formatNumber(connectionTarget),
    }),
    [countData, cpdcCost, budget, connectionTarget],
  );

  if (loading || !hasStarted || !campaignId) {
    return null;
  }

  return (
    <div className={styles.outer}>
      <div className={styles.statistics}>
        <div className={`d-flex ${styles.cell}`}>
          <span className={`text-semibold ${styles.label}`}>
            Total Connections
          </span>
          <div className="mx-3">
            <ProfileSvg />
          </div>
          <span className={styles.statistic}>
            {stat.connections}
            {stat.connectionTarget ? (
              <span className={styles.statisticMuted}>
                {' '}
                / {stat.connectionTarget}
              </span>
            ) : null}
          </span>
        </div>
        <div className={styles.divider} />
        <div className={`d-flex ${styles.cell}`}>
          <span className={`text-semibold ${styles.label}`}>Budget Spent</span>
          <div className="mx-3">
            <DollarSvg />
          </div>
          <span className={styles.statistic}>
            {stat.spent}{' '}
            <span className={styles.statisticMuted}>/ {stat.budget}</span>
          </span>
        </div>
        <Link
          className={styles.cta}
          to={`/campaigns/edit/${campaignId}/report`}
        >
          <ChartSvg />
          <span className={styles.ctaText}>View Analytics</span>
        </Link>
      </div>
    </div>
  );
};

const ProfileSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ clipPath: 'url(#statistics_profile)' }}>
      <path
        d="M11.9969 13.3905C9.59621 13.3905 7.57888 15.1024 7.20206 17.4576C7.15983 17.7045 7.23129 17.9611 7.40022 18.1625C7.57239 18.3574 7.81927 18.4711 8.07591 18.4711C8.51445 18.4711 8.88154 18.1625 8.94975 17.7337C9.19014 16.2426 10.4733 15.1576 11.9969 15.1576C13.5204 15.1576 14.8036 16.2394 15.044 17.7337C15.1122 18.1593 15.4793 18.4711 15.9178 18.4711C16.1777 18.4711 16.4246 18.3574 16.5935 18.1625V18.156C16.7624 17.9579 16.8339 17.7045 16.7917 17.4576C16.4148 15.0992 14.3975 13.3872 11.9969 13.3872V13.3905Z"
        fill="#FF6F00"
      />
      <path
        d="M11.9935 12.5979C12.2046 12.5979 12.4158 12.5816 12.6302 12.5427C13.9783 12.3218 15.2582 11.0419 15.4791 9.69372C15.674 8.51126 15.3199 7.36454 14.5046 6.54916C13.8451 5.88971 12.9518 5.52588 11.9967 5.52588C11.7791 5.52588 11.5647 5.54212 11.3633 5.57786C10.0151 5.802 8.73521 7.08192 8.51431 8.4268C8.3194 9.60926 8.67349 10.756 9.48887 11.5714C10.1581 12.2373 11.0449 12.5979 11.9935 12.5979ZM10.236 9.06026C10.236 7.84207 11.1196 7.29632 11.9967 7.29632C12.8738 7.29632 13.7574 7.84207 13.7574 9.06026C13.7574 10.2785 12.8738 10.8242 11.9967 10.8242C11.5322 10.8242 11.1001 10.6683 10.7785 10.3824C10.4212 10.0673 10.2328 9.60926 10.2328 9.06026H10.236Z"
        fill="#FF6F00"
      />
      <path
        d="M11.9968 0C5.38278 0 0 5.38603 0 12.0032C0 18.6205 5.38278 24 11.9968 24C18.6107 24 23.9935 18.6172 23.9935 12.0032C23.9935 5.38928 18.614 0 11.9968 0ZM11.9968 22.1451C6.40282 22.1451 1.85165 17.5972 1.85165 12.0032C1.85165 6.40931 6.40282 1.85815 11.9968 1.85815C17.5907 1.85815 22.1419 6.40931 22.1419 12.0032C22.1419 17.5972 17.5907 22.1451 11.9968 22.1451Z"
        fill="#FF6F00"
      />
    </g>
    <defs>
      <clipPath id="statistics_profile">
        <rect width="23.9935" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DollarSvg = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ clipPath: 'url(#statistics_dollar)' }}>
      <path
        d="M13.2046 11.0709H12.7789C12.4377 11.0709 12.1908 10.824 12.1908 10.4861C12.1908 10.1645 12.4182 9.9274 12.7399 9.90791L12.7269 9.7195L12.7367 9.89817H14.3614C14.6083 9.90791 14.8553 9.80721 15.0307 9.63179C15.2094 9.45962 15.3102 9.21598 15.3102 8.96909C15.3102 8.72221 15.2094 8.47857 15.0307 8.3064C14.852 8.13422 14.6181 8.03677 14.3646 8.04327H13.9129V7.70867C13.9162 7.45853 13.8187 7.2149 13.6432 7.03948C13.471 6.86406 13.2306 6.7666 12.9836 6.7666C12.9479 6.7666 12.9121 6.7666 12.8764 6.7731C12.6489 6.80234 12.441 6.91278 12.2882 7.08496C12.1388 7.26038 12.0575 7.48127 12.0608 7.70542V8.15696C11.047 8.47207 10.3289 9.42713 10.3289 10.4894C10.3289 11.8343 11.4272 12.929 12.7756 12.929H13.2013C13.5393 12.929 13.7927 13.1824 13.7927 13.5203C13.7927 13.8581 13.5458 14.1082 13.2013 14.1082H13.1266C13.0778 14.1017 13.0259 14.0985 12.9739 14.0985C12.9349 14.0985 12.8959 14.1017 12.8569 14.1082H11.6156C11.3687 14.1017 11.1217 14.1992 10.9462 14.3714C10.7675 14.5468 10.6668 14.7872 10.6668 15.0341C10.6668 15.2809 10.7675 15.5246 10.9462 15.6968C11.125 15.8689 11.3622 15.9664 11.6124 15.9599H12.064V16.2815C12.0413 16.5414 12.129 16.798 12.3045 16.9897C12.48 17.1813 12.7302 17.2918 12.9901 17.2918C13.2501 17.2918 13.5003 17.1813 13.6757 16.9897C13.8512 16.798 13.9389 16.5381 13.9162 16.2977V15.8462C14.93 15.5311 15.6481 14.576 15.6481 13.5138C15.6481 12.1656 14.5498 11.0676 13.2013 11.0676L13.2046 11.0709Z"
        fill="#FF6F00"
      />
      <path
        d="M12.9934 0C6.37765 0 0.993408 5.38603 0.993408 12.0032C0.993408 18.6205 6.37765 24 12.9934 24C19.6092 24 24.9934 18.6172 24.9934 12.0032C24.9934 5.38928 19.6124 0 12.9934 0ZM23.1413 12.0032C23.1413 17.5939 18.5889 22.1451 12.9934 22.1451C7.39796 22.1451 2.84556 17.5939 2.84556 12.0032C2.84556 6.41256 7.39796 1.85815 12.9934 1.85815C18.5889 1.85815 23.1413 6.40931 23.1413 12.0032Z"
        fill="#FF6F00"
      />
    </g>
    <defs>
      <clipPath id="statistics_dollar">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.993408)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ChartSvg = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 16H15H1Z" fill="#FF6F00" />
    <path
      d="M1 16H15"
      style={{
        stroke: '#FF6F00',
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
    />
    <path
      d="M3.84896 6.37891H1.98229C1.85852 6.37891 1.73983 6.42691 1.65231 6.51236C1.56479 6.5978 1.51562 6.71369 1.51562 6.83453V13.0614H4.31562V6.83453C4.31562 6.71369 4.26646 6.5978 4.17894 6.51236C4.09142 6.42691 3.97273 6.37891 3.84896 6.37891Z"
      fill="#FF6F00"
    />
    <path
      d="M8.93368 0H7.06701C6.94324 0 6.82454 0.0480034 6.73703 0.133449C6.64951 0.218896 6.60034 0.334785 6.60034 0.455624V13.0612H9.40034V0.455624C9.40034 0.334785 9.35117 0.218896 9.26366 0.133449C9.17614 0.0480034 9.05744 0 8.93368 0Z"
      fill="#FF6F00"
    />
    <path
      d="M14.0179 2.73389H12.1512C12.0275 2.73389 11.9088 2.78189 11.8213 2.86734C11.7337 2.95278 11.6846 3.06867 11.6846 3.18951V13.0614H14.4846V3.18951C14.4846 3.06867 14.4354 2.95278 14.3479 2.86734C14.2604 2.78189 14.1417 2.73389 14.0179 2.73389Z"
      fill="#FF6F00"
    />
  </svg>
);

StatisticsPanel.propTypes = {
  loading: PropTypes.bool,
  hasStarted: PropTypes.bool,
  cpdcCost: PropTypes.number,
  budget: PropTypes.number,
  campaignId: PropTypes.string,
};

export default StatisticsPanel;
