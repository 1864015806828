import React from 'react';
import Toast from 'react-bootstrap/Toast';

const Toaster = ({
  children,
  toast,
  closeToast,
  autohide = true,
  delay = 6500,
}) => (
  <div
    aria-live="polite"
    aria-atomic="true"
    className={`toast-container ${toast.class}`}
  >
    <Toast
      show={toast.show}
      onClose={closeToast}
      autohide={autohide}
      delay={delay}
    >
      <Toast.Header onClick={closeToast}>
        {toast.header && <strong className="me-auto">{toast.header}</strong>}
      </Toast.Header>

      {toast.body ? (
        <Toast.Body onClick={closeToast}>{toast.body}</Toast.Body>
      ) : null}

      {children ? <Toast.Body onClick={closeToast}>children</Toast.Body> : null}
    </Toast>
  </div>
);

export default Toaster;
