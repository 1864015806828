import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faChartPie,
  faFileCsv,
  faTable,
} from '@fortawesome/free-solid-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './ChartTypeSelect.scss';

export const CHART_BARS = 'bars';
export const CHART_PIE = 'pie';
export const CHART_TABLE = 'table';
export const CHART_CSV = 'csv';

const ChartTypeSelect = ({ selectedType, onTypeChange }) => {
  const selectedClass = (type) =>
    selectedType === type ? 'chartTypeSelect--selected' : '';

  return (
    <div className="chartTypeSelect-wrapper">
      <div className="chartTypeSelect my-4">
        <p className="mb-2">Choose bar type</p>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Change to bar chart</Tooltip>}
        >
          <button
            onClick={() => onTypeChange(CHART_BARS)}
            className={`chartTypeSelect--button ${selectedClass(CHART_BARS)}`}
          >
            <FontAwesomeIcon icon={faChartBar} title="Change to bar chart" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Change to pie chart</Tooltip>}
        >
          <button
            onClick={() => onTypeChange(CHART_PIE)}
            className={`chartTypeSelect--button ${selectedClass(CHART_PIE)}`}
          >
            <FontAwesomeIcon icon={faChartPie} title="Change to pie chart" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Change to table</Tooltip>}
        >
          <button
            onClick={() => onTypeChange(CHART_TABLE)}
            className={`chartTypeSelect--button ${selectedClass(CHART_TABLE)}`}
          >
            <FontAwesomeIcon icon={faTable} title="Change to table" />
          </button>
        </OverlayTrigger>
      </div>
      <div className="csvExport my-4">
        <p className="mb-2 ms-0">Export data</p>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Export to .csv</Tooltip>}
        >
          <button
            onClick={() => onTypeChange(CHART_CSV)}
            className={`chartTypeSelect--button  ${selectedClass(CHART_CSV)}`}
          >
            <FontAwesomeIcon icon={faFileCsv} title="Get CSV" />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ChartTypeSelect;
