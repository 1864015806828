/* eslint-disable import/no-unresolved */

import React, { useRef, useEffect, Children, useState } from 'react';
/**
 * Unable to resolve path to module 'swiper' #2266
 * https://github.com/import-js/eslint-plugin-import/issues/2266
 */
import { register } from 'swiper/element/bundle';
import getSwiperStyles from './getSwiperStyles';

register();

const Carousel = ({
  children,
  slidesPerView = 1,
  slidesPerGroup = 1,
  breakpoints = {},
  slideMinHeight = '0',
}) => {
  const swiperElRef = useRef(null);
  const [slides, setSlides] = useState([]);
  const numberOfSlides = children.length;

  useEffect(() => {
    const _slides = [];
    Children.forEach(children, (child, idx) => {
      _slides.push(
        <swiper-slide
          key={`swiper-slide-${idx}-${child.key}`}
          style={{
            minHeight: `${slideMinHeight < 200 ? 200 : slideMinHeight}px`,
          }}
        >
          {child}
        </swiper-slide>,
      );
    });
    setSlides(_slides);
  }, [children, slideMinHeight]);

  useEffect(() => {
    // Loop is buggy when slides > 1
    // Couldn't see solution, could be internal plugin bug
    const enableLoop = slidesPerGroup === 1 && slidesPerView === 1;
    const swiperParams = {
      slidesPerView,
      slidesPerGroup,
      spaceBetween: 12,
      loop: enableLoop,
      navigation: true,
      injectStyles: [getSwiperStyles(numberOfSlides)],
      pagination: {
        clickable: true,
      },
      breakpoints,
    };

    Object.assign(swiperElRef.current, swiperParams);

    swiperElRef.current.initialize();
  }, [breakpoints, numberOfSlides, slidesPerGroup, slidesPerView]);

  return (
    <swiper-container ref={swiperElRef} init="false">
      {slides}
    </swiper-container>
  );
};

export default Carousel;
