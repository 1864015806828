import React from 'react';

import BrandEditForm from '../BrandEditForm/BrandEditForm';
import Modal from '../../UI/Modal/Modal';
import './BrandEditModal.scss';

const BrandEditModal = ({ id, brandName, brandLogo, show, close }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal
      dialogClassName="edit-brand-modal"
      title="Edit Brand"
      body={
        <BrandEditForm
          id={id}
          brandName={brandName}
          brandLogo={brandLogo}
          hideModal={close}
        />
      }
      handleClose={close}
      size="md"
      show
      dataTestAttribute={{
        modal: 'edit-brand-modal',
        action2: 'edit-brand-modal-close',
      }}
    />
  );
};

export default BrandEditModal;
