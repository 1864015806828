import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CampaignAccordionBootstrap from 'react-bootstrap/Accordion';

import Button from '../../UI/Button/Button';
import Tooltip from '../../UI/Tooltip/Tooltip';

import iconError from '../../../assets/icons/status-error.svg';
import iconComplete from '../../../assets/icons/status-complete.svg';

import styles from './CampaignAccordion.module.scss';

const statusClasses = {
  pending: styles.statusIndicatorPending,
  complete: styles.statusIndicatorSuccess,
  error: styles.statusIndicatorError,
};

const statusLabel = {
  pending: 'Incomplete',
  complete: 'All done',
  error: 'Error, please review',
};

const statusIcon = {
  pending: '',
  complete: iconComplete,
  error: iconError,
};

/**
 * Existing campaigns only show 'error' or 'complete'
 * 'pending' status only applies to a new campaign
 */

const getSectionStatus = (
  formik,
  fieldKeys = [],
  validationSchema,
  completionStatusTest,
) => {
  const validateAllFields = (key) => formik.errors[key];
  const validateTouchedFields = (key) =>
    formik.errors[key] && formik.touched[key];

  const isNewCampaign = !formik.values.id;
  const checkErrors = isNewCampaign ? validateTouchedFields : validateAllFields;
  if (fieldKeys.some(checkErrors)) {
    return 'error';
  }

  if (completionStatusTest && !completionStatusTest(formik.values)) {
    return 'pending';
  }

  const hasAnEmptyRequiredField = fieldKeys.some((key) => {
    const isOptional = validationSchema?.fields?.[key]?.optional;
    const isRequired = isOptional === undefined ? false : !isOptional;
    const hasNoValue = !formik.values[key];
    return isRequired && hasNoValue;
  });

  if (hasAnEmptyRequiredField) {
    return 'pending';
  }

  if (isNewCampaign) {
    const noFieldTouched = !fieldKeys.some((key) => formik.touched[key]);
    if (noFieldTouched) {
      return 'pending';
    }
  }

  return 'complete';
};

export const CampaignAccordionItem = ({
  sectionKey,
  children,
  title,
  step,
  formik,
  fieldKeys,
  showNextSection,
  validationSchema,
  completionStatusTest,
  isLastStep = false,
  sectionsRef,
  onSaveAction,
  isUpdated,
  generateTokenButton = false,
  onGenerateTokenAction = () => {},
  isLoading = false,
}) => {
  const status = getSectionStatus(
    formik,
    fieldKeys,
    validationSchema,
    completionStatusTest,
  );

  const statusClass = statusClasses[status];
  const iconLabel = statusLabel[status];
  const iconSrc = statusIcon[status];

  const isContentArray = Array.isArray(children);
  const tooltipContent = isContentArray ? children[0] : null;
  const mainContent = isContentArray ? children[1] : children;

  const isPending = status === 'pending';

  const setAllFieldsTouched = () => {
    if (fieldKeys) {
      formik.setTouchedFields(fieldKeys);
    }
  };

  const onSave = () => {
    onSaveAction();
    setAllFieldsTouched();
  };

  const onNext = (ev) => {
    showNextSection(ev);
    setAllFieldsTouched();
  };

  const onGenerateToken = (e) => {
    onGenerateTokenAction(e);
  };

  const isDirty = formik.dirty;
  const showNextButton = !isLastStep && !!showNextSection;

  return (
    <div
      className={`${styles.accordionItem} mb-4 position-relative`}
      ref={(element) => {
        // eslint-disable-next-line no-param-reassign
        sectionsRef.current[sectionKey] = element;
      }}
    >
      <Row>
        <Col
          className={`${styles.statusIndicatorColumn} d-flex justify-content-end align-items-start`}
        >
          <div
            className={`${styles.statusIndicator} ${statusClass} d-flex justify-content-center align-items-center`}
          >
            {isPending ? (
              <span>{step}</span>
            ) : (
              <img alt={iconLabel} src={iconSrc} width={16} height={16} />
            )}
          </div>
        </Col>

        <Col className={styles.accordionContent}>
          <CampaignAccordionBootstrap.Item eventKey={`${step}`}>
            <CampaignAccordionBootstrap.Header>
              <div className="d-flex gap-3 align-items-center">
                <span className="h3 mb-0">{title}</span>

                {tooltipContent ? (
                  <CampaignAccordionItem.Tooltip
                    sectionKey={sectionKey}
                    title={tooltipContent.props.title}
                  >
                    {tooltipContent.props.children}
                  </CampaignAccordionItem.Tooltip>
                ) : null}
              </div>
            </CampaignAccordionBootstrap.Header>
            <CampaignAccordionBootstrap.Body className={styles.accordionBody}>
              {mainContent}

              {showNextButton && (
                <div className="d-flex justify-content-end">
                  {generateTokenButton && formik.values.protected && (
                    <Button
                      clicked={onGenerateToken}
                      additionalClasses="mx-3"
                      disabled={
                        !(
                          formik.values.uniqueIdFrom &&
                          formik.values.uniqueIdTo &&
                          formik.values.uniqueIdFrom <= formik.values.uniqueIdTo
                        )
                      }
                      isLoading={isLoading}
                    >
                      Generate Token
                    </Button>
                  )}
                  <Button clicked={onNext} additionalClasses="mx-0">
                    Next
                  </Button>
                </div>
              )}

              {isLastStep && (
                <div className="d-flex justify-content-end">
                  <Button
                    clicked={onSave}
                    additionalClasses="mx-0"
                    type="submit"
                    disabled={!isDirty && !isUpdated}
                  >
                    Save
                  </Button>
                </div>
              )}
            </CampaignAccordionBootstrap.Body>
          </CampaignAccordionBootstrap.Item>
        </Col>
      </Row>
    </div>
  );
};

CampaignAccordionItem.Tooltip = ({
  children: tooltipContent,
  sectionKey,
  title,
}) => {
  return (
    <Tooltip id={sectionKey} title={title}>
      {tooltipContent}
    </Tooltip>
  );
};

// const TooltipIcon = () => {
//   return (
//     <img
//       className="ms-2"
//       alt="More info"
//       src={iconExclamation}
//       width="20"
//       height="20"
//     />
//   );
// };

const CampaignAccordion = ({ children, loading, ...props }) => {
  if (loading) {
    return null;
  }

  return (
    <CampaignAccordionBootstrap {...props}>
      {children}
    </CampaignAccordionBootstrap>
  );
};

export default CampaignAccordion;
