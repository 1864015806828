import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import definedLanguages from '../../../../shared/languages';
import './LanguageCard.scss';

const LanguageCard = ({
  languageName,
  idx,
  onRemoveLanguage,
  provided,
  defaultLanguage,
  isPaywall,
}) => {
  const showActiveIndicatorClass =
    defaultLanguage ===
      Object.keys(definedLanguages).find(
        (lngCode) => definedLanguages[lngCode] === languageName,
      ) && 'activeLanguage';
  return (
    <Card
      className={['languagesCard', !isPaywall && showActiveIndicatorClass]}
      key={`${languageName}_${idx}`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Card.Body>
        <span
          className="languageText"
          {...(idx === 0 && { 'data-test-id': 'default-language' })}
        >
          {languageName}
          <span className="languageText__default">(default)</span>
        </span>

        <FontAwesomeIcon
          title="Remove language name"
          icon={faTimes}
          className="removeLanguage"
          onClick={() =>
            showActiveIndicatorClass ? {} : onRemoveLanguage(idx)
          }
          {...(showActiveIndicatorClass && {
            style: {
              opacity: '.3',
              cursor: 'not-allowed',
            },
          })}
        />
      </Card.Body>
    </Card>
  );
};

export default LanguageCard;
