import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import CarouselImagesService from '../../api/CarouselImages';
import FileService from '../../api/File';
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import Pagination from '../../components/UI/Pagination/Pagination';
import FileUploadButton from '../../components/UI/FileUploadButton/FileUploadButton';
import CarouselImagesList from '../../components/CarouselImages/CarouselImagesList/CarouselImagesList';
import Toaster from '../../components/UI/Toaster/Toaster';
import Modal from '../../components/UI/Modal/Modal';
import OverlaySpinner from '../../components/UI/OverlaySpinner/OverlaySpinner';

const limit = 10;
const order = 'id';
const orderDirection = 'asc';

const ProviderCarouselImagesPage = () => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const providers = useSelector((state) => state.provider?.providersList);
  const [images, setImages] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentProvider, setCurrentProvider] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [modal, setModal] = useState({
    show: false,
    header: '',
    body: '',
    handleClose: null,
    handleSave: null,
  });

  const [isEditing, setIsEditing] = useState('');
  const [alt, setAlt] = useState('');

  useEffect(() => {
    const provider =
      providers.find((p) => p.id === providerId) || providers?.[0];
    if (provider) {
      setCurrentProvider(provider);
    }
  }, [providers, providerId]);

  const getImages = useCallback(
    (page) => {
      if (!currentProvider) return;

      setIsLoading(true);
      CarouselImagesService.list({
        provider: currentProvider?.id,
        page,
        limit,
        order,
        orderDirection,
      })
        .then((result) => {
          const data = result.data ?? null;
          if (data) {
            setImages(data.data ?? null);
            setCurrentPage(data.page ?? currentPage);
            setTotalPages(data.total_pages ?? totalPages);
            setTotalCount(data.total_count ?? totalCount);
          }
        })
        .catch((err) => {
          console.error(err?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [currentPage, currentProvider, totalCount, totalPages],
  );

  /* init fetch */
  useEffect(() => {
    getImages(currentPage);
  }, [currentPage, getImages]);

  const pageChangeHandler = (page) => {
    setCurrentPage(page);
    getImages(page);
  };

  const fileUploadSuccessHandler = (file, response) => {
    CarouselImagesService.create({
      provider: currentProvider?.id,
      image: response.data,
    })
      .then(() => {
        setToast({
          show: true,
          class: 'success',
          header: 'File saved',
        });
        if (response.data) {
          setCurrentPage(1);
          getImages(1);
        }
      })
      .catch(() => {
        setToast({
          show: true,
          class: 'error',
          header: 'We could not save the file',
        });
      });
  };

  const fileUploadErrorHandler = () => {
    setToast({
      show: true,
      class: 'error',
      header: 'We could not save the file',
    });
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const deleteClickHandler = (id) => {
    setModal({
      show: true,
      header: 'Delete confirmation',
      body: 'Are you sure you want to delete the image?',
      handleSave: () => deleteHandler(id),
      handleClose: () => deleteCancelHandler(),
    });
  };

  const deleteCancelHandler = () => {
    setModal({
      show: false,
      header: '',
      body: '',
      handleClose: null,
      handleSave: null,
    });
  };

  const deleteHandler = (id) => {
    CarouselImagesService.remove(id)
      .then(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
        getImages(currentPage);
      })
      .catch(() => {
        setModal({
          show: false,
          header: '',
          body: '',
          handleClose: null,
          handleSave: null,
        });
      });
  };

  const changeEditAltInputHandler = (event) => {
    setAlt(event.target.value);
  };

  const editAltHandler = (id) => {
    const imageToSave = images.find((image) => image.id === id);
    imageToSave.alt = alt;
    CarouselImagesService.update(imageToSave).then(() => {
      setIsEditing('');
      setToast({
        show: true,
        class: 'success',
        header: 'Alternative text saved',
      });
    });
  };

  return (
    <Fragment>
      <ActionsNavbar>
        <Col className="d-flex">
          <Button className="btnDefault" onClick={() => navigate(-1)}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              className="me-3"
            />
            Return
          </Button>
          <FileUploadButton
            buttonText="Add new image"
            multiple
            dataTestAttribute="banner-file-upload"
            onFileUploadSuccess={fileUploadSuccessHandler}
            onFileUploadError={fileUploadErrorHandler}
            fileService={FileService}
          />
        </Col>
        <Col className="ms-auto col-auto">
          <Link className="btnDefault" to={`/codes/${providerId}`}>
            Codes
          </Link>
          <Link className="btnDefault" to={`/offers/edit/${providerId}`}>
            Details
          </Link>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-3">
        <Row>
          <Col>
            <h2>{currentProvider?.name || ''} Banners</h2>
            {isLoading && <OverlaySpinner />}
            {images && images.length ? (
              <Fragment>
                <CarouselImagesList
                  images={images}
                  onDelete={deleteClickHandler}
                  onEditAlt={editAltHandler}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  changeEditAltInput={changeEditAltInputHandler}
                  alt={alt}
                  setAlt={setAlt}
                />
                <Pagination
                  current_page={+currentPage}
                  total_pages={totalPages}
                  total_count={totalCount}
                  pageChange={pageChangeHandler}
                />
              </Fragment>
            ) : (
              <div className="text-center">
                <p>No images</p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Toaster toast={toast} closeToast={closeToast} />
      {modal.show && (
        <Modal
          show
          title={modal.header}
          body={modal.body}
          cancel="No"
          save="Yes"
          handleClose={modal.handleClose}
          handleSave={modal.handleSave}
          dataTestAttribute={{
            modal: 'modal-image-confirmation',
            action1: 'no-image-confirmation',
            action2: 'yes-image-confirmation',
          }}
        />
      )}
    </Fragment>
  );
};

export default ProviderCarouselImagesPage;
