import React, { useEffect, useState, Fragment } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ImageSection } from 'page-section-library';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import VidioSection from './VidioSection/VidioSection';
import PostQuestionCategorySelector from './PostQuestionCategorySelector/PostQuestionCategorySelector';

const Settings = (props) => {
  const {
    settings,
    saveSettingsHandler,
    inputChangedHandler,
    onChangeImageCoords,
    fileChange,
    sectionIndex,
    settingsType,
    showImageLinker,
    setImgUrl,
    serviceType,
    changed,
  } = props;
  const coords = settings?.coords;
  const imgUrl = settings?.controls?.src?.value
    ? `${process.env.REACT_APP_UPLOADS_URL}/${settings.controls.src.value}`
    : '';
  const formElementsArray = [];
  Object.keys(settings.controls).forEach((key) => {
    if (
      key === 'imagePosition' &&
      typeof settings.controls[key].value === 'undefined'
    ) {
      settings.controls[key].value = 'center center';
    }
    if (
      key === 'imageHeight' &&
      Number.isNaN(parseFloat(settings.controls[key].value))
    ) {
      settings.controls[key].value = '';
    }
    formElementsArray.push({
      id: key,
      config: settings.controls[key],
    });
  });

  const [showImgLinkerBtn, setShowImgLinkerBtn] = useState(coords?.length > 0);

  useEffect(() => {
    if (settingsType === 'image' && settings && settings.coords?.length > 0) {
      if (!showImgLinkerBtn) {
        onChangeImageCoords([]);
      }
    }
  }, [onChangeImageCoords, settings, settingsType, showImgLinkerBtn]);

  useEffect(() => {
    if (settingsType === 'image' && imgUrl && showImgLinkerBtn) {
      setImgUrl(imgUrl);
    }
  }, [setImgUrl, imgUrl, settingsType, showImgLinkerBtn]);

  const onClick = (event) => {
    event.preventDefault();
    showImageLinker();
  };

  const renderImgLinkerSection = () => (
    <>
      <Row>
        <Col className="col-12">
          <Button
            additionalClasses="btn btnDefault--medium"
            clicked={onClick}
            dataTestAttribute="image-linker-map-button"
            disabled={!imgUrl}
          >
            Edit links
          </Button>
        </Col>
      </Row>
      <Row
        className="mt-3 mb-4 align-items-center"
        data-test-id="image-linker-map-img-thumb"
      >
        <Col className="col-xl-8 col-12 p-0">
          {imgUrl && (
            <ImageSection
              src={imgUrl}
              linkBoxesMap={coords}
              linkBoxMapStyle={{
                borderWidth: 1,
                borderColor: '#ff6f00ff',
                borderStyle: 'solid',
                backgroundColor: 'rgba(255, 111, 0, .2)',
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );

  const dataTestFormId =
    settingsType === 'globalSettings'
      ? 'global-settings-form'
      : 'section-settings-form';
  const dataTestSubmitFormId =
    settingsType === 'globalSettings'
      ? 'global-settings-submit-button'
      : 'section-settings-submit-button';

  const form = (
    <form onSubmit={saveSettingsHandler} data-test-id={dataTestFormId}>
      {formElementsArray.map((formElement) => {
        if (!formElement.config.elementType) return null;
        const { config } = formElement;
        const { elementConfig } = config; //  settings.controls[key]

        const isImgURl = formElement.id === 'url' && settingsType === 'image';

        if (elementConfig.hidden) {
          return null;
        }

        return (
          <Fragment key={`${sectionIndex}_${formElement.id}`}>
            {!showImgLinkerBtn || !isImgURl ? (
              <Input
                additionalClasses="formInput formInput--bordered"
                keyName={formElement.id}
                elementType={config.elementType}
                elementConfig={elementConfig}
                label={config.label}
                value={config.value}
                invalid={!config.valid}
                shouldValidate={config.validation}
                touched={config?.touched}
                changed={(event) => inputChangedHandler(event, formElement.id)}
                error={config.error}
                fileChange={fileChange}
                dataTestAttribute={`${config?.elementType}-${formElement.id}`}
                required={config?.validation?.required}
                context={{ serviceType }}
              />
            ) : (
              renderImgLinkerSection()
            )}
            {isImgURl && (
              <ToggleSwitchInput
                text="Switch to linked areas"
                onCheck={(val) => {
                  setShowImgLinkerBtn(val);
                }}
                value={showImgLinkerBtn}
                dataTestAttribute="image-linker-map-toggle"
              />
            )}
          </Fragment>
        );
      })}
      {settingsType === 'vidio' && (
        <VidioSection
          config={formElementsArray}
          dataChanged={changed}
          onInputChange={(event, type, data) =>
            inputChangedHandler(event, type, data)
          }
        />
      )}
      {settingsType === 'postQuestions' && (
        <PostQuestionCategorySelector
          config={formElementsArray}
          dataChanged={changed}
          onInputChange={(value, type) => inputChangedHandler(value, type)}
        />
      )}
      <Button
        additionalClasses="btn mt-4"
        dataTestAttribute={dataTestSubmitFormId}
        disabled={!settings.formIsValid}
      >
        {settingsType === 'globalSettings' ? 'Save settings' : 'Save Module'}
      </Button>
    </form>
  );
  return <div>{form}</div>;
};

export default Settings;
