import React from 'react';

import Button from '../../../components/UI/Button/Button';
import TableData from '../../../components/UI/TableData/TableData';
import { SpinnerIcon } from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Toaster from '../../../components/UI/Toaster/Toaster';

import { useQualifierQuestions } from './QualifierQuestions.hooks';
import QualifierQuestionsForm from './QualifierQuestionsForm/QualifierQuestionsForm';

const QualifierQuestions = () => {
  const [getters, actions] = useQualifierQuestions();
  const { questionList, formData, showForm, toast, loading } = getters;
  const {
    addQuestion,
    editQuestion,
    onFormSuccess,
    closeToast,
    setShowForm,
    setToast,
    onFilterUpdated,
    searchValue,
  } = actions;

  if (loading)
    return (
      <div className="d-flex justify-content-center mt-2 mb-2">
        <SpinnerIcon />
      </div>
    );

  return (
    <>
      <div className="flex-column flex">
        <div className="mt-4">
          <TableData
            tableData={questionList}
            tableColumns={[{ key: 'label', label: 'Question' }]}
            actionWidth={95}
            tableFilters={[
              {
                key: 'search',
                label: 'Search',
                value: searchValue,
                type: 'text',
                placeholder: 'Search by Question Name',
                className: 'col col-md-6',
              },
              null,
            ]}
            onFilterUpdated={onFilterUpdated}
            actions={[
              {
                key: 'edit',
                label: 'Edit',
                onClick: editQuestion,
              },
            ]}
          />
        </div>

        <div style={{ marginTop: 'auto' }}>
          <Button variant="outline-primary" clicked={addQuestion}>
            + Add Question
          </Button>
        </div>
      </div>
      <QualifierQuestionsForm
        initialData={formData}
        onSuccess={onFormSuccess}
        showForm={showForm}
        setShowForm={setShowForm}
        setToast={setToast}
      />
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default QualifierQuestions;
