import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useCallback,
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import CampaignService from '../../api/Campaigns';
import TagService from '../../api/Tags';
import Modal from '../../components/UI/Modal/Modal';
import Toaster from '../../components/UI/Toaster/Toaster';
import ToasterBottom from '../../components/UI/Toaster/ToasterBottom';
import { campaignStatus } from '../../shared/campaignStatus';
import * as actions from '../../store/actions/index';

import { CampaignFilterContext } from './CampaignContext';
import CampaignTable from '../../components/Campaign/CampaignTable/CampaignTable';
import CampaignFilterModal from '../../components/Campaign/CampaignFilterForm/CampaignFilterModal';
import CampaignListHeader from '../../components/Campaign/CampaignListHeader/CampaignListHeader';

const hideInactiveReducer = (state, bool) => {
  try {
    window.sessionStorage.setItem('hideInactiveCampaigns', bool);
    return bool;
  } catch {
    return bool;
  }
};

const filterFormFieldsInitialValue = {
  company: [],
  brand: [],
  status: [],
  tags: [],
  startdate: '',
  enddate: '',
  budget: '',
  pageUniqueVisits: '',
  connectionCost: '',
  pageVisits: '',
};

const CampaignNewPage = () => {
  const navigate = useNavigate();
  const unbounceLoader = useRef(null);
  const [searchParams, setSearch] = useSearchParams();
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);
  const [activeFilter, setActiveFilter] = useReducer(
    hideInactiveReducer,
    window.sessionStorage?.hideInactiveCampaigns === 'true' ?? false,
  );

  const [fetchTick, setFetchTick] = useState(0);
  const [fallbackFilter, setFallbackFilter] = useState(false);
  const [advanceFilterConfig, setAdvanceFilterConfig] = useState({
    show: false,
    isReset: false,
  });
  const [toggleArchivedCampaigns, setToggleArchivedCampaigns] = useState(false);
  const [showArchivedCampaigns, setShowArchivedCampaigns] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    brand: '',
    company: '',
    tags: [],
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [filterFormFields, setFilterFormFields] = useState(
    filterFormFieldsInitialValue,
  );
  const [tempFields, setTempFields] = useState(null);
  const dispatch = useDispatch();

  const { hasNewTermsAndConditions, errorAccessingResource } = useSelector(
    (state) => state.termsAndConditions,
  );

  const getCampaigns = async (config, newFilters = {}) => {
    const { advancefilter, ..._newFilters } = newFilters;
    const statusPayload = advancefilter.status.map((stts) => stts.id).join();
    const result = await CampaignService.list({
      limit: config.limit,
      page: config.skip / config.limit + 1,
      order:
        config.sortInfo &&
        ['name', 'brand', 'startDate', 'endDate', 'budget'].includes(
          config.sortInfo.name,
        )
          ? config.sortInfo.name
          : 'updatedAt',
      order_direction:
        config.sortInfo && config.sortInfo.dir === 1 ? 'ASC' : 'DESC',
      providerId: searchParams.get('pid') || '',
      /**
       * multiQuery - matches any of the following fields:
       *  {campaign name}%
       *  {brand}%
       *  {company name}%
       *
       * Do NOT use multi-ended wildcard (%query%).
       */
      multiQuery: searchParams.get('multi-query') || '',
      ...((isAdmin || isBountyAdmin) && { fallback: fallbackFilter }),
      ...advancefilter,
      ..._newFilters,
      status: activeFilter ? campaignStatus.STATE_ACTIVE : '',
      ...(advancefilter.status.length > 0 && {
        status: statusPayload,
        fallback:
          (statusPayload.includes('20') || statusPayload.includes('21')) &&
          true,
      }),
      brand:
        advancefilter.brand.length > 0
          ? advancefilter.brand.map((brnd) => brnd.value).join()
          : _newFilters.brand,
      company:
        advancefilter.company.length > 0
          ? advancefilter.company.map((cmp) => cmp.value).join()
          : _newFilters.company,
      showArchivedCampaigns,
    });

    const campaigns = result.data.data;

    if (
      newFilters.name !== filters.name ||
      newFilters.brand !== filters.brand ||
      newFilters.company !== filters.company
    ) {
      setFilters({
        name: newFilters.name,
        brand: newFilters.brand,
        company: newFilters.company,
      });
    }

    return {
      data: campaigns,
      count: result.data.total_count,
    };
  };

  const getTags = async () => {
    const res = await TagService.list();
    if (res) {
      const tagList = res.data.data.map((tag) => {
        return {
          value: tag.id,
          label: tag.name,
        };
      });
      setTagOptions(tagList);
      return tagList;
    }
  };

  const closeToast = () => {
    dispatch(actions.setErrorAccessingResource(false, null, null));
  };

  const closeBottomToast = () => {
    setToast((state) => ({ ...state, show: false }));
  };

  const search = (event, type, isFilterModal = false) => {
    const val = event?.target?.value ?? event?.value ?? event;
    clearTimeout(unbounceLoader.current);

    let newFilters = {};
    if (Array.isArray(val)) {
      newFilters = {
        ...filters,
        ...(type && { [type]: val }),
      };
    } else {
      newFilters = {
        ...filters,
        ...(type && { [type]: val.substring(0, 100) }),
      };
    }

    const urlParams = new URLSearchParams(
      Object.fromEntries(
        Object.entries(newFilters).filter(([key]) => !key.includes('tags')),
      ),
    );
    if (newFilters.tags?.length) {
      newFilters.tags.forEach((t) => urlParams.append('tags', t.label));
    }

    const hasUrlParams = Object.values(newFilters).filter((e) => e).length;
    setSearch(hasUrlParams ? urlParams.toString() : '');
    setFilters(newFilters);

    // Avoiding multiple calls per user keyup
    if (!isFilterModal) {
      debouncedCall();
    }
  };

  useEffect(() => {
    dispatch(actions.termsAndConditionsState(false, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNewTermsAndConditions]);

  useEffect(() => {
    getTags();

    const name = searchParams.get('name') ?? '';
    const brand = searchParams.get('brand') ?? '';
    const company = searchParams.get('company') ?? '';

    setFilters({ name, brand, company });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tags = searchParams.getAll('tags') ?? [];
    const filteredTags = tagOptions.filter((t) => tags.includes(t.label));

    setFilters({ ...filters, tags: filteredTags });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  const submitAdvanceFilter = (type) => {
    if (type === 'reset') {
      search('', 'tags', true);
    }

    setShowArchivedCampaigns(toggleArchivedCampaigns);
    setFetchTick((tick) => tick + 1);
  };

  const dataSource = useCallback(
    (config) => {
      const name = searchParams.get('name') ?? '';
      const brand = searchParams.get('brand') ?? '';
      const company = searchParams.get('company') ?? '';
      const tags = searchParams.getAll('tags') ?? [];
      const filteredTags = tagOptions
        .filter((t) => tags.includes(t.label))
        .map((ft) => ft.label);

      setSelectedRow({});

      return getCampaigns(config, {
        name,
        brand,
        company,
        tags: filteredTags,
        showArchivedCampaigns,
        advancefilter: showArchivedCampaigns
          ? filterFormFieldsInitialValue
          : filterFormFields,
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilter, fallbackFilter, fetchTick, hasNewTermsAndConditions],
  );

  const onArchiveClick = (event) => {
    event.preventDefault();
    setShowConfirmationModal(true);
  };

  const updateCampaignList = async () => {
    const apiCall = showArchivedCampaigns
      ? CampaignService.restoreCampaigns
      : CampaignService.archiveCampaigns;
    try {
      await apiCall({ campaignIds: Object.keys(selectedRow) });
      setToast({
        show: true,
        success: true,
        body: 'Progress has been saved!',
      });

      debouncedCall();
    } catch (error) {
      if (error?.response?.status === 406) {
        setToast({
          show: true,
          class: 'error',
          body: error?.response?.data?.message || 'Please try again',
        });
      } else if (error?.response?.status !== 200) {
        setToast({
          show: true,
          class: 'error',
          body: 'Please try again',
        });
      } else {
        throw error;
      }
    }
    setShowConfirmationModal(false);
  };

  const getPromptText = () => {
    return `Are you sure you want to ${
      showArchivedCampaigns ? 'restore' : 'archive'
    } these ${Object.keys(selectedRow).length} campaign(s)?`;
  };

  const setField = (key, value) => {
    switch (key) {
      case 'query':
        search(value, 'multi-query');
        break;
      case 'active':
        setActiveFilter(value);
        break;
      case 'fallback':
        setFallbackFilter(value);
        break;
      default:
        console.warn('setField(): No field with key ' + key);
    }
  };

  const headerProps = {
    queryFilter: searchParams['multi-query'],
    activeFilter,
    fallbackFilter,
    selectedRow,
    showArchivedCampaigns,
    onArchiveClick,
    setField,
    showFilters: () => {
      setTempFields(filterFormFields);
      setAdvanceFilterConfig((config) => ({
        ...config,
        show: true,
      }));
    },
  };

  return (
    <>
      <CampaignListHeader {...headerProps} />

      <CampaignFilterContext.Provider value={filters}>
        <Container fluid className="pt-2">
          <Row>
            <Col>
              <CampaignTable
                dataSource={dataSource}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </Col>
          </Row>
        </Container>

        <CampaignFilterModal
          tempFields={tempFields}
          setTempFields={setTempFields}
          setAdvanceFilterConfig={setAdvanceFilterConfig}
          advanceFilterConfig={advanceFilterConfig}
          formSubmit={submitAdvanceFilter}
          filterFormFields={filterFormFields}
          setFilterFormFields={setFilterFormFields}
          filterFormFieldsInitialValue={filterFormFieldsInitialValue}
          toggleArchivedCampaigns={toggleArchivedCampaigns}
          setToggleArchivedCampaigns={setToggleArchivedCampaigns}
          search={search}
          tagOptions={tagOptions}
        />
      </CampaignFilterContext.Provider>

      {showConfirmationModal && (
        <Modal
          title={
            showArchivedCampaigns
              ? 'Restore Campaign(s)?'
              : 'Archive Campaign(s)?'
          }
          body={getPromptText()}
          cancel="NO"
          save="YES"
          handleSave={updateCampaignList}
          handleClose={() => setShowConfirmationModal(false)}
          show
        />
      )}

      <Toaster toast={errorAccessingResource} closeToast={closeToast} />
      <ToasterBottom toast={toast} closeToast={closeBottomToast} />
    </>
  );
};

export default CampaignNewPage;
