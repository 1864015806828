import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Table } from 'react-bootstrap';
import Button from '../../UI/Button/Button';

const WebhookList = ({ webhooks, setShowWebhookManageModal }) => {
  const { id } = useParams();
  const { isAdmin } = useSelector((state) => state.auth);

  const getStatus = (webhook) => {
    const campaignWebhook = webhook.campaignWebhook.find(
      (cwh) => cwh.campaign.id === id,
    );

    if (!campaignWebhook) {
      return 'Inactive';
    }

    return campaignWebhook.enabled ? 'Active' : 'Inactive';
  };

  return (
    <Table
      id="campaign-list-table"
      className="defaultTable"
      striped
      hover
      responsive
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Endpoint</th>
          <th>Status</th>
          {isAdmin && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {webhooks.map((webhook, i) => (
          <tr key={webhook.id}>
            <td>{webhook.name}</td>
            <td>{webhook.endpoint}</td>
            <td>{getStatus(webhook)}</td>
            {isAdmin && (
              <td>
                <Button
                  clicked={() =>
                    setShowWebhookManageModal({
                      show: true,
                      data: webhook,
                      index: i,
                    })
                  }
                  additionalClasses="btnDefault--small"
                >
                  Manage
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WebhookList;
