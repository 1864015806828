import React, { forwardRef, useMemo } from 'react';
import ReactPhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import './PhoneInput.scss';

const customInput = forwardRef((props, ref) => {
  const placeHolderPosition = useMemo(() => {
    if (props.value.length === 2) {
      return 1;
    }
    if (props.value.length === 3) {
      return 2;
    }
    return 3;
  }, [props.value]);

  return (
    <div className="position-relative w-100">
      <input ref={ref} {...props} placeholder="Enter phone number" />
      {props.placeholder && !props.value.includes(' ') ? (
        <div
          className={`phoneInputPlaceholder phoneInputPlaceholderLeft-${placeHolderPosition}`}
        >
          {props.placeholder}
        </div>
      ) : null}
    </div>
  );
});

const PhoneInput = ({
  keyName,
  invalid,
  error,
  country,
  value,
  onChange,
  onBlur,
  hideLabel,
  hideErrorMessage,
  required,
  placeholder,
  label,
  ...rest
}) => {
  const requiredField = required && <span className="text-danger"> *</span>;

  return (
    <>
      {label && (
        <label
          className={`Label ${hideLabel ? 'visuallyHidden' : ''}`}
          htmlFor={keyName}
        >
          {label}
          {requiredField}
        </label>
      )}
      <ReactPhoneInput
        className={[
          'formInput formInput--bordered Input',
          ...(invalid && !hideErrorMessage ? ['phoneInputInvalid'] : []),
          ...(error ? ['phoneInputError'] : []),
        ].join(' ')}
        defaultCountry={country ? country.toUpperCase() : 'ID'}
        international
        value={value}
        countryCallingCodeEditable={false}
        onChange={onChange}
        onBlur={onBlur || (() => {})}
        required={required}
        placeholder={placeholder}
        inputComponent={customInput}
        id={keyName}
        {...rest}
      />
      {invalid && error && !hideErrorMessage && (
        <span className="formInputError mb-3">{error}</span>
      )}
    </>
  );
};

export default PhoneInput;
