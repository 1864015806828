import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './ActionsNavbar.scss';

const ActionsMenu = ({ children }) => (
  <Row className="mt-3 actionsMenuContainer">
    <Col className="d-flex align-items-center">
      <Container>
        <Row className="actionsMenu">{children}</Row>
      </Container>
    </Col>
  </Row>
);

export default ActionsMenu;
