import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import styles from './Tooltip.module.scss';

const Tooltip = ({ children, title, id, ...props }) => {
  return (
    <div>
      <span className={styles.tooltipAction} data-tooltip-id={id}>
        i
      </span>
      <ReactTooltip
        id={id}
        place="right"
        {...props}
        open
        className={`${styles.tooltipContainer} shadow-sm`}
        classNameArrow={styles.tooltipArrow}
      >
        <div className="d-flex align-items-center gap-3 mb-3">
          <span className={styles.tooltipAction}>i</span>
          <span className={styles.fontReset}>
            <h4 className="m-0">{title ?? 'More information'}</h4>
          </span>
        </div>

        <span className={styles.fontReset}>{children}</span>
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
