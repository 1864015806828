import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import OverlaySpinner from '../../../UI/OverlaySpinner/OverlaySpinner';
import FormikInput from '../../../UI/FormikInput/FormikInput';
import CompanyService from '../../../../api/Companies';

import { validationSchema, displayFields } from './CompanyWebhookForm.controls';

const CompanyWebhookForm = ({
  saveTick,
  resetTick,
  companyId,
  formData = {},
  onSuccess,
}) => {
  const isUpdate = !!formData?.id;
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: formData?.name ?? '',
      endpoint: formData?.endpoint ?? '',
      headers: formData?.headers ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await (isUpdate
          ? CompanyService.webhookUpdate(companyId, formData.id, values)
          : CompanyService.webhookCreate(companyId, values));

        setLoading(false);
        onSuccess(response);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (saveTick) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTick]);

  useEffect(() => {
    if (resetTick) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTick]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? <OverlaySpinner /> : ''}

      {displayFields.map((field) => {
        return (
          <FormikInput
            key={field.key}
            field={field}
            formik={formik}
            validationSchema={validationSchema}
          />
        );
      })}
    </form>
  );
};

export default CompanyWebhookForm;
