import React from 'react';
import Toast from 'react-bootstrap/Toast';

import {
  faSquareCheck,
  faSquareXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToasterBottom = ({
  children,
  toast,
  closeToast,
  autohide = true,
  delay = 6500,
}) => {
  const headerMessage = toast.header ?? toast.success ? 'Success' : 'Error';
  const show = Boolean(toast.show && (toast.body || children));

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className={`toast-container toast-container--bottom ${
        toast.success ? 'success' : 'error'
      }`}
    >
      <Toast show={show} onClose={closeToast} autohide={autohide} delay={delay}>
        <Toast.Body
          onClick={closeToast}
          className="d-flex align-items-center gap-4"
        >
          <div>
            {toast.success ? (
              <FontAwesomeIcon icon={faSquareCheck} className="success-icon" />
            ) : (
              <FontAwesomeIcon icon={faSquareXmark} className="error-icon" />
            )}
          </div>
          <div className="toast-body-content">
            <p className="toast-header mb-0">
              <strong>{headerMessage}</strong>
            </p>

            {toast.body ? <p className="body">{toast.body}</p> : children}
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ToasterBottom;
