import React, { useState } from 'react';
import BaseModal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import OverlaySpinner from '../OverlaySpinner/OverlaySpinner';

const Modal = ({
  size,
  show,
  onEnter,
  dataTestAttribute,
  dialogClassName,
  title,
  bodyClass,
  termsAndConditions,
  loading,
  body,
  children,
  cancel,
  handleSave,
  handleClose,
  save,
  checkBeforeClose,
  headerClassName,
  footerClassName,
  customCloseEvent,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <BaseModal
      size={showConfirm ? 'md' : size}
      show={show}
      onHide={checkBeforeClose ? () => setShowConfirm(true) : handleClose}
      onEnter={onEnter}
      centered
      testid={dataTestAttribute?.modal ? dataTestAttribute.modal : null}
      dialogClassName={`defaultModal ${dialogClassName}`}
      backdropClassName="defaultModal__backdrop"
    >
      <BaseModal.Header closeButton className={headerClassName}>
        <BaseModal.Title className="w-100">
          {showConfirm ? 'You have unsaved changes' : title}
        </BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body
        className={bodyClass ?? ''}
        style={{
          whiteSpace: 'pre-line',
          maxHeight: termsAndConditions ? '50vh' : null,
          overflow: termsAndConditions ? 'auto' : null,
        }}
      >
        {loading ? <OverlaySpinner /> : null}
        {showConfirm ? (
          <p> Are you sure you want to close the pop-up without saving?</p>
        ) : (
          body ?? children
        )}
      </BaseModal.Body>
      <BaseModal.Footer className={footerClassName}>
        {showConfirm && (
          <div>
            <Button
              additionalClasses="btnDefault--gray btnDefault--medium"
              clicked={() => {
                setShowConfirm(false);
              }}
            >
              No
            </Button>
            <Button
              additionalClasses="btnDefault--medium"
              clicked={() => {
                handleClose();
                setTimeout(() => {
                  setShowConfirm(false);
                }, 350);
              }}
            >
              Yes
            </Button>
          </div>
        )}
        {!showConfirm && (
          <div>
            {cancel && (
              <Button
                additionalClasses="btnDefault--gray btnDefault--medium"
                clicked={() => {
                  if (customCloseEvent) {
                    customCloseEvent();
                  } else if (checkBeforeClose) {
                    setShowConfirm(true);
                  } else {
                    handleClose();
                  }
                }}
                testid={
                  dataTestAttribute?.action1 ? dataTestAttribute.action1 : null
                }
              >
                {cancel}
              </Button>
            )}
            {save && (
              <Button
                additionalClasses="btnDefault--medium"
                clicked={handleSave}
                disabled={loading}
                isLoading={loading}
                testid={
                  dataTestAttribute?.action2 ? dataTestAttribute.action2 : null
                }
              >
                {save}
              </Button>
            )}
          </div>
        )}
      </BaseModal.Footer>
    </BaseModal>
  );
};

export default Modal;
