/* eslint-disable import/prefer-default-export, no-param-reassign */
export const getCreatorSettingsForType = (
  formSettings,
  globalSettings,
  landingPageSections,
  type,
  index,
  fileChange,
  language = null,
  globalBrandLogoUrl = '',
) => {
  let controls;
  let control;
  const settings = { ...formSettings[type]?.settings };

  if (type === 'image') {
    settings.controls.src.fileChange = fileChange;
  }

  if (landingPageSections[index]) {
    controls = { ...settings.controls };
    Object.keys(controls).forEach((key) => {
      control = { ...controls[key] };

      /* Check if translation exists */
      let translation = landingPageSections[index]?.translations?.[language];
      if (translation && !control?.skipTranslation) {
        translation = { ...translation, ...translation.layout };
      } else {
        landingPageSections[index].config = {
          ...landingPageSections[index].config,
          ...landingPageSections[index].config?.layout,
        };
      }

      const value =
        translation && !control?.skipTranslation
          ? translation?.[key]
          : landingPageSections[index].config[key];
      if (typeof value !== 'undefined') {
        control.value = value;
      }

      /* Legacy - if no companyLogoUrl set value from LP global scope */
      if (key === 'companyLogoUrl' && !control?.value) {
        control.value = globalBrandLogoUrl;
      }

      control.valid = true;
      controls[key] = control;
    });
    settings.controls = controls;
    settings.formIsValid = true;

    if (landingPageSections[index]?.coords) {
      settings.coords = landingPageSections[index]?.coords;
    }

    if (landingPageSections[index]?.translations) {
      settings.translations = landingPageSections[index].translations;
    }
  }
  if (globalSettings && globalSettings.config && type === 'globalSettings') {
    controls = { ...settings.controls };
    Object.keys(controls).forEach((key) => {
      control = { ...controls[key] };
      const value = globalSettings.config[key];
      if (typeof value !== 'undefined') {
        control.value = value;
      }

      if (key === 'languages') {
        control.elementConfig = {
          defaultLanguage: globalSettings.config.defaultLanguage,
        };
      }
      control.valid = true;
      controls[key] = control;
    });
    settings.controls = controls;
    settings.formIsValid = true;
  }
  return settings;
};

export const parseToInt = (value) =>
  Number.isNaN(value) ? null : parseInt(value, 10);
export const parseToFloat = (value) => {
  const floatNumber = parseFloat(value);
  return floatNumber && !Number.isNaN(floatNumber) ? floatNumber : null;
};

export const isNumber = (value) =>
  Number.isInteger(value) && !Number.isNaN(value) && typeof value === 'number';
