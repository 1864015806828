import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './PlaceholderCard.scss';

const PlaceholderCard = ({
  placeholder = 'select the default language',
  onEditLanguage,
}) => (
  <Card
    className={['languagesCard', 'placeholderCard']}
    key="language_placeholder"
  >
    <Card.Body onClick={() => onEditLanguage()}>
      <span className="languageText" data-test-id="language-placeholder">
        {placeholder}
      </span>
      <FontAwesomeIcon
        title="Edit language name"
        icon={faEdit}
        className="editLanguage"
      />
    </Card.Body>
  </Card>
);

export default PlaceholderCard;
