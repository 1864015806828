import React from 'react';

import styles from '../OfferDetail.module.scss';

const OfferStatus = ({ active }) => {
  let classN;

  return active ? (
    <span className={`${styles.offerStatus} ${styles['offerStatus--active']}`}>
      Active
    </span>
  ) : (
    <span
      className={`${styles.offerStatus} ${styles['offerStatus--inactive']}`}
    >
      Inactive
    </span>
  );
};

export default OfferStatus;
