import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import ConnectionsNavBar from '../../components/Connections/ConnectionsNavBar';
import ConnectionsFilterModal from '../../components/Connections/ConnectionsFilterModal/ConnectionsFilterModal';
import Toaster from '../../components/UI/Toaster/Toaster';

import ConfirmDeleteModal from '../../components/Modal/ModalConfirm/ModalConfirm';

import useConnectionsPage from './ConnectionsPage.hooks';
import ModalFileDownload from '../../components/Modal/ModalFileDownload/ModalFileDownload';
import DataTable from '../../components/UI/DataTable/DataTable';
import DataTableFilters from '../../components/UI/DataTableFilters/DataTableFilters';

import { getColumns } from '../../components/Connections/Connections.columns';
import StatisticsPanel from '../../components/Connections/StatisticsPanel/StatisticsPanel';

/**
 * Displays CPDC submission data, aggregated against each
 * email address as the unique user identifier for all campaigns
 */

const ConnectionsPage = (props) => {
  const navigate = useNavigate();
  const [getters, actions] = useConnectionsPage(props);

  const {
    allowListAndDetailView,
    isDashboardView,
    isAdmin,
    isCompanyAdmin,
    filters,
    filterOptions,
    filterLoading,
    totalCount,
    totalSubmissions,
    pendingDeleteCount,
    tableFilters,
    isDownloadPending,
    toast,
    showFilePasswordModal,
    filePassword,
    confirmDeleteProps,
    noResultMessage,
    isLoading,
    showTick,
  } = getters;

  const {
    onFilterUpdated,
    onDownloadReport,
    setDashboardView,
    onResetAllFilters,
    closeToast,
    getTableData,
    setShowFilePasswordModal,
    setFilePassword,
    openDeleteConnectionModal, // TODO
    loadConnections,
    setShowTick,
  } = actions;

  const tableColumns = useMemo(
    () => getColumns({ openDeleteConnectionModal }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const navBarProps = {
    isAdmin,
    isCompanyAdmin,
    isDashboardView,
    setDashboardView,
    filters,
    setShowTick,
    onFilterUpdated,
    totalCount,
    pendingDeleteCount,
    isDownloadPending,
    onDownloadReport,
    onResetAllFilters,
  };

  const filterProps = {
    filters,
    filterOptions,
    filterLoading,
    isAdmin,
    onFilterUpdated,
    onResetAllFilters,
    loadConnections,
    isLoading,
    openFiltersTick: showTick,
  };

  const closeFileModal = () => {
    setFilePassword(false);
    setShowFilePasswordModal(false);
  };

  const onRowClick = ({ data: connection }) => {
    if (allowListAndDetailView) {
      navigate(`/connections/${connection.id}`);
    }
  };

  /** TODO: When dashboard is done, dash becomes default
   *  and toggle view hidden for agency */
  if (!isDashboardView && !allowListAndDetailView) {
    return (
      <>
        <ConnectionsNavBar {...navBarProps} />

        <Container fluid className="pt-3">
          <Row>
            <Col>
              <p>Dashboard reports coming soon.</p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <div className="pb-5">
      <ConnectionsNavBar {...navBarProps} />

      <StatisticsPanel
        stats={[
          {
            label: 'Total Connections',
            count: totalCount,
          },
          {
            label: 'Total Submissions',
            count: totalSubmissions,
          },
          {
            label: 'Pending Deletion',
            count: pendingDeleteCount,
          },
        ]}
      />

      <ConnectionsFilterModal
        {...filterProps}
        hasPendingDeletions={!!Number(pendingDeleteCount)}
      />

      <Toaster toast={toast} closeToast={closeToast} />

      {!isDashboardView && (
        <Container fluid>
          <DataTable
            id="connection"
            rowHeight={null}
            columns={tableColumns}
            dataSource={getTableData}
            style={{ minHeight: 460 }}
            columnOrderPersist
            onRowClick={onRowClick}
            emptyText={noResultMessage}
          />
        </Container>
      )}

      {/* Dashboard View */}
      {isDashboardView && (
        <Container fluid className="pt-3">
          <Row>
            <Col>
              <p>Dashboard reports coming soon.</p>
            </Col>
          </Row>
        </Container>
      )}

      <ModalFileDownload
        filePassword={filePassword}
        show={showFilePasswordModal}
        close={closeFileModal}
      />

      <ConfirmDeleteModal {...confirmDeleteProps}>
        <p>
          You have a legal obligation to delete all of a connection’s PII when a
          data Management Request is received. Please be sure you have also
          deleted this data from your own records
        </p>
        <p>
          <strong>Deleted Data cannot be recovered</strong>
        </p>
      </ConfirmDeleteModal>
    </div>
  );
};

export default ConnectionsPage;
