import React, { useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as auth from 'firebase/auth';
import crossDanger from '../../assets/icons/cross-danger.svg';
import * as actions from '../../store/actions/index';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import { AuthErrorMessage } from './AuthErrorMessage';
import './Auth.scss';

import UserService from '../../api/Users';

const recaptchaInstance = async (firebaseAuth, tick, initialise = false) => {
  const recaptchaVerifier = new auth.RecaptchaVerifier(
    `recaptcha-multifactor-${tick}`,
    {
      size: 'invisible',
    },
    firebaseAuth,
  );
  if (initialise) {
    await recaptchaVerifier.render();
  }
  return recaptchaVerifier;
};

const tickReducer = (state) => state + 1;

/**
 * Recaptcha token will validate immediately to auto-send
 *  the OTP at component initialisation (mfaSignIn:start)
 * Not relevant for the OTP submission (mfaSignIn:finalize)
 *  (can probably be dismounted when awaiting OTP entry)
 */
const Recaptcha = ({ resendPasscodeTicker }) => {
  return (
    <div
      id={`recaptcha-multifactor-${resendPasscodeTicker}`}
      key={resendPasscodeTicker}
    />
  );
};

const AuthMfaSignIn = ({ firebaseResolver, isEmailOtpEnabled, email }) => {
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firebaseAuth] = useState(auth.getAuth());
  const recaptchaVerifier = useRef(null);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [verificationId, setVerificationId] = useState(null);
  const [selectedHint] = useState(firebaseResolver.hints[0]);
  const [resendPasscodeTicker, setResendPasscodeTicker] = useReducer(
    tickReducer,
    0,
  );

  const [countDown, setCountDown] = useState(60);
  const resendDisabled = useMemo(() => !!countDown, [countDown]);
  const resendCtaText = useMemo(() => {
    return !countDown ? '' : `Please try again in ${countDown} seconds`;
  }, [countDown]);

  const resendPasscode = async () => {
    if (!isEmailOtpEnabled) {
      recaptchaVerifier.current.recaptcha.reset();
      recaptchaVerifier.current.clear();
    }
    setResendPasscodeTicker();
    setCountDown(60);
    setError('');
  };

  const [formState, setFormState] = useState({
    passcode: '',
  });

  const handlePasscodeInputEvent = (event) => {
    const { value } = event.target;
    if (value !== '') if (value.length > 6 || !/^\d+$/.test(value)) return;

    setFormState({ passcode: value });
  };

  /**
   * Firebase MFA Resolver
   * https://firebase.google.com/docs/reference/js/auth.multifactorresolver
   */
  const handleSubmit = async () => {
    if (formState.passcode.length === 0) {
      setTimeout(() => {
        setError('');
      }, 3000);
      return setError('This field cannot be empty.');
    }

    if (formState.passcode.length < 6) {
      setTimeout(() => {
        setError('');
      }, 3000);
      return setError('Invalid OTP.');
    }

    setError('');
    setLoading(true);

    if (isEmailOtpEnabled) {
      try {
        const res = await UserService.validateEmailOtp(
          email,
          formState.passcode,
        );

        if (res) {
          const token = res.data.customAuthToken;

          const session = await auth.signInWithCustomToken(firebaseAuth, token);
          if (session?.user?.reloadUserInfo) {
            dispatch(actions.authState(session.user));
          }
          dispatch(actions.authUpdate({ lastInteraction: +new Date() }));
        }
      } catch (e) {
        setError(e?.response?.data?.message);
        setLoading(false);
      }
    } else {
      if (!recaptchaVerifier.current) {
        return setError(
          'Waiting for recaptcha to initialise. If this continues, please try an incognito session.',
        );
      }

      const phoneAuthCredential = auth.PhoneAuthProvider.credential(
        verificationId,
        formState.passcode,
      );
      const multiFactorAssertion =
        auth.PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

      /**
       * Successful authentication will:
       *  1. trigger auth state change (from App.js)
       *  2. verify if mfa auth required then
       *  3. set user in auth store then
       *  4. prompt mfa (if applicable)
       *  5. auto-redirect to main page
       */
      firebaseResolver
        .resolveSignIn(multiFactorAssertion)
        .then((session) => {
          if (session?.user?.reloadUserInfo) {
            /**
             * User already had a session so
             *  auth state change is not triggered
             * Since a prior API 401 response will reset
             *  user store we need to set the user again
             *  then and an auto redirect will occur
             *  (refer routing rules)
             */
            dispatch(actions.authState(session.user));
          }

          // App session timer begins: refer App.session.js
          dispatch(actions.authUpdate({ lastInteraction: +new Date() }));
        })
        .catch((err) => {
          setError(() => {
            switch (err.code) {
              case 'auth/invalid-verification-code':
                return 'Invalid OTP';
              default:
                return err.message;
            }
          });
          setTimeout(() => {
            setError('');
          }, 3000);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const isCountdown = countDown > 0;
    const interval = isCountdown
      ? setInterval(() => {
          setCountDown(countDown - 1);
        }, 1000)
      : null;

    return () => {
      clearInterval(interval);
      mountedRef.current = false;
    };
  }, [countDown]);

  useEffect(() => {
    async function sendPasscode() {
      if (isEmailOtpEnabled) {
        await UserService.sendEmailOtp(email);
      } else {
        recaptchaVerifier.current = await recaptchaInstance(
          firebaseAuth,
          resendPasscodeTicker,
          !recaptchaVerifier.current,
        );

        const phoneAuthProvider = new auth.PhoneAuthProvider(firebaseAuth);
        const phoneInfoOptions = {
          multiFactorHint: selectedHint,
          session: firebaseResolver.session,
        };
        const _verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier.current,
        );

        setVerificationId(_verificationId);
      }
    }

    sendPasscode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendPasscodeTicker, firebaseAuth, firebaseResolver, selectedHint]);

  return (
    <div className="text-center" style={{ maxWidth: '350px' }}>
      <h3 className="authFormHeader mb-3">Enter verification code</h3>
      <p className="subText mb-4">
        {' '}
        A verification code has been sent to your{' '}
        {isEmailOtpEnabled
          ? `email address ${email}`
          : `phone ending in ${selectedHint.phoneNumber}`}
      </p>
      <div className="mb-3 authForm">
        <div className="passcodeInputContainer">
          <Input
            additionalClasses="formInput otpInput"
            key="passcode"
            pressed={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            value={formState.passcode}
            invalid={!!error}
            changed={handlePasscodeInputEvent}
            type="number"
            required
            touched={!!error}
            shouldValidate={1}
          />
          {!!error && (
            <span className="passcodeToggleIcon">
              <img src={crossDanger} alt="Error passcode" />
            </span>
          )}
        </div>
      </div>

      <div className="mb-3 d-flex justify-content-between">
        <button
          disabled={resendDisabled}
          className="authFormResendOtpBtn mb-3"
          onClick={resendPasscode}
        >
          Resend OTP
        </button>
        <div>
          {error ? (
            <AuthErrorMessage error={error} />
          ) : (
            <AuthErrorMessage error={resendCtaText} />
          )}
        </div>
      </div>

      {/* Recaptcha */}
      <Recaptcha resendPasscodeTicker={resendPasscodeTicker} />

      {/* Submit */}
      <div className="d-flex mt-3 mb-4">
        <Button
          id="submit-multifactor"
          additionalClasses="rounded btnDefault--medium flex-grow-1 m-0"
          isLoading={isLoading}
          dataTestAttribute="login-button"
          clicked={handleSubmit}
        >
          Submit
        </Button>
      </div>
      <div
        className="d-flex justify-content-center"
        onClick={() => navigate('/contact-us')}
      >
        <p className="authFormResendOtpContact mb-4">
          Can’t get the OTP? <span>Contact us</span>
        </p>
      </div>
    </div>
  );
};

export default AuthMfaSignIn;
