import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OfferAccordionBootstrap from 'react-bootstrap/Accordion';

import iconError from '../../../assets/icons/status-error.svg';
import iconComplete from '../../../assets/icons/status-complete.svg';

import Tooltip from '../../UI/Tooltip/Tooltip';

import styles from './OfferAccordion.module.scss';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';

const statusIcon = {
  pending: '',
  complete: iconComplete,
  error: iconError,
};

const statusLabel = {
  pending: 'Incomplete',
  complete: 'All done',
  error: 'Error, please review',
};

const statusClasses = {
  pending: styles.statusIndicatorPending,
  complete: styles.statusIndicatorSuccess,
  error: styles.statusIndicatorError,
};

export const OfferAccordionItem = ({ step, title, body }) => {
  const isPending = true;
  const statusClass = statusClasses.pending;
  const iconLabel = statusLabel.pending;
  const iconSrc = statusIcon.pending;
  return (
    <div className={`${styles.accordionItem} mb-4 position-relative`}>
      <Row>
        <Col
          className={`${styles.statusIndicatorColumn} d-flex justify-content-end align-items-start`}
        >
          <div
            className={`${styles.statusIndicator} ${statusClass} d-flex justify-content-center align-items-center`}
          >
            {isPending ? (
              <span>{step}</span>
            ) : (
              <img alt={iconLabel} src={iconSrc} width={16} height={16} />
            )}
          </div>
        </Col>
        <Col className={styles.accordionContent}>
          <OfferAccordionBootstrap.Item eventKey={`${step}`}>
            <OfferAccordionBootstrap.Header>
              <div className="d-flex gap-3 align-items-center">
                <span className="h3 mb-0">{title}</span>
              </div>
            </OfferAccordionBootstrap.Header>
            <OfferAccordionBootstrap.Body className={styles.accordionBody}>
              {body}
            </OfferAccordionBootstrap.Body>
          </OfferAccordionBootstrap.Item>
        </Col>
      </Row>
    </div>
  );
};

OfferAccordionItem.Tooltip = ({
  children: tooltipContent,
  sectionKey,
  title,
}) => {
  return (
    <Tooltip id={sectionKey} title={title}>
      {tooltipContent}
    </Tooltip>
  );
};

const OfferAccordion = ({ children, loading, ...props }) => {
  if (loading) {
    return <OverlaySpinner />;
  }

  return (
    <OfferAccordionBootstrap {...props}>{children}</OfferAccordionBootstrap>
  );
};

export default OfferAccordion;
