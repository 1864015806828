import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Input from '../../UI/Input/Input';
import { campaignStatus } from '../../../shared/campaignStatus';
import { CampaignFilterContext } from '../../../pages/CampaignsPage/CampaignContext';

const status = {
  options: [
    {
      id: campaignStatus.STATE_DRAFT,
      label: 'Draft',
      value: 'Draft',
    },
    {
      id: campaignStatus.STATE_ACTIVE,
      label: 'Active',
      value: 'Active',
    },

    {
      id: campaignStatus.STATE_INACTIVE,
      label: 'Inactive',
      value: 'Inactive',
    },
    {
      id: campaignStatus.STATE_FALLBACK,
      label: 'Fallback',
      value: 'Fallback',
    },
    {
      id: campaignStatus.STATE_SCHEDULED,
      label: 'Scheduled',
      value: 'Scheduled',
    },
    {
      id: campaignStatus.STATE_DEACTIVATED,
      label: 'Deactivated',
      value: 'Deactivated',
    },
  ],
};

const CampaignFilterForm = ({
  setFilterFormFields,
  filterFormFields,
  selectCompanyOptions,
  selectBrandOptions,
  showDateFields,
  search,
  tagOptions,
}) => {
  const filters = useContext(CampaignFilterContext);
  const { isAdmin, isBountyAdmin } = useSelector((state) => state.auth);

  const handleChange = (field, value) => {
    setFilterFormFields((ffprevState) => {
      return {
        ...ffprevState,
        [field]: value,
      };
    });
  };

  /**
   * Search fields were integrated into (i) URL params
   *  and (ii) referenced to the "CampaignFilterContext"
   *  context to retrieve field values. This new
   *  implementation disconnects from both of these
   *  patterns and should be refactored for (i) consistency
   *  and to (ii) allow users to access filters via URL
   *  navigation, as was the prior implementation.
   */

  return (
    <div>
      <Row>
        <Col className="mb-2" md={6} lg={3}>
          <Input
            elementType="multiselect"
            elementConfig={{
              options: [...selectCompanyOptions],
              name: 'company',
              type: 'multiselect',
            }}
            changed={(event) => handleChange('company', event)}
            value={filterFormFields.company}
            label="Company"
            additionalClasses="formInput formInput--bordered"
            dataTestAttribute="company-input"
          />
        </Col>
        <Col className="mb-2" md={6} lg={3}>
          <Input
            label="Brand"
            additionalClasses="formInput formInput--bordered"
            elementType="multiselect"
            elementConfig={{
              options: [...selectBrandOptions],
              name: 'brand',
              type: 'multiselect',
            }}
            value={filterFormFields.brand}
            changed={(event) => handleChange('brand', event)}
          />
        </Col>
        <Col className="mb-2" md={6} lg={3}>
          <Input
            label="Status"
            additionalClasses="formInput formInput--bordered"
            elementType="multiselect"
            elementConfig={{
              options: status.options,
              name: 'status',
              type: 'multiselect',
            }}
            value={filterFormFields.status}
            changed={(event) => handleChange('status', event)}
          />
        </Col>
        {(isAdmin || isBountyAdmin) && (
          <Col className="mb-2" md={6} lg={3}>
            <Input
              label="Tags"
              elementType="multiselect"
              elementConfig={{
                options: tagOptions,
                type: 'multiselect',
              }}
              value={filters.tags}
              changed={(event) => search(event, 'tags', true)}
            />
          </Col>
        )}
      </Row>

      <hr className="my-4" />
      <Row>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          {showDateFields && (
            <Input
              label="Start Date"
              additionalClasses="formInput formInput--bordered"
              elementType="default"
              elementConfig={{
                type: 'datetime-local',
                name: 'startDate',
              }}
              changed={(event) => handleChange('startDate', event.target.value)}
              value={filterFormFields.startDate || ''}
            />
          )}
        </Col>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          {showDateFields && (
            <Input
              label="End Date"
              additionalClasses="formInput formInput--bordered"
              elementType="default"
              elementConfig={{
                type: 'datetime-local',
                name: 'endDate',
              }}
              changed={(event) => handleChange('endDate', event.target.value)}
              value={filterFormFields.endDate}
            />
          )}
        </Col>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          <Input
            additionalClasses="formInput formInput--medium formInput--bordered"
            elementType="number"
            value={filterFormFields.budget}
            label="Budget"
            elementConfig={{
              type: 'number',
              placeholder: '0-100',
              name: 'budget',
              max: 100,
              min: 0,
            }}
            changed={(event) => handleChange('budget', event.target.value)}
          />
        </Col>
      </Row>
      <hr className="my-4" />
      <Row>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          <Input
            additionalClasses="formInput formInput--medium formInput--bordered"
            elementType="text"
            value={filterFormFields.pageUniqueVisits}
            label="Page Unique Visits"
            elementConfig={{
              type: 'number',
              placeholder: '0-1000',
              name: 'pageUniqueVisits',
            }}
            changed={(event) =>
              handleChange('pageUniqueVisits', event.target.value)
            }
          />
        </Col>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          <Input
            additionalClasses="formInput formInput--medium formInput--bordered"
            elementType="text"
            value={filterFormFields.connectionCost}
            label="Connection Cost"
            elementConfig={{
              type: 'number',
              placeholder: '0-1',
              name: 'connectionCost',
            }}
            changed={(event) =>
              handleChange('connectionCost', event.target.value)
            }
          />
        </Col>
        <Col className="mb-2" sm={12} lg={4} md={12}>
          <Input
            additionalClasses="formInput formInput--medium formInput--bordered"
            elementType="text"
            label="Page Visits"
            elementConfig={{
              type: 'number',
              placeholder: '0-10000',
              name: 'pageVisits',
            }}
            value={filterFormFields.pageVisits}
            changed={(event) => handleChange('pageVisits', event.target.value)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CampaignFilterForm;
