import { useEffect, useState, useContext } from 'react';
import _cloneDeep from 'lodash/cloneDeep';

import { CampaignCreatorContext } from '../../Creator.context';

const usePostQuestionCategorySelector = ({
  config,
  dataChanged,
  onInputChange,
}) => {
  const { campaignData, langKey } = useContext(CampaignCreatorContext);
  const [selectedQuestionsConfig, setSelectedQuestionConfig] = useState(
    config.find((e) => e.id === 'selectedQuestionIds'),
  );

  const selectedQuestionIds = selectedQuestionsConfig?.config?.value ?? [];
  const categories = campaignData?.postQuestions?.categories ?? [];
  const hasCategories = categories.length;

  const handleToggle = (questionId, selected) => {
    const updateSelections = _cloneDeep(selectedQuestionIds);
    if (selected) {
      updateSelections.push(questionId);
    } else {
      const idx = updateSelections.findIndex((id) => id === questionId);
      if (idx !== -1) {
        updateSelections.splice(idx, 1);
      }
    }

    onInputChange({ value: updateSelections }, 'selectedQuestionIds');
  };

  const handleSelectAll = (ev, questions) => {
    ev.preventDefault();

    let updateSelectedIds = _cloneDeep(selectedQuestionIds);
    const questionIds = questions.map((q) => q.questionId);
    const questionsSelectedIds = updateSelectedIds.filter((id) =>
      questionIds.includes(id),
    );
    const questionsSelectedCount = questionsSelectedIds.length;
    const questionsAvailableCount = questions.length;

    const deselect = questionsSelectedCount === questionsAvailableCount;
    if (deselect) {
      updateSelectedIds = updateSelectedIds.filter(
        (id) => !questionsSelectedIds.includes(id),
      );
    } else {
      const addQuestionIds = questions
        .map((q) => q.questionId)
        .filter((id) => !updateSelectedIds.includes(id));
      updateSelectedIds = [...updateSelectedIds, ...addQuestionIds];
    }

    onInputChange({ value: updateSelectedIds }, 'selectedQuestionIds');
  };

  useEffect(() => {
    setSelectedQuestionConfig(
      config.find((e) => e.id === 'selectedQuestionIds'),
    );
  }, [config, dataChanged]);

  const getters = {
    langKey,
    hasCategories,
    categories,
    selectedQuestionIds,
  };

  const actions = {
    handleToggle,
    handleSelectAll,
  };

  return [getters, actions];
};

export default usePostQuestionCategorySelector;
