import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from 'react-redux';

import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import DomainWhitelistManager from '../../components/Settings/DomainWhitelistManager/DomainWhitelistManager';

import QualifierQuestions from './QualifierQuestions/QualifierQuestions';
import GlobalTranslations from './GlobalTranslations/GlobalTranslations';
import styles from './SettingsPage.module.scss';

const SettingsPage = () => {
  const { isAuthenticated, isAdmin } = useSelector((state) => state.auth);

  return (
    <>
      <ActionsNavbar>
        <Col className="mb-2 mb-md-0 d-flex align-items-center">
          <Row className="ms-1 justify-content-start  flex-wrap flex-md-nowrap align-items-center" />
        </Col>
      </ActionsNavbar>
      <Container fluid className="settings px-0 px-sm-5">
        <Row className="px-4">
          <Col xl={5} lg={5}>
            <div className="my-4 ">
              <h2>Settings</h2>
            </div>
          </Col>
        </Row>
        <div className="px-4 mb-5">
          <div className={styles.accordionContainer}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Manage Qualifying Questions</Accordion.Header>
                <Accordion.Body>
                  <QualifierQuestions />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Manage Whitelist Domains</Accordion.Header>
                <Accordion.Body>
                  <DomainWhitelistManager />
                </Accordion.Body>
              </Accordion.Item>
              {isAuthenticated && isAdmin ? (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Global Translations</Accordion.Header>
                  <Accordion.Body>
                    <GlobalTranslations />
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SettingsPage;
