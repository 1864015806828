import createInput from '../../../shared/Form/createInput';

export default [
  {
    tags: createInput(
      'Tags',
      {
        type: 'multiselect',
        placeholder: 'Enter tag(s)',
      },
      { required: false, testId: 'input-tags' },
      undefined,
      true,
      'create-multi',
    ),
  },
  {
    signature: createInput('Signature', { disabled: true }, { type: 'text' }),
    decipherKey: createInput(
      'Decipher Key',
      { disabled: true },
      { type: 'text' },
    ),
    name: createInput('Campaign name', { type: 'text' }),
    company: createInput(
      'Company',
      {
        type: 'select',
      },
      { required: true },
      '',
      false,
      'modern-select',
    ),
    clientBrand: createInput(
      'Brand',
      { type: 'select', placeholder: 'Select existing brand...' },
      { required: true },
      '',
      false,
      'modern-select',
    ),
  },
  {
    budget: createInput(
      'Campaign budget',
      {
        type: 'number',
        min: 0,
        placeholder: '',
      },
      {
        isMoney: true,
        required: true,
      },
    ),
    maxPerConnection: createInput(
      'Max per connection',
      {
        type: 'number',
        min: 0,
        placeholder: '',
      },
      {
        isMoney: true,
        required: false,
      },
    ),
    fallbackUrl: createInput(
      'Fallback URL',
      {
        type: 'text',
        placeholder: 'https://',
        description:
          'Visitor will be redirected to the Fallback URL if campaign ends',
      },
      {
        isUrl: true,
        required: true,
      },
    ),
  },
  {
    startDate: createInput(
      'Start date',
      { type: 'datetime-local' },
      {
        required: false,
        lessThan: 'endDate',
      },
      '',
      true,
    ),
    endDate: createInput(
      'End date',
      { type: 'datetime-local' },
      {
        required: false,
        greaterThan: 'startDate',
      },
      '',
      true,
    ),
  },
];
