import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';

import CompanyService from '../../api/Companies';
import * as storeActions from '../../store/actions/index';

import Button from '../../components/UI/Button/Button';
import CompanyAddModal from '../../components/CompaniesList/CompanyAddModal/CompanyAddModal';
import CompanyTable from '../../components/CompaniesList/CompanyTable/CompanyTable';
import ActionsNavbar from '../../components/ActionsNavbar/ActionsNavbar';
import Input from '../../components/UI/Input/Input';
import CompanyDeleteModal, {
  useModalConfirm as useCompanyDeleteModal,
} from '../../components/Modal/ModalConfirm/ModalConfirm';
import Toaster from '../../components/UI/Toaster/Toaster';

const CompaniesPage = () => {
  const navigate = useNavigate();
  const { isAdmin, isAdvertiser, isBountyAdmin } = useSelector(
    (state) => state.auth,
  );
  const [isChecked] = useState(false);
  const [refetchTick, setRefetchTick] = useState(0);
  const [filters, setFilters] = useState({ type: null, name: null });
  const [showAddForm, setShowAddForm] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const [fetchTick, setFetchTick] = useState(0);
  const dispatch = useDispatch();
  const { hasNewTermsAndConditions } = useSelector(
    (state) => state.termsAndConditions,
  );

  const getCompanies = async (config, _filters = {}) => {
    try {
      const result = await CompanyService.list({
        limit: config.limit,
        page: config.skip / config.limit + 1,
        order: config.sortInfo ? config.sortInfo.name : 'updatedAt',
        order_direction:
          config.sortInfo && config.sortInfo.dir === 1 ? 'ASC' : 'DESC',
        ..._filters,
      });

      const redirectToCompanyDetail = !isAdmin && result.data.total_count === 1;

      if (redirectToCompanyDetail) {
        const companyId = result.data.data[0].id;
        navigate(`/companies/${companyId}`);
        return {
          data: [],
          count: 0,
        };
      }

      const companiesInResult = result.data.data;

      return {
        data: companiesInResult,
        count: result.data.total_count,
      };
    } catch (error) {
      navigate('/');
    }
  };

  const dataSource = useCallback(
    (config) => {
      let withSearchPhrase = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          withSearchPhrase = { ...withSearchPhrase, [key]: value };
        }
      });

      return getCompanies(config, withSearchPhrase);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, hasNewTermsAndConditions, refetchTick, fetchTick, filters],
  );

  const [companyDeleteProps, setCompanyDeleteProps] = useCompanyDeleteModal({
    title: 'Deactivation confirmation',
    apiRequest: CompanyService.deactivate,
    supplementalErrorKey: 'campaigns',
    onSuccess: () => setRefetchTick((tick) => tick + 1),
  });

  const onDeleteCompany = (company) => {
    setCompanyDeleteProps({
      apiId: company.id,
      body: `Are you sure you want to deactivate '${company.name}'? This will result in the company admins and associated advertising agencies from losing access to company campaigns and connections data.`,
      toastSuccessMsg: company.name + ' has successfully been deactivated',
    });
  };

  const onAddCompany = async (company) =>
    CompanyService.save(company)
      .then((response) => ({
        errors: null,
        data: response?.data,
        action: () => {
          setShowAddForm(false);
          setRefetchTick((tick) => tick + 1);
        },
      }))
      .catch((err) => {
        const errors = err.response.data._errors;

        if (!errors) {
          setToast({
            show: true,
            header:
              'An Error occurred please try again or contact a system administrator',
            class: 'error',
          });

          return {
            data: null,
            action: () => setShowAddForm(false),
          };
        }

        if (errors.slug) {
          delete errors.slug;
        }

        if (errors.newBrands) {
          errors.brands = errors.newBrands;
        }

        return {
          errors,
          data: null,
          action: () => setShowAddForm(false),
        };
      });

  useEffect(() => {
    dispatch(storeActions.termsAndConditionsState(false, false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNewTermsAndConditions]);

  const searchUpdated = (event) => {
    const phrase = event.target.value;
    setFilters({ ...filters, name: phrase.substring(0, 100) });

    if (phrase.length < 3 && phrase.length > 0) return;

    // Avoiding multiple calls per user keyup
    debouncedCall();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(() => {
      setFetchTick((tick) => tick + 1);
    }, 500),

    [],
  );

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
                mb-2
                mb-md-0
                d-flex
                align-items-center"
        >
          <Row
            className="
                    ms-1
                    justify-content-start
                    flex-wrap
                    flex-md-nowrap
                    align-items-center"
          >
            {(isAdmin || isBountyAdmin) && (
              <Button
                clicked={() => setShowAddForm(true)}
                dataTestAttribute="add-company"
              >
                Add company
              </Button>
            )}
            <Input
              elementType="input"
              elementConfig={{
                placeholder: 'Search companies',
                type: 'text',
              }}
              additionalClasses="formInput"
              value={filters.search}
              changed={searchUpdated}
            />
            <Input
              elementType="modern-select"
              value={filters.type}
              elementConfig={{
                options: [
                  {
                    label: 'Advertising Partner ',
                    value: 'ADVERTISER',
                  },
                  {
                    label: 'Provider Partner',
                    value: 'PROVIDER',
                  },
                  {
                    label: 'Show All',
                    value: '',
                  },
                ],
                type: 'select',
                placeholder: 'Select Type',
              }}
              label="Filter by type"
              changed={(option) => {
                setFilters({ ...filters, type: option.value });
              }}
              dataTestAttribute="search-by-tag-input"
            />
          </Row>
        </Col>
      </ActionsNavbar>
      <Container fluid className="pt-2">
        <Row>
          <Col>
            <CompanyTable
              dataSource={dataSource}
              pathToDetail="/companies"
              onDeleteCompany={onDeleteCompany}
              isAdvertiser={isAdvertiser}
            />
          </Col>
        </Row>
      </Container>

      <CompanyAddModal
        show={showAddForm}
        hideModal={() => setShowAddForm(false)}
        action={async (data) => onAddCompany(data)}
      />
      <CompanyDeleteModal {...companyDeleteProps} />
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default CompaniesPage;
