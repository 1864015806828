import axios from 'axios';
import isBefore from 'date-fns/isBefore';
import { getAuth, signOut } from 'firebase/auth';
import { auth as firebaseAuth } from '../vendor/firebase';
// eslint-disable-next-line import/no-cycle
import { logout, setToken } from '../store/actions';
// eslint-disable-next-line import/no-cycle
import store from '../store';
import { customHistory } from '../router/CustomBrowserRouter';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

const AUTH_PATH = '/auth';

export const refreshToken = async () => {
  const { auth } = store.getState();
  if (!auth.tokenExp || isBefore(new Date(auth.tokenExp), new Date())) {
    const response = await firebaseAuth.currentUser?.getIdTokenResult(true);
    if (response) {
      await store.dispatch(
        setToken({
          token: response.token,
          tokenExp: response.expirationTime,
        }),
      );
    }
  }
};

instance.interceptors.request.use(async (request) => {
  await refreshToken();
  const { auth } = store.getState();
  if (auth.token) {
    request.headers.authorization = `Bearer ${auth.token}`;
  }
  return request;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== AUTH_PATH
    ) {
      const auth = getAuth();
      await signOut(auth).then(() => {
        store.dispatch(logout());
        customHistory.push(AUTH_PATH);
      });
    }
    return Promise.reject(error);
  },
);

export default instance;
