import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Toaster from '../../UI/Toaster/Toaster';
import Modal from '../../UI/Modal/Modal';
import Error from '../../UI/Error/Error';
import useModalConfirm from './ModalConfirm.hook';

const ModalBody = ({ children, body, error, supplementalError }) => {
  const [primaryErrorMessage, errorValueLabelPairs] = supplementalError;
  const paragraphs = Array.isArray(body) ? body : [body];

  return (
    <>
      {children}
      {paragraphs.map((p, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={`p-${idx}`}> {p} </p>
      ))}

      {primaryErrorMessage ? (
        <p className="text-danger">{primaryErrorMessage}</p>
      ) : (
        error && (
          <Error msg={primaryErrorMessage ?? error} fullWidth>
            {errorValueLabelPairs?.length && (
              <ul className="mt-4 pb-2">
                {errorValueLabelPairs.map((_error, i) => {
                  return (
                    <li key={i}>
                      <Link
                        className="text-danger"
                        to={`/campaigns/edit/${_error.value}`}
                      >
                        {_error.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </Error>
        )
      )}
    </>
  );
};

const ModalConfirm = ({
  children,
  title,
  body,
  toastSuccessMsg,
  apiRequest,
  apiId,
  onConfirm,
  onCancel,
  onSuccess,
  onClose,
  formatSupplementalError,
  saveText,
  cancelText,
  openTick,
  pageNr,
}) => {
  const [showModal, _setShowModal] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const [reconfirmState, setReconfirmStatus] = useState(false);
  const [error, setError] = useState(null);
  const [errorSupplemental, setSupplementalError] = useState([null, null]);
  const [isLoading, setLoading] = useState(false);

  const setShowModal = (bool) => {
    _setShowModal(bool);
    setReconfirmStatus(false);
    if (!bool && onClose) onClose();
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const cancelHandler = () => {
    setShowModal(false);

    if (onCancel) {
      onCancel();
    }
  };

  if (!onConfirm && !apiRequest) {
    console.error('ModalConfirm: No confirmation handler was provided.');
  }

  const handleApiResponse = (response) => {
    /**
     * 202: Process has not been completed
     *      End-user must confirm again to proceed
     * 205: Will not complete, reset document to original state
     */
    if (response?.status === 202 || response?.status === 205) {
      setError(response.data.message);

      if (response?.status === 202) {
        setReconfirmStatus(true);
      }
      return false;
    }

    /**
     * 200: Complete with response
     * 204: Complete with an empty response
     */
    setToast({
      show: true,
      class: 'success',
      header: toastSuccessMsg,
    });
    setShowModal(false);
    return onSuccess?.(pageNr);
  };

  const handleApiError = (_error) => {
    setError(_error?.response?.data?.message ?? _error.message);

    if (_error?.response && formatSupplementalError) {
      setSupplementalError(formatSupplementalError(_error.response));
    }
  };

  const confirmHandler = async () => {
    if (onConfirm) {
      return onConfirm();
    }

    setLoading(true);
    setError(null);
    setSupplementalError([null, null]);

    const requestPromise =
      apiId != null
        ? apiRequest(apiId, reconfirmState)
        : apiRequest(reconfirmState);

    return requestPromise
      .then(handleApiResponse)
      .catch(handleApiError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (openTick) {
      setShowModal(true);
      setError(null);
      setSupplementalError([null, null]);
    }
    // eslint-disable-next-line
  }, [openTick]);

  return (
    <>
      <Toaster toast={toast} closeToast={closeToast} />
      <Modal
        show={showModal}
        title={title}
        body={
          <ModalBody
            body={body}
            error={error}
            supplementalError={errorSupplemental}
          >
            {children}
          </ModalBody>
        }
        bodyClass={isLoading ? 'p-5' : 'px-4 pt-4 pb-3'}
        cancel={error ? 'Close' : cancelText ?? 'No'}
        save={error ? 'Retry' : saveText ?? 'Yes'}
        loading={isLoading}
        handleClose={cancelHandler}
        handleSave={confirmHandler}
        dataTestAttribute={{
          modal: 'modal-confirm',
          action1: 'action-1',
          action2: 'action-2',
        }}
      />
    </>
  );
};

export default ModalConfirm;

export { useModalConfirm };
