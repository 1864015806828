import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import SettingsService from '../../../api/Settings';

import DataTable from '../../../components/UI/DataTable/DataTable';
import Toaster from '../../../components/UI/Toaster/Toaster';
import Button from '../../../components/UI/Button/Button';

import { getColumns } from './CampaignTermsListPage.columns';

/**
 * This component services 2 paths
 *  campaignId is optional
 */

const getEditUrl = (termsId, campaignId) => {
  if (!campaignId) {
    return `/settings/manage-terms/${termsId}`;
  }

  return `/campaigns/edit/${campaignId}/manage-terms/${termsId}`;
};

const CampaignTermsListPage = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [hasDraft, setHasDraft] = useState(true);
  const [campaignName, setCampaignName] = useState(null);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const columns = useMemo(
    () => getColumns({ campaignId, navigate, getEditUrl }),
    [campaignId, navigate],
  );

  const onCreateDraft = async () => {
    // if (!hasDraft) {
    const requester = campaignId
      ? SettingsService.createTermsCampaign
      : SettingsService.createTermsBase;

    try {
      const result = await requester(campaignId);
      if (!result.data.id) {
        throw new Error('No draft created');
      } else {
        navigate(getEditUrl(result.data.id, campaignId));
      }
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error);
      setToast({
        show: true,
        class: 'error',
        header:
          error?.response?.data?.message ??
          'There was an error creating your draft. Please try again.',
      });
    }
    // }
  };

  const termsType = campaignId ? 'Campaign' : 'Base';

  const getTableData = useCallback(
    async (config) => {
      const requester = campaignId
        ? SettingsService.listTermsCampaign
        : SettingsService.listTermsBase;

      const filters = {
        limit: config.limit,
        page: config.skip / config.limit + 1,
      };

      let result;
      try {
        result = await requester(filters, campaignId);
      } catch {
        return {
          data: [],
          count: 0,
        };
      }

      setHasDraft(result.data.hasDraft);
      setCampaignName(result.data?.campaignName ?? null);

      return {
        data: result.data?.list ?? [],
        count: Number(result.data?.count ?? 0),
      };
    },
    [campaignId],
  );

  return (
    <>
      <Toaster toast={toast} closeToast={closeToast} />

      <Container fluid="xxl" className="mt-5">
        <header className="mb-5">
          <div className="d-flex justify-content-between align-items-center  flex-wrap">
            <h2 className="mb-3 mr-3">
              Campaign Terms and Condition - {termsType} versions
            </h2>
            <div className="mb-3 btnContainer">
              {campaignId && (
                <Dropdown className="d-inline-flex">
                  <Dropdown.Toggle
                    data-test-id="campaign-more-actions-dropdown"
                    className="btnDefault p-3"
                  >
                    <FontAwesomeIcon fixedWidth icon={faEllipsisH} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => navigate(`/campaigns/edit/${campaignId}/`)}
                    >
                      Return to Campaign
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Button
                clicked={onCreateDraft}
                // disabled={hasDraft}
                title={hasDraft ? 'Only one draft is allowed at a time' : ''}
              >
                Create New Draft
              </Button>
            </div>
          </div>
          {campaignName ? <h3 className="mb-3">{campaignName}</h3> : null}
        </header>
        <DataTable
          id="terms"
          columns={columns}
          dataSource={getTableData}
          style={{ minHeight: 360 }}
          columnOrderPersist
          defaultLimit={20}
          pageSizes={[20, 50]}
        />
      </Container>
    </>
  );
};

export default CampaignTermsListPage;
