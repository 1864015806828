import * as actionTypes from './actionTypes';

export const setTermsAndConditions = (
  hasNewTermsAndConditions,
  hasDataToFetch,
) => ({
  type: actionTypes.SET_NEW_TERMS_AND_CONDITIONS,
  hasNewTermsAndConditions,
  hasDataToFetch,
});

export const resetTermsAndConditions = (
  hasNewTermsAndConditions,
  hasDataToFetch,
) => ({
  type: actionTypes.RESET_TERMS_AND_CONDITIONS,
  hasNewTermsAndConditions,
  hasDataToFetch,
});

export const setErrorAccessingResource = (show, className, header) => ({
  type: actionTypes.SET_ERROR_ACCESSING_CAMPAIGN,
  show,
  className,
  header,
});

export const termsAndConditionsState =
  (hasNewTermsAndConditions, hasDataToFetch) => (dispatch) => {
    if (hasNewTermsAndConditions) {
      dispatch(setTermsAndConditions(hasNewTermsAndConditions, hasDataToFetch));
    } else {
      dispatch(
        resetTermsAndConditions(hasNewTermsAndConditions, hasDataToFetch),
      );
    }
  };
