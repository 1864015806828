import instance from './Api';
import fileService from './File';

const getReport = (campaignId, formId, questionName, queryParams) => {
  let query = '';
  Object.keys(queryParams).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${queryParams[filter]}`;
  });
  return instance.get(
    `/cpdc/report/${campaignId}/${formId}/${questionName}${query}`,
  );
};

const getMandatoryFieldsList = (includeHidden = false) =>
  instance.get(
    `/cpdc/mandatory-fields${includeHidden ? '?includeHidden=true' : ''}`,
  );

const getAnswers = (campaign, question) =>
  instance.get(`/cpdc/answers/${campaign}/${question}`);

const getReportFieldsList = (dataSource) =>
  instance.get('/cpdc/report-fields?dataSource=' + dataSource ?? '');

const downloadEncryptedData = ({
  campaignId,
  queryParams = {},
  password,
  onError,
  onEnd,
  fileName = 'cpdcs-data',
}) => {
  const query = new URLSearchParams({
    ...queryParams,
    fileName,
    password,
  }).toString();

  fileService.download({
    url: `/cpdc/generate-csv/${campaignId}?${query}`,
    fileName,
    fileType: 'zip',
    onError,
    onEnd,
  });
};

export default {
  getReport,
  getMandatoryFieldsList,
  getAnswers,
  getReportFieldsList,
  downloadEncryptedData,
};
