import React from 'react';

import { CompanyAccordionItem } from '../CompanyAccordion/CompanyAccordion';
import PostQuestions from '../../PostQuestions/PostQuestions';

const CompanySectionPostConnectForms = ({
  step,
  postQuestions,
  companyId,
  setRefresh,
  setAccordionActiveKey,
  refreshTick,
  saveTick,
  setAccordionsFormSaveStatus,
}) => {
  return (
    <CompanyAccordionItem
      step={step}
      title="Post-Connect Form"
      body={
        <>
          <h4 className="mb-3">Post Connect Form Configuration</h4>
          <PostQuestions
            companyId={companyId}
            categoryQuestions={postQuestions}
            onUpdate={() => setRefresh(refreshTick + 1)}
            saveTick={saveTick}
            setAccordionActiveKey={setAccordionActiveKey}
            setAccordionsFormSaveStatus={setAccordionsFormSaveStatus}
          />
        </>
      }
    />
  );
};

export default CompanySectionPostConnectForms;
