import instance from './Api';

const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
    limit: 20,
    order: 'name',
    order_direction: 'asc',
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });

  return instance.get(`/cpdc/whitelist${query}`);
};

const save = (domains) => instance.post('/cpdc/whitelist/add', { domains });
const remove = (id) => instance.delete('/cpdc/whitelist/' + id);

export default {
  list,
  save,
  remove,
};
