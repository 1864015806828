import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// eslint-disable-next-line import/no-cycle
import authReducer from './reducers/auth';
import providerReducer from './reducers/provider';
import termsAndConditionsReducer from './reducers/termsAndConditions';

if (
  process.env.NODE_ENV === 'production' &&
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers &&
  Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers).length
) {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {};
}

const devtools =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const composeEnhancers =
  process.env.NODE_ENV === 'development' ? devtools : compose;

const rootReducer = combineReducers({
  auth: authReducer,
  provider: providerReducer,
  termsAndConditions: termsAndConditionsReducer,
});

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
