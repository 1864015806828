import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import FormikInput from '../../UI/FormikInput/FormikInput';
import FormikSelectAsync from '../../UI/FormikSelectAsync/FormikSelectAsync';

const CampaignSectionTarget = ({
  step,
  displayFields,
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  isLastStep,
  onSaveAction,
  sectionsRef,
}) => {
  return (
    <CampaignAccordionItem
      step={step}
      sectionKey="target"
      title="Target Group"
      formik={formik}
      fieldKeys={fieldKeys}
      showNextSection={showNextSection}
      validationSchema={validationSchema}
      isLastStep={isLastStep}
      onSaveAction={onSaveAction}
      sectionsRef={sectionsRef}
    >
      <CampaignAccordionItem.Tooltip>
        <p>
          To define the target consumers for the campaign, by specifying the
          desired criteria including age range, gender, country, and city.
        </p>
      </CampaignAccordionItem.Tooltip>

      <>
        <Row>
          {['ageFrom', 'ageTo', 'gender'].map((fieldKey) => {
            return (
              <Col xs={4} key={fieldKey}>
                <FormikInput
                  key={fieldKey}
                  field={displayFields[fieldKey]}
                  formik={formik}
                  validationSchema={validationSchema}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xs={4}>
            <FormikSelectAsync
              key="countries"
              field={displayFields.countries}
              formik={formik}
              validationSchema={validationSchema}
              optionsApiUrl="/dictionary/countries/search?query=:query"
              apiDataKey="countries"
            />
          </Col>
          <Col xs={4}>
            <FormikSelectAsync
              key="cities"
              field={displayFields.cities}
              formik={formik}
              validationSchema={validationSchema}
              optionsApiUrl={`/dictionary/cities/search?countryCode=${formik.values.countries?.value}&query=:query`}
              apiDataKey="cities"
              disabled={!formik.values.countries?.value}
              isMulti
            />
          </Col>
        </Row>
      </>
    </CampaignAccordionItem>
  );
};

export default CampaignSectionTarget;
