import { array, object, string } from 'yup';

export const getSchema = () => {
  return {
    paywall: object().shape({
      qualificationRequirements: object().shape({
        questions: array().of(
          object().shape({
            questionId: string(),
            answers: array().when('questionId', {
              is: (val) => !!val,
              then: (schema) =>
                schema.min(1, 'At least one answer is required'),
            }),
          }),
        ),
      }),
    }),
  };
};

export const getQualificationQuestionQuestionField = (idx) => ({
  key: `paywall.qualificationRequirements.questions[${idx}]`,
  label: `Question ${idx + 1}`,
  type: 'modern-select',
  elementConfig: {
    placeholder: 'Select...',
  },
  shouldValidate: false,
  defaultValue: {
    answers: [],
  },
  outputRawEvent: true,
  formatOutput: (selected) => ({
    questionId: selected.value,
    questionLabel: {
      en: selected.label,
    },
    answers: [],
  }),
  formatInputValue: (value) => {
    return value?.questionId ?? '';
  },
});

export const getQualificationQuestionAnswersField = (idx) => ({
  key: `paywall.qualificationRequirements.questions[${idx}].answers`,
  label: `Answer ${idx + 1}`,
  type: 'multiselect',
  defaultValue: [],
  outputRawEvent: true,
  formatOutput: (arr) => {
    return arr.map((selected) => ({
      answerId: selected.value,
      answerLabel: {
        en: selected.label,
      },
    }));
  },
  formatInputValue: (arr) => {
    if (!arr) {
      return [];
    }
    return arr.map((selected) => selected?.answerId ?? '');
  },
});

const displayFields = {
  question0: getQualificationQuestionQuestionField(0),
  answers0: getQualificationQuestionAnswersField(0),
  question1: getQualificationQuestionQuestionField(1),
  answers1: getQualificationQuestionAnswersField(1),
};

export default displayFields;
