import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import BountyBannerLogo from '../../components/UI/Logo/BountyBannerLogo';
import bountyMediaLogo from '../../assets/images/bounty-hlogo.svg';
import Button from '../../components/UI/Button/Button';

import SignUpForm from './SignUpForm/SignUpForm';

import styles from './SignUpPage.module.scss';

const ContactUsPage = () => {
  const navigate = useNavigate();
  const [formSuccess, setFormSuccess] = useState(false);

  return (
    <div className={styles.signUpPage}>
      <BountyBannerLogo />
      <section className={styles.loginBox}>
        {/* Sign up form */}
        {!formSuccess && (
          <>
            <main className="d-flex flex-column justify-content-start align-items-center signUpForm">
              <button
                onClick={() => navigate('/auth')}
                className={styles.backButton}
              >
                <FontAwesomeIcon size="xl" width="54" icon={faArrowLeftLong} />{' '}
                <p>Return</p>
              </button>

              <div className="h-100 w-100 d-flex justify-content-start align-items-center flex-column">
                <div className="mb-4">
                  <img src={bountyMediaLogo} alt="Bounty Media logo small" />
                </div>
                <div className={`${styles.tagLine} mb-2`}>
                  <p>The best way to acquire consumer data at scale.</p>
                </div>

                <SignUpForm setFormSuccess={setFormSuccess} />

                <p className={styles.privacyPolicy}>
                  By creating an account you are agreeing to our{' '}
                  <a
                    target="_blank"
                    href="https://www.bounty.media/copy-of-privacy-policy-1"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </main>

            <div className="text-center mt-5 mb-5">
              <p className="mb-1">Already have an account?</p>
              <p className="mb-0">
                <Link to="/auth">Sign in</Link>
              </p>
            </div>
          </>
        )}

        {/* Success Message */}
        {formSuccess && (
          <main className="d-flex flex-column justify-content-center align-items-center">
            <div className={styles.successPromptContainer}>
              <div className="my-4">
                <h3 className={styles.successPromptTitle}>Thank you!</h3>
                <div className="text-center">
                  <p className="mb-0">
                    <small>Thank you for registering with Bounty!</small>
                  </p>
                  <p>
                    <small>
                      Your interest in our product is greatly appreciated. To
                      ensure the security and quality of our platform, we
                      manually approve accounts for new users. Our team will
                      review your registration information and get back to you
                      as soon as possible.
                    </small>
                  </p>
                  <p>
                    <small>
                      We look forward to welcoming you officially to our
                      platform!
                    </small>
                  </p>
                </div>
              </div>

              <Button
                clicked={() => navigate('/auth')}
                additionalClasses="btnDefault w-100 mt-4"
                dataTestAttribute="send-button"
              >
                Return to Sign In
              </Button>
            </div>
          </main>
        )}

        <div
          className="formText d-flex justify-content-center"
          onClick={() => navigate('/contact-us')}
        >
          <p className={styles.contactUs}>
            Need help?
            <span> Contact Us</span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
