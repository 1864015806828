import instance from './Api';

const getTotalBalance = (arProviderId) =>
  instance.get('/automatic-redemption/balance/' + arProviderId);
const getArProviders = () => instance.get('/automatic-redemption/ar-providers');
const getRewardTypes = (arProviderId) =>
  instance.get('/automatic-redemption/options/' + arProviderId);

export default {
  getTotalBalance,
  getArProviders,
  getRewardTypes,
};
