import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './StatisticsPanel.module.scss';
import Button from '../../UI/Button/Button';

const StatisticsPanel = ({ provider }) => {
  const navigate = useNavigate();

  if (!provider) return null;

  return (
    <div className={styles.outer}>
      <div className={styles.statistics}>
        <div className={`d-flex flex-fill justify-content-end ${styles.cell}`}>
          <span className={`text-semibold ${styles.label}`}>Campaigns</span>
          <div className="mx-2"> </div>
          <span className={styles.statistic}>
            {provider?.stats?.campaigns ?? 0}
          </span>
        </div>
        <div className={styles.divider} />
        <div className={`d-flex ${styles.cell}`}>
          <span className={`text-semibold ${styles.label}`}>
            Codes Available
          </span>
          <div className="mx-2"> </div>
          <span className={styles.statistic}>
            {provider?.stats?.availableCodes ?? 0}/{provider?.stats?.codes ?? 0}
          </span>
        </div>
        <div className={`d-flex ${styles.cell}`}>
          <span className={`text-semibold ${styles.label}`}>Banners</span>
          <div className="mx-2"> </div>
          <span className={styles.statistic}>
            {provider?.stats?.banners ?? 0}
          </span>
        </div>
        <div className={`d-flex flex-fill justify-content-end ${styles.cell}`}>
          <Button
            additionalClasses={styles.btnCodes}
            onClick={() => navigate(`/codes/${provider.id}`)}
          >
            <span>
              <CodesSvg />
            </span>
            Codes
          </Button>
        </div>
      </div>
    </div>
  );
};

const CodesSvg = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2420_2093)">
      <path
        d="M21.3728 6.34867C20.9526 6.77172 20.6903 7.35554 20.6903 7.99859C20.6903 9.13238 21.4997 10.0772 22.5715 10.2887C22.8338 10.3395 23.0227 10.5708 23.0227 10.8387V14.9734C23.0227 15.2836 22.7689 15.5375 22.4587 15.5375H1.02379C0.713551 15.5375 0.459717 15.2836 0.459717 14.9734V10.8387C0.459717 10.5708 0.648682 10.3395 0.910977 10.2887C1.37352 10.1985 1.78811 9.97003 2.10964 9.65133C2.52987 9.22827 2.79217 8.64445 2.79217 8.00141C2.79217 7.35836 2.52987 6.77454 2.10964 6.35149C1.78811 6.03278 1.37352 5.80433 0.910977 5.71408C0.648682 5.66331 0.459717 5.43204 0.459717 5.16411V1.02379C0.459717 0.713551 0.713551 0.459717 1.02379 0.459717H22.4587C22.7689 0.459717 23.0227 0.713551 23.0227 1.02379V5.15847C23.0227 5.4264 22.8338 5.65767 22.5715 5.70844C22.1089 5.79869 21.6943 6.02714 21.3728 6.34584V6.34867Z"
        stroke="#FF6F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1652 2.51013V13.2417"
        stroke="#FF6F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2420_2093">
        <rect width="23.4853" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StatisticsPanel;
