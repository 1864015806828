import createInput from '../../../shared/Form/createInput';
import textBanner from '../../../assets/images/modules/text.png';
import imageBanner from '../../../assets/images/modules/image.png';
import headerBanner from '../../../assets/images/modules/header.png';
import youTubeBanner from '../../../assets/images/modules/youTube.png';
import imagesCarouselBanner from '../../../assets/images/modules/imagesCarousel.png';
import vidioBanner from '../../../assets/images/modules/vidio.gif';

export const text = {
  name: 'Text',
  description:
    'Text paragraph module. You can define text content.' +
    ' To create multiple paragraphs, add the next modules.',
  image: textBanner,
  settings: {
    controls: {
      paragraph: createInput(
        'Paragraph content',
        {
          placeholder: 'Paragraph content',
          rows: 10,
        },
        {
          required: true,
        },
        '',
        false,
        'richtext',
      ),
    },
    formIsValid: false,
  },
};

export const image = {
  name: 'Image',
  description:
    'Upload the image to your page. ' +
    'You can set up a link address and alternative text. ' +
    'To change image position use height, image fit, and align configuration.',
  image: imageBanner,
  settings: {
    controls: {
      src: createInput(
        'Image',
        {
          placeholder: 'Image',
        },
        {
          required: true,
        },
        '',
        false,
        'image',
      ),
      url: createInput(
        'Link url',
        {
          placeholder: 'Link url',
        },
        {
          required: false,
        },
        '',
        true,
      ),
      alt: createInput('Alternative text', {
        placeholder: 'Alternative text',
        description: "Alternate text if the image can't be displayed",
      }),
      imageHeight: createInput(
        'Custom Image Height in pixels',
        {
          placeholder: 'auto',
          type: 'number',
        },
        {
          required: false,
        },
        'auto',
        true,
      ),
      fullViewportWidth: createInput(
        'Set Image to Full Viewport Width',
        {},
        {
          required: false,
        },
        false,
        true,
        'boolean',
      ),
      imageFit: createInput(
        'Image Fit',
        {
          placeholder: 'Image Fit',
          options: [
            {
              value: 'contain',
              label: 'Contain',
            },
            {
              value: 'cover',
              label: 'Cover',
            },
          ],
        },
        {
          required: false,
        },
        'contain',
        true,
        'modern-select',
      ),
      imagePosition: createInput(
        'Image Align Within Container',
        {
          placeholder: 'Image Align Within Container',
        },
        {
          required: false,
        },
        'center center',
        true,
        'position',
      ),
    },
    coords: [],
    formIsValid: false,
  },
};

export const header = {
  name: 'Header',
  description:
    'Create text headers on the landing page. ' +
    'You can choose from h1 (large) to h5 (small) size.',
  image: headerBanner,
  settings: {
    controls: {
      header: createInput(
        'Text',
        {
          placeholder: 'Text',
        },
        {
          required: true,
        },
        '',
        false,
        'textarea',
      ),
      headerSize: createInput(
        'Header Type',
        {
          placeholder: 'Header Type',
          options: [
            {
              value: 'h1',
              label: 'H1',
            },
            {
              value: 'h2',
              label: 'H2',
            },
            {
              value: 'h3',
              label: 'H3',
            },
            {
              value: 'h4',
              label: 'H4',
            },
            {
              value: 'h5',
              label: 'H5',
            },
          ],
        },
        {
          required: true,
        },
        'h1',
        true,
        'modern-select',
      ),
    },
    formIsValid: false,
  },
};

export const youTube = {
  name: 'YouTube Video',
  description:
    'Use this module to io insert a YouTube video to the page. ' +
    'Add video url and determine if the video should play automatically after site load.',
  image: youTubeBanner,
  settings: {
    controls: {
      src: createInput(
        'Video URL',
        {
          placeholder: 'Video URL',
        },
        {
          required: true,
          isYouTube: true,
        },
      ),
      autoplay: createInput(
        'Autoplay',
        {
          placeholder: 'Autoplay',
          options: [
            {
              value: '1',
              label: 'Yes',
            },
            {
              value: '0',
              label: 'No',
            },
          ],
        },
        {
          required: true,
        },
        '1',
        true,
        'modern-select',
      ),
    },
    formIsValid: false,
  },
};

export const imagesCarousel = {
  name: 'Images Carousel',
  description:
    'Add images slideshow to the landing page. ' +
    'Upload multiple files, set links, and alternative texts. ' +
    'Change the order by dragging thumbnails.',
  image: imagesCarouselBanner,
  settings: {
    controls: {
      slides: createInput(
        'Carousel slides',
        {},
        {
          minCount: 1,
        },
        [],
        false,
        'carousel',
      ),
      fullViewportWidth: createInput(
        'Set Carousel to Full Viewport Width',
        {},
        {
          required: false,
        },
        false,
        true,
        'boolean',
      ),
      imagesPerSlide: createInput(
        'Choose number of images per slide',
        {
          placeholder: 'Choose number of images per slide',
          min: 1,
          max: 10,
          type: 'number',
        },
        {
          required: false,
          isInt: true,
        },
        1,
        true,
        'input',
      ),
    },
    formIsValid: false,
  },
};

export const vidio = {
  name: 'Connect Form',
  description:
    'Add images carousel with the subscription form. When the user clicks ' +
    'the image, the form appears. You can set additional fields and conditions.',
  image: vidioBanner,
  disabledForType: 'vidio',
  settings: {
    controls: {
      companyLogoUrl: createInput(
        'Brand logo in Connection Form',
        {
          placeholder: 'Image',
        },
        {},
        '',
        true,
        'image',
      ),
      brandAnimationUrl: createInput(
        'Brand Animation File(Lottie Json)',
        {
          placeHolder: 'Animation Json File',
        },
        {},
        '',
        true,
        'json',
      ),
      brandBanner: createInput(
        'Brand banner in email',
        {
          placeholder: 'Image',
        },
        {},
        '',
        true,
        'image',
      ),
      introMessage: createInput(
        'Intro Message/Instructions',
        {
          placeholder: '',
          description: 'Enter a text message or form filling instructions',
          rows: 5,
        },
        {
          required: false,
        },
        '',
        true,
        'textarea',
      ),
      // Hard set to inline format
      inlineForm: createInput(
        'Embed as popup form with banner',
        {
          description: 'Show Connection Form as a popup, with carousel banners',
          reverseBoolean: true,
          hidden: true,
        },
        {
          required: false,
        },
        true,
        true,
        'boolean',
      ),
      slides: createInput(
        'Upload Image',
        {
          hidden: true,
        },
        {
          minCount: 1,
          required: false,
        },
        [],
        true,
        'bannerPicker',
      ),
      terms: { valid: true },
      inputs: { valid: true },
      textFields: { valid: true },
      showOnLoad: createInput(
        'Show on page load',
        {
          description: 'Show Connection Form window after page is loaded',
        },
        {
          required: false,
        },
        false,
        true,
        'boolean',
      ),
      inputFontSize: createInput(
        'Input font size',
        {
          placeholder: 'Input font size',
          options: Array.from(Array(24).keys())
            .filter((num) => num % 2 === 0)
            .map((num) => ({
              value: num + 8,
              label: num + 8,
            })),
        },
        {
          required: false,
        },
        16,
        true,
        'modern-select',
      ),
      formWidth: createInput(
        'Form width',
        {
          placeholder: 'Form width',
          description: 'Works only when image position is set to "Side"',
          options: Array.from(Array(12).keys()).map((num) => ({
            value: num + 1,
            label: `${num + 1}/12`,
          })),
        },
        {
          required: false,
        },
        6,
        true,
        'modern-select',
      ),
      imageBoxPosition: createInput(
        'Image box position',
        {
          placeholder: 'Image box position',
          options: [
            {
              value: 'side',
              label: 'Side',
            },
            {
              value: 'top',
              label: 'Top',
            },
          ],
        },
        {
          required: false,
        },
        'side',
        true,
        'modern-select',
      ),
      imageAlign: createInput(
        'Image align inside image box',
        {
          placeholder: 'Image align inside image box',
          description: 'Works only when image position is set to "Side"',
          options: [
            {
              value: 'top',
              label: 'Top',
            },
            {
              value: 'center',
              label: 'Center',
            },
            {
              value: 'bottom',
              label: 'Bottom',
            },
          ],
        },
        {
          required: false,
        },
        'center',
        true,
        'modern-select',
      ),
      showOnLoadTimout: createInput(
        'Delay for showing on page load',
        {
          description:
            'In seconds, works only when show on page load is set to true',
          placeholder: '3',
          min: 0,
          type: 'number',
        },
        {
          required: false,
          isInt: true,
        },
        3,
        true,
        'input',
      ),
    },
    translations: {},
    formIsValid: true,
  },
};

export const postQuestions = {
  name: 'Post Connect Form',
  description:
    'Add a Post Connect form. Appears once an end-user submits successfully the "Connect Form". ' +
    'Requires the "Connect Form" to be configured.',
  image: vidioBanner,
  settings: {
    controls: {
      introHeading: createInput(
        'Heading',
        {
          placeholder: 'Bonus. Click to win.',
          description:
            'A short heading for Post Connect form category selection',
        },
        {
          required: true,
        },
        '',
        true,
        'input',
      ),
      introMessage: createInput(
        'Intro Message/Instructions',
        {
          placeholder: 'Choose a category for another chance to win',
          description: 'Enter a text message or form filling instructions',
          rows: 5,
        },
        {
          required: true,
        },
        '',
        true,
        'textarea',
      ),
      footerMessage: createInput(
        'Form footer message',
        {
          placeholder: '',
          description:
            'Footer content for disclaimer and/or terms and conditions',
          rows: 5,
        },
        {
          required: false,
        },
        '',
        true,
        'textarea',
      ),
      successMessage: createInput(
        'Success message',
        {
          description: 'Shown on submission of the form',
          rows: 5,
        },
        {
          required: false,
        },
        '',
        true,
        'textarea',
      ),
      emailMessage: createInput(
        'Email Notification message',
        {
          description:
            'Additional message will be added to the email notification',
          rows: 5,
        },
        {
          required: false,
        },
        '',
        true,
        'textarea',
      ),
      categories: { valid: true },
      selectedQuestionIds: {
        valid: false,
        skipTranslation: true, // Persist in {config} rather than {translation}
        validation: {
          required: true,
        },
      },
    },
    translations: {},
    formIsValid: false,
  },
};

export const globalSettings = {
  name: 'Global Settings',
  description:
    'Change global landing page visual settings. ' +
    'For example, you can set up page base colors and sections spacing. ',
  config: {
    defaultLanguage: 'en',
    languages: ['en'],
  },
  settings: {
    controls: {
      mainColor: createInput(
        'Pick main color',
        {},
        {},
        '#000000',
        false,
        'colorPicker',
      ),
      backgroundColor: createInput(
        'Pick background color',
        {},
        {},
        '#ffffff',
        false,
        'colorPicker',
      ),
      imageBoxColor: createInput(
        'Pick image box background color',
        {},
        {},
        '#000000',
        false,
        'colorPicker',
      ),
      paddingTop: createInput(
        'Padding top',
        {
          type: 'number',
          description: 'Applies to every module',
          min: 0,
        },
        {
          isInt: true,
        },
        '0',
        true,
      ),
      paddingBottom: createInput(
        'Padding bottom',
        {
          type: 'number',
          description: 'Applies to every module',
          min: 0,
        },
        {
          isInt: true,
        },
        '0',
        true,
      ),
      languages: createInput(
        'Add language',
        {},
        { required: true },
        [],
        true,
        'languages',
      ),
      htmlCode: createInput(
        'Custom Html Code',
        {
          description:
            'Your code will be rendered inside <body> tag, ' +
            'at the end of the generated page and with an embedded form. ' +
            'Use it to inject custom tracking code (GA, GTM, Hotjar etc.) to the campaign.',
          placeholder: 'Html code',
          rows: 5,
        },
        {
          required: false,
          maxLength: 10000,
        },
        '',
        true,
        'textarea',
      ),
    },
    formIsValid: true,
  },
};
