import instance from './Api';

const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });

  return instance.get(`/companies${query}`);
};

const options = (getCampaignOptions) =>
  instance.get(
    `/companies/options${getCampaignOptions ? '?getCampaignOptions=true' : ''}`,
  );
const save = (company) => instance.post('/companies', company);
const update = (id, company) => instance.put(`/companies/${id}`, company);
const get = (id) => instance.get(`/companies/${id}`);
const deactivate = (id) => instance.put(`/companies/${id}/deactivate`);
const companyOptions = (id) => instance.get(`/companies/${id}/options`);
const getUsers = (id, filters) => {
  let query = '';
  Object.keys(filters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${filters[filter]}`;
  });
  return instance.get(`/companies/${id}/advertisers${query}`);
};

const saveCategories = (id, formData) => {
  return instance.post(`/companies/${id}/categories`, formData);
};

const removeCategory = (id, categoryId, confirmed = false) => {
  const queryConfirm = confirmed ? '?confirm=true' : '';
  return instance.delete(
    `/companies/${id}/categories/${categoryId}${queryConfirm}`,
  );
};

const removeCategoryQuestion = (
  id,
  categoryId,
  questionId,
  confirmed = false,
) => {
  const queryConfirm = confirmed ? '?confirm=true' : '';
  return instance.delete(
    `/companies/${id}/categories/${categoryId}/post-question/${questionId}${queryConfirm}`,
  );
};

const webhookList = (companyId) => {
  return instance.get(`/companies/${companyId}/hook`);
};

const webhookUpdate = (companyId, hookId, webhook) => {
  return instance.put(`/companies/${companyId}/hook/${hookId}`, webhook);
};

const webhookCreate = (companyId, webhook) => {
  return instance.post(`/companies/${companyId}/hook`, webhook);
};

export default {
  list,
  options,
  save,
  update,
  get,
  deactivate,
  companyOptions,
  getUsers,
  saveCategories,
  removeCategory,
  removeCategoryQuestion,
  webhookList,
  webhookUpdate,
  webhookCreate,
};
