import React, { useEffect, useState } from 'react';
import Input from '../../../../../../components/UI/Input/Input';
import Toaster from '../../../../../../components/UI/Toaster/Toaster';

const valuesToOptions = (values) =>
  values?.map((val) => ({
    value: val,
    label: val,
  })) || [];

const FilterInputSelect = ({
  filterConfig,
  filterValue,
  onFilterValueChange,
}) => {
  const emptyToast = { show: false, header: '', class: '' };

  const optionsToValues = (options) => options?.map((sel) => sel.value) || [];

  const [filterAnswers, setFilterAnswers] = useState(
    valuesToOptions(filterConfig.answers),
  );
  const [toast, setToast] = useState(emptyToast);

  const closeToast = () => {
    setToast(emptyToast);
  };

  useEffect(() => {
    if (typeof filterConfig.answersFn === 'function') {
      filterConfig
        .answersFn()
        .then((answersList) => {
          setFilterAnswers(valuesToOptions(answersList));
        })
        .catch(() => {
          setFilterAnswers(valuesToOptions([]));
          setToast({
            show: true,
            class: 'error',
            header: `We could not import values for the '${filterConfig.label}' input`,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Input
        label={filterConfig.label}
        elementType="multiselect"
        additionalClasses="formInput formInput--bordered"
        dataTestAttribute={`filters-input-${filterConfig.name}-select`}
        elementConfig={{ options: filterAnswers }}
        value={valuesToOptions(filterValue)}
        changed={(selectedValues) =>
          onFilterValueChange(optionsToValues(selectedValues))
        }
      />
      <Toaster toast={toast} closeToast={closeToast} />
    </>
  );
};

export default FilterInputSelect;
