import { useEffect, useState } from 'react';
import PaywallApi from '../../../api/Paywall';
import { transposeQuestions } from './QualifierQuestions.transpose';

export const useQualifierQuestions = () => {
  const [questionListAll, setQuestionListAll] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [formData, setFormData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [searchValue, setSearch] = useState('');

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const getQuestionList = async () => {
    setLoading(true);
    PaywallApi.getQuestions()
      .then((response) => {
        setQuestionListAll(transposeQuestions(response.data.questions));
        setQuestionList(transposeQuestions(response.data.questions));
      })
      .catch(() => {
        console.error('error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addQuestion = () => {
    setFormData({});
    setShowForm(true);
  };

  const editQuestion = (data = {}) => {
    setFormData(data);
    setShowForm(true);
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  const onFormSuccess = () => {
    setFormData({});
    getQuestionList();
  };

  const onFilterUpdated = (key, value) => {
    if (key === 'search') {
      setSearch(value);
      const filteredList = questionListAll.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      );
      setQuestionList(filteredList);
    }
  };

  const getters = {
    questionList,
    formData,
    showForm,
    toast,
    loading,
    searchValue,
  };
  const actions = {
    addQuestion,
    editQuestion,
    getQuestionList,
    setShowForm,
    onFormSuccess,
    closeToast,
    setToast,
    setQuestionList,
    onFilterUpdated,
  };

  return [getters, actions];
};
