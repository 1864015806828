import React from 'react';
import Button from '../../../components/UI/Button/Button';
import { formatDate } from '../../../shared/dateTime';

const renderValueOrNa = ({ value }) => {
  return value || 'n/a';
};

const actionButton = (e, { termsId, navigate, campaignId, getEditUrl }) => {
  e.stopPropagation();
  navigate(getEditUrl(termsId, campaignId));
};

export const getColumns = ({ campaignId, navigate, getEditUrl }) => [
  {
    name: 'id',
    header: 'Version',
    render: ({ value }) => {
      return value + '.0' + (!campaignId ? 'b' : '');
    },
  },
  {
    name: 'publishDate',
    header: 'Date Published',
    defaultFlex: 1,
    render: ({ value }) => {
      return formatDate(value, 'dd-MM-yyyy') || 'n/a';
    },
  },
  {
    name: 'createdBy',
    header: 'Created By',
    defaultWidth: 150,
    defaultFlex: 1,
    render: renderValueOrNa,
  },
  {
    name: 'lastEditBy',
    header: 'Edited By',
    defaultWidth: 150,
    defaultFlex: 1,
    render: renderValueOrNa,
  },
  {
    name: 'descriptor',
    header: 'Description',
    defaultWidth: 200,
    defaultFlex: 1,
    render: renderValueOrNa,
  },

  {
    name: 'actions',
    header: 'Actions',
    minWidth: 200,
    render: ({ data }) => {
      return (
        <Button
          additionalClasses="btnDefault--small"
          clicked={(e) =>
            actionButton(e, {
              termsId: data.id,
              campaignId,
              navigate,
              getEditUrl,
            })
          }
        >
          {data.published ? 'View' : 'Edit'}
        </Button>
      );
    },
  },
];
