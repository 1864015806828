import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import AuthMfaEnroll from '../../../components/Auth/AuthMfaEnroll';

/**
 * Catches non-MFA role-based-requirement user sign in (only)
 */
const SetMultiFactorPage = () => {
  return <AuthMfaEnroll />;
};

export const SetMultiFactorRedirect = () => {
  const location = useLocation();
  return <Navigate to={'/activate-mfa' + location.search} replace />;
};

export default SetMultiFactorPage;
