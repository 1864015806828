import CampaignSectionInformation from './CampaignSections/CampaignSectionInformation';
import CampaignSectionProtected from './CampaignSections/CampaignSectionProtected';
import CampaignSectionTarget from './CampaignSections/CampaignSectionTarget';
import CampaignSectionServiceType from './CampaignSections/CampaignSectionServiceType';
import CampaignSectionQualifications from './CampaignSections/CampaignSectionQualifications';
import CampaignSectionCallback from './CampaignSections/CampaignSectionCallback';
import CampaignSectionBuilder from './CampaignSections/CampaignSectionBuilder';
import CampaignSectionEmail from './CampaignSections/CampaignSectionEmail';

/**
 * Keys must match 'sectionConfig' from
 *  controls/index
 */
export const getCampaignSections = ({
  isAdmin = false,
  isBountyAdmin = false,
} = {}) => [
  {
    Component: CampaignSectionInformation,
    key: 'information',
    displayTest: () => true,
  },
  {
    Component: CampaignSectionProtected,
    key: 'protected',
    displayTest: (values) => !!values.id && (isAdmin || isBountyAdmin),
  },
  {
    Component: CampaignSectionTarget,
    key: 'target',
    displayTest: (values) =>
      !values.isProviderFallback && !values.isGlobalFallback,
  },
  {
    Component: CampaignSectionQualifications,
    key: 'qualifications',
    displayTest: (values) => values.paywall?.enabled,
  },
  {
    Component: CampaignSectionCallback,
    key: 'callback',
    displayTest: (values) => !!values.id,
  },
  {
    Component: CampaignSectionServiceType,
    key: 'serviceType',
    displayTest: (values) => !!values.id || !values.paywall?.enabled,
  },
  {
    Component: CampaignSectionEmail,
    key: 'email',
    displayTest: (values) => !!values.id && (isAdmin || isBountyAdmin),
  },
  {
    Component: CampaignSectionBuilder,
    key: 'builder',
    displayTest: (values) => !!values.id,
  },
];
