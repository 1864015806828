import React from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from '../UI/Pagination/Pagination';

const ProviderCostHistory = ({
  onPageChange,
  costsHistory: { page, data, total_pages, total_count },
}) => (
  <>
    <Table className="table-sm">
      <thead>
        <tr>
          <th>Date</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {data?.length &&
          data.map((costEntry) => (
            <tr key={costEntry.id}>
              <td>{new Date(costEntry.dateAdded).toLocaleDateString()}</td>
              <td>{costEntry.cost}</td>
            </tr>
          ))}
      </tbody>
    </Table>
    <Pagination
      current_page={+page}
      total_pages={total_pages}
      total_count={total_count}
      pageChange={onPageChange}
    />
  </>
);

export default ProviderCostHistory;
