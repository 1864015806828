import { string, boolean } from 'yup';

export const getSchema = () => ({
  protected: boolean(),
  signature: string(),
  decipherKey: string(),
});

const displayFields = {
  protected: {
    key: 'protected',
    defaultValue: false,
  },
  signature: {
    key: 'signature',
    label: 'Signature',
    type: 'default',
    elementConfig: {
      disabled: true,
    },
  },
  decipherKey: {
    key: 'decipherKey',
    label: 'Decipher Key',
    type: 'default',
    elementConfig: {
      disabled: true,
    },
  },
  uniqueIdFrom: {
    key: 'uniqueIdFrom',
    elementConfig: {
      type: 'number',
    },
  },
  uniqueIdTo: {
    key: 'uniqueIdTo',
    elementConfig: {
      type: 'number',
    },
  },
};

export default displayFields;
