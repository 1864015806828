import React, { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

// Component Imports
import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import ActionsNavbar from '../../../components/ActionsNavbar/ActionsNavbar';
import Button from '../../../components/UI/Button/Button';
import PhoneInput from '../../../components/UI/PhoneInput/PhoneInput';
import ProfilePhotoInput from '../../../components/UI/ProfilePhotoInput/ProfilePhotoInput';
import Input from '../../../components/UI/Input/Input';
import Modal from '../../../components/UI/Modal/Modal';
import Toaster from '../../../components/UI/Toaster/Toaster';

// Local Imports
import useUserEditPage from './UserEditPage.hooks';
import './UserEditPage.scss';
import ToggleSwitchInput from '../../../components/UI/ToggleSwitchInput/ToggleSwitchInput';

const DEFAULT_COUNTRY_CODE = 'id';

const UserEditForm = ({
  onSubmit,
  controls,
  editingSelf,
  isAdmin,
  isBountyAdmin,
  onInputChangeHandler,
  formTouched,
  formValid,
  toast,
  setToast,
  id,
}) => {
  const formElementsArray = useMemo(() => {
    if (!controls) return {};
    return Object.keys(controls).reduce((arr, key) => {
      arr.push({
        id: key,
        ...controls[key],
      });
      return arr;
    }, []);
  }, [controls]);

  return (
    <form onSubmit={onSubmit}>
      {formElementsArray.map((formElement) => {
        const {
          id: formElementId,
          label,
          value,
          valid,
          validation,
          error,
          elementConfig,
          elementType,
          touched,
          testId,
        } = formElement;

        if (elementType === 'profile-photo' && editingSelf) {
          return (
            <ProfilePhotoInput
              key={formElementId}
              keyName={formElementId}
              value={value}
              onChange={(val) => onInputChangeHandler(val, formElementId)}
              placeholder={elementConfig?.placeholder}
              invalid={!valid}
              error={error}
            />
          );
        }
        if (
          elementType === 'phone' &&
          (editingSelf || isAdmin || isBountyAdmin)
        ) {
          return (
            <PhoneInput
              key={formElementId}
              keyName={formElementId}
              label={label}
              value={value}
              onChange={(val) => onInputChangeHandler(val, formElementId)}
              required={validation?.required}
              disabled={elementConfig?.disabled}
              placeholder={elementConfig?.placeholder}
              country={DEFAULT_COUNTRY_CODE}
              enableSearch
              invalid={!valid}
              error={error}
            />
          );
        }
        if (formElement.elementType === 'toggle') {
          return (
            <ToggleSwitchInput
              key={formElement.id}
              label={formElement.label}
              text={formElement.value ? 'Yes' : 'No'}
              value={formElement.value}
              description={formElement.description}
              onCheck={(val) => onInputChangeHandler(val, formElement.id)}
            />
          );
        }
        if (label === 'Company') {
          const newVal = Array.isArray(value)
            ? value.map((val) => val.id || val.value)
            : value;
          return (
            <Input
              additionalClasses="formInput formInput--bordered"
              key={formElementId}
              keyName={formElementId}
              elementType={elementType}
              elementConfig={elementConfig}
              value={newVal}
              label={label}
              invalid={!valid}
              shouldValidate={validation}
              touched={touched}
              changed={(event) => onInputChangeHandler(event, formElementId)}
              error={error}
              dataTestAttribute={testId}
              required={validation?.required}
            />
          );
        }
        return (
          <Input
            additionalClasses="formInput formInput--bordered"
            key={formElementId}
            keyName={formElementId}
            elementType={elementType}
            elementConfig={elementConfig}
            value={value}
            label={label}
            invalid={!valid}
            shouldValidate={validation}
            touched={touched}
            changed={(event) => onInputChangeHandler(event, formElementId)}
            error={error}
            dataTestAttribute={testId}
            required={validation?.required}
          />
        );
      })}

      <Button
        additionalClasses="mt-1"
        disabled={!formTouched || !formValid}
        dataTestAttribute="save-profile"
      >
        {id ? 'Save' : 'Invite'}
      </Button>

      <Toaster
        toast={toast}
        closeToast={() => {
          setToast({ show: false, header: '', class: '' });
        }}
      />
    </form>
  );
};

const UserEditPage = () => {
  const [getters, actions] = useUserEditPage();

  const {
    toast,
    showConfirmationModal,
    title,
    loading,
    formTouched,
    formValid,
    initialLoad,
    controls,
    editingSelf,
    isAdmin,
    isBountyAdmin,
    id,
  } = getters;

  const {
    onSubmit,
    onInputChangeHandler,
    cancel,
    navigate,
    setToast,
    saveData,
  } = actions;

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
                col-auto
                mb-2
                mb-md-0
                d-flex
                flex-wrap
                align-content-center"
        >
          <Button
            additionalClasses="
                        _add-campaign
                        btnDefault--with-icon
                        btnDefault--with-icon-left"
            clicked={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              className=" btnDefault--with-icon__icon"
            />
            Return
          </Button>
        </Col>
      </ActionsNavbar>

      <Container className="userEdit">
        <Row className="justify-content-md-center mt-5">
          <Col xs={12} md={6}>
            <h2>{title}</h2>
            {initialLoad ? (
              <OverlaySpinner />
            ) : (
              <>
                {loading && <OverlaySpinner />}
                <UserEditForm
                  {...{
                    onSubmit,
                    controls,
                    editingSelf,
                    isAdmin,
                    isBountyAdmin,
                    onInputChangeHandler,
                    formTouched,
                    formValid,
                    toast,
                    setToast,
                    id,
                  }}
                />
              </>
            )}
            {showConfirmationModal.show ? (
              <Modal
                title={showConfirmationModal.header}
                body={showConfirmationModal.body}
                cancel="No"
                save="Yes"
                handleSave={() => (id ? saveData(true) : saveData(false))}
                show
                handleClose={cancel}
                dataTestAttribute={{
                  modal: 'modal-campaign-form',
                  action1: 'no-save-form',
                  action2: 'yes-save-form',
                }}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserEditPage;
