import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

const LanguageDropdown = ({
  current,
  availableLanguages,
  switchLanguage,
  type = 'NORMAL',
  toggleClassName = '',
  label,
}) => {
  const formattedLanguages = availableLanguages.map((language) => {
    return typeof language === 'object'
      ? language
      : { value: language, label: language.toUpperCase() };
  });

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={`btnDefault btnDefault--with-icon btnDefault--with-icon-add ${toggleClassName}`}
        id="switch-language"
        data-test-id="switch-language-button"
      >
        {type !== 'NORMAL' ? (
          <>
            <span className="text-capitalize">{label}</span>{' '}
            <span style={{ marginLeft: '1rem', marginRight: '0.5rem' }}>
              {current}
            </span>
            <LanguangeGlobeSvg />
          </>
        ) : (
          <>Translation</>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {formattedLanguages?.map((language) => (
          <Dropdown.Item
            key={language.value}
            onClick={() => switchLanguage(language.value)}
            data-test-id={`switch-language-${language.value}`}
            disabled={language.value === current}
          >
            {language.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const LanguangeGlobeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99998 17C11.2776 17 13.124 13.4183 13.124 9C13.124 4.58172 11.2776 1 8.99998 1C6.72235 1 4.87598 4.58172 4.87598 9C4.87598 13.4183 6.72235 17 8.99998 17Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 1V17"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 9H1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

LanguageDropdown.defaultProps = {
  type: 'NORMAL',
};

LanguageDropdown.propTypes = {
  type: PropTypes.oneOf(['NORMAL', 'DEFAULT']),
};

export default LanguageDropdown;
