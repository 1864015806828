import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import UserService from '../../api/Users';

import successIcon from '../../assets/icons/status-complete.svg';
import Input from '../../components/UI/Input/Input';
import PhoneInput from '../../components/UI/PhoneInput/PhoneInput';
import Button from '../../components/UI/Button/Button';
import BountyBannerLogo from '../../components/UI/Logo/BountyBannerLogo';
import inputChangedHandler from '../../shared/inputChangeHandler';
import controls from './controls';

import styles from './ContactUsPage.module.scss';

const DEFAULT_COUNTRY_CODE = 'ID';

const ContactUsForm = ({ setIsMessageSent }) => {
  const [geoLocationCountry, setGeoLocationCountry] = useState(null);

  const [error, setError] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [authForm, setAuthForm] = useState({
    controls: {
      name: controls.name,
      email: controls.email,
      phoneNumber: controls.phoneNumber,
      subject: controls.subject,
      message: controls.message,
    },
  });

  useEffect(() => {
    const getGeoLocationInfo = async () => {
      try {
        const { data } = await axios.get('https://js.instantgeo.info/json');
        setGeoLocationCountry(data.country.toLowerCase());
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') console.error(err);
        // non-critical error
      }
    };

    getGeoLocationInfo();
  }, []);

  const sendToAdmin = async (event) => {
    event.preventDefault();
    setError('');

    if (!formIsValid) {
      getFormElements().map((formElement) => {
        const { id, config } = formElement;
        setAuthForm((prevState) => {
          const [updatedControls, newFormIsValid] = inputChangedHandler(
            config.value,
            prevState,
            id,
          );
          setFormIsValid(newFormIsValid);
          return { controls: updatedControls };
        });
      });
      setError('These fields are required.');
      return;
    }

    const contactAdminRequest = {
      name: authForm.controls.name.value,
      email: authForm.controls.email.value,
      phoneNumber: authForm.controls.phoneNumber.value,
      subject: authForm.controls.subject.value,
      message: authForm.controls.message.value,
    };

    setLoading(true);

    try {
      await UserService.contactAdmin(contactAdminRequest);
      if (typeof setIsMessageSent === 'function') setIsMessageSent(true);
    } catch (e) {
      console.error(e?.message);
      setLoading(false);
    }
  };

  const getFormElements = () => {
    const formElementsArray = [];
    Object.keys(authForm.controls).forEach((key) => {
      formElementsArray.push({
        id: key,
        config: authForm.controls[key],
      });
    });
    return formElementsArray;
  };

  const onInputChangeHandler = (event, inputIdentifier) => {
    let value;
    if (typeof event === 'string') {
      value = event;
    } else {
      value = event?.target?.value || '';
    }
    setAuthForm((prevState) => {
      const [updatedControls, newFormIsValid] = inputChangedHandler(
        value,
        prevState,
        inputIdentifier,
      );
      setFormIsValid(newFormIsValid);
      return { controls: updatedControls };
    });
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.subTitle}>
        <p>
          Complete our contact form and we will be in touch as soon as possible
        </p>
      </div>
      <form className="authForm" onSubmit={sendToAdmin}>
        {getFormElements().map((formElement) => {
          const { id, config } = formElement;
          if (config.elementType === 'phone') {
            return (
              <PhoneInput
                key={id}
                keyName={id}
                value={config.value}
                onChange={(value) => onInputChangeHandler(value, id)}
                required={config.validation?.required}
                disabled={config.elementConfig?.disabled ?? false}
                placeholder={config.elementConfig?.placeholder ?? false}
                country={geoLocationCountry ?? DEFAULT_COUNTRY_CODE}
                enableSearch
                invalid={!config.valid && config.touched}
                error={config.error}
                hideErrorMessage
              />
            );
          }
          return (
            <Input
              additionalClasses={`formInput ${
                config.elementType === 'textarea' && styles.formInputTextArea
              }`}
              key={id}
              elementType={config.elementType}
              elementConfig={config.elementConfig}
              value={config.value}
              invalid={!config.valid}
              shouldValidate={config.validation}
              touched={config.touched}
              changed={(event) => onInputChangeHandler(event, id)}
              required={config?.validation?.required}
            />
          );
        })}

        {error ? (
          <p className="authFormErrorText text-center mt-2 mb-0">{error}</p>
        ) : null}

        <Button
          additionalClasses="btnDefault w-100 mt-4"
          dataTestAttribute="send-button"
          isLoading={isLoading}
        >
          Send
        </Button>
      </form>
    </div>
  );
};

const SuccessPrompt = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.successPromptContainer}>
      <div className="my-4">
        <div className={styles.successPromptTitle}>
          <div>
            <img alt="Success" src={successIcon} width={16} height={16} />
          </div>
          <span>Success</span>
        </div>
        <div className={styles.successPromptLabel}>
          <p>
            Your message was received, our support team will be in touch with
            you soon.
          </p>
        </div>
      </div>
      <Button
        clicked={() => navigate('/auth')}
        additionalClasses="btnDefault"
        dataTestAttribute="return-button"
      >
        Return
      </Button>
    </div>
  );
};

const ContactUsPage = () => {
  const navigate = useNavigate();
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleReturn = () => {
    navigate('/auth');
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isMessageSent) {
        navigate('/auth');
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isMessageSent, navigate]);

  return (
    <div className={styles.contactUsPage}>
      <BountyBannerLogo />
      <section className={styles.loginBox}>
        <button onClick={handleReturn} className={styles.backButton}>
          <FontAwesomeIcon size="xl" width="54" icon={faArrowLeftLong} />{' '}
          <p>Return</p>
        </button>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>Contact Us</p>
          </div>
          {isMessageSent ? (
            <SuccessPrompt />
          ) : (
            <ContactUsForm setIsMessageSent={setIsMessageSent} />
          )}
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
