import React from 'react';

const LinkArea = ({
  linkBoxMapStyle = {},
  index = 0,
  onClick,
  dataTestAttribute,
}) => (
  <div
    onClick={() => onClick(index)}
    style={linkBoxMapStyle}
    data-test-id={dataTestAttribute || null}
  />
);

export default LinkArea;
