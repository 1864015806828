import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FileUploadButton from '../FileUploadButton/FileUploadButton';
import CarouselThumb from './CarouselThumb';
// eslint-disable-next-line import/no-cycle
import CarouselSlideForm from './CarouselSlideForm';

const CarouselInput = ({ name, onChange, value }) => {
  const [slides, setSlides] = useState(value);
  const [fileErrors, setFileErrors] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(value.length ? 0 : null);

  const onChangeHandle = (newValue) => {
    onChange({ target: { value: newValue } }, name);
  };

  const addSlide = (imageUrl) => {
    const slideData = {
      imageUrl,
      imageAlt: '',
      linkUrl: '',
    };

    setSlides((oldSlides) => [...oldSlides, slideData]);
    setCurrentSlide(slides.length);
  };

  const removeSlide = (index) => {
    setSlides((oldSlides) => {
      const newSlides = [...oldSlides];
      newSlides.splice(index, 1);
      return newSlides;
    });
    if (currentSlide === index) {
      setCurrentSlide(null);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const reorderSlides = (result) => {
    if (!result.destination) {
      return;
    }
    setSlides(reorder(slides, result.source.index, result.destination.index));
    setCurrentSlide(result.destination.index);
  };

  const onFileErrors = (file, errors) => {
    const errorMessage = `${errors.filename}: ${errors.errors.join(', ')}`;
    setFileErrors((errs) => [...errs, errorMessage]);
  };

  const onSlideFormChanged = (newSlideData) => {
    setSlides((oldSlides) => {
      const newSlides = [...oldSlides];
      newSlides[currentSlide] = newSlideData;
      return newSlides;
    });
  };

  useEffect(() => {
    if (slides !== value) {
      onChangeHandle(slides);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  const uploadErrorsTemplate = (
    <div className="formInputError">
      {/* eslint-disable-next-line react/no-array-index-key */}
      {fileErrors.map((errorMessage, i) => (
        <p key={i}>{errorMessage}</p>
      ))}
    </div>
  );

  return (
    <div className="carouselInput" data-test-id="carousel-input-upload">
      <FileUploadButton
        onFileUploadSuccess={(_, uploadResponse) =>
          addSlide(uploadResponse.data)
        }
        onFileUploadError={onFileErrors}
        onUploadStart={() => setFileErrors([])}
        multiple
        buttonText="Add photos"
      />

      {fileErrors.length ? uploadErrorsTemplate : null}

      {slides.length ? (
        <DragDropContext onDragEnd={reorderSlides}>
          <Droppable droppableId="carouselInput" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className="carouselInputThumbs"
                data-test-id="carousel-input-thumbs"
                {...provided.droppableProps}
              >
                {slides &&
                  slides.map((slide, index) => (
                    <Draggable
                      key={slide.imageUrl}
                      draggableId={slide.imageUrl}
                      index={index}
                    >
                      {(provided_) => (
                        <div
                          ref={provided_.innerRef}
                          {...provided_.draggableProps}
                          {...provided_.dragHandleProps}
                        >
                          <CarouselThumb
                            imageUrl={slide.imageUrl}
                            selected={index === currentSlide}
                            onSelect={() => setCurrentSlide(index)}
                            onRemove={() => removeSlide(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}

      {currentSlide !== null && currentSlide in slides && (
        <CarouselSlideForm
          onFormChanged={onSlideFormChanged}
          slide={slides[currentSlide]}
        />
      )}
    </div>
  );
};
export default CarouselInput;
