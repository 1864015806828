import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import { produce } from 'immer';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faArrowLeft,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

// Bootstrap Imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';

// API Imports
import CampaignService from '../../../api/Campaigns';
import CompanyService from '../../../api/Companies';
/* Deprecated - Service and Connect form cannot be changed */
// import CarouselImages from '../../../api/CarouselImages';
import CpdcService from '../../../api/Cpdcs';
import TagService from '../../../api/Tags';

// Component Imports
import PromptModal from '../../../components/UI/PromptModal/PromptModal';
import ActionsNavbar from '../../../components/ActionsNavbar/ActionsNavbar';
import Creator from '../../../components/Creator/Creator';
import CampaignEnable from '../../../components/CampaignEnable/CampaignEnable';
import FormInput from '../../../components/UI/FormInput/FormInput';
import Button from '../../../components/UI/Button/Button';
import TextLink from '../../../components/UI/Button/TextLink';
import Tile from '../../../components/UI/Tile/Tile';
import Modal from '../../../components/UI/Modal/Modal';
import ToggleSwitchInput from '../../../components/UI/ToggleSwitchInput/ToggleSwitchInput';
import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import Toaster from '../../../components/UI/Toaster/Toaster';

// Local Imports
import CampaignForm from '../CampaignForm/CampaignForm';
import CampaignUrlModal from './CampaignUrlModal';
import { prepareValuesToDisplay } from './tileValues';
import * as actions from '../../../store/actions/index';

import './CampaignEditPage.scss';
import CampaignNotes from '../CampaignNotes/CampaignNotes';
import initControls from './controls';
import QualifyQuestions from '../QualifyQuestions/QualifyQuestions';

// Shared & Fixtures imports
import { inputFormatDate } from '../../../shared/dateTime';
/* Deprecated - Service and Connect form cannot be changed */
// import {
//   replaceSlidesInTranslations,
//   reduceProviderInfoInBanners,
// } from '../../../shared/vidioSectionData';
import { capitalize } from '../../../shared/capitalize';

/**
 * IMPORTANT
 *
 * Imported due to some compatibility issues mentioned
 * in README "Important" section.
 */
import 'react-phone-input-2/lib/bootstrap.css';
import { formatHttps } from '../../../shared/inputFormatting';
import WebhookList from '../../../components/Campaign/Webhook/WebhookList';
import CampaignWebhookModal from '../../../components/Campaign/Webhook/CampaignWebhookModal';

const STATS_REFRESH_INTERVAL = 30000;
const DEFAULT_VERIFICATION_METHOD = 'email';

const getSelectedLabels = (e, options = []) =>
  typeof e === 'object'
    ? e.label
    : options.find((o) => o.value === e).label || '';

const CampaignEditPage = () => {
  const PUBLIC_PAGES_URL = process.env.REACT_APP_PUBLIC_PAGES_URL.replace(
    /\/$/,
    '',
  );

  const navigate = useNavigate();
  const { id: campaignId } = useParams();
  const dispatch = useDispatch();
  const { hasNewTermsAndConditions } = useSelector(
    (state) => state.termsAndConditions,
  );

  const mountedRef = useRef(true);
  const { hasDataToFetch } = useSelector((state) => state.termsAndConditions);
  const { isAdmin, isBountyAdmin, isCompanyAdmin } = useSelector(
    (state) => state.auth,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [formData, setFormData] = useState({
    data: {},
    errors: {},
    touched: {},
    isValid: true,
  });
  const formHasChanged = !!Object.keys(formData.touched).length;
  const [campaignData, setCampaignData] = useState({});
  const [countData, setCountData] = useState({
    pageViews: 0,
    cpdcs: 0,
    uniquePageViews: 0,
  });
  const [oldBudget, setOldBudget] = useState(0);
  const [serviceType, setServiceType] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({});
  const isCampaignEnabledRef = useRef(formData?.data?.enabled);
  const isEn = formData?.data?.enabled && formData?.data?.enabled !== undefined;
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [webhookManageModal, setWebhookManageModal] = useState({
    show: false,
    data: null,
  });

  const [providers, setProviders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [webhooks, setWebhooks] = useState([]);

  const [providerFallbackMode, setProviderFallbackMode] = useState(false);
  const [globalFallbackMode, setGlobalFallbackMode] = useState(false);
  const [verificationMethods, setVerificationMethods] = useState([]);
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState(
    DEFAULT_VERIFICATION_METHOD,
  );

  const [filePassword, setFilePassword] = useState(null);
  const [showFilePasswordModal, setShowFilePasswordModal] = useState(false);

  const isFallbackCampaign = providerFallbackMode || globalFallbackMode;

  const [controls, setControls] = useState(initControls);
  const [updateControls] = useState(controls);

  useEffect(() => {
    if (hasNewTermsAndConditions && hasDataToFetch) {
      pageInit();
    }
    dispatch(actions.termsAndConditionsState(false, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNewTermsAndConditions]);

  // populate verification methods select input
  // with options
  useEffect(() => {
    if (serviceType) {
      const scopedUpdateControls = _cloneDeep(updateControls);
      scopedUpdateControls.verificationMethod.elementConfig.options =
        serviceType.verificationMethods.map((method) => {
          return {
            label:
              method.name === 'sms'
                ? method.name.toUpperCase()
                : capitalize(method.name),
            value: method.name,
          };
        });
      setVerificationMethods(serviceType.verificationMethods);
      setControls(scopedUpdateControls);
    }
  }, [serviceType, updateControls]);

  useEffect(() => {
    if (isCampaignEnabledRef.current !== isEn) {
      isCampaignEnabledRef.current = isEn;
    }
  }, [isEn]);

  async function pageInit() {
    // 1. Get Campaign data
    const campaignDataPromise = CampaignService.get(campaignId)
      .then((response) => response?.data)
      .catch((error) => {
        const { response } = error;
        const errData = response.data;
        dispatch(
          actions.setErrorAccessingResource(
            true,
            'error',
            errData.message || 'Error accessing campaign',
          ),
        );
        return null;
      });

    // 2. Get Company options
    const companyOptionsPromise = CompanyService.options(true)
      .then((response) => response?.data)
      .catch(() => null);

    const campaignWebhooksPromise = CampaignService.getWebhooks(campaignId)
      .then((response) => response?.data)
      .catch(() => null);

    const [_campaignData, companyOptions, campaignWebhooks] = await Promise.all(
      [campaignDataPromise, companyOptionsPromise, campaignWebhooksPromise],
    );

    // Return if required data is missing - error messaging?
    if (!_campaignData || !companyOptions) {
      navigate(-1);
      return;
    }

    setWebhooks(campaignWebhooks.data.list || []);
    setCompanies(companyOptions);

    // 3. Get Company-related brands and provider options
    const campaignCompanyId = _campaignData.company.value;
    getCompanyBrandsProviders(campaignCompanyId).then(
      ({ providers: _providers }) => {
        if (_campaignData.gender) {
          _campaignData.gender = _campaignData.gender.map((gender) => ({
            value: gender,
            label: gender,
          }));
        }

        setControls(updateControls);
        setSelectedVerificationMethod(_campaignData.verificationMethod.name);
        if (_campaignData.videoProvider?.verificationMethods) {
          setVerificationMethods(
            _campaignData.videoProvider?.verificationMethods,
          );
        }

        if (!Array.isArray(_campaignData.landingPageContent)) {
          _campaignData.landingPageContent = [];
        }

        _campaignData.company = _campaignData.company.value;
        _campaignData.clientBrand = _campaignData.brand.value;

        setFormData({
          data: _campaignData,
          errors: formData.errors,
          touched: formData.touched,
          isValid: true,
        });

        setCampaignData(_campaignData);
        setProviderFallbackMode(_campaignData.isProviderFallback ?? false);
        setGlobalFallbackMode(_campaignData.isGlobalFallback ?? false);

        if (
          _providers &&
          _providers.find((e) => e.id === _campaignData.videoProvider?.id)
        ) {
          setServiceType(_campaignData.videoProvider);
        }
        setGlobalSettings(_campaignData.globalSettings);
        setIsLoading(false);
        setOldBudget(_campaignData.budget);
      },
    );

    CampaignService.getStatus(campaignId)
      .then((result) => {
        setCampaignStatus(result.data);
      })
      .catch(() => {
        /* show error message */
      });

    // Get All Tag options
    if (isAdmin || isBountyAdmin) {
      const res = await TagService.list();
      if (res) {
        const tagList = res.data.data.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        });
        setTags(tagList);
      }
    }

    getEventsCount();
  }

  useEffect(() => {
    pageInit();
    // eslint-disable-next-line
  }, []);

  const getCompanyBrandsProviders = async (companyId) => {
    const brandProviderOptions = await CompanyService.companyOptions(companyId)
      .then((response) => response.data)
      .catch(() => null);
    if (brandProviderOptions) {
      setBrands(brandProviderOptions.brands);
      setProviders(brandProviderOptions.providers);
    }
    return brandProviderOptions;
  };

  const getEventsCount = useCallback(() => {
    CampaignService.countEvents(campaignId).then((result) => {
      const { data } = result;
      setCountData(data);
    });
  }, [campaignId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isCampaignEnabledRef.current) {
        getEventsCount();
      }
    }, STATS_REFRESH_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading, getEventsCount, isCampaignEnabledRef]);

  const onSelectedHandler = (event, name) => {
    const { value } = event && 'value' in event ? event : event?.target ?? {};

    if (name === 'verificationMethod') {
      setSelectedVerificationMethod(value);
    }

    if (name === 'serviceType') {
      const selectedProvider = providers.find(
        (provider) => provider.id === value,
      );

      setSelectedVerificationMethod(DEFAULT_VERIFICATION_METHOD);
      /* Deprecated - Service and Connect form cannot be changed */
      // setOldServiceType(serviceType);
      setServiceType(selectedProvider);

      /* Deprecated - Service and Connect form cannot be changed */
      //   const isContentExists = formData?.data?.landingPageContent?.length;
      //   const isConnectionFormExists =
      //     isContentExists &&
      //     [...formData.data.landingPageContent].filter(
      //       (item) => item.type === 'vidio',
      //     );

      /* Deprecated - Service and Connect form cannot be changed */
      //   if (isConnectionFormExists?.length && selectedProvider) {
      //     setShowReplaceConnectionFormSectionModal(true);
      //   }
    }

    if (name === 'callbackParams') {
      setFormData(
        produce((state) => {
          const { data } = state;
          data.callbackParams = !data.callbackParams;
        }),
      );
    }

    const isToggle = name === 'callbackEnable';
    const isCallbackType = name === 'callback';
    const urlRegex =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

    if (isToggle || isCallbackType) {
      const modifiedValue = (() => {
        if (isToggle) {
          return value === 0 ? 1 : 0;
        }
        return value;
      })();

      setFormData(
        produce((state) => {
          const { data, touched } = state;

          // isvalid ignores other form field states here
          // since campaign management is pending a complete
          // refactor, this doesnt matter now
          // eslint-disable-next-line no-param-reassign
          state.isValid =
            modifiedValue === 0 ||
            modifiedValue === 1 ||
            (modifiedValue === 2 && urlRegex.test(state.data.callbackUrl));

          data.callback = modifiedValue;
          touched.callback = true;
        }),
      );
    }

    if (name === 'callbackUrl') {
      const modifiedValue = formatHttps(value);

      setFormData(
        produce((state) => {
          const { data, touched, errors } = state;

          const isValid = urlRegex.test(modifiedValue);
          if (isValid) {
            delete errors[name];
          } else {
            errors[name] = 'Please check the callback url is valid';
          }

          data[name] = modifiedValue;
          touched.callback = true;
        }),
      );
    }

    setFormTouchedKey(name);
  };

  const onCallbackUrlFocus = () => {
    if (!formData.data.callbackUrl?.includes('https://')) {
      setFormData((state) => ({
        ...state,
        data: {
          ...state.data,
          callbackUrl: 'https://',
        },
      }));
    }
  };

  const setFormTouchedKey = (fieldName) => {
    setFormData((oldState) => {
      const touched = { ...oldState.touched, [fieldName]: true };
      return { ...oldState, touched };
    });
  };

  const onCampaignStateChange = (newEnabledStatus) => {
    setFormData((state) => {
      return { ...state, data: { ...state.data, enabled: newEnabledStatus } };
    });

    if (newEnabledStatus && !campaignData.enabledAt) {
      setCampaignData((state) => ({
        ...state,
        enabledAt: new Date(),
      }));
    }
  };

  const closeToast = () => {
    setToast({ show: false, header: '', class: '' });
  };

  const onFormUpdated = (newData, isUserUpdate = true) => {
    setFormData((state) => {
      const companyChanged =
        newData.data?.company && state.data.company !== newData.data.company;
      if (companyChanged) {
        getCompanyBrandsProviders(newData.data.company);
      }
      return {
        ...state,
        ...newData,
      };
    }, isUserUpdate);
  };

  const doSave = async (event) => {
    event.preventDefault();
    setShowConfirmationModal(false);
    setIsLoading(true);

    const verificationMethod = verificationMethods.find((method) => {
      return method.name === selectedVerificationMethod;
    });

    const newTags = formData.data.tags.map(getSelectedLabels);

    const data = {
      ...formData.data,
      brand: formData.data.clientBrand,
      tags: newTags,
      videoProvider: serviceType,
      maxPerConnection: formData.data.maxPerConnection
        ? parseFloat(formData.data.maxPerConnection)
        : null,
      ...(!isFallbackCampaign && {
        countries: formData.data?.countries ?? null,
        cities: formData.data?.cities ?? null,
        gender: formData.data.gender?.length
          ? formData.data.gender?.map((gender) => gender.value)
          : null,
        ageFrom: formData.data.ageFrom ? formData.data.ageFrom : null,
        ageTo: formData.data.ageTo ? formData.data.ageTo : null,
        verificationMethod,
      }),
    };

    try {
      await CampaignService.update(campaignId, data)
        .then((response) => {
          const _data = response.data || {};
          _data.company = _data.company.id;

          if (!isFallbackCampaign) {
            _data.countries = _data.countries ?? {};
            _data.gender = _data.gender?.map((gender) => ({
              value: gender,
              label: gender,
            }));
          }

          setFormData({
            data: {
              ..._data,
              tags: formData.data.tags,
            },
            errors: null,
            touched: {},
            isValid: true,
          });
          setIsLoading(false);
          setOldBudget(response.data.budget);
        })
        .catch((err) => {
          /* show error message */
          const { response } = err;
          const errData = response.data;
          if (errData.statusCode === 400) {
            setToast({
              show: true,
              class: 'error',
              header:
                'We could not update data of the current campaign, ' +
                'see the error below',
            });
            setFormData((prevState) => ({
              ...prevState,
              errors: errData._errors,
              isValid: false,
            }));
          } else if (errData.statusCode === 409) {
            setToast({
              show: true,
              class: 'error',
              header:
                'We could not update data of the current campaign, ' +
                'see the error below',
            });
            setFormData((prevState) => ({
              ...prevState,
              errors: errData._errors || {
                landingPageContent: errData.message,
              },
              isValid: false,
            }));
          } else {
            setToast({
              show: true,
              class: 'error',
              header: errData?.message || 'System error',
            });
            if (errData?._errors) {
              setFormData((prevState) => ({
                ...prevState,
                errors: errData._errors,
                isValid: false,
              }));
            }
          }
          setIsLoading(false);
        });

      const statusResult = await CampaignService.getStatus(campaignId);
      setCampaignStatus(statusResult.data);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = (event) => {
    event.preventDefault();

    const errorKeys = Object.keys(formData.errors ?? {});

    let formIsValid = true;

    if (errorKeys.length) {
      errorKeys.forEach((key) => {
        // check if error still exists
        if (formData.errors[key]?.length) {
          formIsValid = false;
        }
        // make sure inputs with errors have "touched = true" so they show
        setFormData((prevState) => {
          const touched = prevState.touched;
          touched[key] = true;
          return {
            ...prevState,
            ...touched,
          };
        });
      });
    }

    // continue to submit form
    if (formIsValid) {
      setShowConfirmationModal(true);
    }
  };

  const saveSections = (sections, gSettings = null) => {
    const newFormData = {
      ...formData.data,
      globalSettings: {
        ...formData.data.globalSettings,
        ...gSettings,
      },
      landingPageContent: sections,
    };
    setGlobalSettings(newFormData.globalSettings);
    setFormData((oldState) => {
      const touched = { ...oldState.touched, globalSettings: true };
      return {
        ...formData,
        data: newFormData,
        touched,
      };
    });
  };

  const showPreviewHandler = async () => {
    const token = await CampaignService.getToken(formData.data.id);
    const PREVIEW_PAGES_URL = process.env.REACT_APP_PREVIEW_PAGES_URL.replace(
      /\/+$/,
      '',
    );
    window.open(
      `${PREVIEW_PAGES_URL}/${formData.data.slug}?token=${token.data}`,
    );
  };

  /* Deprecated - Service and Connect form cannot be changed */
  /* const replaceConnetionFormSection = async (page = 1) => {
      setPending(true);
      CarouselImages.list({
        provider: serviceType?.id,
        page,
        limit: 1,
      })
        .then((response) => {
          const respData = response?.data?.data;
          const bannersOfCurrentProvider =
            respData && reduceProviderInfoInBanners(respData);

          const newLPContent = [...formData.data.landingPageContent].map(
            (item) => {
              if (item.type === 'vidio') {
                const newItem = { ...item };
                const newSlides = bannersOfCurrentProvider || [];
                if (newItem.config) {
                  newItem.config.slides = newSlides;
                }
                if (newItem?.translations) {
                  newItem.translations = replaceSlidesInTranslations(
                    newItem.translations,
                    newSlides,
                  );
                }

                return newItem;
              }
              return item;
            },
          );
          setFormData((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              landingPageContent: newLPContent,
            },
          }));
          setOldServiceType(null);
        })
        .catch((err) => {
          console.error(err?.message);
        })
        .finally(() => {
          setPending(false);
          setShowReplaceConnectionFormSectionModal(false);
        });
    }; */

  /* Deprecated - Service and Connect form cannot be changed */
  /* const cancelReplaceConnectionFormSection = async () => {
    const oldService = oldServiceType ?? serviceType.IFLIX;
    setServiceType(oldService);
    setOldServiceType(null);

    setShowReplaceConnectionFormSectionModal(false);
  }; */

  const campaignEnableSupplementalMessage = formData.data.paywall?.enabled
    ? 'Changes to Paywall enablement, Qualifier questions, and Service provider assignment will not be permitted once the campaign has been started.'
    : '';

  const download = useCallback(
    (source) => {
      setPending(true);
      const password = uuidv4();
      setFilePassword(password);

      CpdcService.downloadEncryptedData({
        campaignId,
        queryParams: {
          source: source ?? 'connect-form',
        },
        password,
        onError: () => {
          if (!mountedRef.current) return;
          setPending(false);
          setToast({
            show: true,
            class: 'error',
            header:
              'There was a problem downloading the report please try again',
          });
        },
        onEnd: () => {
          if (!mountedRef.current) return;
          setPending(false);
          setShowFilePasswordModal(true);
        },
      });
    },
    [campaignId],
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const campaignPaywallEnabled = !!formData.data.paywall?.enabled;
  const requiresServiceType = !campaignPaywallEnabled && !serviceType;
  const campaignNotStarted = !campaignData.enabledAt;
  const campaignHasServiceSet = !!serviceType?.id;
  const allowRemoveService = campaignHasServiceSet;
  // campaignPaywallEnabled && campaignNotStarted && campaignHasServiceSet;
  const showBroadSpectrumServicePaywallMessage =
    campaignPaywallEnabled && !campaignNotStarted && !campaignHasServiceSet;

  const showVerificationMethodInput =
    !campaignPaywallEnabled && serviceType?.id;
  const showProviderFallbackToggle =
    (isAdmin || isBountyAdmin) && campaignPaywallEnabled && serviceType?.id;

  const callbackEnabled = !!(
    formData.data.callback && formData.data.callback !== 0
  );

  return (
    <>
      <ActionsNavbar>
        <Col
          className="
                col-auto
                mb-2
                mb-md-0
                d-flex
                flex-wrap
                align-content-center"
        >
          <Button
            additionalClasses="
                        _add-campaign
                        btnDefault--with-icon
                        btnDefault--with-icon-left"
            clicked={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              className=" btnDefault--with-icon__icon"
            />
            Return
          </Button>
        </Col>
        <Col
          className="
                d-flex
                justify-content-start
                justify-content-sm-end
                flex-wrap
                align-content-center"
        >
          <Dropdown className="d-inline-flex">
            <Dropdown.Toggle
              data-test-id="campaign-more-actions-dropdown"
              className="btnDefault p-3"
            >
              <FontAwesomeIcon fixedWidth icon={faEllipsisH} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                data-test-id="campaign-show-report-button"
                as={Link}
                to={`/campaigns/edit/${campaignId}/report`}
              >
                Connections answers report
              </Dropdown.Item>

              {(isAdmin || isCompanyAdmin) && (
                <Dropdown.Item
                  onClick={() => download()}
                  data-test-id="campaign-download-button"
                >
                  Download Connections data
                  {pending && (
                    <FontAwesomeIcon
                      fixedWidth
                      className="ms-3"
                      icon={faCircleNotch}
                      spin
                    />
                  )}
                </Dropdown.Item>
              )}

              {(isAdmin || isCompanyAdmin) && (
                <Dropdown.Item
                  onClick={() => download('post-questions')}
                  data-test-id="campaign-download-post-form-data-button"
                >
                  Download Post Form data
                  {pending && (
                    <FontAwesomeIcon
                      fixedWidth
                      className="ml-3"
                      icon={faCircleNotch}
                      spin
                    />
                  )}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            additionalClasses="_add-campaign"
            clicked={showModal}
            dataTestAttribute="save-edited-campaign"
            // formData.isValid is faulty due to poor design
            // disable state is removed, pending re-implementation
            //  in the new campaign form design (SP16)
            // disabled={!formData || !formData.isValid}
          >
            Save
          </Button>
          {!globalFallbackMode && formData.data ? (
            <CampaignEnable
              hasPendingChanges={formHasChanged}
              requiresServiceType={requiresServiceType}
              campaign={formData.data}
              campaignStateChange={onCampaignStateChange}
              setToast={setToast}
              globalFallbackMode={globalFallbackMode}
              startSupplementalMessage={campaignEnableSupplementalMessage}
            />
          ) : null}
        </Col>
      </ActionsNavbar>
      <div className="campaignEditPage">
        <Container fluid>
          {isLoading ? (
            <OverlaySpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <Tile
                    data={prepareValuesToDisplay(
                      countData,
                      oldBudget,
                      formData.data.budget,
                      formData.data.cpdcCost,
                    )}
                  />
                </Col>
              </Row>
              <Row>
                {verificationMethods.length > 0 && (
                  <Col lg={{ order: 2, span: 4 }}>
                    <CampaignNotes
                      campaign={formData.data}
                      campaignStatus={campaignStatus}
                      campaignStatistics={countData}
                      selectedVerificationMethod={selectedVerificationMethod}
                      verificationMethods={verificationMethods}
                      className="mx-0 mx-md-3"
                    />
                  </Col>
                )}

                <Col
                  lg={{
                    order: 1,
                    span: verificationMethods.length > 0 ? 8 : 12,
                  }}
                >
                  <div className="mx-0 mx-md-3 mt-5 w-auto">
                    <CampaignForm
                      {...{ isAdmin, isBountyAdmin, setFormData }}
                      key={`${campaignId}-campaignForm`}
                      formTitle={formData?.data?.name ?? null}
                      data={formData}
                      onFormChanged={onFormUpdated}
                      campaignId={campaignId}
                      options={{
                        dateFormat: inputFormatDate,
                        usedBudget: countData.cpdcs * formData.data.cpdcCost,
                        brands,
                        companies,
                        tags,
                      }}
                      isFallbackCampaign={isFallbackCampaign}
                      isGlobalFallback={globalFallbackMode}
                      className="w-auto"
                      campaignStatus={campaignStatus}
                    />
                    {!isFallbackCampaign && (
                      <QualifyQuestions
                        campaignWasStarted={campaignData.enabledAt}
                        {...{ formData, setFormData, setServiceType }}
                        className="mt-5"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {webhooks.length > 0 && (
                <Row>
                  <Col xs={12} lg={8} className="mt-4">
                    <div className="mx-0 mx-md-3 w-auto">
                      <h1 className="mb-4">Webhooks</h1>
                      <WebhookList
                        setShowWebhookManageModal={setWebhookManageModal}
                        webhooks={webhooks}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} lg={8} className="mt-4 mb-4">
                  <div className="mx-0 mx-md-3 mt-5">
                    <h1 className="mb-4">Callback URL</h1>
                    <Row className="mb-3">
                      <Col className="col-10 col-lg-4 mb-4">
                        <ToggleSwitchInput
                          id="callbackUrl"
                          label="Enable Callback URL"
                          text={callbackEnabled ? 'Yes' : 'No'}
                          description="At completion of the campaign submission, the user can return to a designated URL"
                          onCheck={() =>
                            onSelectedHandler(
                              {
                                value: formData.data.callback,
                              },
                              'callbackEnable',
                            )
                          }
                          value={callbackEnabled}
                        />
                        {callbackEnabled && (
                          <div className="mt-4">
                            <ToggleSwitchInput
                              id="callbackParams"
                              label="Include Consumer Parameters"
                              text={formData.data.callbackParams ? 'Yes' : 'No'}
                              description="The following parameters will be passed back in the return URL if enabled: bm_cid, bm_pid, bm_bid, bm_campaign_name, bm_submission_date"
                              onCheck={() =>
                                onSelectedHandler(
                                  {
                                    value: formData.data.callbackParams,
                                  },
                                  'callbackParams',
                                )
                              }
                              value={!!formData.data.callbackParams}
                            />
                          </div>
                        )}
                      </Col>
                      {callbackEnabled && (
                        <>
                          <Col className="col-8 col-lg-4 mb-4">
                            <FormInput
                              control={controls.callbackType(
                                !formData.data.paywall.enabled,
                                false,
                                formData.data.callback,
                              )}
                              inputChangedHandler={onSelectedHandler}
                              inputKey="callback"
                              value={formData.data.callback}
                            />
                          </Col>
                          {formData.data.callback === 2 && (
                            <Col className="col-12 col-lg-4">
                              <FormInput
                                control={{
                                  ...controls.callbackUrl(
                                    !formData.data.paywall.enabled,
                                  ),
                                  touched: formData.touched.callbackUrl,
                                }}
                                inputChangedHandler={onSelectedHandler}
                                inputKey="callbackUrl"
                                value={formData.data.callbackUrl}
                                onFocus={onCallbackUrlFocus}
                                error={formData.errors?.callbackUrl}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xs={12}
                  md={9}
                  className="mt-4 mb-4 landing-page-edit-form"
                >
                  <div className="mx-0 mx-md-3 mt-5">
                    <h1 className="mb-2">Landing page</h1>
                    <Row className="mb-3">
                      <Col>
                        {formData?.errors?.landingPageContent && (
                          <Alert
                            key="landingPageContent"
                            variant="danger"
                            data-test-id="landing-page-content-error"
                          >
                            {formData.errors.landingPageContent}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="Label">
                          *** SHOW PREVIEW ** is used only for previews, with no
                          form submission
                        </p>
                        <Button
                          additionalClasses="_add-campaign"
                          clicked={showPreviewHandler}
                          dataTestAttribute="show-preview-button"
                        >
                          SHOW PREVIEW
                        </Button>
                        <Button
                          additionalClasses="_add-campaign"
                          clicked={() => setShowUrlModal(true)}
                          dataTestAttribute="get-url-button"
                        >
                          GET URL
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className="mx-0 mx-md-3 mt-5">
                <Row>
                  <Col className="col-auto video-provider-select">
                    <FormInput
                      control={controls.serviceType(
                        providers,
                        !formData.data.paywall.enabled,
                        false,
                      )}
                      inputChangedHandler={onSelectedHandler}
                      inputKey="serviceType"
                      value={serviceType?.id || ''}
                    />
                    {showBroadSpectrumServicePaywallMessage && (
                      <p className="mt-2 text-muted ml-1 cpep-short-paragraph">
                        <small>
                          This campaign will be delivered via any Paywall
                          Provider{' '}
                          {!globalFallbackMode &&
                            'based on matching qualification criteria'}
                        </small>
                      </p>
                    )}
                    {allowRemoveService ? (
                      <TextLink
                        className="mt-2"
                        muted
                        clicked={() => {
                          setProviderFallbackMode(false);
                          formData.data.isProviderFallback = false;
                          onSelectedHandler(null, 'serviceType');
                        }}
                      >
                        <small>Unset {serviceType.name}</small>
                      </TextLink>
                    ) : null}
                  </Col>
                  {showVerificationMethodInput ? (
                    <Col className="col-auto">
                      <FormInput
                        control={controls.verificationMethod}
                        inputChangedHandler={onSelectedHandler}
                        inputKey="verificationMethod"
                        value={selectedVerificationMethod}
                      />
                    </Col>
                  ) : null}
                  {showProviderFallbackToggle ? (
                    <Col className="col-auto">
                      <ToggleSwitchInput
                        label="Enable fallback-only mode"
                        text="Paywall Fallback"
                        description="Campaign will be shown if no other campaigns match the user qualification criteria"
                        onCheck={(bool) => {
                          setProviderFallbackMode(bool);
                          setFormTouchedKey('isProviderFallback');
                          formData.data.isProviderFallback = bool;
                        }}
                        value={formData.data.isProviderFallback}
                      />
                    </Col>
                  ) : null}
                  {!isFallbackCampaign && (
                    <Col className="col-auto">
                      <ToggleSwitchInput
                        label="Multiple access to the same user"
                        text="Multiple submissions"
                        onCheck={(val) => {
                          setFormTouchedKey('multipleSubscriptionsAllowed');
                          formData.data.multipleSubscriptionsAllowed = val;
                        }}
                        value={formData.data.multipleSubscriptionsAllowed}
                      />
                    </Col>
                  )}
                </Row>
              </div>

              <div className="mx-0 mx-md-3">
                <Row>
                  <Creator
                    campaignData={campaignData}
                    key={serviceType}
                    landingPageSections={formData.data.landingPageContent || []}
                    saveSections={saveSections}
                    serviceType={serviceType}
                    globalSettings={globalSettings}
                    setGlobalSettings={setGlobalSettings}
                    globalBrandLogoUrl={formData.data.companyLogoUrl || ''}
                    videoProvider={formData.data.videoProvider}
                    campaignId={campaignId}
                    verificationMethod={selectedVerificationMethod}
                    isPaywall={campaignPaywallEnabled}
                  />
                </Row>
              </div>
            </>
          )}
        </Container>
        <Toaster toast={toast} closeToast={closeToast} />
        {showUrlModal && (
          <CampaignUrlModal
            campaignUrl={campaignData.campaignUrl}
            shortUrl={campaignData.shortUrl}
            consumerVerificationUrl={`${PUBLIC_PAGES_URL}/verifyme/${campaignData.id}`}
            onClose={() => setShowUrlModal(false)}
            isPaywall={campaignPaywallEnabled}
          />
        )}
        {showConfirmationModal && (
          <Modal
            title="Update campaign data"
            body="Are you sure, you want to save changes?"
            cancel="No"
            save="Yes"
            handleSave={doSave}
            show
            handleClose={() => setShowConfirmationModal(false)}
            dataTestAttribute={{
              modal: 'modal-campaign-form',
              action1: 'cancel-campaign-form',
              action2: 'save-campaign-form',
            }}
          />
        )}
        {/* Deprecated Modal - Service and Connect form cannot be changed */}
        {/* {showReplaceConnectionFormSectionModal && (
        <Modal
          title="Warning, change service type"
          body={<ModalContentRemoveConnectionFormSection name={serviceType.name} />}
          cancel="No"
          save="Yes"
          handleSave={() => replaceConnetionFormSection()}
          show
          handleClose={cancelReplaceConnectionFormSection}
          dataTestAttribute={{
            modal: 'modal-replace-vidio-section',
            action1: 'cancel-modal-replace-vidio-section',
            action2: 'save-modal-replace-vidio-section',
          }}
        />
      )} */}
      </div>

      <PromptModal when={formHasChanged} />
      {webhookManageModal.show && (
        <CampaignWebhookModal
          show={webhookManageModal.show}
          data={webhookManageModal.data}
          close={() =>
            setWebhookManageModal((prevState) => ({
              ...prevState,
              show: !prevState.show,
            }))
          }
        />
      )}
      {showFilePasswordModal && (
        <Modal
          title="File password"
          body={
            <>
              <p>The password to extract the zip file is:</p>
              <p>
                <strong>{filePassword}</strong>
              </p>
              <p>
                The csv file contains PII (personally identifiable information)
                and should be treated with care
              </p>
              <p className="mb-0">
                It is recommended to use a program such as 7-zip to extract the
                csv file
              </p>
            </>
          }
          show={showFilePasswordModal}
          handleClose={() => {
            setFilePassword(false);
            setShowFilePasswordModal(false);
          }}
        />
      )}
    </>
  );
};

export default CampaignEditPage;
