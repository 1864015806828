import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { produce } from 'immer';
import _cloneDeep from 'lodash/cloneDeep';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import inputChangedHandler from '../../../shared/inputChangeHandler';
import languages from '../../../shared/languages';
import { useAutomaticRedemption } from './ProviderForm.AutomaticRedemption.hooks';

import LanguageDropdown from '../../UI/LanguageDropdown/LanguageDropdown';
import FormInput from '../../UI/FormInput/FormInput';
import ToggleSwitchInput from '../../UI/ToggleSwitchInput/ToggleSwitchInput';
import OverlaySpinner from '../../UI/OverlaySpinner/OverlaySpinner';
import Modal from '../../UI/Modal/Modal';
import ProviderTranslationsForm from './ProviderTranslationsForm/ProviderTranslationsForm';

import initData from './controls';

import DictionariesApi from '../../../api/Dictionaries';
import ProvidersApi from '../../../api/Providers';
import IndustriesApi from '../../../api/Industries';
import CompanyService from '../../../api/Companies';
import BrandService from '../../../api/Brands';
import TranslationApi from '../../../api/Translation';

import { productTypes, getKey } from '../constants';
import styles from './ProviderForm.module.scss';

const ProviderForm = ({
  data,
  onSubmitForm,
  setToast,
  setIsFormValid,
  filePath,
  filePathLogo,
  backgroundColor,
  textColor,
  setIsLoading,
  isLoading,
  saveTick,
  providerActive,
}) => {
  const [offerInstructionEditor, setOfferInstructionEditor] = useState(null);

  const offerInstruction = useMemo(() => {
    if (offerInstructionEditor) return offerInstructionEditor;
    if (!data) return EditorState.createEmpty();
    if (!data.offerInstruction) return EditorState.createEmpty();
    // Parse the JSON string back to an object.
    const rawContentState = JSON.parse(data.offerInstruction);
    // Convert the raw content state to an EditorState.
    const contentState = convertFromRaw(rawContentState);
    // Set the EditorState in your component state.
    return EditorState.createWithContent(contentState);
  }, [data, offerInstructionEditor]);

  const [formData, setFormData] = useState(_cloneDeep(initData));

  // {countrycode}-{countrykey} eg. 61-AU
  const defaultCountryValue = data?.defaultPhoneCountryCode
    ? `${data?.defaultPhoneCountryCode}-${data?.defaultPhoneCountryKey ?? ''}`
    : '';
  const [defaultCountrySelection, setDefaultCountrySelection] =
    useState(defaultCountryValue);

  const [isProductNameDisabled, setIsProductNameDisabled] = useState(false);
  const [isUrlSlugDisabled, setIsUrlSlugDisabled] = useState(false);
  const [showUrlSlugChangeWarning, setShowUrlSlugChangeWarning] =
    useState(false);
  const [showSlugChangeDialog, setShowSlugChangeDialog] = useState(false);

  const [currentLanguage, setLanguage] = useState('en');
  const [defaultLanguage, setDefaultLanguage] = useState(
    data?.defaultLanguage || 'en',
  );
  const [availableLanguages, setAvailableLanguages] = useState(
    Object.keys(languages),
  );
  const [translations, setTranslations] = useState(data?.translations);
  const settingTranslation = useMemo(() => {
    return currentLanguage !== 'en';
  }, [currentLanguage]);

  const [verificationMethods, setVerificationMethods] = useState([]);
  const [verificationMethodsOptions, setVerificationMethodsOptions] = useState({
    ...formData.verificationMethods,
    elementConfig: {
      description: formData.verificationMethods.elementConfig.description,
      options: [],
    },
  });
  const [selectedVerificationMethods, setSelectedVerificationMethods] =
    useState([]);
  const [
    previousSelectedVerificationMethods,
    setPreviousSelectedVerificationMethods,
  ] = useState([]);
  const [campaignsWithPhoneVerification, setCampaignsWithPhoneVerification] =
    useState([]);

  const { isAdmin, isCompanyAdmin, isCompanyUser } = useSelector(
    (state) => state.auth,
  );

  useAutomaticRedemption(formData, setFormData);

  const showRedemptionDetailInput = ['accountUpdate', 'smsCode'].includes(
    formData.productType.value,
  );

  const showArProviderInput = ['automaticRedemption'].includes(
    formData.productType.value,
  );

  function resetRedemptionProductNameArProviderRewardType() {
    try {
      setFormData((state) => {
        const formDataState = _cloneDeep(state);
        formDataState.redemptionDetails.value = '';
        formDataState.arProvider.value = '';
        formDataState.rewardType.value = '';
        formDataState.productName.value = '';
        return formDataState;
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Derive the default "code" and "key" from the input
   *  selection updated
   */
  useEffect(() => {
    setFormData((state) => {
      const updatedForm = _cloneDeep(state);
      const [code, key] = defaultCountrySelection.split('-');
      const defaultPhoneCountryCode = code ?? '';
      const defaultPhoneCountryKey = key ?? '';

      updatedForm.defaultPhoneCountryCode.value = defaultPhoneCountryCode;
      updatedForm.defaultPhoneCountryKey.value = defaultPhoneCountryKey;
      return updatedForm;
    });
  }, [defaultCountrySelection]);

  useEffect(() => {
    const populateVerificationMethodsOptions = (options) => {
      // filter out "email" verification method
      // which is default and it shouldn't be chosen
      // explicitly
      const formOptions = options
        .filter((method) => method.name !== 'email')
        .map((method) => {
          return transformMethodForMultiselect(method);
        });

      setVerificationMethodsOptions((state) => ({
        ...state,
        elementConfig: {
          ...state.elementConfig,
          options: formOptions,
        },
      }));
    };

    DictionariesApi.getVerificationMethods()
      .then((response) => {
        setVerificationMethods(() => [...response.data]);
        populateVerificationMethodsOptions(response.data);
      })
      .catch(() => {
        setVerificationMethods([]);
      });
  }, []);

  useEffect(() => {
    const getIndustryOptions = async () => {
      try {
        const industryOptions = await IndustriesApi.options();
        const companyOptions = await CompanyService.options(true);
        let brandOptions;
        if (data) {
          brandOptions = await BrandService.brandCompaniesOptions({
            company: [data?.company[0]?.id],
          });
        }

        if (!data && isCompanyAdmin) {
          brandOptions = await BrandService.brandCompaniesOptions({
            company: [companyOptions?.data[0].value],
          });
        }

        setFormData((state) => {
          const updatedState = { ...state };
          updatedState.brand.elementConfig.options = brandOptions?.data ?? [];
          updatedState.excludedIndustries.elementConfig.options =
            industryOptions?.data ?? [];
          updatedState.company.elementConfig.options =
            companyOptions?.data ?? [];

          if (!data && isCompanyAdmin) {
            updatedState.company.value = companyOptions?.data[0].value;
          }
          return { ...updatedState };
        });
      } catch (error) {
        console.error(error);
      }
    };
    getIndustryOptions();
  }, [data]);

  useEffect(() => {
    if (data && Object.keys(data)) {
      setFormData((state) => {
        const form = state;
        let isValid = false;
        Object.keys(data).map((el) => {
          if (form?.[el]) {
            if (el === 'company') {
              form[el].value = data[el][0]?.id;
            } else if (el === 'brand') {
              form[el].value = data[el]?.id;
            } else {
              form[el].value = data[el];
              form[el].valid = !!data[el];
              isValid = !!data[el];
            }
          }
          form.urlSlug.value = data.slug;
          return el;
        });

        const setValueForMultiselect = form.verificationMethods.value
          .filter((method) => method != null && method.name !== 'email')
          .map((method) => {
            return transformMethodForMultiselect(method);
          });

        setSelectedVerificationMethods(setValueForMultiselect);

        // updating formData values for verificationMethods
        // to keep it in sync with values stored in selectedVerificationMethods
        // state hook
        const _verificationMethods = {
          ...form.verificationMethods,
          value: setValueForMultiselect,
        };

        return {
          ...form,
          verificationMethods: _verificationMethods,
        };
      });
    }
  }, [data]);

  useEffect(() => {
    let _isFormValid = true;

    const testKey = (fieldKey, kind = 'valid') => {
      // log for validation debugging
      const value = formData[fieldKey][kind];
      return value;
    };

    if (formData.productName.value) {
      formData.productName.valid = true;
    }

    if (formData.urlSlug.value) {
      formData.urlSlug.valid = true;
    }

    if (
      formData.productType.value ===
      getKey(productTypes, 'Automatic Redemption')
    ) {
      formData.redemptionDetails.valid = true;
    }

    if (formData.productType.value === getKey(productTypes, 'Unique Code')) {
      formData.redemptionDetails.valid = true;
      formData.arProvider.valid = true;
      formData.rewardType.valid = true;
    }

    if (
      formData.productType.value === getKey(productTypes, 'Account Update') ||
      formData.productType.value === getKey(productTypes, 'SMS Code')
    ) {
      if (!testKey('redemptionDetails', 'value')) {
        formData.redemptionDetails.valid = false;
        _isFormValid = false;
      } else {
        formData.redemptionDetails.valid = true;
        formData.arProvider.valid = true;
        formData.rewardType.valid = true;
      }
    }

    Object.keys(formData).forEach((inputName) => {
      if (
        formData[inputName]?.validation?.required &&
        inputName !== 'redemptionDetails' &&
        inputName !== 'rewardType' &&
        inputName !== 'arProvider'
      ) {
        if (!testKey(inputName, 'valid') || !testKey(inputName, 'value')) {
          _isFormValid = false;
        }
      }
    });
    setIsFormValid(_isFormValid);
  }, [formData]);

  useEffect(() => {
    setFormData((state) => ({
      ...state,
      redemptionDetails: {
        ...state.redemptionDetails,
        label:
          formData.productType.value === 'smsCode'
            ? 'Redemption SMS Text'
            : 'Redemption Details',
      },
    }));

    const updateProductNameDisabled =
      formData.productType.value === 'automaticRedemption';
    setIsProductNameDisabled(updateProductNameDisabled);
  }, [formData.productType.value]);

  useEffect(() => {
    if (data?.slugLock) {
      // Once the offer has a campaign live on it and
      // is active, the slug can not be changed.
      setIsUrlSlugDisabled(true);
    }
  }, []);

  useEffect(() => {
    TranslationApi.getLanguages()
      .then((response) => {
        setAvailableLanguages(
          response.data.map((language) => ({
            value: language.languageCode,
            label: `${
              language.languageName
            }(${language.languageCode.toUpperCase()})`,
          })),
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onInputChangeHandler = async (event, inputIdentifier) => {
    let value;

    if (inputIdentifier === 'company') {
      const brands = await BrandService.brandCompaniesOptions({
        company: [event.value],
      });

      setFormData((state) => {
        const updatedState = { ...state };
        updatedState.brand.elementConfig.options = brands?.data ?? [];
        return updatedState;
      });
    }

    if (inputIdentifier === 'verificationMethods') {
      // Allow ALL selections, since this will be chosen (singularly)
      //  on the campaign configuration from these available options.

      if (selectedVerificationMethods) {
        setPreviousSelectedVerificationMethods(selectedVerificationMethods);
      }

      let isVerificationMethodSame = false;
      if (event && data?.verificationMethods?.length) {
        data.verificationMethods.forEach((method) => {
          const [chosenMethod] = event;
          if (method.name === chosenMethod?.value) {
            isVerificationMethodSame = true;
          }
        });

        if (!isVerificationMethodSame) {
          ProvidersApi.getCampaignsWithPhoneVerification(data.id)
            .then((response) => {
              setCampaignsWithPhoneVerification(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }

      setSelectedVerificationMethods(event);
      // updating formData values for verificationMethods
      // to keep it in sync with values stored in selectedVerificationMethods
      // state hook
      value = event;
    } else {
      value =
        event?.currentTarget?.value ??
        event?.target?.value ??
        event?.value ??
        event;
    }

    if (inputIdentifier === 'minimumAge') {
      if (value > 120) value = 120;
      else if (value < 0) value = 0;
    }

    if (inputIdentifier === 'urlSlug') {
      value = value.replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s/g, '-');

      if (data?.id) {
        setShowUrlSlugChangeWarning(true);
      }
    }

    const [updatedProviderForm, formIsValid] = inputChangedHandler(
      value,
      { controls: formData },
      inputIdentifier,
    );

    setFormData(updatedProviderForm);
    setIsFormValid(formIsValid);

    if (inputIdentifier === 'productType') {
      resetRedemptionProductNameArProviderRewardType();
    }
  };

  const checkSlugChangeDialog = () => {
    if (showUrlSlugChangeWarning) {
      setShowUrlSlugChangeWarning(false);
      setShowSlugChangeDialog(true);
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    const contentState = offerInstruction.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const offerInstructionJSON = JSON.stringify(rawContentState);

    const excludedIndustries = (formData.excludedIndustries?.value ?? []).map(
      (e) => e.value,
    );

    const provider = {
      name: formData.name.value,
      productName: formData.productName.value,
      productType: formData.productType.value,
      company: formData.company.value,
      brand: formData.brand.value,
      defaultLanguage,
      redemptionDetails: formData.redemptionDetails.value,
      url: formData.url.value,
      urlSlug: formData.urlSlug.value,
      paywallBannerUrl: filePath,
      paywallLogoUrl: filePathLogo,
      backgroundColor: backgroundColor.substring(0, 7),
      textColor: textColor.substring(0, 7),
      active: providerActive,
      ...(formData.defaultPhoneCountryCode.value && {
        defaultPhoneCountryCode: formData.defaultPhoneCountryCode.value,
        defaultPhoneCountryKey: formData.defaultPhoneCountryKey.value,
      }),
      excludedIndustries,
      offerInstruction: offerInstructionJSON,
      isTestPhoneEnabled: formData.isTestPhoneEnabled.value,
      runningLowNotificationEnabled:
        formData.runningLowNotificationEnabled.value,
      verificationMethods: [
        ...(selectedVerificationMethods?.map((method) => {
          const price = verificationMethods.filter(
            (m) => m.name === method.value,
          )[0]?.price;

          return {
            price,
            name: method.value,
          };
        }) || []),
        // attach default method "email" to payload
        verificationMethods.find((method) => method.name === 'email'),
      ],
      minimumAge: formData.minimumAge.value || 0,
    };

    if (formData.rewardType.value) {
      provider.arTypeId = formData.rewardType.value;
    }

    const result = await onSubmitForm(provider);

    setIsLoading(false);

    if (result) {
      result.action();
    }

    setShowUrlSlugChangeWarning(false);
  };

  useEffect(() => {
    if (saveTick) {
      submitForm();
    }
  }, [saveTick]);

  const urlSlugField = produce((state) => {
    const domain = process.env.REACT_APP_PUBLIC_PAGES_URL;
    const slug = state.value.toLowerCase().replace(/\s/g, '-');
    const { elementConfig } = state;
    elementConfig.description = `${domain}/paywall/${slug}`;
    elementConfig.onBlur = checkSlugChangeDialog;
  })(formData.urlSlug);

  return (
    <>
      <Modal
        title="Slug Modification"
        show={showSlugChangeDialog}
        cancel="Revert"
        save="Confirm"
        handleClose={() => {
          setShowSlugChangeDialog(false);
          setFormData((state) => {
            const formDataState = _cloneDeep(state);
            formDataState.urlSlug.value = data.slug;
            return formDataState;
          });
        }}
        handleSave={() => {
          setShowSlugChangeDialog(false);
        }}
      >
        <p>
          You are attempting to modify the slug, which will impact the URL
          associated with this content. Please be aware that if you proceed with
          this change, the existing URL will become invalid, leading to a broken
          link for anyone attempting to access the content.
        </p>
      </Modal>
      {campaignsWithPhoneVerification.length ? (
        <Modal
          title="Verification Method"
          body={
            <span>
              Changing the verification method will affect the following
              campaigns:&nbsp;
              <b>
                {campaignsWithPhoneVerification
                  .map((campaign) => {
                    return campaign.name;
                  })
                  .toString()}
              </b>
              . It is recommended not to change. If you must change please note:
              <br />
              <br /> - Switch verification to email
              <br /> - Go to each campaign and set verification to email
              <br /> - Come back to this screen and switch to your other option
              <br /> - Go to each campaign and set verification to your new
              option
            </span>
          }
          cancel="No"
          save="Yes"
          handleSave={() => {
            setCampaignsWithPhoneVerification([]);
          }}
          handleClose={() => {
            setSelectedVerificationMethods(previousSelectedVerificationMethods);
            setCampaignsWithPhoneVerification([]);
          }}
          show
        />
      ) : null}
      <Row>
        <Col>
          {isLoading && <OverlaySpinner />}

          <div className="mb-4">
            {settingTranslation && (
              <>
                <LanguageDropdown
                  current={currentLanguage}
                  availableLanguages={availableLanguages}
                  switchLanguage={setLanguage}
                />
                <p className="mt-2">
                  <small className="formInputDescription">
                    You are currently adding translations for the fields enabled
                    below. They are displayed to users in Bounty’s terms and
                    conditions, and on the paywall. To edit other fields or set
                    default language, you must switch to English.
                  </small>
                </p>
              </>
            )}
          </div>

          {settingTranslation && (
            <ProviderTranslationsForm
              providerId={data?.id}
              defaults={formData}
              currentLanguage={currentLanguage}
              translations={translations}
              setTranslations={setTranslations}
              showRedemptionDetailInput={showRedemptionDetailInput}
            />
          )}

          {!settingTranslation && (
            <>
              <Row className="mb-4">
                <Col>
                  <div className="d-flex gap-3">
                    <LanguageDropdown
                      label="Default Language"
                      current={defaultLanguage}
                      availableLanguages={availableLanguages}
                      switchLanguage={setDefaultLanguage}
                      type="DEFAULT"
                    />
                    {data?.id ? (
                      <div className="mb-4">
                        <LanguageDropdown
                          current={currentLanguage}
                          availableLanguages={availableLanguages}
                          switchLanguage={setLanguage}
                          disabled
                        />
                        {settingTranslation && (
                          <p className="mt-2">
                            <small className="formInputDescription">
                              You are currently adding translations for the
                              fields enabled below. They are displayed to users
                              in Bounty’s terms and conditions, and on the
                              paywall. To edit other fields or set default
                              language, you must switch to English.
                            </small>
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={formData.name}
                    inputKey="name"
                    value={formData.name.value}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={urlSlugField}
                    inputKey="urlSlug"
                    value={formData.urlSlug.value}
                    inputChangedHandler={onInputChangeHandler}
                    disabled={isUrlSlugDisabled}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={formData.productName}
                    inputKey="productName"
                    value={formData.productName.value}
                    inputChangedHandler={onInputChangeHandler}
                    dataTestAttribute="client-brand-input"
                    disabled={isProductNameDisabled}
                  />
                </Col>
                <Col>
                  <FormInput
                    control={formData.productType}
                    inputKey="productType"
                    value={formData.productType.value}
                    inputChangedHandler={onInputChangeHandler}
                    dataTestAttribute="client-brand-input"
                  />
                </Col>
              </Row>
              {isAdmin && data && (
                <Row className="mb-4">
                  <Col>
                    <ToggleSwitchInput
                      label={formData.isTestPhoneEnabled.label}
                      text={formData.isTestPhoneEnabled.value ? 'Yes' : 'No'}
                      value={formData.isTestPhoneEnabled.value}
                      description={formData.isTestPhoneEnabled.description}
                      onCheck={(val) =>
                        onInputChangeHandler(val, 'isTestPhoneEnabled')
                      }
                    />
                  </Col>
                </Row>
              )}

              {isAdmin && data && (
                <Row className="mb-4">
                  <Col>
                    <ToggleSwitchInput
                      label={formData.runningLowNotificationEnabled.label}
                      text={
                        formData.runningLowNotificationEnabled.value
                          ? 'Yes'
                          : 'No'
                      }
                      value={formData.runningLowNotificationEnabled.value}
                      description={
                        formData.runningLowNotificationEnabled.description
                      }
                      onCheck={(val) =>
                        onInputChangeHandler(
                          val,
                          'runningLowNotificationEnabled',
                        )
                      }
                    />
                  </Col>
                </Row>
              )}

              <Row className="mb-4">
                <Col>
                  <FormInput
                    disabled={isCompanyAdmin || isCompanyUser}
                    control={formData.company}
                    inputKey="company"
                    value={formData.company.value}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
                <Col>
                  <FormInput
                    control={formData.brand}
                    inputKey="brand"
                    value={formData.brand.value}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
              </Row>

              {showArProviderInput && (
                <Row className="mb-4">
                  <Col>
                    <FormInput
                      control={formData.arProvider}
                      inputKey="arProvider"
                      value={formData.arProvider.value}
                      inputChangedHandler={onInputChangeHandler}
                      dataTestAttribute="client-brand-input"
                    />
                  </Col>
                  <Col>
                    <FormInput
                      control={formData.rewardType}
                      inputKey="rewardType"
                      value={formData.rewardType.value}
                      inputChangedHandler={onInputChangeHandler}
                      dataTestAttribute="client-brand-input"
                    />
                  </Col>
                </Row>
              )}

              <Row className="mb-4">
                <Col sm={12} md={8} lg={8} xl={8}>
                  <FormInput
                    control={formData.url}
                    inputKey="url"
                    value={formData.url.value}
                    inputChangedHandler={onInputChangeHandler}
                    dataTestAttribute="client-brand-input"
                  />
                </Col>
                <Col>
                  <FormInput
                    control={verificationMethodsOptions}
                    inputKey="verificationMethods"
                    value={selectedVerificationMethods}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
              </Row>
              {showRedemptionDetailInput && (
                <Row className="mb-4">
                  <Col>
                    <FormInput
                      control={formData.redemptionDetails}
                      inputKey="redemptionDetails"
                      value={formData.redemptionDetails.value}
                      inputChangedHandler={onInputChangeHandler}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={formData.minimumAge}
                    inputKey="minimumAge"
                    value={formData.minimumAge.value || 0}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={formData.defaultPhoneCountryCode}
                    inputKey="defaultPhoneCountryCode"
                    value={defaultCountrySelection}
                    inputChangedHandler={(ev) =>
                      setDefaultCountrySelection(ev.value)
                    }
                  />

                  <small className="mt-2 formInputDescription">
                    Phone cc is defaulted to provider’s default language if
                    field is empty.
                  </small>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <FormInput
                    control={formData.excludedIndustries}
                    inputKey="excludedIndustries"
                    value={formData.excludedIndustries.value}
                    inputChangedHandler={onInputChangeHandler}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <p className="Label">Instructions</p>
                  <Editor
                    editorState={offerInstruction}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={styles.editorClassName}
                    onEditorStateChange={setOfferInstructionEditor}
                    toolbar={{
                      options: [
                        'inline',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'link',
                      ],
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

function transformMethodForMultiselect(method) {
  if (method.name === 'sms') {
    return {
      label: method.name.toUpperCase(),
      value: method.name,
    };
  }

  return {
    label: capitalize(method.name),
    value: method.name,
  };
}

function capitalize(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export default ProviderForm;
