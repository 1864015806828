import React from 'react';

import './CarouselImageTile.scss';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';

const CarouselImageTile = (props) => {
  const {
    image,
    editAlt,
    isEditing,
    setIsEditing,
    changeEditAltInput,
    alt,
    setAlt,
  } = props;

  return (
    <div
      className="carousel-image-tile"
      data-test-id={`carousel-image-${image.id}`}
    >
      <div className="carousel-image-box">
        <a
          href={`${process.env.REACT_APP_UPLOADS_URL}/${image.image}`}
          target="_blank"
          rel="noopener noreferrer"
          className="d-block"
        >
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/${image.image}`}
            alt={image.alt}
            className="carousel-image-tile-image"
          />
        </a>
        {isEditing !== image.id && (
          <div
            className="carousel-image-alt"
            data-test-id={`carousel-image-alt${image.alt ? '-full' : ''}`}
          >
            {image.alt}
            {image.alt === '' && (
              <span
                className="carousel-image-info"
                onClick={() => {
                  setIsEditing(image.id);
                  setAlt(image.alt);
                }}
              >
                Click here to set alternative text
              </span>
            )}
            <FontAwesomeIcon
              icon={faCog}
              onClick={() => {
                setIsEditing(image.id);
                setAlt(image.alt);
              }}
              title="Edit alternative text"
              className="carousel-image-settings"
              data-test-id="carousel-image-alt-edit-button"
            />
          </div>
        )}
        {isEditing === image.id && (
          <div
            className="carousel-image-form"
            data-test-id="carousel-image-alt-form"
          >
            <Input
              elementType="textarea"
              elementConfig={{ placeholder: 'Alt' }}
              additionalClasses="formInput formInput--bordered formInput--smaller"
              changed={(event) => changeEditAltInput(event)}
              dataTestAttribute="edit-alt-input"
              value={alt}
              keyName="alt"
            />
            <Button
              clicked={() => editAlt(image.id)}
              dataTestAttribute="save-alt-edit"
              additionalClasses="btnDefault--medium"
            >
              Save
            </Button>
            <Button
              clicked={() => setIsEditing('')}
              additionalClasses="btnDefault--medium btnDefault--gray"
              dataTestAttribute="cancel-alt-edit"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselImageTile;
