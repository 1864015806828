import JsFileDownloader from 'js-file-downloader';
import instance, { refreshToken } from './Api';
import store from '../store';

const upload = (file, uploadUrl) => {
  const fd = new FormData();
  fd.append('file', file);

  const url = uploadUrl || '/file/upload';

  return instance.post(url, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const download = async ({
  url,
  fileName,
  fileType, // e.g: zip, json
  onProgress,
  onEnd,
  onError,
}) => {
  await refreshToken();

  const { auth } = store.getState();
  if (!auth.token) {
    return;
  }

  const fileNameWithExtension = fileType ? `${fileName}.${fileType}` : fileName;

  return new JsFileDownloader({
    url: `${process.env.REACT_APP_API_URL}/api${url}`,
    filename: fileNameWithExtension,
    forceDesktopMode: true, // False (default) sends mobile users directly to the URL without Bearer token
    headers: [
      { name: 'Authorization', value: `Bearer ${auth.token}` },
      { name: 'Accept', value: fileType || 'octet-stream' },
    ],
    process: function process(event) {
      if (!event.lengthComputable) return; // guard
      const downloadingPercentage = Math.floor(
        (event.loaded / event.total) * 100,
      );
      onProgress && onProgress(downloadingPercentage);
    },
  })
    .then((e) => onEnd && onEnd(e))
    .catch((err) => {
      onError && onError(err);
    });
};

export default {
  upload,
  download,
};
