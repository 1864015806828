// eslint-disable-next-line import/prefer-default-export
export const campaignStatus = {
  STATE_INACTIVE: 0,
  STATE_ACTIVE: 1,
  STATE_DRAFT: 2,
  STATE_SCHEDULED: 3,
  STATE_ACTIVE_PAYWALL: 10,
  STATE_ACTIVE_PAYWALL_NO_PROVIDER: 11,
  STATE_FALLBACK_GLOBAL: 20,
  STATE_FALLBACK: 21,
  STATE_FALLBACK_INACTIVE: 22,
  STATE_DEACTIVATED: 99,
};

export const translateCampaignStatus = (statusCode) => {
  switch (statusCode) {
    case campaignStatus.STATE_FALLBACK_GLOBAL:
      return 'Fallback (G)';
    case campaignStatus.STATE_FALLBACK:
    case campaignStatus.STATE_FALLBACK_INACTIVE:
      return 'Fallback';
    case campaignStatus.STATE_INACTIVE:
      return 'Inactive';
    case campaignStatus.STATE_ACTIVE:
      return 'Active';
    case campaignStatus.STATE_ACTIVE_PAYWALL:
    case campaignStatus.STATE_ACTIVE_PAYWALL_NO_PROVIDER:
      return 'Active (P)';
    case campaignStatus.STATE_DRAFT:
      return 'Draft';
    case campaignStatus.STATE_SCHEDULED:
      return 'Scheduled';
    case campaignStatus.STATE_DEACTIVATED:
      return 'Deactivated';
    default:
      return 'Inactive';
  }
};
