import React from 'react';
import _get from 'lodash/get';

import Input from '../Input/Input';

const FormikInput = ({
  containerClass = 'mb-4',
  validationSchema,
  field: config,
  formik,
  options = null,
  description = null,
  showError = true,
  ...rest
}) => {
  /**
   * updateFieldValue is a custom over-ride as
   *  setFieldValue() does NOT set touched state
   */
  const formikFieldUpdate = formik.updateFieldValue ?? formik.setFieldValue;

  const setFieldValue = async (ev) => {
    const passThruFormatter = typeof config.formatOutput === 'function';
    const isElementTarget = ev && 'target' in ev;
    const isSelectValue = ev && 'value' in ev;
    const isPrimitive = ['string', 'number', 'boolean'].includes(typeof ev);

    if (passThruFormatter) {
      const value = config.outputRawEvent
        ? ev
        : ev.target?.value ?? ev?.value ?? ev;
      const modifiedValue = config.formatOutput(value, options);
      await formikFieldUpdate(config.key, modifiedValue);
    } else if (isElementTarget) {
      formik.handleChange(ev);
    } else if (isSelectValue && !config.outputRawEvent) {
      await formikFieldUpdate(config.key, ev.value);
    } else if (isPrimitive || config.outputRawEvent) {
      await formikFieldUpdate(config.key, ev);
    } else {
      console.warn('Unprocessed input event', ev);
    }
  };

  const transposeOptions = typeof config.transposeOptions === 'function';
  const _options = transposeOptions
    ? config.transposeOptions(options ?? [])
    : options;

  const isOptional = validationSchema?.fields[config.key]?.optional;
  const isRequired = isOptional === undefined ? false : !isOptional;
  const fieldValue = _get(formik.values, config.key);

  // (i) a custom property can be defined for value
  // (ii) default to 'value' for typical 'value,label' select options
  // (iii) fallback for primitive 'id' selector, string, or object

  const value = getValue(fieldValue, config);
  let error = showError ? _get(formik.errors, config.key, '') : '';
  const touched = _get(formik.touched, config.key, false);
  if (typeof error === 'object') {
    error = Object.values(error).join(', ');
  }

  return (
    <Input
      key={config.key}
      dataTestAttribute={config.dataTestAttribute}
      keyName={config.key}
      label={config.label}
      containerClasses={containerClass}
      additionalClasses="formInput formInput--bordered"
      elementType={config.type}
      elementConfig={{
        onBlur: formik.handleBlur,
        ...config.elementConfig,
        ...(options && { options: _options }),
        ...(description && { description }),
      }}
      required={isRequired}
      touched={touched}
      invalid={!!error}
      shouldValidate={config.shouldValidate !== false}
      error={typeof error === 'string' ? error : ''}
      value={value}
      {...(config.type === 'image'
        ? { fileChange: config.fileChange }
        : { changed: setFieldValue })}
      {...rest}
    />
  );
};

export default FormikInput;

const getValue = (fieldValue, config) => {
  if (config.formatInputValue) {
    return config.formatInputValue(fieldValue);
  }
  return fieldValue?.[config?.valueKey ?? 'value'] ?? fieldValue;
};
