import React, { useContext, useMemo, useState, useEffect } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import chevDown from '../../../assets/icons/chevron-down.svg';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import styles from './CampaignTable.module.scss';

const CampaignTableManager = ({
  tableColumnSettings,
  columns,
  setTableColumnSettings,
}) => {
  const onColumnVisibilityChange = (name) => {
    setTableColumnSettings((prevState) => {
      prevState[name].visible = !prevState[name].visible;

      localStorage.setItem('campaignColumns', JSON.stringify(prevState));
      return { ...prevState };
    });
  };

  useEffect(() => {
    const campaignColumns = localStorage.getItem('campaignColumns');

    if (campaignColumns) {
      setTableColumnSettings(JSON.parse(campaignColumns));
    }
  }, []);

  return (
    <div className="d-flex justify-content-end mb-2">
      <Dropdown>
        <Dropdown.Toggle
          className={styles.columnOptionManageBtn}
          variant="success"
          id="dropdown-basic"
        >
          <p className={`${styles.columnOptionHeaderText} m-0`}>
            Manage Columns
          </p>

          <img src={chevDown} alt="drop-down" />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: 250 }} className="p-3">
          <p className={`${styles.columnOptionHeaderText} pt-3 px-2`}>
            Hide / Show Columns
          </p>
          {Object.keys(tableColumnSettings).map((key) => {
            const column = columns.find((c) => c.name === key);
            return (
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  key={key}
                  darkMode={1}
                  className="py-1"
                  value={tableColumnSettings[key].visible}
                  onCheck={() => {
                    onColumnVisibilityChange(key);
                  }}
                />
                <span className={styles.columnOptionText}>{column.header}</span>
              </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CampaignTableManager;
