import React from 'react';
import Modal from '../../UI/Modal/Modal';

const ModalFileDownload = ({ filePassword, show, close }) => {
  return (
    <Modal
      title="File password"
      body={
        <>
          <p>The password to extract the zip file is:</p>
          <p>
            <strong>{filePassword}</strong>
          </p>
          <p>
            The csv file contains PII (personally identifiable information) and
            should be treated with care
          </p>
          <p className="mb-0">
            It is recommended to use a program such as 7-zip to extract the csv
            file
          </p>
        </>
      }
      show={show}
      handleClose={close}
    />
  );
};

export default ModalFileDownload;
