import React from 'react';
import _get from 'lodash/get';

import SelectAsync from '../SelectAsync/SelectAsync';

/**
 * Wrapper for SelectAsync component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @example <FormikSelectAsync
 *              field={displayFields.countries}
 *              formik={formik}
 *              validationSchema={validationSchema}
 *              optionsApiUrl="/dictionary/countries/search?query=:query"
 *              apiDataKey="countries"
 *          />
 */
const FormikSelectAsync = ({
  validationSchema,
  field: config,
  formik,
  optionsApiUrl,
  apiDataKey,
  apiQueryKey = ':query',
  isMulti,
  disabled,
  ...rest
}) => {
  /**
   * updateFieldValue is a custom over-ride as
   *  setFieldValue() does NOT set touched state
   */
  const formikFieldUpdate = formik.updateFieldValue ?? formik.setFieldValue;

  const setFieldValue = async (selected) => {
    const passThruFormatter = typeof config.formatOutput === 'function';

    if (passThruFormatter) {
      const modifiedValue = config.formatOutput(selected);
      await formikFieldUpdate(config.key, modifiedValue);
    } else {
      await formikFieldUpdate(config.key, selected);
    }
  };

  const isOptional = validationSchema?.fields[config.key]?.optional;
  const isRequired = isOptional === undefined ? false : !isOptional;
  const fieldValue = formik.values[config.key];

  const value = getValue(fieldValue, config);
  let error = _get(formik.errors, config.key, '');
  const touched = _get(formik.touched, config.key, false);

  if (typeof error === 'object') {
    error = Object.values(error).join(', ');
  }

  return (
    <SelectAsync
      controlId={config.key}
      label={config.label}
      touched={touched}
      changed={setFieldValue}
      defaultValue={value}
      error={error}
      required={isRequired}
      optionsApiUrl={optionsApiUrl}
      apiDataKey={apiDataKey ?? ''}
      apiQueryKey={apiQueryKey}
      isMulti={isMulti}
      placeholder={config.elementConfig?.placeholder ?? ''}
      disabled={disabled}
      description={config.elementConfig?.description ?? ''}
      value={value}
      {...rest}
    />
  );
};

export default FormikSelectAsync;

const getValue = (fieldValue, config) => {
  if (config.formatInputValue) {
    return config.formatInputValue(fieldValue);
  }
  return fieldValue;
};
