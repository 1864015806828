import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styles from './TermsPage.module.scss';

const TermsPage = () => (
  <Container className="my-5">
    <Row>
      <Col>
        <h3 className="mb-4 text-center">
          <u>Platform Terms & Conditions</u>
        </h3>
      </Col>
    </Row>
    <main>
      <Row>
        <Col>
          <p>
            These Platform Terms and Conditions (the <b>“Terms”</b>) are a
            binding agreement between Bounty Media Pte Ltd (<b>“Bounty”</b>,
            <b>“we”</b> or <b>“us”</b>) and you and the organisation you
            represent (if you are using the Platform as an employee or agent of
            the organisation) (collectively referred as <b>“User”</b> or
            <b>“you”</b>). These Terms govern your access to and use of the
            web-based digital solutions platform named “Bounty Platform” (the
            “Platform”). Please note that these Terms include and incorporate by
            reference our Privacy Policy accessible at <b>www.bounty.media</b>
          </p>
          <p>
            By accessing any part of the Platform, you acknowledge and agree
            that (i) you have read, understand, and agree to be bound by these
            Terms, (ii) you are of legal age and capacity to form a binding
            contract with Bounty, and (iii) you have the authority to enter into
            these Terms personally or on behalf of your organisation, and to
            bind your organisation to these Terms.{' '}
            <b>
              If you do not agree to be bound by these Terms, are not of legal
              age, do not have capacity to be bound by these Terms, or do not
              have the authority to enter into these Terms personally or on
              behalf of your organisation, you must not access or use the
              Platform.
            </b>
          </p>
          <p>
            These Terms may be revised from time to time by Bounty upon notice
            to you, such as by posting a revised version on the Platform. Your
            continued access to or use of the Platform after such notice had
            been given shall constitute your acceptance of the revised Terms.
          </p>

          <ol className={styles.parent} type="1">
            {/* 1 */}
            <li>
              <b>Definitions.</b>
              <ol className={styles.subParent}>
                {/* 1.1 */}
                <li>
                  In these Terms, the following words and expressions shall have
                  meanings ascribed below:
                  <p>
                    <b>&quot;Account&quot;</b> has the meaning given to it in
                    <b> Clause 4.1.</b>
                  </p>
                  <p>
                    <b>&quot;Authorised Users&quot;</b> means the User’s
                    employees, contractors, and other persons to whom the User
                    provides or makes available the Platform.
                  </p>
                  <p>
                    <b>&quot;Documentation&quot;</b> means any and all user
                    guides or other documentation describing the features,
                    functionality or operation of the Platform, including
                    without limitation any and all revisions, modifications, and
                    updates to such Documentation made by Bounty from time to
                    time.
                  </p>
                  <p>
                    <b>&quot;Governmental Authority&quot;</b> means any
                    governmental, administrative, statutory, regulatory or
                    self-regulatory, judicial or arbitral authority or body
                    (including any division thereof), anywhere in the world with
                    jurisdiction over the Platform or relevant affairs of Bounty
                    or the User.
                  </p>
                  <p>
                    <b>&quot;Intellectual Property Rights&quot;</b> means
                    patents, trademarks, service marks, copyright, know-how,
                    design rights, database rights, rights in software, rights
                    in designs and inventions, trade secrets, confidential
                    information, trade and business names and brands, internet
                    domain names, any application (whether pending, in process
                    or issued) for any of the foregoing and any other
                    industrial, intellectual property or protected right similar
                    to the foregoing (whether registered, registrable or
                    unregistered) in any country and in any form, media, or
                    technology now known or later developed.
                  </p>
                  <p>
                    <b>&quot;Registered User&quot;</b> has the meaning given to
                    it in <b>&quot;Clause 4.1.&quot;</b>
                  </p>
                  <p>
                    <b>&quot;Registration Data&quot;</b> has the meaning given
                    to it in <b>&quot;Clause 4.2.&quot;</b>
                  </p>
                  <p>
                    <b>&quot;Technical Data&quot;</b> means system specific data
                    or information and other technical data relating to the
                    Platform.
                  </p>
                  <p>
                    <b>&quot;Term&quot;</b> has the meaning given to it in{' '}
                    <b>Clause 13.1.</b>
                  </p>
                  <p>
                    <b>&quot;Third-Party Materials&quot;</b> has the meaning
                    given to it in <b>Clause 8.1.</b>
                  </p>
                  <p>
                    <b>&quot;User Content &quot;</b> means the data and
                    information submitted or provided to Bounty by User and/or
                    its Authorised Users.
                  </p>
                  <p>
                    <b>&quot;User Device&quot;</b> means a mobile phone, a
                    tablet, or other compatible computing devices capable of
                    accessing the Internet and compliant with the specifications
                    set forth in the Documentation which are required for
                    accessing and using the Platform.
                  </p>
                  <p>
                    <b>&quot;User Failure&quot;</b> means any failure or
                    inability to access or use the Platform that is attributable
                    to the User, including without limitation (i) your access or
                    use of, or attempt to access or use, the Platform in breach
                    of these Terms, (ii) your non-compliance with the User
                    Restrictions as set out in <b>Clause 3</b> of these Terms;
                    (iii) the User&apos;s delay in performing, or failure to
                    perform any of its obligations under these Terms (including
                    making payments), (iv) connectivity issues with the
                    Users&apos; internet connection, or (v) failure or
                    incompatibility of the User Devices and other hardware or
                    software components required by the User to access or use
                    Platform which are not supplied or provided by us.
                  </p>
                  <p>
                    <b>&quot;User Terms&quot;</b> has the meaning given to it in
                    <b> Clause 3.4.</b>
                  </p>
                </li>
              </ol>
            </li>

            {/* 2 */}
            <li>
              <b>License.</b>
              <ol className={styles.subParent}>
                {/* 2.1 */}
                <li>
                  Bounty hereby grants you a limited, revocable,
                  non-transferable, non-sublicensable and non-exclusive license
                  to access and use the Platform subject to your compliance with
                  these Terms in addition to all other applicable terms and
                  policies including the terms of any agreement you have entered
                  into with us. The grant of this licence does not constitute a
                  transfer or sale of the Platform or any copy thereof, and we
                  retain all right, title, and interest in and to the Platform,
                  including any Intellectual Property Rights therein. For the
                  avoidance of doubt, the Platform is licensed, not sold, to you
                  by us pursuant and subject to these Terms and any other
                  applicable terms and conditions imposed by us.
                </li>
                {/* 2.2 */}
                <li>
                  Bounty will use commercially reasonable efforts to make the
                  Platform available to you. Notwithstanding the foregoing and
                  without limitation to <b>Clauses 10, 11 and 12</b> of these
                  Terms, you acknowledge and agree that:
                  <ol type="a">
                    <li>
                      As the applicable laws may be subject to changes from time
                      to time, Bounty cannot guarantee that your access to or
                      use of the Platform will always remain compliant with the
                      applicable laws;
                    </li>
                    <li>
                      The availability and performance of the Platform may vary
                      depending on a variety of factors, including without
                      limitation to the specifications of the User Device used
                      by you, stability of the internet, or telecommunication
                      network. Bounty makes no representations or warranties,
                      whether express, implied, statutory or otherwise, to the
                      performance (including without limitation to quality,
                      speed, service level or efficiency), uptime, or
                      availability of the Platform, or that your access to or
                      use of the Platform will be uninterrupted, error-free or
                      will meet your needs or requirements; and
                    </li>
                    <li>
                      Bounty shall not be held responsible or liable for any
                      errors or disruptions in the Platform <b>(“Errors”)</b>,
                      or your inability to access or use the Platform (or any
                      part thereof) <b>(“Unavailability”)</b> for any reasons,
                      and any losses or damages you may suffer as a result of
                      such Error or Unavailability, including where such Error
                      or Unavailability is due in part or in whole to any of the
                      following:
                    </li>
                    <ol type="i">
                      <li>User Failure;</li>
                      <li>Force Majeure Event;</li>
                      <li>
                        changes in laws or orders or requirements of a court or
                        other Governmental Authority;
                      </li>
                      <li>
                        any suspension or termination of your Account, access
                        and/or use of the Platform in accordance with these
                        Terms; and
                      </li>
                      <li>
                        failure of the internet, telecommunication network, or
                        any other reasons not caused by or attributable to
                        Bounty, or beyond Bounty’s control.
                      </li>
                    </ol>
                  </ol>
                </li>
              </ol>
            </li>

            {/* 3 */}
            <li>
              <b>Use Restrictions.</b>

              <ol className={styles.subParent}>
                {/* 3.1 */}
                <li>
                  You shall:
                  <ol type="a">
                    <li>
                      access and use the Platform in compliance with these Terms
                      and all applicable laws, and shall
                      <br /> be responsible for ensuring your Authorised Users
                      will do the same;
                    </li>
                    <li>
                      obtain all consents, permissions, and authorisation
                      required under applicable law from Authorised Users and
                      other individual end users as necessary to input, provide,
                      transfer, and make available data (including personal
                      data) to Bounty for the purposes of using the Platform;
                    </li>
                  </ol>
                  <li>
                    use commercially reasonable efforts to prevent unauthorised
                    access to or use of the Platform by any person other than
                    Authorised Users, and notify Bounty promptly of any such
                    unauthorised access or use; and
                  </li>
                  <li>
                    use the Platform only in accordance with the Documentation
                    and applicable laws, rules and regulations, including
                    directions or requests by Governmental Authority.
                  </li>
                </li>
                {/* 3.2 */}
                <li>
                  You shall not, and shall ensure your Authorised Users shall
                  not:
                  <ol type="a">
                    <li>
                      make the Platform or the Documentation (or any part
                      thereof) available to, provide services using the
                      Platform, or use the Platform, or the Documentation (or
                      any part thereof) for the benefit of anyone other than
                      yourself or your Authorised Users;
                    </li>
                    <li>
                      rent, lease, lend, sell, resell, sublicense, assign,
                      distribute, publish, or lease the Platform or otherwise
                      make available to any third party for any reason,
                      including the Documentation;
                    </li>
                    <li>
                      interfere with or disrupt the integrity or performance of
                      the Platform, or any information and content contained
                      thereon;
                    </li>
                    <li>
                      copy, adapt, modify, prepare derivative works based upon,
                      transfer, publicly display, transmit, or otherwise exploit
                      the Platform, including any function or feature thereof;
                    </li>
                    <li>
                      use any manual or automated software, devices or other
                      processes (including but not limited to spiders, robots,
                      scrapers, crawlers, avatars, data mining tools or the
                      like) to “scrape” or download any information and data
                      from the Platform;
                    </li>
                    <li>
                      access the Platform in order to build a competitive
                      product or service or otherwise to compete with Bounty;
                    </li>
                    <li>
                      reverse engineer, disassemble, or otherwise attempt to
                      derive or gain access to the source code or infrastructure
                      of the Platform or any part thereof;
                    </li>
                    <li>
                      attempt to probe, scan, or test the vulnerability of the
                      Platform, any Bounty system or network or breach any
                      security or authentication measures, or otherwise attempt
                      to benchmark the Platform or Bounty’s performance of any
                      services;
                    </li>
                    <li>
                      store or transmit code, files, agents, or programs that
                      could harm the Platform or other users, including viruses,
                      worms, time bombs, and Trojan horses;
                    </li>
                    <li>
                      remove, delete, alter or obscure any trademarks or any
                      copyright, trademark, patent or other intellectual
                      property or proprietary rights notices from the Platform,
                      including any copy thereof;
                    </li>
                    <li>
                      post, upload, publish, submit or transmit or otherwise
                      make available any User Content that you do not have a
                      right to make available under any law or under contractual
                      or fiduciary relationships, that infringes,
                      misappropriates or violates a third party’s patent,
                      copyright, trademark, trade secret, moral or other
                      Intellectual Property Rights, or rights of publicity or
                      privacy, or that otherwise violates applicable law or
                      regulation; or
                    </li>
                    <li>
                      provide any competitor of Bounty (including any employee
                      or contractor of such competitor) with access to or use of
                      the Platform (or any part thereof), including by read-only
                      access, direct access through an Authorised User
                      identification and password information, or otherwise.
                    </li>
                  </ol>
                </li>

                {/* 3.3 */}
                <li>
                  You acknowledge and agree that:
                  <ol type="a">
                    <li>
                      Bounty has no obligation to monitor your access to or use
                      of the Platform but has the right to do so for the purpose
                      of operating the Platform, to ensure your compliance with
                      these Terms or to comply with applicable laws or the order
                      or requirement of a court, or Governmental Authority;
                    </li>
                    <li>
                      Bounty reserves the right, at any time and without prior
                      notice, to terminate the license granted to you under
                      these Terms and remove or disable your access to and use
                      of the Platform (or any part thereof) should Bounty, at
                      its sole discretion, consider any of your actions or
                      operations to be in violation of these Terms or is
                      otherwise harmful to the Platform or any users of the
                      Platform; and
                    </li>
                    <li>
                      Bounty may investigate and prosecute violations of these
                      Terms to the fullest extent of the law and involve and
                      cooperate with law enforcement authorities in prosecuting
                      users who violate these Terms.
                    </li>
                  </ol>
                </li>
                {/* 3.4 */}
                <li>
                  Your use of the Platform also shall be subject to the terms
                  and conditions of the Privacy Policy presented or made
                  available when accessing the Platform, and any other
                  Documentation and other applicable terms and conditions
                  applicable (collectively the <b>“User Terms”</b>) for the time
                  being in force. You shall ensure that each Authorised User
                  agrees to the terms and conditions of the User Terms prior to
                  using the Platform.
                </li>

                {/* 3.5 */}
                <li>
                  <b>Updates.</b> You acknowledge and agree that Bounty may
                  regularly update the Platform and reserves the right to add
                  and/or substitute functionally equivalent features for any
                  reason, without notifying you. Bounty shall have the right to
                  remove, suspend or discontinue any component, feature, or
                  function of the Platform without any liability towards you.
                  You further agree that all updates to the Platform will be
                  deemed part of the Platform and be subject to all terms and
                  conditions of these Terms.
                </li>
              </ol>
            </li>
            {/* 4 */}
            <li>
              <b>User Account</b>

              <ol className={styles.subParent}>
                {/* 4.1 */}
                <li>
                  In order to access certain features of the Platform you are
                  required to become a Registered User. For purposes of these
                  Terms, a <b>&quot;Registered User&quot;</b> is a user who has
                  registered an account on the Platform
                  <b> (&quot;Account&quot;)</b>.
                </li>

                {/* 4.2 */}
                <li>
                  In creating an Account, you agree to:
                  <ol type="a">
                    <li>
                      provide true, accurate, current and complete information
                      about yourself as may be required by Bounty (the
                      <b>“Registration Data”</b>);
                    </li>
                    <li>
                      maintain and promptly update the Registration Data to keep
                      it true, accurate, current and complete;
                    </li>
                    <li>
                      not create an Account using a false identity or
                      information, or on behalf of someone other than yourself;
                      and
                    </li>
                    <li>
                      immediately notify Bounty in writing if you suspect or
                      become aware of any unauthorised use of your Account or
                      any other breach of security.
                    </li>
                  </ol>
                </li>

                {/* 4.3 */}

                <li>
                  You shall be solely responsible for:
                  <ol type="a">
                    <li>
                      maintaining the strict confidentiality and security of
                      your Account and shall not allow another person to use
                      your Account to access the Platform.
                    </li>
                    <li>
                      all activities on the Platform that occur under or through
                      the use of your Account. If you are accessing the Platform
                      on behalf of your organisation, all such activities will
                      be attributable to and binding on your organisation.
                    </li>
                  </ol>
                </li>

                {/* 4.4 */}
                <li>
                  Bounty shall not be liable to you for any harm or loss arising
                  from or relating to the theft of your Account, your disclosure
                  of your Account, or the use of your Account by another person
                  or entity.
                </li>

                {/* 4.5 */}
                <li>
                  Bounty reserves the right to temporarily or permanently
                  suspend or terminate your Account or impose limits on or
                  restrict your access to and use of the Platform with or
                  without notice at any time for any or no reason including:
                  <ol type="a">
                    <li>
                      if you provide any Registration Data that is untrue,
                      inaccurate, not current or incomplete, or Bounty has
                      reasonable grounds to suspect that any Registration Data
                      you provide is untrue, inaccurate, not current or
                      incomplete;
                    </li>
                    <li>
                      if Bounty has reasonable grounds to believe you have
                      violated, or are about to violate these Terms, including
                      any incorporated agreements, policies or guidelines, or
                      any applicable laws or regulations, or that your Account
                      may have been compromised;
                    </li>
                    <li>
                      if activities occur under your Account which, in Bounty’s
                      sole discretion, would or might cause damage or losses to
                      Bounty or any users (whether yourself or other users),
                      impair Bounty’s ability to provide the Platform, or
                      infringe or violate any third party rights (including
                      Intellectual Property Rights);
                    </li>

                    <li>
                      in response to requests by law enforcement or other
                      Governmental Authority; or
                    </li>
                    <li>
                      due to unexpected technical or security issues or
                      problems.
                    </li>
                  </ol>
                </li>

                {/* 4.6 */}
                <li>
                  You agree not to create an Account or use the Platform if you
                  have previously been banned from the Platform by Bounty.
                </li>
              </ol>
            </li>

            {/* 5 */}
            <li>
              <b>User Content</b>
              <ol className={styles.subParent}>
                {/* 5.1 */}
                <li>
                  You hereby grant Bounty a non-exclusive, royalty-free license
                  to access and use User Content during the Term for the
                  purposes of:
                  <ol type="a">
                    <li>providing the Platform to you;</li>
                    <li>
                      monitoring and improving the performance of the Platform
                      and other internal business purposes of Bounty; and
                    </li>
                    <li>creating de-identified versions of User Content.</li>
                  </ol>
                </li>

                {/* 5.2 */}
                <li>
                  You represent and warrant that you own or have the legal right
                  and authority, and will continue to own or maintain the legal
                  right and authority, to grant to Bounty the User Content set
                  forth in <b>Clause 5.1</b> during the Term.
                </li>

                {/* 5.3 */}
                <li>
                  You acknowledge and agree that
                  <ol type="a">
                    <li>
                      Bounty does not monitor User Content transmitted by you or
                      your Authorised User through the Platform, and that Bounty
                      shall not be responsible for such User Content;
                    </li>

                    <li>
                      you are solely responsible for making sure that the
                      disclosure and use of data, User Content and information
                      that you or your Authorised Users provide to Bounty
                      through the means described herein does not violate any
                      applicable law or infringe upon the Intellectual Property
                      Rights of any third party;
                    </li>

                    <li>
                      you and your Authorised Users, each individually, shall
                      not knowingly post or upload any User Content or data
                      which:
                      <ol type="i">
                        <li>
                          is libelous, defamatory, obscene, pornographic,
                          abusive, harassing or threatening;
                        </li>

                        <li>
                          violates the rights of others, such as data which
                          infringes on any Intellectual Property Rights or
                          violates any right of privacy or publicity; or
                        </li>
                        <li>otherwise violates any applicable law;</li>
                      </ol>
                    </li>

                    <li>
                      Bounty may remove any violating content posted on or
                      transmitted through the Platform at any time; and
                    </li>

                    <li>
                      you shall promptly provide written notice to Bounty in the
                      event you discover any User Content provided is unlawful,
                      contains errors, or otherwise violates the provisions of
                      these Terms.
                    </li>
                  </ol>
                </li>
                <li>
                  {/* 5.4 */}
                  You acknowledge and agree that Bounty shall be entitled to:
                  <ol type="a">
                    <li>
                      collect, access, modify, distribute, audit, reproduce,
                      delete or remove any User Content relating to you, the
                      Authorised Users, and/or the use of the Platform freely
                      and in perpetuity to the extent necessary to:
                    </li>

                    <ol type="i">
                      <li>
                        protect you, your Authorised Users and other users of
                        the Platform;
                      </li>

                      <li>
                        provide, protect, and improve Bounty’s products and
                        services, whether now in existence or in the future;
                      </li>

                      <li>provide statistical analysis;</li>

                      <li>
                        protect the integrity of any data held by Bounty; and
                      </li>

                      <li>
                        ensure your compliance with these Terms and any
                        applicable laws or regulations; and
                      </li>

                      <li>
                        disclose such data and information if required by law,
                        to enforce these Terms, or to protect Bounty’s rights or
                        those of other users.
                      </li>
                    </ol>
                  </ol>
                </li>
              </ol>
            </li>

            {/* 6 */}
            <li>
              <b>Personal Data Protection</b>
              <ol className={styles.subParent}>
                <li>
                  The Platform allows you to access, review, and export data
                  (including personal data) collected or generated by Bounty on
                  behalf of and for the purpose of your organisation. You hereby
                  acknowledge and agree that:
                  <ol type="a">
                    <li>
                      You will collect, use, disclose and/or process such data
                      strictly in compliance with applicable laws, and the
                      instructions, directions and applicable internal policies
                      of your organisation.
                    </li>
                    <li>
                      You shall not collect, use, disclose and/or process such
                      data for any purposes other than the purposes for which
                      you are authorised to by your organisation.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* 7 */}
            <li>
              <b>Technical Data </b>
              <ol className={styles.subParent}>
                {/* 7.1 */}
                <li>
                  It may be necessary for Bounty or its affiliates to obtain,
                  receive, or collect Technical Data from you to provide you
                  access to and use of the Platform. You hereby grant Bounty and
                  its affiliates a non-exclusive, worldwide, royalty-free,
                  perpetual, irrevocable license to:
                  <ol type="a">
                    <li>
                      compile, use, distribute, display, store, process,
                      reproduce, or create derivative works of the Technical
                      Data;
                    </li>
                    <li>
                      aggregate the Technical Data with other data for use in an
                      anonymous manner for Bounty’s marketing and sales
                      activities; and
                    </li>
                    <li>
                      exploit aggregated Technical Data and/or provide
                      aggregated Technical Data to third parties, so long as
                      such data is presented in the aggregated format and does
                      not identify you or your Authorised Users.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* 8 */}
            <li>
              <b>Third-Party Materials. </b>
              <ol className={styles.subParent}>
                {/* 8.1 */}
                <li>
                  Bounty may display, include or make available third-party
                  content or provide links to third-party websites or services
                  (collectively, <b>“Third-Party Materials”</b>) on the
                  Platform. You acknowledge and agree that any Third-Party
                  Materials you or your Authorised Users access through the
                  Platform are entirely at your own risk.{' '}
                </li>

                {/* 8.2 */}
                <li>
                  Without prejudice to the foregoing, you acknowledge and agree
                  that:
                  <ol type="a">
                    <li>
                      Bounty may but shall have no obligation to monitor and
                      review such Third-Party Materials, and shall not be
                      responsible for such Third-Party Materials, including
                      their accuracy, completeness, timeliness, validity,
                      copyright compliance, legality, decency, quality or any
                      other aspect thereof;
                    </li>
                    <li>
                      Bounty does not assume and will not have any liability or
                      responsibility to you or any other person or entity for
                      any Third-Party Materials;
                    </li>

                    <li>
                      Third-Party Materials and links thereto are provided
                      solely as a convenience to you and you access and use them
                      entirely at your own risk and subject to such third
                      parties’ terms and conditions;
                    </li>

                    <li>
                      Bounty does not guarantee the continued availability of
                      such Third-Party Materials, and may cease displaying,
                      including or making available such Third-Party Materials
                      at any time at its sole discretion without any liability
                      to you; and
                    </li>

                    <li>
                      Bounty makes no representations or warranties whatsoever,
                      and shall not be liable for any damage, liabilities,
                      losses (including any loss of data or profits), or any
                      other consequences that you may incur arising out of or in
                      relation to your use of Third-Party Materials, any
                      transactions completed in or through the same, nor for any
                      contract entered into by you or any Authorised User (as
                      the case may be), with any third party.
                    </li>
                  </ol>
                </li>
                {/* 8.3 */}
                <li>
                  You shall indemnify Bounty against all costs, losses,
                  liabilities and damages which arise from any action or claim
                  against Bounty from third parties in respect of your use of,
                  integration and/or interface with Third-Party Materials and
                  related data.
                </li>
              </ol>
            </li>

            {/* 9 */}
            <li>
              <b>Third-Party Materials. </b>
              <ol className={styles.subParent}>
                {/* 9.1 */}
                <li>
                  <b>Ownership.</b> You acknowledge and agree that Bounty and
                  its third-party licensors own all rights, title and interests
                  (including without limitation to Intellectual Property Rights)
                  in and to:
                  <ol type="a">
                    <li>the Platform and Documentation;</li>
                    <li>
                      any trademarks, service marks, trade names, domain names,
                      website name, other significant brand features or specific
                      descriptions which will allow a third party to identify
                      Bounty and/or its affiliates (collectively, “Bounty
                      Proprietary Markings”);
                    </li>
                    <li>
                      any trademarks, service marks, trade names, domain names,
                      website name, other significant brand features or specific
                      descriptions which will allow a third party to identify
                      Bounty and/or its affiliates (collectively,{' '}
                      <b>“Bounty Proprietary Markings”</b>);
                    </li>
                    <li>
                      any reports or data generated by Bounty in the course of
                      providing the Platform to you or from User Content
                      uploaded or entered by you to the Platform;
                    </li>
                    <li>
                      any intangible ideas, residual knowledge, concepts,
                      know-how and techniques related to or learned from
                      Bounty’s provision of the Platform, including, without
                      limitation, any intangible ideas, residual knowledge,
                      concepts, know-how, and techniques related to any new
                      features for the Platform, whether or not created for you;
                      and
                    </li>
                    <li>
                      any operation and technical data relating to the Platform
                      (including without limitation the user Account
                      information, Registration Data, operation records, and
                      service orders). (collectively, the{' '}
                      <b>“Bounty Intellectual Property”</b>).
                    </li>
                  </ol>
                </li>
                {/* 9.2 */}
                <li>
                  Other than the limited license and use rights expressly set
                  forth in these Terms to the Bounty Intellectual Property,
                  Bounty does not grant you any rights to the Bounty
                  Intellectual Property and reserves all rights therein.
                </li>
                {/* 9.3 */}
                <li>
                  You shall not, and shall not permit any other person to:
                  <ol type="a">
                    <li>
                      access or use the Bounty Intellectual Property except in
                      accordance with these Terms;
                    </li>
                    <li>
                      display, use, apply for registration any Bounty
                      Proprietary Markings;
                    </li>
                    <li>
                      represent to any other persons, that you have the right to
                      display, use or to otherwise dispose of the Bounty
                      Proprietary Markings;
                    </li>
                    <li>
                      modify, alter, remove, delete or destroy any Bounty
                      Proprietary Markings placed upon or contained within the
                      Platform, Services or any Documentation; or
                    </li>
                    <li>
                      take any action which would cause the Platform and/or
                      Services or any part thereof to be placed in the public
                      domain or to become open source software.
                    </li>
                  </ol>
                </li>
                {/* 9.4 */}
                <li>
                  <b>Feedback.</b> If you propose or provide any ideas,
                  suggestions, recommendations, enhancements, improvements, or
                  other feedback (collectively <b>“Feedback”</b>) to Bounty,
                  then you hereby assign all rights, title, and interests,
                  including all copyright, patent, trade dress rights and other
                  Intellectual Property Rights, in and to such Feedback to
                  Bounty. Bounty shall have the right to use and disclose any
                  ideas, know-how, concepts, techniques, or other Intellectual
                  Property Rights contained in such Feedback in any manner and
                  for any purpose in Bounty’s discretion without remuneration,
                  compensation or attribution to you, provided that the
                  foregoing shall not create or imply any obligation on the part
                  of Bounty to use such Feedback.
                </li>
                {/* 9.5 */}
                <li>
                  <b>Publicity.</b> You acknowledge and agree that Bounty may
                  identify you as a user of the Platform and use your
                  trademarks, service marks, or logos in sales presentations,
                  press releases, and marketing materials in order to reference
                  you as a customer of Bounty.
                </li>
              </ol>
            </li>

            {/* 10 */}
            <li>
              <b>Disclaimer of Warranties. </b>
              <ol className={styles.subParent}>
                <li>
                  <b>Services Provided “As Is”.</b> YOU ACKNOWLEDGE AND AGREE
                  THAT TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE
                  PLATFORM IS PROVIDED BY BOUNTY ON AN “AS IS,” AND “AS
                  AVAILABLE” BASIS, WITH ALL FAULTS. BOUNTY EXPRESSLY DISCLAIMS
                  ALL OTHER WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY
                  KIND, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THESE TERMS,
                  THE PLATFORM AND/OR THE RESULTS THAT MAY (OR MAY NOT) BE
                  ACHIEVED BY USE OF THE PLATFORM BY YOU OR YOUR AUTHORISED
                  USERS, INCLUDING WITHOUT LIMITATION ALL IMPLIED WARRANTIES OF
                  MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
                  NON-FRINGEMENT, ACCURACY, TIMELINESS OR EFFECTIVENESS AND ANY
                  WARRANTIES ARISING FROM A COURSE OF DEALING OR USAGE OR TRADE.
                  BOUNTY DOES NOT WARRANT THAT THE OPERATION OF THE PLATFORM
                  WILL BE UNINTERRUPTED OR ERROR-FREE AND WHILE BOUNTY HAS USED
                  REASONABLE MEASURES TO MAINTAIN THE CONFIDENTIALITY OF
                  AUTHORISED USERS’ INFORMATION, YOU ACKNOWLEDGE AND AGREE THAT
                  BOUNTY CANNOT GUARANTEE THE SECURITY OR ACCESSIBILITY OF SUCH
                  INFORMATION. YOU FURTHER ACKNOWLEDGE THAT BOUNTY USES A THIRD
                  PARTY TO HOST THE PLATFORM AND TO STORE USERS’ INFORMATION AND
                  BOUNTY MAKES NO REPRESENTATION OR WARRANTY AND SHALL HAVE NO
                  LIABILITY FOR SUCH THIRD PARTY SERVICES. BOUNTY MAKES NO
                  WARRANTIES REGARDING THE ACCURACY OR COMPLETENESS OF ANY
                  INFORMATION PROVIDED IN CONNECTION WITH OR RESULTS OBTAINED
                  THROUGH THE USE OF THE PLATFORM AND BOUNTY SHALL HAVE NO
                  LIABILITY FOR ANY CLAIM ARISING FROM ANY USE OF SUCH
                  INFORMATION OR RESULTS.
                </li>
              </ol>
            </li>
            {/* 11 */}
            <li>
              <b>Indemnity </b>
              <ol className={styles.subParent}>
                {/* 11.1 */}
                <li>
                  You shall indemnify, defend and hold harmless Bounty and its
                  affiliates, and their respective owners, managers, directors,
                  employees, and contractors from and against all direct and
                  indirect losses, damages, liabilities and costs (including
                  reasonable attorneys’ fees) incurred in connection with (i)
                  your use of the Platform or the results obtained therefrom,
                  (ii) any breach of these Terms by you or your Authorised
                  Users, and (iii) any third party claim arising out of or
                  relating to User Content and Bounty’s use thereof, including
                  without limitation if it infringes any Intellectual Property
                  Rights of a third party.
                </li>
              </ol>
            </li>
            {/* 12 */}
            <li>
              <b>Limitation of Liability. </b>
              <ol className={styles.subParent}>
                {/** 12.1 */}
                <li>
                  IN NO EVENT SHALL BOUNTY BE LIABLE TO YOU FOR:
                  <ol type="a">
                    <li>
                      ANY LOSS OF REVENUE, PROFITS, SALES, CONTRACTS, BUSINESS
                      OPPORTUNITY, BUSINESS OR ANTICIPATED SAVINGS;
                    </li>
                    <li>
                      ANY LOSS OF GOODWILL, OR DAMAGE TO BUSINESS REPUTATION;
                    </li>
                    <li>ANY LOSS OR CORRUPTION OF DATA; OR</li>
                    <li>
                      ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                      EXEMPLARY, PUNITIVE, ENHANCED, OR OTHER DAMAGES,{' '}
                    </li>
                  </ol>
                  HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY ARISING OUT
                  OF THESE TERMS OR THE PLATFORM, WHETHER OR NOT BOUNTY HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF,
                  RESULTING FROM OR RELATING IN ANY WAY TO (1) YOUR USE OR
                  INABILITY TO USE THE PLATFORM; (2) THE COST OF PROCUREMENT OF
                  SUBSTITUTE GOODS OR SERVICES RESULTING FROM ANY GOODS, DATA,
                  INFORMATION OBTAINED THROUGH THE PLATFORM; (3) UNAUTHORISED
                  ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4)
                  STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON PLATFORM; OR (5)
                  ANY OTHER MATTER RELATED TO THE PLATFORM.
                </li>
                {/** 12.2 */}
                <li>
                  IN NO EVENT SHALL BOUNTY’S AGGREGATE LIABILITY ARISING OUT OF
                  OR RELATED TO THESE TERMS EXCEED THE AMOUNT OF MONIES PAID BY
                  YOU OR YOUR ORGANISATION (AS THE CASE MAY BE) TO BOUNTY IN THE
                  ONE (1) YEAR PERIOD PRIOR TO SUCH CLAIM BEING MADE.
                </li>
                {/** 12.3 */}
                <li>
                  You hereby acknowledge and agree that the disclaimer of
                  warranties in <b>Clause 10</b>, indemnity in <b>Clause 11</b>{' '}
                  and limitations of liability in this <b>Clause 12</b> and in
                  the other provisions of these Terms and the allocation of risk
                  therein are essential elements of the bargain in Bounty’s
                  provision of the Platform, without which Bounty would not have
                  provided the Platform or entered into these Terms.
                </li>
              </ol>
            </li>

            {/* 13 */}
            <li>
              <b>Term and Termination. </b>
              <ol className={styles.subParent}>
                {/* 13.1 */}
                <li>
                  These Terms shall commence on the date when you accepted these
                  Terms (as described in the preamble above) and remain in full
                  force and effect while you access or use the Platform unless
                  terminated earlier in accordance with these Terms (the
                  “Term”). For the avoidance of doubt, you hereby acknowledge
                  and agree that the Terms commenced on the earlier of (a) the
                  date you first accessed the Platform or (b) the date you
                  accepted the Terms.
                </li>
                {/* 13.2 */}
                <li>
                  Upon any termination of these Terms:
                  <ol type="a">
                    <li>
                      all rights and licenses granted to you and Authorised
                      Users shall cease, and you and your Authorised Users shall
                      immediately discontinue use of the Platform;
                    </li>
                    <li>your access to the Platform may be barred;</li>

                    <li>
                      your Account and related information, files and content
                      associated with or inside your Account (or any part
                      thereof), including your User Content, may be deleted from
                      Bounty’s database at Bounty’s discretion. You acknowledge
                      and agree that Bounty shall not have any obligation to
                      retain information, files and content associated with or
                      inside your Account (or any part thereof), including your
                      User Content, and shall not have any liability whatsoever
                      to you for deletion of such information, files, content
                      and User Content; and
                    </li>

                    <li>
                      where applicable, you shall promptly pay (or procure your
                      organisation to pay) all amounts due to Bounty as of the
                      effective date of termination.
                    </li>
                  </ol>
                </li>
                {/* 13.3 */}
                <li>
                  Any provision of these Terms that expressly or by implication
                  is intended to come into or continue in force on or after the
                  termination of these Terms shall remain in full force and
                  effect, including without limitation to <b>Clause 3</b> (Use
                  Restrictions), <b>6</b> (Personal Data Protection), <b>9</b>{' '}
                  (Intellectual Property), <b>10</b> (Disclaimer of Warranties),{' '}
                  <b>11</b> (Indemnity), <b>12</b>
                  (Limitation of Liability), <b>13</b> (Term and Termination),{' '}
                  <b>14</b>
                  (Governing Law and Dispute Resolution) and <b>15</b> (General
                  Provisions).
                </li>
              </ol>
            </li>
            {/* 14 */}
            <li>
              <b>Governing Law and Dispute Resolution. </b>
              <ol className={styles.subParent}>
                {/* 14.1 */}
                <li>
                  These Terms shall be governed by and construed under the laws
                  of the Republic of Singapore.
                </li>
                {/* 14.2 */}
                <li>
                  Any dispute arising out of or in connection with these Terms,
                  including any question regarding its existence, validity or
                  termination, shall be referred to and finally resolved by
                  arbitration administered by the Singapore International
                  Arbitration Centre <b>(“SIAC”)</b> in accordance with the
                  Arbitration Rules of SIAC for the time being in force, which
                  rules are deemed to be incorporated by reference in this
                  clause. The seat of the arbitration shall be Singapore. The
                  Tribunal shall consist of one arbitrator appointed by the
                  mutual agreement of you and Bounty. If you and Bounty are
                  unable to agree on an arbitrator, the arbitrator shall be
                  appointed by the President of SIAC in accordance with the SIAC
                  Rules. The language of the arbitration shall be English.
                </li>
              </ol>
            </li>

            {/* 15 */}
            <li>
              <b>General Provisions. </b>
              <ol className={styles.subParent}>
                {/* 15.1 */}
                <li>
                  Bounty shall not be liable for any delay or failure to perform
                  resulting from causes outside its reasonable control,
                  including, but not limited to, acts of God, war, terrorism,
                  riots, embargos, acts of civil or military authorities, fire,
                  floods, accidents, epidemics, pandemics, strikes or shortages
                  of transportation facilities, fuel, energy, labor or materials
                  (<b>“Force Majeure Event”</b>). If a Force Majeure Event
                  occurs that affects Bounty’s performance of its obligations
                  under these Terms, Bounty’s obligations under these Terms will
                  be suspended and the time for Bounty’s performance of its
                  obligations will be extended for the duration of the Force
                  Majeure Event.{' '}
                </li>
                {/* 15.2 */}
                <li>
                  These Terms are the entire agreement between you and us in
                  relation to your access and use of our Platform and shall
                  supersede any previous communications (whether written, oral
                  or otherwise), discussions or letters relating to the same.
                </li>

                {/* 15.3 */}
                <li>
                  These Terms are entered into between you and us. For the
                  avoidance of doubt, except as expressly stated in these Terms,
                  a person who is not a party to these Terms shall have no right
                  under the Contracts (Rights of Third Parties) Act 2001 to
                  enforce any of the terms of these Terms.
                </li>

                {/* 15.4 */}
                <li>
                  No failure or delay by either party in exercising any right
                  under these Terms shall constitute a waiver of that right.
                </li>

                {/* 15.5 */}
                <li>
                  These Terms, and your rights and obligations hereunder, may
                  not be assigned, subcontracted, delegated or otherwise
                  transferred by you without Bounty’s prior written consent, and
                  any attempted assignment, subcontract, delegation, or transfer
                  in violation of the foregoing will be null and void.
                </li>

                {/* 15.6 */}
                <li>
                  If a court of competent jurisdiction finds any provision of
                  these Terms invalid or unenforceable, that provision of these
                  Terms will be amended to achieve as nearly as possible the
                  intent of the parties, and the remainder of these Terms will
                  remain in full force and effect.
                </li>

                {/* 15.7 */}
                <li>
                  You agree that no joint venture, partnership, employment, or
                  agency relationship will exist between you and us as a result
                  of these Terms and/or your access and use of the Platform.
                </li>
              </ol>
            </li>
            {/* 16 */}
            <li>
              <b>Contact Information. </b>
              <ol className={styles.subParent}>
                {/* 16.1 */}
                <li>
                  If you have any questions about these Terms, or if you wish to
                  send us any notices in relation to these Terms, you may
                  contact us at:
                  <Row md={12} className={styles.contact}>
                    <Col md={4} sm={4} lg={4}>
                      <p>
                        <u>Company Name:</u>
                      </p>
                      <p>
                        <u>Address:</u>
                      </p>
                      <p>
                        <u>Email:</u>
                      </p>
                    </Col>

                    <Col md spacing>
                      <p>Bounty Media Pte. Ltd. </p>
                      <p>
                        30 Cecil Street #19-08 Prudential Tower Singapore 049712
                      </p>
                      <p> privacy@bounty.media</p>
                    </Col>
                  </Row>
                </li>
                {/* 16.2 */}
                <li>
                  Any notice that we intend to give to you may be carried out by
                  sending such notice to you through the Platform or to any
                  contact information you may have provided us with through the
                  Platform or otherwise. You are deemed to have received notice
                  of the same upon us sending such notice to you through the
                  Platform or to any contact information you may have provided
                  us with through the Platform or otherwise.
                </li>
              </ol>
              Updated On: 17 March 2023
            </li>
          </ol>
        </Col>
      </Row>
    </main>
  </Container>
);

export default TermsPage;
