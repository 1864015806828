import createInput from '../../shared/Form/createInput';

export default {
  email: createInput(
    null,
    {
      type: 'email',
      placeholder: 'Email',
    },
    {
      required: true,
      isEmail: true,
    },
  ),
  password: createInput(
    null,
    {
      type: 'password',
      placeholder: 'Password',
    },
    {
      required: true,
      minLength: 8,
    },
  ),
};
