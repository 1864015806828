const createInput = (
  label,
  elementConfig,
  /*
   * In most instances the below default will not work,
   * if you need required = true make sure to set it when calling the createInput function
   */
  validation = { required: true },
  value = '',
  valid = false,
  elementType = 'input',
  fileChange = null,
) => ({
  elementType,
  elementConfig,
  label,
  value,
  validation,
  valid,
  touched: false,
  error: null,
  fileChange,
});

export default createInput;
