import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import OverlaySpinner from '../../../components/UI/OverlaySpinner/OverlaySpinner';
import TableData from '../../../components/UI/TableData/TableData';

import ConnectionsDataRequestPending from '../../../components/Connections/ConnectionsDataRequestPending';

import ConfirmDeleteModal from '../../../components/Modal/ModalConfirm/ModalConfirm';

import useConnectionDetailPage from './ConnectionDetailPage.hooks';

const ConnectionDetailPage = (props) => {
  const [getters, actions] = useConnectionDetailPage(props);

  const {
    userData,
    userDataColumns,
    qualifiers,
    campaignData,
    campaignDataColumns,
    isLoading,
    confirmDeleteProps,
  } = getters;

  const { openDeleteConnectionModal } = actions;

  if (isLoading) {
    return <OverlaySpinner />;
  }

  const [userInfo] = userData;

  return (
    <Container fluid className="pt-3">
      <Row>
        <Col className="mt-3">
          <div className="d-flex align-items-center flex-wrap mb-3">
            <h1 className="me-4">Connection Detail</h1>

            {userInfo?.pendingDelete ? (
              <div className="d-flex align-items-center">
                <ConnectionsDataRequestPending />
              </div>
            ) : null}
          </div>

          <TableData
            tableColumns={userDataColumns}
            tableData={userData}
            disableInactive
            onDelete={openDeleteConnectionModal}
          />
        </Col>
      </Row>

      {qualifiers.length ? (
        <Row>
          <Col className="mt-3">
            <h2>Paywall Qualifier Answers</h2>
            <TableData
              tableColumns={campaignDataColumns}
              tableData={qualifiers}
              evenColumns
              disableInactive
            />
          </Col>
        </Row>
      ) : null}

      {campaignData.length
        ? campaignData.map((campaign, i) => {
            return (
              <Row key={campaign.id ?? i}>
                <Col className="mt-3">
                  <h2>{campaign.name}</h2>
                  <TableData
                    tableColumns={campaignDataColumns}
                    tableData={campaign.questions}
                    evenColumns
                    disableInactive
                  />
                </Col>
              </Row>
            );
          })
        : null}

      <ConfirmDeleteModal {...confirmDeleteProps}>
        <p>
          You have a legal obligation to delete all of a connection’s PII when a
          data Management Request is received. Please be sure you have also
          deleted this data from your own records
        </p>
        <p>
          <strong>Deleted Data cannot be recovered</strong>
        </p>
      </ConfirmDeleteModal>
    </Container>
  );
};

export default ConnectionDetailPage;
