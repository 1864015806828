import { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import TranslationApi from '../../../../api/Translation';

export const useGlobalTranslationsForm = ({
  initialData = {},
  setShowForm,
  onSuccess,
  setToast,
}) => {
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialData.initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await TranslationApi.addTranslationContent(
          values,
          initialData.translationId,
          initialData.languageId,
        );
        if (response.error) {
          setToast({
            show: true,
            header:
              'An Error occurred please try again or contact a system administrator',
            class: 'error',
          });
          setLoading(false);
          return;
        }
        setToast({
          show: true,
          header: 'Translation saved successfully',
          class: 'success',
        });
        setLoading(false);
        setShowForm(false);
        onSuccess();
      } catch (err) {
        setToast({
          show: true,
          header:
            err.response?.data?.message ||
            'An Error occurred please try again or contact a system administrator',
          class: 'error',
        });
        console.error(err);
        setLoading(false);
      }
    },
  });

  const handleClose = () => {
    setShowForm(false);
  };

  const getters = {
    isLoading,
    formik,
  };

  const actions = {
    submitForm: formik.submitForm,
    handleClose,
  };

  return [getters, actions];
};
