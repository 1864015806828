import React, { useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async';
import instance from '../../../api/Api';

const formatOption = (option) => {
  if (typeof option === 'string') {
    return { value: option, label: option };
  }
  if (option.id) {
    return { value: option.id, label: option.label };
  }
  return option;
};

const createGetOptions = (url, apiQueryKey = ':query', apiDataKey = null) => {
  return async function getOptions(queryString) {
    const getList = (response) =>
      apiDataKey ? response.data?.[apiDataKey] ?? [] : response.data;
    const formatOptions = (arr) => {
      return arr.map((e) => formatOption(e));
    };
    const response = await instance
      .get(url.replace(apiQueryKey, queryString))
      .then((r) => getList(r))
      .then((e) => formatOptions(e));
    return response;
  };
};

const FormSelectAsync = ({
  label,
  error,
  placeholder = '',
  changed,
  controlId,
  disabled,
  isMulti,
  defaultValue = null, // { id: 123, label: 'label' } || [{ id: 123, label: 'label' }, { id: 123, label: 'label' }]
  optionsApiUrl,
  apiQueryKey = ':query',
  apiDataKey = null,
  required,
  description,
  touched,
  value,
  hideLabel,
  hideErrorMessage,
}) => {
  const getOptions = useMemo(() => {
    return createGetOptions(optionsApiUrl, apiQueryKey, apiDataKey);
  }, [optionsApiUrl, apiQueryKey, apiDataKey]);

  const requiredField = required && <span className="text-danger"> *</span>;
  const showError = error && touched && !hideErrorMessage;
  const isInvalid = error && touched;
  return (
    <Form.Group controlId={controlId} className="Input">
      {label && (
        <Form.Label className={`Label ${hideLabel ? 'visuallyHidden' : ''}`}>
          {label}
          {requiredField}
        </Form.Label>
      )}

      <AsyncSelect
        id={controlId}
        isMulti={isMulti}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
        value={value}
        noOptionsMessage={() => 'Start typing for options..'}
        onChange={changed}
        loadOptions={getOptions}
        className={isInvalid ? 'invalid' : ''}
        isDisabled={disabled}
        placeholder={
          placeholder || `Please select ${isMulti ? 'one or more' : 'one'}`
        }
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#f57300',
            primary75: 'rgba(245, 115, 0, 0.75)',
            primary50: 'rgba(245, 115, 0, 0.5)',
            primary25: 'rgba(245, 115, 0, 0.25)',
          },
        })}
      />

      {description && !error && (
        <small className="formInputDescription">{description}</small>
      )}
      {showError ? <span className="formInputError">{error}</span> : null}
    </Form.Group>
  );
};

export default FormSelectAsync;
