import { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';

import PaywallApi from '../../../../api/Paywall';

import { getSchema, getFormFields } from './QualifierQuestionsForm.controls';

export const useQualifierQuestionsForm = ({
  initialData = {},
  setShowForm,
  onSuccess,
  setToast,
}) => {
  const [isUpdate, setIsUpdate] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [language, setLanguage] = useState('en');
  const [answers, setAnswers] = useState([]);
  const [checkLanguageChange, setCheckLanguageChange] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [validationSchema, setValidationSchema] = useState({});

  const getFormValues = useCallback(
    (_language, _answers) => {
      const translatedAnswers = _answers.length
        ? Object.assign(
            ..._answers.map((answer) => ({
              [answer.id]: _language
                ? answer.answerTranslations?.[_language] ?? ''
                : answer.value ?? '',
            })),
          )
        : { id: 'answer1', label: 'Answer 1', value: '' };

      return {
        questionType: initialData?.questionType ?? 'select',
        questionLabel: initialData?.questionTranslations?.[_language] ?? '',
        ...translatedAnswers,
      };
    },
    [initialData],
  );

  const [initialValues, setInitialValues] = useState(
    getFormValues(language, answers),
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const columnKeys = [
        'questionId',
        'primaryKey',
        'questionType',
        'questionLabel',
        'answerId',
        'answerLabel',
      ];

      const questionData = [
        initialData.id ?? 1,
        '',
        values.questionType,
        values.questionLabel,
      ];

      const csvString = `${columnKeys.join(',')}\r\n${answers
        .map((answer) => {
          return `${questionData.join(',')},${answer.id},${
            values[answer.id]
          }\r\n`;
        })
        .join('')}`;

      try {
        const response = await PaywallApi.addQuestions(csvString, language);

        if (response.error) {
          setToast({
            show: true,
            header:
              'An Error occurred please try again or contact a system administrator',
            class: 'error',
          });
          setLoading(false);

          return;
        }

        setToast({
          show: true,
          header: 'Question saved successfully',
          class: 'success',
        });

        setLoading(false);
        setShowForm(false);
        onSuccess();
        setLanguage('en');
      } catch (err) {
        setToast({
          show: true,
          header:
            'An Error occurred please try again or contact a system administrator',
          class: 'error',
        });
        console.error(err);
        setLoading(false);
      }
    },
  });

  // Initialize form fields and data
  useEffect(() => {
    const defaultLanguage = 'en';
    const _isUpdate = !!Object.keys(initialData).length;
    const _answers = initialData?.answers ?? [
      { id: 'answer1', label: 'Answer 1', value: '' },
    ];

    setInitialValues(getFormValues(defaultLanguage, _answers));

    setFormFields(
      getFormFields({
        answers: _answers,
        language: defaultLanguage,
        questionTranslations: initialData?.questionTranslations ?? {},
        disableQuestionType: _isUpdate,
      }),
    );

    setValidationSchema(getSchema(_answers));
    setAnswers(_answers);
    setLanguage(defaultLanguage);
    setIsUpdate(_isUpdate);
  }, [initialData, getFormValues]);

  const handleClose = () => {
    setShowForm(false);
    setLanguage('en');
  };

  const addAnswer = () => {
    const _answers = [
      ...answers,
      {
        id: `answer${answers.length + 1}`,
        label: `Answer ${answers.length + 1}`,
        isNewAnswer: true,
      },
    ];

    formik.resetForm({
      values: {
        [`answer${answers.length + 1}`]: '',
        ...formik.values,
      },
    });

    setAnswers(_answers);
    setValidationSchema(getSchema(_answers));
    setFormFields(
      getFormFields({
        answers: _answers,
        language,
        questionTranslations: initialData?.questionTranslations ?? {},
        disableQuestionType: !isUpdate,
      }),
    );
  };

  const removeAnswer = (answerId) => {
    const _answers = answers.filter((answer) => answer.id !== answerId);
    setAnswers(_answers);
    setValidationSchema(getSchema(_answers));

    const values = { ...formik.values };
    delete values[answerId];
    formik.resetForm({ values });

    setFormFields(
      getFormFields({
        answers: _answers,
        language,
        questionTranslations: initialData?.questionTranslations ?? {},
        disableQuestionType: !isUpdate,
      }),
    );
  };

  const updateLanguage = (_language, forceUpdate = false) => {
    if (Object.keys(formik.touched).length && !forceUpdate) {
      setCheckLanguageChange(_language);
      return;
    }

    formik.resetForm({
      values: getFormValues(_language, answers),
    });

    setLanguage(_language);

    setFormFields(
      getFormFields({
        answers,
        language: _language,
        questionTranslations: initialData?.questionTranslations ?? {},
        disableQuestionType: !isUpdate,
      }),
    );
  };

  const formikIsReady = formFields.every((field) =>
    Object.keys(formik.values).includes(field.key),
  );

  const getters = {
    isUpdate,
    isLoading,
    formik,
    checkLanguageChange,
    language,
    formFields,
    validationSchema,
    formikIsReady,
  };

  const actions = {
    submitForm: formik.submitForm,
    setCheckLanguageChange,
    updateLanguage,
    addAnswer,
    removeAnswer,
    handleClose,
  };

  return [getters, actions];
};
