import { object, string } from 'yup';
import { formatHttps } from '../../../../shared/inputFormatting';

export const validationSchema = object({
  name: string()
    .max(40, 'Must be less than 40 characters')
    .required('Please enter a hook name for reference'),
  endpoint: string()
    .matches(
      /((https):\/\/)(www.)?[a-z0-9-]+(\.[a-z0-9]{2,})?(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#_\-.]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'A valid endpoint is required (special chars allowed: #_-.)',
    )
    .required('Please enter an endpoint URL'),
  headers: string().required('Please add at least one credential'),
});

export const displayFields = [
  {
    key: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    key: 'endpoint',
    label: 'Secure Endpoint',
    type: 'text',
    elementConfig: {
      placeholder: 'https://domain.url',
    },
    formatOutput: formatHttps,
  },
  {
    key: 'headers',
    label: 'Authorization Headers',
    type: 'textarea',
    elementConfig: {
      placeholder: 'Authorization: Bearer abcd1234\nX-Custom-Header: Key',
      description:
        'Include any required security headers to establish connection',
      rows: 3,
    },
  },
];
