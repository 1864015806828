import React, { useState } from 'react';
import InputColor from 'react-input-color';

import './ColorPickerInput.scss';

const ColorPickerInput = ({
  name,
  onChange,
  value,
  dataTestAttribute = null,
}) => {
  const [color, setColor] = useState('#FFF');

  const onChangeHandle = (pickedColor) => {
    const newColor = pickedColor.hex;
    onChange({ target: { value: newColor, name } }, name);
    setColor(newColor);
  };
  const onChangeTextHandle = (e) => {
    const newColor = e.target.value;
    onChange({ target: { value: newColor, name } }, name);
    setColor(newColor);
  };

  return (
    <div
      className="d-flex align-items-center inputColorContainer"
      data-test-id={`${dataTestAttribute}-picker`}
    >
      <input disabled value={color} type="text" className="inputTextColor" />
      <InputColor
        className="inputColor"
        initialValue={value}
        onChange={onChangeHandle}
      />
    </div>
  );
};
export default ColorPickerInput;
