/**
 * Firebase Auth
 *
 * Api quick reference: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
 * Demo: https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/demo/src/index.js
 * Persistence: https://firebase.google.com/docs/auth/web/auth-state-persistence
 *
 * Auth implementation: src/components/Auth/Auth.js
 * Auth events: src/App.js
 */

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);
