import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import FileUploadInput from '../FileUploadInput/FileUploadInput';
import './BannerPickerInput.scss';

const BannerPickerInput = ({
  onChange,
  value,
  serviceType,
  dataTestAttribute = 'banner-picker-input',
}) => {
  const getImageUrl = (banner) =>
    `${process.env.REACT_APP_UPLOADS_URL}/${banner.image}`;

  const removeSlide = (index) => {
    const newSlides = value.map((slide) => ({ ...slide }));
    newSlides.splice(index, 1);
    onChange({ target: { value: newSlides } });
  };

  const reorderSlides = (result) => {
    const newSlides = value.map((banner) => ({ ...banner }));
    const [removed] = newSlides.splice(result.source.index, 1);
    newSlides.splice(result.destination.index, 0, removed);
    onChange({ target: { value: newSlides } });
  };

  const previewBanner = (banner) => {
    window.open(getImageUrl(banner));
  };

  const onSuccessUpload = (uploadPath) => {
    onChange({
      target: {
        value: [
          ...value,
          {
            id: uuidv4(),
            image: uploadPath || '',
            alt: '', // TODO: add a possibility to edit alt
            provider: serviceType,
            custom: true,
          },
        ],
      },
    });
  };

  const bannerCard = (banner, index, provided_) => (
    <Card
      className="bannerPickerCard"
      key={banner.id}
      ref={provided_.innerRef}
      {...provided_.draggableProps}
      {...provided_.dragHandleProps}
    >
      <Card.Body>
        <div
          className="bannerThumb"
          style={{ backgroundImage: `url(${getImageUrl(banner)})` }}
        />
        <div className="bannerAlt text-truncate">{banner.alt}</div>
        {banner?.custom && (
          <FontAwesomeIcon
            title="Custom banner"
            icon={faInfo}
            className="infoBanner"
          />
        )}
        <FontAwesomeIcon
          title="Preview banner in new window"
          icon={faEye}
          className="previewBanner"
          onClick={() => previewBanner(banner)}
        />
        <FontAwesomeIcon
          title="Remove banner"
          icon={faTimes}
          className="removeBanner"
          onClick={() => removeSlide(index)}
        />
      </Card.Body>
    </Card>
  );

  return (
    <div className="bannerPickerInput" data-test-id={dataTestAttribute}>
      <FileUploadInput
        buttonText="Upload"
        onFileUrlChange={onSuccessUpload}
        withPreview={false}
        dataTestAttribute="image-customBanner"
      />

      <DragDropContext onDragEnd={reorderSlides}>
        <Droppable droppableId="bannerPickerInput">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {value &&
                value.map((banner, index) => (
                  <Draggable
                    key={banner.id}
                    draggableId={banner.id}
                    index={index}
                  >
                    {(provided_) => bannerCard(banner, index, provided_)}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default BannerPickerInput;
