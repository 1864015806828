export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CLAIMS_SUCCESS = 'CLAIMS_SUCCESS';
export const LOCK_ACTIVATION = 'LOCK_ACTIVATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const MULTI_FACTOR = 'MULTI_FACTOR';
export const PHONE_VERIFIED = 'PHONE_VERIFIED';
export const GENERIC_UPDATE = 'GENERIC_UPDATE'; // Pass action.values for new values
export const SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS';

/* Providers */
export const FETCH_PROVIDERS_START = 'FETCH_PROVIDERS_START';
export const FETCH_PROVIDERS_SUCCCESS = 'FETCH_PROVIDERS_SUCCCESS';
export const FETCH_PROVIDERS_FAIL = 'FETCH_PROVIDERS_FAIL';
export const SET_PROVIDERS = 'SET_PROVIDERS';

/* Terms and Conditions */
export const SET_NEW_TERMS_AND_CONDITIONS = 'UPDATE_NEW_TERMS_AND_CONDITIONS';
export const RESET_TERMS_AND_CONDITIONS = 'RESET_TERMS_AND_CONDITIONS';
export const SET_ERROR_ACCESSING_CAMPAIGN = 'SET_ERROR_ACCESSING_CAMPAIGN';
