/* eslint-disable no-param-reassign */
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import {
  CampaignDataContext,
  CampaignCallbackContext,
} from '../CampaignContext';
import FormInputs from './FormInputs/FormInputs';

import controls from './controls';
import './CampaignForm.scss';
import ToggleSwitchInput from '../../../components/UI/ToggleSwitchInput/ToggleSwitchInput';
import CampaignStatus from '../../../components/Campaign/CampaignStatus/CampaignStatus';

const renderFormInputs = (
  campaignId,
  options,
  isPaywallEnabled,
  isFallbackCampaign,
  isGlobalFallback,
  isProtected,
  isAdmin,
  isBountyAdmin,
) => {
  let keyNumber = 0;
  const filteredControls = isProtected
    ? controls
    : controls.map((obj) => {
        const { signature, decipherKey, ...rest } = obj;
        return rest;
      });

  return filteredControls.map((el) => {
    keyNumber += 1;
    if (el.clientBrand) {
      el.clientBrand.elementConfig.options = options.brands;
    }

    if (el.company) {
      el.company.elementConfig.options = options.companies;
    }

    if (el.tags) {
      el.tags.elementConfig.options = options.tags;

      return (
        (isAdmin || isBountyAdmin) && (
          <FormInputs
            key={`${campaignId}-formInputs-${keyNumber}`}
            isPaywallEnabled={isPaywallEnabled}
            isFallbackCampaign={isFallbackCampaign}
            isGlobalFallback={isGlobalFallback}
            controls={el}
            options={options}
          />
        )
      );
    }

    if (el.maxPerConnection) {
      el.maxPerConnection.validation = {
        isMoney: true,
        required: isPaywallEnabled,
      };
    }

    return (
      <FormInputs
        key={`${campaignId}-formInputs-${keyNumber}`}
        isPaywallEnabled={isPaywallEnabled}
        isFallbackCampaign={isFallbackCampaign}
        isGlobalFallback={isGlobalFallback}
        controls={el}
        options={options}
      />
    );
  });
};

const CampaignForm = ({
  campaignId,
  data,
  setFormData,
  onFormChanged,
  isAdmin,
  isBountyAdmin,
  options = {},
  className = '',
  formTitle = '',
  isFallbackCampaign = false,
  isGlobalFallback = false,
  campaignStatus,
}) => {
  const isPaywallEnabled = data.data?.paywall?.enabled;
  const isProtected =
    data?.data?.protected !== undefined ? data.data.protected : false;
  const videoProvider = data?.data?.videoProvider;

  return (
    <Row className={className}>
      <Col xs={8}>
        {formTitle && (
          <h1
            className={`${
              videoProvider ? 'mb-0' : 'mb-4'
            } d-flex align-items-center gap-3`}
          >
            {formTitle} <CampaignStatus campaignStatusData={campaignStatus} />
          </h1>
        )}
      </Col>
      <Col xs={8}>
        {videoProvider && (
          <div className="provider-link">
            <span>Provider: </span>
            <Link
              className="link-bm-orange"
              to={`/offers/edit/${videoProvider.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {videoProvider.name}
            </Link>
          </div>
        )}
      </Col>
      <Col xs={12} className="campaignEditForm">
        <CampaignDataContext.Provider value={data}>
          <CampaignCallbackContext.Provider value={onFormChanged}>
            <form data-test-id="campaign-edit-form">
              {(isAdmin || isBountyAdmin) && (
                <ToggleSwitchInput
                  label="Is this campaign protected?"
                  text={data.data.protected ? 'Yes' : 'No'}
                  value={data.data.protected}
                  onCheck={(bool) => {
                    setFormData((state) => {
                      const stateData = state.data;
                      stateData.protected = bool;
                      return {
                        ...state,
                      };
                    });
                  }}
                />
              )}
              {renderFormInputs(
                campaignId,
                options,
                isPaywallEnabled,
                isFallbackCampaign,
                isGlobalFallback,
                isProtected,
                isAdmin,
                isBountyAdmin,
              )}
            </form>
          </CampaignCallbackContext.Provider>
        </CampaignDataContext.Provider>
      </Col>
    </Row>
  );
};

export default CampaignForm;
